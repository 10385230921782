var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "media-library-field" }, [
        _vm.loading
          ? _c(
              "div",
              { staticClass: "media-library-field__loader" },
              [_c("app-medialibrary-loader")],
              1
            )
          : _vm.content == null
          ? _c("div", { staticClass: "media-library-field__selector" }, [
              _c(
                "span",
                {
                  staticClass: "media-library-field__selector__button",
                  on: {
                    click: function($event) {
                      _vm.showManager = true
                    }
                  }
                },
                [
                  _vm._v(
                    "Attach " +
                      _vm._s(
                        _vm.types.images && _vm.types.files
                          ? "file"
                          : _vm.types.images && !_vm.types.files
                          ? "image"
                          : "file"
                      )
                  )
                ]
              ),
              _vm._v(" "),
              _vm.helper
                ? _c("p", {
                    staticClass: "media-library-field__selector__helper",
                    domProps: { innerHTML: _vm._s(_vm.helper) }
                  })
                : _vm._e()
            ])
          : _vm.file
          ? _c("div", { staticClass: "media-library-field__selected" }, [
              _c(
                "div",
                { staticClass: "media-library-field__selected__inner" },
                [
                  _vm.file.collection_name == "images"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "media-library-field__selected__inner__img",
                          on: {
                            click: function($event) {
                              _vm.showManager = true
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "media-library-field__selected__inner__img__frame"
                            },
                            [
                              _c("div", {
                                staticClass:
                                  "media-library-field__selected__inner__img__frame__image",
                                style:
                                  "background-image: url('" +
                                  _vm.file.dataUrl +
                                  "');"
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(0)
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "media-library-field__selected__inner__details"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "media-library-field__selected__inner__details__name"
                        },
                        [_vm._v(_vm._s(_vm.file.file_name))]
                      ),
                      _vm._v(" "),
                      _vm.file.collection_name == "images"
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "media-library-field__selected__inner__details__dimensions"
                            },
                            [
                              _vm._v(
                                "Dimensions: " +
                                  _vm._s(_vm.file.image.width) +
                                  " × " +
                                  _vm._s(_vm.file.image.height)
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "media-library-field__selected__inner__details__edit",
                          on: {
                            click: function($event) {
                              _vm.showManager = true
                            }
                          }
                        },
                        [_vm._v("Edit info")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            margin: "2rem -0.5rem 0 -0.5rem"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "flex-grow": "1",
                                padding: "0 0.5rem"
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "media-library-field__selected__inner__details__button",
                                  attrs: { href: _vm.file.downloadUrl }
                                },
                                [_vm._v("Download")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "flex-grow": "1",
                                padding: "0 0.5rem"
                              }
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "media-library-field__selected__inner__details__button media-library-field__selected__inner__details__button--delete",
                                  on: { click: _vm.clear }
                                },
                                [_vm._v("Remove")]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.helper
                        ? _c("p", {
                            staticClass:
                              "media-library-field__selected__inner__details__helper",
                            domProps: { innerHTML: _vm._s(_vm.helper) }
                          })
                        : _vm._e()
                    ]
                  )
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("vue-medialibrary-manager", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showManager,
            expression: "showManager"
          }
        ],
        attrs: {
          filetypes: _vm.filetypes,
          types: _vm.types,
          selected: _vm.value,
          selectable: true
        },
        on: {
          close: function($event) {
            _vm.showManager = false
          },
          select: _vm.insert,
          "fail-to-find": _vm.clear
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "media-library-field__selected__inner__img__edit" },
      [_c("span", [_vm._v("Edit")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }