var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    slot: "item-title",
                    to: "/admin/sites/" + this.site
                  },
                  slot: "item-title"
                },
                [_vm._v("Vezérlőpult")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                {
                  attrs: { slot: "item-title", to: "/admin/posts" },
                  slot: "item-title"
                },
                [_vm._v("Bejegyzések")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v("Új bejegyzés")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("h1", [_vm._v("Új bejegyzés")]),
          _vm._v(" "),
          _c("div", { staticClass: "btn-group ml-auto" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-success",
                attrs: { form: "postEditForm" }
              },
              [
                !_vm.saving
                  ? _c("i", { staticClass: "icon-fa icon-fa-save" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.saving
                  ? _c("i", {
                      staticClass: "icon-fa icon-fa-circle-o-notch icon-fa-spin"
                    })
                  : _vm._e(),
                _vm._v("\n                    Mentés\n                ")
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("post-editor", {
        attrs: { original: _vm.post, save: _vm.saveCallback },
        on: { saving: _vm.onSaveStart }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }