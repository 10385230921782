var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/sites/" + this.site },
                slot: "item-title"
              },
              [_vm._v("Vezérlőpult")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/contacts" },
                slot: "item-title"
              },
              [_vm._v("Megrendelők")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Megrendelő adatlap")
        ])
      ]),
      _vm._v(" "),
      _vm.contact
        ? _c("h1", { staticClass: "d-inline mr-3" }, [
            _vm._v(_vm._s(_vm.contact.name))
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    !_vm.contact
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", {
            staticClass:
              "icon-fa icon-fa-circle-o-notch icon-fa-spin icon-fa-4x"
          })
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.contact
      ? _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "tabs",
                { staticClass: "tabs-simple" },
                [
                  _c("tab", { attrs: { id: "data", name: "Adatok" } }, [
                    _c("table", { staticClass: "table table-striped" }, [
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("Név")]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(_vm.contact.name))])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("th", [_vm._v("Email cím")]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(_vm.contact.email))])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("th", [_vm._v("Telefonszám")]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(_vm.contact.phone))])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("th", [_vm._v("Cím")]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(_vm.contact.address))])
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tab", { attrs: { id: "orders", name: "Rendelések" } }, [
                    _c("table", { staticClass: "table table-striped" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("Azonosító")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Státusz")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Dátum")])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.contact.relations.orders, function(order) {
                          return _c("tr", { key: order.id }, [
                            _c(
                              "td",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: { to: "/admin/orders/" + order.id }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(order.code) +
                                        "\n                                    "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.getStatus(order.relations.status))
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.formatUtcDate(order.created_at))
                              )
                            ])
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }