var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.validateBeforeSubmit($event)
        }
      }
    },
    [
      _c(
        "div",
        {
          class: ["form-group", { "is-invalid": _vm.$v.loginData.email.$error }]
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.loginData.email,
                expression: "loginData.email",
                modifiers: { trim: true }
              }
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.$v.loginData.email.$error },
            attrs: { placeholder: "Enter Email", type: "email" },
            domProps: { value: _vm.loginData.email },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.loginData, "email", $event.target.value.trim())
                },
                function($event) {
                  return _vm.$v.loginData.email.$touch()
                }
              ],
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          !_vm.$v.loginData.email.required
            ? _c("span", { staticClass: "invalid-feedback" }, [
                _vm._v("\n      Kötelező megadni az email címet\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.$v.loginData.email.email
            ? _c("span", { staticClass: "invalid-feedback" }, [
                _vm._v("\n      Hibás email cím\n    ")
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "form-group",
            { "is-invalid": _vm.$v.loginData.password.$error }
          ]
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.loginData.password,
                expression: "loginData.password",
                modifiers: { trim: true }
              }
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.$v.loginData.password.$error },
            attrs: { placeholder: "Enter Password", type: "password" },
            domProps: { value: _vm.loginData.password },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.loginData,
                    "password",
                    $event.target.value.trim()
                  )
                },
                function($event) {
                  return _vm.$v.loginData.password.$touch()
                }
              ],
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          !_vm.$v.loginData.password.required
            ? _c("span", { staticClass: "invalid-feedback" }, [
                _vm._v("\n      Kötelező megadni a jelszót\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.$v.loginData.password.minLength
            ? _c("span", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n      A jelszó legalább " +
                    _vm._s(_vm.$v.loginData.password.$params.minLength.min) +
                    " karakter hosszú.\n    "
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "other-actions row" }, [
        _c("div", { staticClass: "col" }, [
          _c("div", { staticClass: "checkbox" }, [
            _c("label", { staticClass: "c-input c-checkbox" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.loginData.remember,
                    expression: "loginData.remember"
                  }
                ],
                attrs: { type: "checkbox", name: "remember" },
                domProps: {
                  checked: Array.isArray(_vm.loginData.remember)
                    ? _vm._i(_vm.loginData.remember, null) > -1
                    : _vm.loginData.remember
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.loginData.remember,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.loginData, "remember", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.loginData,
                            "remember",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.loginData, "remember", $$c)
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "c-indicator" }),
              _vm._v("\n          Bejelentkezve maradok\n        ")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "btn btn-theme btn-full" }, [
        _vm._v("Bejelentkezés")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }