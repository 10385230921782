var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _vm._m(1),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c(
            "table-component",
            {
              attrs: {
                data: [
                  {
                    firstName: "John",
                    lastName: "Lennon",
                    instrument: "Guitar",
                    birthday: "04/10/1940",
                    songs: 72
                  },
                  {
                    firstName: "Paul",
                    lastName: "McCartney",
                    instrument: "Bass",
                    birthday: "18/06/1942",
                    songs: 70
                  },
                  {
                    firstName: "George",
                    lastName: "Harrison",
                    instrument: "Guitar",
                    birthday: "25/02/1943",
                    songs: 22
                  },
                  {
                    firstName: "Ringo",
                    lastName: "Starr",
                    instrument: "Drums",
                    birthday: "07/07/1940",
                    songs: 2
                  }
                ],
                "table-class": "table",
                "sort-by": "songs",
                "sort-order": "asc"
              }
            },
            [
              _c("table-column", {
                attrs: { show: "firstName", label: "First name" }
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { show: "lastName", label: "Last name" }
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { show: "instrument", label: "Instrument" }
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { show: "songs", label: "Songs", "data-type": "numeric" }
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: {
                  show: "birthday",
                  label: "Birthday",
                  "data-type": "date:DD/MM/YYYY"
                }
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { sortable: false, filterable: false, label: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("div", { staticClass: "table__actions" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-default btn-sm",
                              attrs: { href: "/admin/conversations/243/edit" }
                            },
                            [_vm._v("\n                Edit\n              ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-default btn-sm",
                              attrs: { href: "/admin/conversations/243/edit" }
                            },
                            [_vm._v("\n                Delete\n              ")]
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _vm._m(2),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c(
            "table-component",
            { attrs: { data: _vm.fetchData, "table-class": "table" } },
            [
              _c("table-column", { attrs: { show: "name", label: "Name" } }),
              _vm._v(" "),
              _c("table-column", { attrs: { show: "email", label: "Email" } }),
              _vm._v(" "),
              _c("table-column", { attrs: { show: "role", label: "Role" } }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { show: "created_at", label: "Registered On" }
              }),
              _vm._v(" "),
              _c("table-column", {
                attrs: { sortable: false, filterable: false, label: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("div", { staticClass: "table__actions" }, [
                          _c("a", { attrs: { href: "#" + row.name } }, [
                            _vm._v("View")
                          ]),
                          _vm._v(" "),
                          _c("a", { attrs: { href: "#" + row.name } }, [
                            _vm._v("Edit")
                          ]),
                          _vm._v(" "),
                          _c("a", { attrs: { href: "#" + row.name } }, [
                            _vm._v("Delete")
                          ])
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Vue Datatables")]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Components")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Vue Datatables")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [_vm._v("Default")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [_vm._v("Asynchronous Pagination")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }