<template>
    <div class="card my-1 mx-3" v-if="product.id">

        <div class="card-body">
            <div class="row">
                <div class="col">
                    <tabs class="tabs-simple">
                        <tab id="stock" name="Készlet kezelése" v-if="product.relations.stock.enabled">
                            <h3>Aktuális készlet: {{ product.relations.stock.value }} {{ product.relations.stock.track_unit }}</h3>

                            <div class="form-group">
                                <label for="updateStockInput">Készlet módosítása a megadott mennyiséggel</label>
                                <input type="number"
                                    id="updateStockInput"
                                    class="form-control"
                                    min="0"
                                    v-model="updateStockWith">
                            </div>

                            <div class="btn-group btn-block">
                                <button class="btn btn-sm btn-danger"
                                    type="button"
                                    :disabled="updateStockWith <= 0 || (product.relations.stock.value-updateStockWith <= 0)"
                                    @click="updateStock('decrease')">
                                    <i class="icon-fa icon-fa-minus"></i>
                                    Csökkentés
                                </button>

                                <button class="btn btn-sm btn-success"
                                    type="button"
                                    :disabled="updateStockWith <= 0"
                                    @click="updateStock('increase')">
                                    <i class="icon-fa icon-fa-plus"></i>
                                    Növelés
                                </button>
                            </div>
                        </tab>

                        <tab id="settings" name="Beállítások">
                            <div>
                                <div class="custom-control custom-checkbox mb-3">
                                    <input id="stockEnabledInput"
                                        type="checkbox"
                                        class="custom-control-input"
                                        v-model="product.relations.stock.enabled">

                                    <label class="custom-control-label" for="stockEnabledInput">
                                        Készletkezelés engedélyezés a terméknél
                                    </label>
                                </div>

                                <div class="form-group">
                                    <label for="trackByInput">
                                        Követés tulajdonság alapján
                                        <span v-tooltip="`A tulajdonságok lapon megadott opciók közül lehet választani,
                                            valamint lehetőség van darabszám alapú követésre.
                                            Tulajdonság alapú követésnél az ott beállított mértékegység alapján fog számolni a rendszer
                                            és a manuális készletfrissítésnél is azzal a mértékegysággel kell megadni a változásokat.`">
                                            <i class="icon-fa icon-fa-question-circle"></i>
                                        </span>

                                        <span v-if="product.relations.stock.value!=0"
                                            v-tooltip="'Csak 0 készlettel módosítható a használt tulajdonság'">
                                            <i class="icon-fa icon-fa-exclamation-triangle text-warning"></i>
                                        </span>
                                    </label>
                                    <select id="trackByInput"
                                        class="form-control"
                                        v-model="product.relations.stock.track_by"
                                        :disabled="product.relations.stock.value!=0">
                                        <option v-for="attribute in product.relations.stock.product_attributes"
                                            :key="attribute"
                                            :value="attribute">
                                            {{ attribute }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </tab>
                    </tabs>
                </div>

                <div class="col">
                    <h3>Készlet változásai</h3>

                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th>Mennyiség</th>
                                <th>Link</th>
                                <th>Időpont</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="change in product.relations.stockChanges" :key="change.id">
                                <td>{{ change.amount }}{{ change.unit }}</td>
                                <td>
                                    <router-link v-if="change.order_id" :to="`/admin/orders/${change.order_id}`">
                                        Rendelés #{{ change.order_id }}
                                    </router-link>

                                    <router-link v-else-if="change.user" :to="`/admin/users/${change.user_id}`">
                                        {{ change.user.name }}
                                    </router-link>

                                    <span v-else>&mdash;</span>
                                </td>
                                <td>{{ change.created_at }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>



        </div>
    </div>
</template>

<script>
import store from '../services/vuex'
import { Tabs, Tab } from 'vue-tabs-component'

export default {
    components: {
        Tab,
        Tabs,
    },
    props: {
        product: { type: [Object], required: true },
    },
    data(){
        return {
            updateStockWith: 0,
        }
    },
    methods: {
        updateStock(mode){
            store.dispatch('products/updateStock', {
                id: this.product.id,
                quantity: mode=='increase' ? this.updateStockWith : this.updateStockWith * -1,
            })
        }
    },
    watch: {
        "product.relations.stock": {
            handler(stock){
                this.$emit('updateStock', stock)
            },
            deep: true
        }
    }
}
</script>

<style>

</style>
