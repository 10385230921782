var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content vue-dropzone" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("Simple")
              ]),
              _vm._v(" "),
              _c("vue-dropzone", {
                ref: "myVueDropzone",
                attrs: { id: "dropzone", options: _vm.dropzoneOptions1 }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-4" }, [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("with Remove Links")
              ]),
              _vm._v(" "),
              _c("vue-dropzone", {
                ref: "myVueDropzone",
                attrs: { id: "dropzone", options: _vm.dropzoneOptions2 }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-4" }, [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("with Icon Description")
              ]),
              _vm._v(" "),
              _c("vue-dropzone", {
                ref: "myVueDropzone",
                attrs: { id: "dropzone", options: _vm.dropzoneOptions3 }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-4 manual-demo" }, [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("Manually add file")
              ]),
              _vm._v(" "),
              _c("vue-dropzone", {
                ref: "myVueDropzone",
                attrs: { id: "dropzone", options: _vm.dropzoneOptions2 }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mt-4",
                  on: { click: _vm.AddFile }
                },
                [_vm._v("Add File")]
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Vue Dropzone")]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Components")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Vue Dropzone")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }