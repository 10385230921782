var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/sites/" + this.site },
                slot: "item-title"
              },
              [_vm._v("Vezérlőpult")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Termékek")
        ])
      ]),
      _vm._v(" "),
      _c("h1", { staticClass: "d-inline mr-3" }, [_vm._v("Termékek")]),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-outline-info btn-sm align-text-bottom" },
        [
          _c("router-link", { attrs: { to: "./products/create" } }, [
            _c("i", { staticClass: "icon-fa icon-fa-plus" }),
            _vm._v(" Új hozzáadása\n            ")
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("cool-table", {
            attrs: {
              fields: _vm.fields,
              "data-source": _vm.dataSource,
              textfilter: true,
              selectfilters: _vm.filters
            },
            scopedSlots: _vm._u([
              {
                key: "product_type",
                fn: function(props) {
                  return [
                    _c("i", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: {
                            content:
                              _vm.types[props.rowData.product_type].name +
                              " termék"
                          },
                          expression:
                            "{content: `${types[props.rowData.product_type].name} termék` }"
                        }
                      ],
                      staticClass: "icon-fa icon-fa-fw",
                      class: _vm.types[props.rowData.product_type].icon
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "d-none d-md-inline" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.types[props.rowData.product_type].name) +
                          "\n                    "
                      )
                    ])
                  ]
                }
              },
              {
                key: "title",
                fn: function(props) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: "/admin/products/" + props.rowData.id + "/edit"
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(props.rowData.title) +
                            "\n                    "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }