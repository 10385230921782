<template>
    <div class="mx-3 my-1">
        <div class="alert alert-danger" v-if="current==null">
            Nincs beállítva ár ehhez a termékhez!
        </div>

        <div class="alert alert-info" v-if="current!=null">
            Termék aktuális ára: <strong>{{ current.price }} Ft</strong>
        </div>

        <div class="alert alert-info" v-if="current!=null && product.relations.sale">
            A termék szerepel a
            <router-link :to="`/admin/sales/${product.relations.sale.id}`" class="alert-link">
                {{ product.relations.sale.name }}
            </router-link>
            nevű akcióban.
            Az ár <strong>{{ product.relations.sale.discount }} {{ product.relations.sale.type }}</strong> kedvezmény után:
            <strong>{{ product.relations.sale.price }} Ft</strong>
        </div>

        <div id="newPriceForm">
            <button class="my-2 btn btn-sm btn-primary"
                type="button"
                @click="showNewPriceCollapse()"
                v-if="!showNewPriceForm">
                <i class="icon-fa icon-fa-plus"></i>
                Új ár ütemezése
            </button>

            <div class="btn-group" v-if="showNewPriceForm">
                <button class="my-2 btn btn-sm btn-success"
                    type="button"
                    @click="saveNewPrice">
                    <i class="icon-fa icon-fa-save"></i>
                    Mentés
                </button>
                <button class="my-2 btn btn-sm btn-danger"
                    type="button"
                    @click="showNewPriceForm=false">
                    <i class="icon-fa icon-fa-times"></i>
                    Mégse
                </button>
            </div>

            <div class="row" v-if="showNewPriceForm">
                <div class="col">
                    <div class="form-group">
                        <label for="priceInput">Ár</label>
                        <input type="number"
                            min="1"
                            id="priceInput"
                            class="form-control"
                            :class="{'is-invalid': price.price < 1}"
                            v-model="price.price">
                        <div class="invalid-feedback">Érvénytelen ár</div>
                    </div>

                    <div class="form-group">
                        <label>Aktiválás ekkor</label>

                        <datetime type="datetime"
                            v-model="price.published_at"
                            :min-datetime="(new Date).toISOString()"
                            format="yyyy-MM-dd HH:mm"
                            input-class="form-control">
                        </datetime>
                    </div>
                </div>

                <div class="col">
                    <div class="form-group">
                        <label for="noteInput">Megjegyzés</label>
                        <textarea id="noteInput"
                            class="form-control"
                            style="height:125px"
                            v-model="price.note"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <table class="table mt-2"
            v-if="prices.length">
            <thead>
                <tr>
                    <th>Ár</th>
                    <th>Aktív ettől</th>
                    <th>Ki ütemezte</th>
                </tr>
            </thead>

            <tbody v-for="(price, key) in prices"
                :key="price.id"
                :class="{
                    'bg-stripe': key%2!=0,
                    'bg-success': current && price.id==current.id
                }"
                v-tooltip="current && price.id==current.id ? 'Aktuális ár' : ''">
                <tr>
                    <td>{{ price.price }} Ft</td>
                    <td>{{ formatUtcDate(price.published_at) }}</td>
                    <td>{{ price.user.name }}</td>
                </tr>
                <tr v-if="price.note != ''">
                    <td colspan="3">{{ price.note }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

export default {
    props: {
        prices: { type: [Array], default: () => [] },
        current: { type: [Object] },
        product: { type: [Object] },
        variation: { type: [Object], default: null },
    },
    components: {
        Datetime,
    },
    data () {
        return {
            showNewPriceForm: false,
            price: {
               price: 1,
               note: '',
               published_at: null,
            },
            updatedCurrent: null,
        }
    },
    methods: {
        showNewPriceCollapse(){
            this.showNewPriceForm = true
            this.price.note = ''
            this.price.published_at = (new Date).toISOString()
            this.price.price = this.current
                ? this.current.price
                : 1
        },
        saveNewPrice(){
            if(this.variation){
                this.price.variation = this.variation.id
            }

            axios.post(`/api/admin/publications/${this.product.id}/prices`, this.price)
                .then(result => {
                    this.$emit('updateCurrent', result.data.data, this.variation)
                    this.prices.unshift(result.data.data)
                    toastr.success('Az új árat sikeresen rögzítettük', 'Siker')
                    this.showNewPriceForm = false
                })
                .catch(err => {
                    toastr.error('Nem sikerült menteni az árat', 'Hiba')
                })
        },
    }
}
</script>

<style>
.bg-stripe{
    background: rgba(0, 0, 0, .05) !important;
}
</style>
