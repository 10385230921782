<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item active">Rendelések</li>
            </ol>
            <h1 class="d-inline mr-3">Rendelések</h1>
        </div>
        <div class="card">
            <div class="card-body">
                <cool-table :fields="fields"
                    :data-source="dataSource"
                    :textfilter="true"
                    :selectfilters="selectFilters">
                    <template slot="code" slot-scope="props">
                        <router-link :to="`/admin/orders/${props.rowData.id}`">
                            {{ props.rowData.code ? props.rowData.code : 'Nincs rendelésszám!' }}
                        </router-link>
                    </template>

                    <template slot="contact" slot-scope="props">
                        {{ props.rowData.relations.contact.name }}
                    </template>

                    <template slot="status" slot-scope="props" v-if="props.rowData.relations.status">
                        {{ getStatus(props.rowData.relations.status.status) }}
                        <span v-if="props.rowData.relations.statusWarning"
                            v-tooltip="getStatusWarning(props.rowData)">
                            <i class="icon-fa icon-fa-clock-o text-danger"></i>
                        </span>
                    </template>

                    <template slot="created_at" slot-scope="props">
                        {{ formatUtcDate(props.rowData.created_at) }}
                    </template>
                </cool-table>
            </div>
        </div>
    </div>
</template>

<script>
import ls from '../../services/ls'
import CoolTable from '../../components/CoolTable'

export default {
    components: {
        CoolTable
    },
    data () {
        return {
            site: null,
            fields: [
                {
                    name: 'code',
                    title: 'Azonosító'
                },
                {
                    name: 'status',
                    title: 'Státusz',
                    sortField: 'status',
                },
                {
                    name: 'contact',
                    title: 'Megrendelő',
                    sortField: 'contact'
                },
                {
                    name: 'created_at',
                    title: 'Rendelés ideje',
                    sortField: 'created_at'
                }
            ],
            selectFilters: {
                status: {
                    name: 'Státusz',
                    value: -1,
                    options: {
                        '-1': 'Mindegy',
                        '0': 'Új rendelés',
                        '1': 'Egyeztetés alatt',
                        '2': 'Ajánlatkészítés',
                        '3': 'Ajánlat kiküldve',
                        '4': 'Ajánlat elfogadva',
                        '5': 'Kifizetve',
                        '6': 'Sikeresen lezárult',
                        '7': 'Sikertelenül lezárult',
                    }
                },
            },
            statuses: {
                0: 'Új rendelés',
                1: 'Egyeztetés alatt',
                2: 'Ajánlatkészítés',
                3: 'Ajánlat kiküldve',
                4: 'Ajánlat elfogadva',
                5: 'Fizetve',
                6: 'Sikeresen lezárult',
                7: 'Meghiúsult rendelés',
            },
        }
    },
    methods: {
        dataSource (sortOrder, pagination, filters) {
            var sort = {
                field: sortOrder[0] ? sortOrder[0].sortField : 'created_at',
                direction: sortOrder[0] ? sortOrder[0].direction : 'desc',
            };

            return axios.get('/api/admin/orders', {
                params: {
                    with: 'contact,status,statusWarning',
                    page: pagination.current_page,
                    filters: {
                        site: this.site,
                        ...filters,
                    },
                    sort: sort.field,
                    order: sort.direction
                }
            })
        },
        getStatus(status){
            return this.statuses[status]
        },
        getStatusWarning(order){
            let warning = order.relations.statusWarning

            if(!warning){
                return ''
            }

            let target = this.statuses[warning.target]

            return `A megrendelést ${warning.age} napja a "${target}" státuszra kellett volna váltani, kérlek ellenőrizd!`
        }
    },
    created() {
        this.site = ls.get('site')
    }
}
</script>

<style>

</style>
