var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main-content" }, [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [_vm._v("Progress Bar")]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Basic UI")])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v("Progress Bar")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _vm._v("Basic Progress Bars")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "progress" }, [
                _c("div", {
                  staticClass: "progress-bar",
                  attrs: {
                    role: "progressbar",
                    "aria-valuenow": "0",
                    "aria-valuemin": "0",
                    "aria-valuemax": "100"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "progress" }, [
                _c("div", {
                  staticClass: "progress-bar",
                  staticStyle: { width: "25%" },
                  attrs: {
                    role: "progressbar",
                    "aria-valuenow": "25",
                    "aria-valuemin": "0",
                    "aria-valuemax": "100"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "progress" }, [
                _c(
                  "div",
                  {
                    staticClass: "progress-bar",
                    staticStyle: { width: "50%" },
                    attrs: {
                      role: "progressbar",
                      "aria-valuenow": "50",
                      "aria-valuemin": "0",
                      "aria-valuemax": "100"
                    }
                  },
                  [_vm._v("\n              50%\n            ")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "progress" }, [
                _c("div", {
                  staticClass: "progress-bar",
                  staticStyle: { width: "75%" },
                  attrs: {
                    role: "progressbar",
                    "aria-valuenow": "75",
                    "aria-valuemin": "0",
                    "aria-valuemax": "100"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "progress" }, [
                _c(
                  "div",
                  {
                    staticClass: "progress-bar",
                    staticStyle: { width: "100%" },
                    attrs: {
                      role: "progressbar",
                      "aria-valuenow": "100",
                      "aria-valuemin": "0",
                      "aria-valuemax": "100"
                    }
                  },
                  [_vm._v("\n              100%\n            ")]
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _vm._v("Progress Bars Colored")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "progress" }, [
                _c("div", {
                  staticClass: "progress-bar bg-success",
                  staticStyle: { width: "25%" },
                  attrs: {
                    role: "progressbar",
                    "aria-valuenow": "25",
                    "aria-valuemin": "0",
                    "aria-valuemax": "100"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "progress" }, [
                _c("div", {
                  staticClass: "progress-bar bg-info",
                  staticStyle: { width: "50%" },
                  attrs: {
                    role: "progressbar",
                    "aria-valuenow": "50",
                    "aria-valuemin": "0",
                    "aria-valuemax": "100"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "progress" }, [
                _c("div", {
                  staticClass: "progress-bar bg-warning",
                  staticStyle: { width: "75%" },
                  attrs: {
                    role: "progressbar",
                    "aria-valuenow": "75",
                    "aria-valuemin": "0",
                    "aria-valuemax": "100"
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "progress" }, [
                _c("div", {
                  staticClass: "progress-bar bg-danger",
                  staticStyle: { width: "100%" },
                  attrs: {
                    role: "progressbar",
                    "aria-valuenow": "100",
                    "aria-valuemin": "0",
                    "aria-valuemax": "100"
                  }
                })
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _vm._v("Multiple Progress Bars")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "progress" }, [
            _c("div", {
              staticClass: "progress-bar",
              staticStyle: { width: "15%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": "15",
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "progress-bar bg-success",
              staticStyle: { width: "30%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": "30",
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "progress-bar bg-info",
              staticStyle: { width: "20%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": "20",
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _vm._v("Custom Height Progress Bars")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "progress", staticStyle: { height: "1px" } },
            [
              _c("div", {
                staticClass: "progress-bar",
                staticStyle: { width: "25%" },
                attrs: {
                  role: "progressbar",
                  "aria-valuenow": "25",
                  "aria-valuemin": "0",
                  "aria-valuemax": "100"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "progress", staticStyle: { height: "20px" } },
            [
              _c("div", {
                staticClass: "progress-bar",
                staticStyle: { width: "25%" },
                attrs: {
                  role: "progressbar",
                  "aria-valuenow": "25",
                  "aria-valuemin": "0",
                  "aria-valuemax": "100"
                }
              })
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _vm._v("Striped Progress Bars")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "progress" }, [
            _c("div", {
              staticClass: "progress-bar progress-bar-striped",
              staticStyle: { width: "10%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": "10",
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "progress" }, [
            _c("div", {
              staticClass: "progress-bar progress-bar-striped bg-success",
              staticStyle: { width: "25%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": "25",
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "progress" }, [
            _c("div", {
              staticClass: "progress-bar progress-bar-striped bg-info",
              staticStyle: { width: "50%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": "50",
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "progress" }, [
            _c("div", {
              staticClass: "progress-bar progress-bar-striped bg-warning",
              staticStyle: { width: "75%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": "75",
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "progress" }, [
            _c("div", {
              staticClass: "progress-bar progress-bar-striped bg-danger",
              staticStyle: { width: "100%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": "100",
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _vm._v("Animated Striped Progress Bars")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "progress" }, [
            _c("div", {
              staticClass:
                "progress-bar progress-bar-striped progress-bar-animated",
              staticStyle: { width: "10%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": "10",
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "progress" }, [
            _c("div", {
              staticClass:
                "progress-bar progress-bar-striped bg-success progress-bar-animated",
              staticStyle: { width: "25%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": "25",
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "progress" }, [
            _c("div", {
              staticClass:
                "progress-bar progress-bar-striped bg-info progress-bar-animated",
              staticStyle: { width: "50%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": "50",
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "progress" }, [
            _c("div", {
              staticClass:
                "progress-bar progress-bar-striped bg-warning progress-bar-animated",
              staticStyle: { width: "75%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": "75",
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "progress" }, [
            _c("div", {
              staticClass:
                "progress-bar progress-bar-striped bg-danger progress-bar-animated",
              staticStyle: { width: "100%" },
              attrs: {
                role: "progressbar",
                "aria-valuenow": "100",
                "aria-valuemin": "0",
                "aria-valuemax": "100"
              }
            })
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }