<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item"><router-link slot="item-title" to="/admin/sales">Akciók</router-link></li>
                <li class="breadcrumb-item active">Új akció</li>
            </ol>
            <div class="d-flex align-items-center">
                <h1>Akció létrehozása</h1>

                <div class="btn-group ml-auto">
                    <button class="btn btn-sm btn-success" form="saleEditForm">
                        <i class="icon-fa icon-fa-save" v-if="!saving"></i>
                        <i class="icon-fa icon-fa-circle-o-notch icon-fa-spin" v-if="saving"></i>
                        Mentés
                    </button>
                </div>
            </div>
        </div>

        <sale-editor :original="sale"
            :save="saveCallback">
        </sale-editor>
    </div>
</template>

<script>
import ls from '../../services/ls.js'
import store from '../../services/vuex'
import SaleEditor from './SaleEditor'

export default {
    components: {
        SaleEditor,
    },
    data () {
        return {
            site: null,
        }
    },
    computed: {
        sale(){
            return store.state.sales.sale
        },
        saving(){
            return store.state.sales.saving
        }
    },
    methods: {
        saveCallback(sale){
            sale.site = this.site

            store.dispatch('sales/storeSale', sale)
                .then(response => {
                    this.$router.push(`/admin/sales/${response.data.data.id}/edit`)
                })
        }
    },
    created() {
        this.site = ls.get('site')
    },
}
</script>

<style scoped>
.table td{
    border-top: none;
}
</style>
