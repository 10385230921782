<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item active">Fűrészáru</li>
            </ol>
            <h1 class="d-inline mr-3">Fűrészáru</h1>
            <button class="btn btn-outline-info btn-sm align-text-bottom"
                @click="screen='handling'"
                v-if="screen=='overview'">
                <i class="icon-fa icon-fa-gear"/> Kezelés árai
            </button>

            <button class="btn btn-outline-info btn-sm align-text-bottom"
                @click="screen='overview'"
                v-if="screen!='loading' && screen!='overview'">
                <i class="icon-fa icon-fa-arrow-left"/> Vissza
            </button>

            <button class="btn btn-success btn-sm align-text-bottom pull-right"
                type="button"
                @click="save"
                v-if="screen=='overview'"
                v-tooltip="'Minden fűrészáru minden méretének és a kezelés paramétereinek mentése'">
                <i class="icon-fa icon-fa-save" v-if="!saving"></i>
                <i class="icon-fa icon-fa-circle-o-notch icon-fa-spin" v-if="saving"></i>
                Mentés
            </button>
        </div>

        <div class="text-center" v-if="screen=='loading'">
            <i class="icon-fa icon-fa-2x icon-fa-spin icon-fa-circle-o-notch"></i>
        </div>

        <div class="row" v-if="screen=='overview'">
            <div class="col-12 col-md-6">
                <div class="card">
                    <div class="card-header">
                        Gerendák
                    </div>
                    <table class="table mb-0">
                        <tr v-for="timber in beams"
                            :key="timber.id">
                            <td>
                                <button class="btn btn-default btn-sm"
                                    type="button"
                                    @click="selected=timber.id;screen='detail'">
                                    {{ timber.name }}
                                </button>
                            </td>
                            <td>{{ Object.keys(timber.prices).length }} méret</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="card">
                    <div class="card-header">
                        Pallók
                    </div>
                    <table class="table mb-0">
                        <tr v-for="timber in planks"
                            :key="timber.id">
                            <td>
                                <button class="btn btn-default btn-sm"
                                    type="button"
                                    @click="selected=timber.id;screen='detail'">
                                    {{ timber.name }}
                                </button>
                            </td>
                            <td>{{ Object.keys(timber.prices).length }} méret</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="card">
                    <div class="card-header">
                        Deszkák
                    </div>
                    <table class="table mb-0">
                        <tr v-for="timber in boards"
                            :key="timber.id">
                            <td>
                                <button class="btn btn-default btn-sm"
                                    type="button"
                                    @click="selected=timber.id;screen='detail'">
                                    {{ timber.name }}
                                </button>
                            </td>
                            <td>{{ Object.keys(timber.prices).length }} méret</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="card">
                    <div class="card-header">
                        Lécek
                    </div>
                    <table class="table mb-0">
                        <tr v-for="timber in slats"
                            :key="timber.id">
                            <td>
                                <button class="btn btn-default btn-sm"
                                    type="button"
                                    @click="selected=timber.id;screen='detail'">
                                    {{ timber.name }}
                                </button>
                            </td>
                            <td>{{ Object.keys(timber.prices).length }} méret</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div class="card" v-if="screen=='detail'">
            <div class="card-header">{{ activeTimber.name }}</div>

            <table class="table mb-0">
                <tr v-for="i in 10"
                    :key="i">
                    <td>
                        {{ i }} méter</td>
                    <td>
                        <div class="input-group">
                            <input type="number"
                                min="0"
                                class="form-control"
                                v-model="activeTimber.prices[i]">
                            <div class="input-group-append">
                                <span class="input-group-text">
                                    Ft
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <div class="card" v-if="screen=='handling'">
            <div class="card-header">Faanyag kezelés árai</div>

            <table class="table mb-0">
                <tr>
                    <td>Gyalulás</td>
                    <td>
                        <div class="input-group">
                            <input type="number"
                                min="0"
                                class="form-control"
                                v-model.number="handling.planing">
                            <div class="input-group-append">
                                <span class="input-group-text">
                                    Ft
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Impregnálás</td>
                    <td>
                        <div class="input-group">
                            <input type="number"
                                min="0"
                                class="form-control"
                                v-model.number="handling.impregnation">
                            <div class="input-group-append">
                                <span class="input-group-text">
                                    Ft
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>


<script>
import ls from '../../services/ls'

export default {
    data () {
        return {
            timber: null,
            handling: null,
            screen: 'loading',
            selected: null,
            site: null,
            saving: false,
        }
    },
    computed: {
        beams(){ return this.getTimberByType('beam') },
        boards(){ return this.getTimberByType('board') },
        planks(){ return this.getTimberByType('plank') },
        slats(){ return this.getTimberByType('slat') },
        activeTimber(){
            return this.timber.find(item => {
                return item.id == this.selected
            })
        }
    },
    methods: {
        getTimberByType(type){
            return this.timber.filter(item => {
                return item.type == type
            })
        },
        save(){
            this.saving = true

            axios.patch(`/api/admin/timber/prices`, {
                handling: this.handling,
                timber: this.timber,
            }).then(result => {
                this.timber = result.data.items.map(item => {
                    if(item.prices.length==0){
                        item.prices = {}
                    }

                    return item
                })
                this.handling = result.data.handling
                toastr.success('Adatok elmentve', 'Siker')
            }).catch(err => {
                toastr.error(err.message, 'Mentés sikertelen')
            }).finally(() => {
                this.saving = false
            })
        }
    },
    created() {
        this.site = ls.get('site')

        axios.get('/api/admin/timber/prices')
            .then(result => {
                this.timber = result.data.items.map(item => {
                    if(item.prices.length==0){
                        item.prices = {}
                    }

                    return item
                })
                this.handling = result.data.handling
                this.screen = 'overview'
            })
    }
}
</script>
