var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/sites/" + this.site },
                slot: "item-title"
              },
              [_vm._v("Vezérlőpult")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Fordítás linkek")
        ])
      ]),
      _vm._v(" "),
      _c("h1", { staticClass: "d-inline mr-3" }, [_vm._v("Fordítás linkek")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-outline-info btn-sm align-text-bottom",
          attrs: { type: "button" },
          on: { click: _vm.addItem }
        },
        [
          _c("i", { staticClass: "icon-fa icon-fa-plus" }),
          _vm._v("\n            Új hozzáadása\n        ")
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("form", [
          _c("table", { staticClass: "table table-striped" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.translations, function(translation, key) {
                return _c("tr", { key: key }, [
                  _c("td", [
                    _c("div", { staticClass: "form-group mb-0" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: translation.url,
                            expression: "translation.url"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid": _vm.isInvalid(translation.url, key)
                        },
                        attrs: { type: "text" },
                        domProps: { value: translation.url },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(translation, "url", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.hasDuplicate(translation.url, key)
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n                                        Duplikált URL!\n                                    "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isInvalidUrl(translation.url)
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n                                        Hibás URL!\n                                    "
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: translation.label,
                          expression: "translation.label"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: translation.label },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(translation, "label", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "remove-button" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.removeItem(key)
                          }
                        }
                      },
                      [_c("i", { staticClass: "icon-fa icon-fa-trash" })]
                    )
                  ])
                ])
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-success btn-sm",
              attrs: { type: "button" },
              on: { click: _vm.save }
            },
            [
              !_vm.saving
                ? _c("i", { staticClass: "icon-fa icon-fa-save" })
                : _vm._e(),
              _vm._v(" "),
              _vm.saving
                ? _c("i", {
                    staticClass: "icon-fa icon-fa-spin icon-fa-circle-o-notch"
                  })
                : _vm._e(),
              _vm._v("\n                    Linkek mentése\n                ")
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Itt lehet megadni a nyelvválasztóban látható linkeket."),
      _c("br"),
      _vm._v(
        "\n                Mindig csak az adott domain teljes url-jét kell megadni / végződéssel (pl: https://itzen.hu/).\n            "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Főoldal URL")]),
        _vm._v(" "),
        _c("th", [_vm._v("Címke")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }