import { render, staticRenderFns } from "./Cards.vue?vue&type=template&id=376950f4&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/media/thed/vol_40/Laravel/bmat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('376950f4')) {
      api.createRecord('376950f4', component.options)
    } else {
      api.reload('376950f4', component.options)
    }
    module.hot.accept("./Cards.vue?vue&type=template&id=376950f4&", function () {
      api.rerender('376950f4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/admin/basic-ui/Cards.vue"
export default component.exports