<template>
    <div class="sidebar-left">
        <div class="sidebar-body scroll-pane">
            <div class="side-nav">
                <v-collapse accordion>

                    <v-collapse-item active-url="/admin/dashboard" v-if="!site">
                        <router-link slot="item-title"
                            to="/admin/dashboard">
                            <i class="icon-fa icon-fa-dashboard"/>Vezérlőpult
                        </router-link>
                    </v-collapse-item>

                    <v-collapse-item active-url="/admin/sites" v-if="!site">
                        <span slot="item-title"
                            class="menu-title">
                            <i class="icon-fa icon-fa-globe"/>Domainek
                        </span>

                        <router-link
                            to="/admin/sites">
                            Összes domain
                        </router-link>

                        <router-link
                            to="/admin/sites/create">
                            Új hozzáadása
                        </router-link>
                    </v-collapse-item>


                    <v-collapse-item :active-url="`/admin/sites/${site}`" v-if="site">
                        <span slot="item-title"
                            class="menu-title">
                            <i class="icon-fa icon-fa-dashboard"/>
                            Vezérlőpult
                        </span>

                        <router-link
                            :to="`/admin/sites/${site}`">
                            Kezdőlap
                        </router-link>

                        <router-link
                            to="/admin/translations">
                            Fordítások
                        </router-link>

                        <router-link
                            to="/admin/translation-links">
                            Fordítás linkek
                        </router-link>

                        <router-link
                            :to="`/admin/sites/${site}/edit`">
                            Beállítások
                        </router-link>
                    </v-collapse-item>

                    <v-collapse-item active-url="/admin/posts" v-if="site">
                        <span slot="item-title"
                            class="menu-title">
                            <i class="icon-fa icon-fa-edit"/>
                            Bejegyzések
                        </span>

                        <router-link
                            to="/admin/posts">
                            Összes bejegyzés
                        </router-link>

                        <router-link
                            to="/admin/posts/create">
                            Új hozzáadása
                        </router-link>
                    </v-collapse-item>


                    <v-collapse-item active-url="/admin/pages" v-if="site">
                        <span slot="item-title"
                            class="menu-title">
                            <i class="icon-fa icon-fa-file-o"/>
                            Oldalak
                        </span>

                        <router-link
                            to="/admin/pages">
                            Összes Oldal
                        </router-link>

                        <router-link
                            to="/admin/pages/create">
                            Új hozzáadása
                        </router-link>
                    </v-collapse-item>

                    <v-collapse-item active-url="/admin/carousels" v-if="site">
                        <span slot="item-title"
                            class="menu-title">
                            <i class="icon-fa icon-fa-image"/>
                            Képváltók
                        </span>

                        <router-link
                            to="/admin/carousels">
                            Összes képváltó
                        </router-link>

                        <router-link
                            to="/admin/carousels/create">
                            Új hozzáadása
                        </router-link>
                    </v-collapse-item>


                    <v-collapse-item active-url="/admin/products" v-if="site">
                        <span slot="item-title" class="menu-title">
                            <i class="icon-fa icon-fa-shopping-basket"/>
                            Termékek
                        </span>

                        <router-link
                            to="/admin/products">
                            Összes termék
                        </router-link>

                        <router-link
                            to="/admin/products/create">
                            Új hozzáadása
                        </router-link>

                        <router-link
                            to="/admin/sales">
                            Akciók
                        </router-link>
                    </v-collapse-item>

                    <v-collapse-item active-url="/admin/timber" v-if="site">
                        <router-link to="/admin/timber" slot="item-title">
                            <i class="icon-fa icon-fa-tree"></i>
                            Fűrészáru
                        </router-link>
                    </v-collapse-item>

                    <hr v-if="site">

                    <v-collapse-item active-url="/admin/categories" v-if="site">
                        <router-link slot="item-title"
                            to="/admin/categories">
                            <i class="icon-fa icon-fa-sitemap"></i>
                            Kategóriák
                        </router-link>
                    </v-collapse-item>

                    <v-collapse-item active-url="/admin/tags" v-if="site">
                        <router-link slot="item-title"
                            to="/admin/tags">
                            <i class="icon-fa icon-fa-tag"></i>
                            Címkék
                        </router-link>
                    </v-collapse-item>

                    <hr v-if="site">

                    <v-collapse-item active-url="/admin/orders" v-if="site">
                        <span slot="item-title"
                            class="menu-title">
                            <i class="icon-fa icon-fa-truck"/>
                            Rendelések

                            <span class="badge badge-info" v-if="counters.orders" v-tooltip="'Új rendelések száma'">
                                {{ counters.orders }}
                            </span>
                        </span>

                        <router-link to="/admin/orders">
                            Összes rendelés
                        </router-link>

                        <router-link to="/admin/contacts">
                            Megrendelők
                        </router-link>
                    </v-collapse-item>

                    <hr v-if="site">

                    <v-collapse-item v-if="site">
                        <a :href="`//${domain}`" target="_blank" slot="item-title">
                            <i class="icon-fa icon-fa-external-link"/>
                            {{ domain }} megtekintése
                        </a>
                    </v-collapse-item>

                    <v-collapse-item v-if="site">
                        <router-link slot="item-title"
                            to="/admin/sites">
                            <i class="icon-fa icon-fa-arrow-left"/>
                            Vissza
                        </router-link>
                    </v-collapse-item>
                </v-collapse>
            </div>
        </div>
    </div>
</template>

<script>
import ls from '../../../services/ls.js'
import store from '../../../services/vuex'

export default {
    data(){
        return {
            site: null,
            domain: '',
        }
    },
    computed: {
        counters(){
            return store.state.app.counters
        }
    },
    methods: {
        refreshSite(){
            this.site = ls.get('site');
            this.domain = ls.get('siteDomain')
            store.dispatch('app/getBeat')
        }
    },
    created() {
        this.refreshSite()
        this.$root.$on('siteChange', () => {
            this.refreshSite()
        })
    }
}
</script>

<style>
.side-nav .menu-title{
    display: block;
    font-size: 14px;
    padding: 12px 12px 12px 30px;
    position: relative;
    text-decoration: none;
    color: #c7cbd1;
    cursor: pointer;
}
.side-nav .menu-title:hover{
    background-color: #505862;
}
</style>
