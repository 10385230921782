<template>
    <div class="cool-table">
        <div class="form-group">
            <div class="input-group">
                <div class="input-group-prepend" v-for="(filter, key) in checkfilters" v-bind:key="key">
                    <div class="input-group-text">
                        <input type="checkbox"
                            v-model="filters[key]">
                        &nbsp;{{ filter.name }}
                    </div>
                </div>

                <input type="text"
                    class="form-control"
                    v-model="filters.text"
                    placeholder="Szűrés...">
            </div>
        </div>

        <div class="form-group">
            <div class="input-group">
                <select class="custom-select"
                    v-for="(filter, key) in selectfilters"
                    v-bind:key="key"
                    v-model="filters[key]">
                    <option :value="i"
                        v-for="(option, i) in filter.options"
                        v-bind:key="i"
                        :selected="i==filter.value">
                        {{ filter.name }}: {{ option }}
                    </option>
                </select>
            </div>
        </div>

        <vuetable ref="vuetable"
            :api-mode="false"
            :fields="fields"
            :data-manager="dataManager"
            :show-sort-icon="true"
            :css="tableCss"
            :row-class="rowClass"
            :detail-row-component="detailRow"
            pagination-path="pagination"
            @vuetable:row-clicked="onRowClicked"
            @vuetable:pagination-data="onPaginationData">
            <slot></slot>
            <template v-for="(index, name) in $slots" v-slot:[name]="data">
                <slot :name="name" v-bind="data"></slot>
            </template>
            <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
                <slot :name="name" v-bind="data"></slot>
            </template>
        </vuetable>

        <vuetable-pagination-bootstrap ref="pagination"
            @vuetable-pagination:change-page="onChangePage">
        </vuetable-pagination-bootstrap>
    </div>
</template>

<script>
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from './VueTablePaginationBootstrap';
import tableCss from '../helpers/tableCss.js'

export default {
    components: {
        Vuetable,
        VuetablePaginationBootstrap,
    },
    props: {
        dataSource: { default: () => [], type: [Function] },
        fields: { default: () => [] , type: [Array] },
        checkfilters: { default: () => {}, type: [Object] },
        selectfilters: { default: () => {}, type: [Object] },
        textfilter: { default: () => true, type: [Boolean] },
        textThreshold: { default: 3, type: [Number] },
        rowClass: { default: () => '', type: [Function] },
        detailRow: { default: () => '' }
    },
    data(){
        return {
            data: {
                data: [],
                pagination: {},
            },
            filters: {},
            lastTextFilter: '',
            tableCss: tableCss,
        };
    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page);
        },
        onRowClicked(data){
            this.$refs.vuetable.toggleDetailRow(data.data.id)
        },
        reloadData(){
            this.$refs.vuetable.refresh()
        },
        async dataManager(sortOrder, pagination) {
            let filters = JSON.parse(JSON.stringify(this.filters))

            if(filters.text.length < this.textThreshold){
                filters.text = ''
            }

            await this.dataSource(sortOrder, pagination, filters)
                .then(response => {
                    this.data = {
                        pagination: response.data.meta,
                        data: response.data.data,
                    };
                    this.$refs.vuetable.visibleDetailRows = []
                })

            return this.data;
        }
    },
    created(){
        for (const key in this.checkfilters) {
            this.$set(this.filters, key, this.checkfilters[key].value);
        }
        for (const key in this.selectfilters) {
            this.$set(this.filters, key, this.selectfilters[key].value);
        }

        this.$set(this.filters, 'text', '')

        this.$watch('filters', function(newValue, oldValue){
            // text didnt change
            if(this.lastTextFilter == newValue.text){
                this.reloadData()
            }

            // text changed, and proper length
            if(newValue.text.length >= this.textThreshold){
                this.reloadData()
            }

            // text changed AND under limit AND last query was long enough text
            if(this.lastTextFilter.length >= this.textThreshold){
                this.reloadData()
            }

            this.lastTextFilter = newValue.text
        }, { deep: true })

        this.$root.$on('detailRow:close', payload => {
            this.$refs.vuetable.toggleDetailRow(payload)
        })
    }
}
</script>

<style>

</style>
