var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "card-body buttons-demo" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    on: {
                      click: function($event) {
                        return _vm.toastrNotifs("success")
                      }
                    }
                  },
                  [_vm._v("\n                Success\n              ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-info",
                    on: {
                      click: function($event) {
                        return _vm.toastrNotifs("info")
                      }
                    }
                  },
                  [_vm._v("\n                Info\n              ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger",
                    on: {
                      click: function($event) {
                        return _vm.toastrNotifs("error")
                      }
                    }
                  },
                  [_vm._v("\n                Error\n              ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    on: {
                      click: function($event) {
                        return _vm.toastrNotifs("warning")
                      }
                    }
                  },
                  [_vm._v("\n                Warning\n              ")]
                )
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "card-body buttons-demo" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    on: {
                      click: function($event) {
                        return _vm.notieNotifs("success")
                      }
                    }
                  },
                  [_vm._v("\n                Success\n              ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-info",
                    on: {
                      click: function($event) {
                        return _vm.notieNotifs("info")
                      }
                    }
                  },
                  [_vm._v("\n                Info\n              ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger",
                    on: {
                      click: function($event) {
                        return _vm.notieNotifs("error")
                      }
                    }
                  },
                  [_vm._v("\n                Error\n              ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    on: {
                      click: function($event) {
                        return _vm.notieNotifs("warning")
                      }
                    }
                  },
                  [_vm._v("\n                Warning\n              ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function($event) {
                        return _vm.notieNotifs("confirm")
                      }
                    }
                  },
                  [_vm._v("\n                Confirm\n              ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-primary",
                    on: {
                      click: function($event) {
                        return _vm.notieNotifs("input")
                      }
                    }
                  },
                  [_vm._v("\n                Input\n              ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-rounded",
                    on: {
                      click: function($event) {
                        return _vm.notieNotifs("select")
                      }
                    }
                  },
                  [_vm._v("\n                Select\n              ")]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Notifications")]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Notifications")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Notifications")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [_vm._v("Toastr")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [_vm._v("Notie.js")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }