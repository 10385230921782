var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "section section-hero-area" }, [
      _c("div", { staticClass: "container text-sm-center" }, [
        _c("h1", [_vm._v("Laraspace Frontside Template")]),
        _vm._v(" "),
        _c("p", { staticClass: "lead" }, [
          _vm._v(
            "Use this document as a way to quickly start any new project."
          ),
          _c("br")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }