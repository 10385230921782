<template>
  <div class="error-box">
    <div class="row">
      <div class="col-sm-12 text-sm-center">
        <h1>404</h1>
        <h5>Whoops! You got Lost!</h5>
        <router-link class="btn btn-lg bg-yellow" to="/blog">
          Go to Blog page <i class="icon-fa icon-fa-arrow-right"/>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    this.setLayoutClasses()
  },
  destroyed () {
    document.body.classList.remove('page-error-404')
  },
  methods: {
    setLayoutClasses () {
      let body = document.body
      body.classList.add('page-error-404')
    }
  }
}
</script>

<style>
.page-error-404 {
    background: #f5f5f5;
}

.error-box h1 {
    color: #993333;
}

.error-box h5 {
    color: #333;
}
</style>
