var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-container" },
    [
      _c("header", { staticClass: "site-header" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("ul", { staticClass: "action-list" }, [
          _c(
            "li",
            { staticClass: "action-item" },
            [
              _c("router-link", { attrs: { to: "/admin" } }, [
                _vm._v("Admin Dashboard")
              ])
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [_c("router-view")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "brand-main", attrs: { href: "#" } }, [
      _c("img", {
        staticClass: "hidden-sm-down",
        attrs: {
          id: "logo-desk",
          src: "/assets/img/logo-desk.png",
          alt: "Laraspace Logo"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }