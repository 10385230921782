var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/sites/" + this.site },
                slot: "item-title"
              },
              [_vm._v("Vezérlőpult")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/carousels" },
                slot: "item-title"
              },
              [_vm._v("Képváltók")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Új képváltó")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c("h1", [_vm._v("Új képváltó")]),
        _vm._v(" "),
        _c("div", { staticClass: "btn-group ml-auto" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-success",
              attrs: { form: "carouselEditForm" }
            },
            [
              !_vm.saving
                ? _c("i", { staticClass: "icon-fa icon-fa-save" })
                : _vm._e(),
              _vm._v(" "),
              _vm.saving
                ? _c("i", {
                    staticClass: "icon-fa icon-fa-circle-o-notch icon-fa-spin"
                  })
                : _vm._e(),
              _vm._v("\n                    Mentés\n                ")
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { id: "carouselEditForm" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submit($event)
          }
        }
      },
      [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "carouselNameInput" } }, [_vm._v("Név")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.carousel.name,
                expression: "carousel.name"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text", id: "carouselNameInput" },
            domProps: { value: _vm.carousel.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.carousel, "name", $event.target.value)
              }
            }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }