var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cool-table" },
    [
      _c("div", { staticClass: "form-group" }, [
        _c(
          "div",
          { staticClass: "input-group" },
          [
            _vm._l(_vm.checkfilters, function(filter, key) {
              return _c(
                "div",
                { key: key, staticClass: "input-group-prepend" },
                [
                  _c("div", { staticClass: "input-group-text" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters[key],
                          expression: "filters[key]"
                        }
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.filters[key])
                          ? _vm._i(_vm.filters[key], null) > -1
                          : _vm.filters[key]
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.filters[key],
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(_vm.filters, key, $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.filters,
                                  key,
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.filters, key, $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(
                      "\n                     " +
                        _vm._s(filter.name) +
                        "\n                "
                    )
                  ])
                ]
              )
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filters.text,
                  expression: "filters.text"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "Szűrés..." },
              domProps: { value: _vm.filters.text },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.filters, "text", $event.target.value)
                }
              }
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "div",
          { staticClass: "input-group" },
          _vm._l(_vm.selectfilters, function(filter, key) {
            return _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filters[key],
                    expression: "filters[key]"
                  }
                ],
                key: key,
                staticClass: "custom-select",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.filters,
                      key,
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              _vm._l(filter.options, function(option, i) {
                return _c(
                  "option",
                  {
                    key: i,
                    domProps: { value: i, selected: i == filter.value }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(filter.name) +
                        ": " +
                        _vm._s(option) +
                        "\n                "
                    )
                  ]
                )
              }),
              0
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "vuetable",
        {
          ref: "vuetable",
          attrs: {
            "api-mode": false,
            fields: _vm.fields,
            "data-manager": _vm.dataManager,
            "show-sort-icon": true,
            css: _vm.tableCss,
            "row-class": _vm.rowClass,
            "detail-row-component": _vm.detailRow,
            "pagination-path": "pagination"
          },
          on: {
            "vuetable:row-clicked": _vm.onRowClicked,
            "vuetable:pagination-data": _vm.onPaginationData
          },
          scopedSlots: _vm._u(
            [
              _vm._l(_vm.$scopedSlots, function(index, name) {
                return {
                  key: name,
                  fn: function(data) {
                    return [_vm._t(name, null, null, data)]
                  }
                }
              })
            ],
            null,
            true
          )
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c("vuetable-pagination-bootstrap", {
        ref: "pagination",
        on: { "vuetable-pagination:change-page": _vm.onChangePage }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }