var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.product.id
    ? _c("div", { staticClass: "card my-1 mx-3" }, [
        _c("div", { staticClass: "card-header d-flex align-items-center" }, [
          _c(
            "span",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value:
                    "A csoportosított termékbe tartozó termékek. Több termék összefogható egy csomagba és a csomag külön termékként árusítható.",
                  expression:
                    "'A csoportosított termékbe tartozó termékek. Több termék összefogható egy csomagba és a csomag külön termékként árusítható.'"
                }
              ]
            },
            [
              _vm._v("\n            Csoport termékei\n            "),
              _c("i", { staticClass: "icon-fa icon-fa-question-circle" })
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("multiselect", {
                attrs: {
                  options: _vm.products,
                  label: "title",
                  "track-by": "id",
                  "deselect-label": "Kiválasztott",
                  placeholder: "Válassz ki egy terméket",
                  "select-label": "Enter a kiválasztáshoz",
                  "selected-label": "Kiválasztott",
                  "allow-empty": false
                },
                on: { "search-change": _vm.loadProducts },
                model: {
                  value: _vm.bundleProduct,
                  callback: function($$v) {
                    _vm.bundleProduct = $$v
                  },
                  expression: "bundleProduct"
                }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-info nowrap",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.addProduct()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "icon-fa icon-fa-plus" }),
                  _vm._v("\n                Hozzáadás\n            ")
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.product.relations.bundles && _vm.product.relations.bundles.length
            ? _c("table", { staticClass: "table table-striped mt-1" }, [
                _c("thead", [
                  _c("tr", [
                    _c(
                      "th",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "A termék neve",
                            expression: "'A termék neve'"
                          }
                        ]
                      },
                      [_vm._v("Termék")]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "Mennyi van a csomagban ebből a termékből",
                            expression:
                              "'Mennyi van a csomagban ebből a termékből'"
                          }
                        ]
                      },
                      [_vm._v("Mennyiség")]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "Az egyedi termék aktuális egységára.",
                            expression: "'Az egyedi termék aktuális egységára.'"
                          }
                        ]
                      },
                      [_vm._v("Egységár")]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "Az egyedi termék összege",
                            expression: "'Az egyedi termék összege'"
                          }
                        ]
                      },
                      [_vm._v("Összeg")]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.product.relations.bundles, function(bundle) {
                    return _c("tr", { key: bundle.product.id }, [
                      _c("td", [_vm._v(_vm._s(bundle.product.title))]),
                      _vm._v(" "),
                      _c("td", { staticStyle: { width: "10em" } }, [
                        _c("div", { staticClass: "form-group mb-0" }, [
                          _c(
                            "div",
                            { staticClass: "input-group input-group-sm" },
                            [
                              _c(
                                "div",
                                { staticClass: "input-group-prepend" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.decrease(bundle.product.id)
                                        }
                                      }
                                    },
                                    [
                                      bundle.quantity <= 1
                                        ? _c("i", {
                                            staticClass:
                                              "icon-fa icon-fa-fw icon-fa-trash w-100"
                                          })
                                        : _c("i", {
                                            staticClass:
                                              "icon-fa icon-fa-fw icon-fa-minus w-100"
                                          })
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: bundle.quantity,
                                    expression: "bundle.quantity"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  min: "0",
                                  step: "any"
                                },
                                domProps: { value: bundle.quantity },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      bundle,
                                      "quantity",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "input-group-append" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.increase(bundle.product.id)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "icon-fa icon-fa-fw icon-fa-plus w-100"
                                    })
                                  ]
                                )
                              ])
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("td", { staticStyle: { width: "10em" } }, [
                        bundle.product.relations.currentPrice
                          ? _c("span", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    bundle.product.relations.currentPrice.price
                                  ) +
                                  "\n                        "
                              )
                            ])
                          : _c("span", { staticClass: "text-danger" }, [
                              _vm._v("Nincs ár beállítva")
                            ])
                      ]),
                      _vm._v(" "),
                      _c("td", { staticStyle: { width: "10em" } }, [
                        bundle.product.relations.currentPrice
                          ? _c("span", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    bundle.product.relations.currentPrice
                                      .price * bundle.quantity
                                  ) +
                                  "\n                        "
                              )
                            ])
                          : _c("span", { staticClass: "text-danger" }, [
                              _vm._v("Nincs ár beállítva")
                            ])
                      ])
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _c("tfoot", [
                  _c("tr", [
                    _c("td", { attrs: { colspan: "3" } }, [_vm._v("Összesen")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.bundleSum))])
                  ])
                ])
              ])
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }