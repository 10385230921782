var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.product.id
    ? _c("div", { staticClass: "card my-1 mx-3" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c(
                  "tabs",
                  { staticClass: "tabs-simple" },
                  [
                    _vm.product.relations.stock.enabled
                      ? _c(
                          "tab",
                          { attrs: { id: "stock", name: "Készlet kezelése" } },
                          [
                            _c("h3", [
                              _vm._v(
                                "Aktuális készlet: " +
                                  _vm._s(_vm.product.relations.stock.value) +
                                  " " +
                                  _vm._s(_vm.product.relations.stock.track_unit)
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                { attrs: { for: "updateStockInput" } },
                                [
                                  _vm._v(
                                    "Készlet módosítása a megadott mennyiséggel"
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.updateStockWith,
                                    expression: "updateStockWith"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  id: "updateStockInput",
                                  min: "0"
                                },
                                domProps: { value: _vm.updateStockWith },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.updateStockWith = $event.target.value
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "btn-group btn-block" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-danger",
                                  attrs: {
                                    type: "button",
                                    disabled:
                                      _vm.updateStockWith <= 0 ||
                                      _vm.product.relations.stock.value -
                                        _vm.updateStockWith <=
                                        0
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.updateStock("decrease")
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon-fa icon-fa-minus"
                                  }),
                                  _vm._v(
                                    "\n                                Csökkentés\n                            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-sm btn-success",
                                  attrs: {
                                    type: "button",
                                    disabled: _vm.updateStockWith <= 0
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.updateStock("increase")
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon-fa icon-fa-plus"
                                  }),
                                  _vm._v(
                                    "\n                                Növelés\n                            "
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "tab",
                      { attrs: { id: "settings", name: "Beállítások" } },
                      [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "custom-control custom-checkbox mb-3"
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.product.relations.stock.enabled,
                                    expression:
                                      "product.relations.stock.enabled"
                                  }
                                ],
                                staticClass: "custom-control-input",
                                attrs: {
                                  id: "stockEnabledInput",
                                  type: "checkbox"
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.product.relations.stock.enabled
                                  )
                                    ? _vm._i(
                                        _vm.product.relations.stock.enabled,
                                        null
                                      ) > -1
                                    : _vm.product.relations.stock.enabled
                                },
                                on: {
                                  change: function($event) {
                                    var $$a =
                                        _vm.product.relations.stock.enabled,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.product.relations.stock,
                                            "enabled",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.product.relations.stock,
                                            "enabled",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.product.relations.stock,
                                        "enabled",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass: "custom-control-label",
                                  attrs: { for: "stockEnabledInput" }
                                },
                                [
                                  _vm._v(
                                    "\n                                    Készletkezelés engedélyezés a terméknél\n                                "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "trackByInput" } }, [
                              _vm._v(
                                "\n                                    Követés tulajdonság alapján\n                                    "
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value:
                                        "A tulajdonságok lapon megadott opciók közül lehet választani,\n                                        valamint lehetőség van darabszám alapú követésre.\n                                        Tulajdonság alapú követésnél az ott beállított mértékegység alapján fog számolni a rendszer\n                                        és a manuális készletfrissítésnél is azzal a mértékegysággel kell megadni a változásokat.",
                                      expression:
                                        "`A tulajdonságok lapon megadott opciók közül lehet választani,\n                                        valamint lehetőség van darabszám alapú követésre.\n                                        Tulajdonság alapú követésnél az ott beállított mértékegység alapján fog számolni a rendszer\n                                        és a manuális készletfrissítésnél is azzal a mértékegysággel kell megadni a változásokat.`"
                                    }
                                  ]
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "icon-fa icon-fa-question-circle"
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _vm.product.relations.stock.value != 0
                                ? _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value:
                                            "Csak 0 készlettel módosítható a használt tulajdonság",
                                          expression:
                                            "'Csak 0 készlettel módosítható a használt tulajdonság'"
                                        }
                                      ]
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "icon-fa icon-fa-exclamation-triangle text-warning"
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.product.relations.stock.track_by,
                                    expression:
                                      "product.relations.stock.track_by"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "trackByInput",
                                  disabled:
                                    _vm.product.relations.stock.value != 0
                                },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.product.relations.stock,
                                      "track_by",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(
                                _vm.product.relations.stock.product_attributes,
                                function(attribute) {
                                  return _c(
                                    "option",
                                    {
                                      key: attribute,
                                      domProps: { value: attribute }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(attribute) +
                                          "\n                                    "
                                      )
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          ])
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _c("h3", [_vm._v("Készlet változásai")]),
              _vm._v(" "),
              _c("table", { staticClass: "table table-sm" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.product.relations.stockChanges, function(change) {
                    return _c("tr", { key: change.id }, [
                      _c("td", [
                        _vm._v(_vm._s(change.amount) + _vm._s(change.unit))
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          change.order_id
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: "/admin/orders/" + change.order_id
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                    Rendelés #" +
                                      _vm._s(change.order_id) +
                                      "\n                                "
                                  )
                                ]
                              )
                            : change.user
                            ? _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: "/admin/users/" + change.user_id
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(change.user.name) +
                                      "\n                                "
                                  )
                                ]
                              )
                            : _c("span", [_vm._v("—")])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(change.created_at))])
                    ])
                  }),
                  0
                )
              ])
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Mennyiség")]),
        _vm._v(" "),
        _c("th", [_vm._v("Link")]),
        _vm._v(" "),
        _c("th", [_vm._v("Időpont")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }