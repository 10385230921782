var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mailbox-sidebar" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-theme btn-sm btn-block",
        on: { click: _vm.openComposeModal }
      },
      [_vm._v("Compose")]
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "sidebar-menu mt-4" },
      _vm._l(_vm.categories, function(category) {
        return _c(
          "li",
          {
            key: category.id,
            class: { active: _vm.selectedCategory === category.slug },
            on: {
              click: function($event) {
                return _vm.selectCategory(category)
              }
            }
          },
          [
            _c("a", { attrs: { href: "#" } }, [
              _c("i", { class: category.icon }),
              _vm._v("\n        " + _vm._s(category.name) + "\n        "),
              category.unread
                ? _c("span", { staticClass: "badge badge-pill badge-danger" }, [
                    _vm._v(_vm._s(category.unread))
                  ])
                : _vm._e()
            ])
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "left-sidebar-btn",
        on: {
          click: function($event) {
            return _vm.$emit("toggle")
          }
        }
      },
      [
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLeftSidebarVisible,
              expression: "!isLeftSidebarVisible"
            }
          ],
          staticClass: "icon-fa icon-fa-angle-right"
        }),
        _vm._v(" "),
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLeftSidebarVisible,
              expression: "isLeftSidebarVisible"
            }
          ],
          staticClass: "icon-fa icon-fa-angle-left"
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }