<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item active">Megrendelők</li>
            </ol>
            <h1 class="d-inline mr-3">Megrendelők</h1>
        </div>
        <div class="card">
            <div class="card-body">
                <cool-table :fields="fields"
                    :data-source="dataSource"
                    :textfilter="true">
                    <template slot="name" slot-scope="props">
                        <router-link :to="`/admin/contacts/${props.rowData.id}`">
                            {{ props.rowData.name }}
                        </router-link>
                    </template>

                    <template slot="email" slot-scope="props">
                        <router-link :to="`/admin/contacts/${props.rowData.id}`">
                            {{ props.rowData.email }}
                        </router-link>
                    </template>

                    <template slot="created_at" slot-scope="props">
                        {{ formatUtcDate(props.rowData.created_at) }}
                    </template>
                </cool-table>
            </div>
        </div>
    </div>
</template>

<script>
import ls from '../../services/ls'
import CoolTable from '../../components/CoolTable'

export default {
    components: {
        CoolTable
    },
    data () {
        return {
            site: null,
            fields: [
                {
                    name: 'name',
                    title: 'Név'
                },
                {
                    name: 'email',
                    title: 'Email',
                },
                {
                    name: 'created_at',
                    title: 'Utolsó rendelés',
                },
            ],
        }
    },
    methods: {
        dataSource (sortOrder, pagination, filters) {
            var sort = {
                field: sortOrder[0] ? sortOrder[0].sortField : 'created_at',
                direction: sortOrder[0] ? sortOrder[0].direction : 'desc',
            };

            return axios.get('/api/admin/contacts', {
                params: {
                    with: 'lastOrder,sumOrders',
                    page: pagination.current_page,
                    filters: {
                        site: this.site,
                        ...filters,
                    },
                    sort: sort.field,
                    order: sort.direction
                }
            })
        },
    },
    created() {
        this.site = ls.get('site')
    }
}
</script>

<style>

</style>
