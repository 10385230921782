var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main-content" }, [
      _c("div", { staticClass: "page-header" }, [
        _c("h4", { staticClass: "page-title" }, [_vm._v("Font Awesome")]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Icons")])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Font Awesome")])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("h6", [_vm._v("Example Usage")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("pre", { staticClass: "language-html" }, [
                _c("code", [_vm._v('<i class="icon-fa icon-fa-adjust"></i>')])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "page-header" }, [
                _vm._v("Web Application Icons")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "icon-container" }, [
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-adjust" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- adjust")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass:
                        "icon-fa icon-fa-american-sign-language-interpreting"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-fa-american-sign-language-interpreting")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-anchor" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-anchor")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-archive" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-archive")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-area-chart" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-area-chart")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-arrows" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-arrows")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-arrows-h" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-arrows-h")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-arrows-v" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-arrows-v")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-asl-interpreting" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-asl-interpreting")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-assistive-listening-systems"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-assistive-listening-systems")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-asterisk" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-asterisk")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-at" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-at")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-audio-description"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-audio-description")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-automobile" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-automobile")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-balance-scale" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-balance-scale")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-ban" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-ban")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bank" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bank")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bar-chart" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bar-chart")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bar-chart-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bar-chart-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-barcode" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-barcode")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bars" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bars")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-battery-0" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-battery-0")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-battery-1" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-battery-1(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-battery-2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-battery-2(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-battery-3" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-battery-3(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-battery-4" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-battery-4(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-battery-empty" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- battery-empty")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-battery-full" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-battery-full")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-battery-half" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-battery-half")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-battery-quarter" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-battery-quarter")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-battery-three-quarters"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-battery-three-quarters")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bed" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bed")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-beer" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-beer")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bell" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bell")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bell-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bell-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bell-slash" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bell-slash")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bell-slash-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bell-slash-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bicycle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bicycle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-binoculars" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-binoculars")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-birthday-cake" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-birthday-cake")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-blind" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-blind")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bluetooth" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bluetooth")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bluetooth-b" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bluetooth-b")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bolt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bolt")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bomb" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bomb")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-book" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-book")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bookmark" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bookmark")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bookmark-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bookmark-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-braille" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-braille")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-briefcase" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-briefcase")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bug" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bug")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-building" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-building")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-building-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-building-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bullhorn" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bullhorn")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bullseye" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bullseye")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bus")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cab" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cab(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-calculator" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-calculator")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-calendar" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-calendar")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-calendar-check-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-calendar-check-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-calendar-minus-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-calendar-minus-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-calendar-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-calendar-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-calendar-plus-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-calendar-plus-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-calendar-times-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-calendar-times-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-camera" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-camera")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-camera-retro" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-camera-retro")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-car" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-car")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-caret-square-o-down"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-caret-square-o-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-caret-square-o-left"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-caret-square-o-left")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-caret-square-o-right"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-caret-square-o-right")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-caret-square-o-up"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-caret-square-o-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cart-arrow-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cart-arrow-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cart-plus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cart-plus")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cc")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-certificate" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-certificate")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-check" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-check")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-check-circle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-check-circle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-check-circle-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-check-circle-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-check-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-check-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-check-square-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-check-square-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-child" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-child")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-circle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-circle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-circle-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-circle-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-circle-o-notch" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-circle-o-notch")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-circle-thin" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-circle-thin")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-clock-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-clock-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-clone" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-clone")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-close" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-close(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cloud" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cloud")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cloud-download" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cloud-download")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cloud-upload" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cloud-upload")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-code" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-code")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-code-fork" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-code-fork")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-coffee" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-coffee")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cog" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cog")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cogs" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cogs")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-comment" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-comment")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-comment-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-comment-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-commenting" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-commenting")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-commenting-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-commenting-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-comments" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-comments")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-comments-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-comments-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-compass" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-compass")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-copyright" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-copyright")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-creative-commons" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-creative-commons")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-credit-card" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-credit-card")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-credit-card-alt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-credit-card-alt")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-crop" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-crop")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-crosshairs" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-crosshairs")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cube" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cube")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cubes" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cubes")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cutlery" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cutlery")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-dashboard" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-dashboard(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-database" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-database")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-deaf" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-deaf")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-deafness" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-deafness")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-desktop" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-diamond")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-dot-circle-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-dot-circle-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-edit" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-edit(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-ellipsis-h" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-ellipsis-h")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-ellipsis-v" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-ellipsis-v")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-envelope" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-envelope")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-envelope-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-envelope-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-envelope-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-envelope-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-eraser" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-eraser")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-exchange" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-exchange")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-exclamation" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-exclamation")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-exclamation-circle"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-exclamation-circle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-exclamation-triangle"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-exclamation-triangle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-external-link" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-external-link")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-external-link-square"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-external-link-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-eye" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- eye\n                ")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-eye-slash" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-eye-slash")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-eyedropper" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-eyedropper")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-fax" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-fax")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-feed" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-feed(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-female" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-female")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-fighter-jet" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-fighter-jet")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-archive-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-archive-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-audio-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-audio-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-code-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-code-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-excel-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-excel-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-image-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-image-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-movie-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-movie-o(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-pdf-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-pdf-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-photo-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-photo-o(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-picture-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-picture-o(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-file-powerpoint-o"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-powerpoint-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-sound-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-sound-o(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-video-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-video-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-word-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-word-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-zip-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-zip-o(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-film" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-film")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-filter" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-filter")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-fire-extinguisher"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-fire-extinguisher")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-flag" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-flag")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-flag-checkered" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-flag-checkered")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-flag-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-flag-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-flash" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-flash>(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-flask" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-flask")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-folder" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-folder")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-folder-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-folder-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-folder-open" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-folder-open")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-folder-open-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-folder-open-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-frown-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-frown-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-futbol-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-futbol-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-gamepad" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-gamepad")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-gavel" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-gavel")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-gear" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-gear(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-gears" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-gears>(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-gift" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-gift")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-glass" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-glass")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-globe" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-globe")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-graduation-cap" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-graduation-cap")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-group" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-group>(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-grab-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-grab-o(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-lizard-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-lizard-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-paper-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-paper-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-peace-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-peace-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-pointer-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-pointer-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-rock-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-rock-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-scissors-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-scissors-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-spock-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-spock-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-stop-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-stop-o(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hard-of-hearing" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hard-of-hearing(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hashtag" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hashtag")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hdd-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hdd-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-headphones" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-headphones")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-heart" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-heart")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-heart-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-heart-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-heartbeat" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-heartbeat")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-history" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-history")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-home" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-home")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hotel" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hotel>(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hourglass" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hourglass")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hourglass-1" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hourglass-1(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hourglass-2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hourglass-2(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hourglass-3" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hourglass-3(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hourglass-end" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hourglass-end")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hourglass-half" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hourglass-half")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hourglass-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hourglass-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hourglass-start" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hourglass-start")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-i-cursor" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-i-cursor")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-image" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-image(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-inbox" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-inbox")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-industry" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-industry")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-info" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-info")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-info-circle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-info-circle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-institution" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-institution(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-key" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-key")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-keyboard-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-keyboard-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-language" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-language")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-laptop" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-laptop")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-leaf" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-leaf")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-legal" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-legal(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-lemon-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-lemon-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-level-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-level-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-level-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-level-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-life-bouy" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-life-bouy(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-life-buoy" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-life-buoy(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-life-ring" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-life-ring")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-life-saver" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-life-saver(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-lightbulb-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-lightbulb-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-line-chart" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-line-chart")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-location-arrow" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-location-arrow")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-lock" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-lock")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-low-vision" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-low-vision")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-magic" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-magic")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-magnet" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-magnet")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-mail-forward" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-mail-forward(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-mail-reply" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-mail-reply(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-mail-reply-all" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-mail-reply-all(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-male" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-male")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-map" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-map")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-map-marker" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-map-marker")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-map-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-map-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-map-pin" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-map-pin")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-map-signs" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-map-signs")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-meh-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-meh-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-microphone" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-microphone")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-microphone-slash" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-microphone-slash")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-minus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-minus")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-minus-circle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-minus-circle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-minus-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-minus-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-minus-square-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-minus-square-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-mobile" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-mobile")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-mobile-phone" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-mobile-phone(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-money" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-money")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-moon-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-moon-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-mortar-board" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-mortar-board(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-motorcycle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-motorcycle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-mouse-pointer" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-mouse-pointer")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-music" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-music")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-navicon" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-navicon(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-newspaper-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-newspaper-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-object-group" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-object-group")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-object-ungroup" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-object-ungroup")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-paint-brush" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-paint-brush")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-paper-plane" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-paper-plane")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-paper-plane-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-paper-plane-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-paw" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-paw")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-pencil" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-pencil")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-pencil-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-pencil-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-pencil-square-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-pencil-square-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-percent" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-percent")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-phone" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-phone")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-phone-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-phone-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-photo" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-photo>(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-picture-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-picture-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-pie-chart" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-pie-chart")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-plane" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-plane")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-plug" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-plug")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-plus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-plus")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-plus-circle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-plus-circle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-plus-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-plus-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-plus-square-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-plus-square-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-power-off" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-power-off")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-print" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-print")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-puzzle-piece" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-puzzle-piece")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-qrcode" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-qrcode")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-question" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-question")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-question-circle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-question-circle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-question-circle-o"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-question-circle-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-quote-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-quote-left")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-quote-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-quote-right")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-random" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-random")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-recycle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-recycle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-refresh" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-refresh")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-registered" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-registered")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-remove" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-remove>(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-reorder" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-reorder>(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-reply" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-reply")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-reply-all" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-reply-all")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-retweet" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-retweet")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-road" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-road")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-rocket" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-rocket")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-rss" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-rss")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-rss-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-rss-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-search" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-search")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-search-minus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-search-minus")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-search-plus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-search-plus")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-send" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-send(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-send-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-send-o>(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-server" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-server")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-share" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-share")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-share-alt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-share-alt")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-share-alt-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-share-alt-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-share-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-share-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-share-square-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-share-square-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-shield" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-shield")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-ship" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-ship")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-shopping-bag" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-shopping-bag")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-shopping-basket" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-shopping-basket")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-shopping-cart" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-shopping-cart")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sign-in" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sign-in")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sign-language" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sign-language")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sign-out" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sign-out")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-signal" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-signal")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-signing" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-signing>(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sitemap" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sitemap")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sliders" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sliders")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-smile-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-smile-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-soccer-ball-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-soccer-ball-o>(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sort" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sort")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sort-alpha-asc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sort-alpha-asc")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sort-alpha-desc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sort-alpha-desc")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sort-amount-asc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sort-amount-asc")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sort-amount-desc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sort-amount-desc")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sort-asc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sort-asc")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sort-desc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sort-desc")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sort-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sort-down>(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sort-numeric-asc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sort-numeric-asc")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-sort-numeric-desc"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sort-numeric-desc")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sort-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sort-up(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-space-shuttle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-space-shuttle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-spinner" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-spinner")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-spoon" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-spoon")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-square-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-square-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-star" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-star")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-star-half" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-star-half")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-star-half-empty" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-star-half-empty(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-star-half-full" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-star-half-full(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-star-half-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-star-half-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-star-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-star-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sticky-note" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sticky-note")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sticky-note-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sticky-note-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-street-view" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-street-view")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-suitcase" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-suitcase")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sun-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sun-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-support" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-support(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-tablet" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-tablet")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-tachometer" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-tachometer")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-tag" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-tag")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-tags" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-tags")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-tasks" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-tasks")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-taxi" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-taxi")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-television" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-television")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-terminal" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-terminal")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-thumb-tack" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-thumb-tack")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-thumbs-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-thumbs-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-thumbs-o-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-thumbs-o-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-thumbs-o-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-thumbs-o-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-thumbs-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-thumbs-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-ticket" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-ticket")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-times" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-times")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-times-circle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-times-circle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-times-circle-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-times-circle-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-tint" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-tint")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-toggle-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-toggle-down(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-toggle-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-toggle-left(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-toggle-off" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-toggle-off")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-toggle-on" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-toggle-on")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-toggle-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-toggle-right(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-toggle-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-toggle-up(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-trademark" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-trademark")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-trash" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-trash")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-trash-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-trash-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-tree" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-tree")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-trophy" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-trophy")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-truck" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-truck")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-tty" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- tty")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-tv" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-tv(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-umbrella" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-umbrella")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-universal-access" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-universal-access")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-university" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-university")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-unlock" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-unlock")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-unlock-alt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-unlock-alt")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-unsorted" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-unsorted(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-upload" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-upload")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-user" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-user")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-user-circle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-user-circle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-user-plus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-user-plus")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-user-secret" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-user-secret")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-user-times" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-user-times")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-users" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-users")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-video-camera" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-video-camera")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-volume-control-phone"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-volume-control-phone")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-volume-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-volume-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-volume-off" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-volume-off")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-volume-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-volume-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-warning" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-warning(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-wheelchair" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- wheelchair")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-wheelchair-alt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- wheelchair-alt")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-wifi" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- wifi")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-wrench" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- wrench")])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h4", { staticClass: "page-header" }, [
                _vm._v("Accessibility Icons")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "icon-container" }, [
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass:
                        "icon-fa icon-fa-american-sign-language-interpreting"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-fa-american-sign-language-interpreting")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-asl-interpreting" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-asl-interpreting(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-assistive-listening-systems"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-assistive-listening-systems")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-audio-description"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-audio-description")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-blind" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-blind")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-braille" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-braille")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cc")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-deaf" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-deaf")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-deafness" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-deafness(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hard-of-hearing" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hard-of-hearing(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-low-vision" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-low-vision")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-question-circle-o"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-question-circle-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sign-language" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sign-language")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-signing" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-signing(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-tty" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-tty")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-universal-access" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-universal-access")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-volume-control-phone"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-volume-control-phone")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-wheelchair" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-wheelchair")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-wheelchair-alt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-wheelchair-alt")])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h4", { staticClass: "page-header" }, [_vm._v("Hand Icons")]),
              _vm._v(" "),
              _c("div", { staticClass: "icon-container" }, [
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-grab-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-grab-o(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-lizard-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-lizard-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-o-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-o-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-o-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-o-left")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-o-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-o-right")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-o-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-o-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-paper-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-paper-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-peace-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-peace-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-pointer-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-pointer-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-rock-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-rock-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-scissors-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-scissors-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-spock-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-spock-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-stop-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-stop-o(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-thumbs-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-thumbs-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-thumbs-o-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-thumbs-o-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-thumbs-o-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-thumbs-o-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-thumbs-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-thumbs-up")])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h4", { staticClass: "page-header" }, [
                _vm._v("Transportation Icons")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "icon-container" }, [
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-ambulance" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-ambulance")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-automobile" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-automobile(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bicycle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bicycle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bus")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cab" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cab(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-car" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-car")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-fighter-jet" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-fighter-jet")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-motorcycle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-motorcycle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-plane" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-plane")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-rocket" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-rocket")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-ship" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-ship")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-space-shuttle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-space-shuttle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-subway" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-subway")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-taxi" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-taxi")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-train" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-train")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-truck" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-truck")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-wheelchair" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-wheelchair")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-wheelchair-alt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-wheelchair-alt")])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h4", { staticClass: "page-header" }, [
                _vm._v("Gender Icons")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "icon-container" }, [
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-genderless" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- genderless")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-intersex" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- intersex(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-mars" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-mars")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-mars-double" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-mars-double")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-mars-stroke" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-mars-stroke")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-mars-stroke-h" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-mars-stroke-h")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-mars-stroke-v" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-mars-stroke-v")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-mercury" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-mercury")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-neuter" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-neuter")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-transgender" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-transgender")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-transgender-alt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-transgender-alt")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-venus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-venus")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-venus-double" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-venus-double")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-venus-mars" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-venus-mars")])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h4", { staticClass: "page-header" }, [
                _vm._v("File Type Icons")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "icon-container" }, [
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- file")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-archive-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- file-archive-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-audio-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- file-audio-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-code-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- file-code-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-excel-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- file-excel-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-image-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- file-image-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-movie-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- file-movie-o(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-pdf-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-pdf-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-photo-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-photo-o(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-picture-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-picture-o(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-file-powerpoint-o"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-powerpoint-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-sound-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-sound-o(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-text" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-text")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-text-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-text-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-video-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-video-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-word-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-word-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-zip-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-zip-o")])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h4", { staticClass: "page-header" }, [
                _vm._v("Spinner Icons")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "icon-container" }, [
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-circle-o-notch" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- circle-o-notch")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cog" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cog")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-gear" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-gear(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-refresh" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-refresh")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-spinner" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-spinner")])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h4", { staticClass: "page-header" }, [
                _vm._v("Form Control Icons")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "icon-container" }, [
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-check-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-check-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-check-square-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-check-square-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-circle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-circle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-circle-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-circle-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-dot-circle-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-dot-circle-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-minus-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-minus-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-minus-square-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-minus-square-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-plus-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-plus-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-plus-square-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-plus-square-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-square-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-square-o")])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h4", { staticClass: "page-header" }, [
                _vm._v("Payment Icons")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "icon-container" }, [
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cc-amex" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cc-amex")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cc-diners-club" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cc-diners-club")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cc-discover" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cc-discover")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cc-jcb" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cc-jcb")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cc-mastercard" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cc-mastercard")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cc-paypal" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cc-paypal")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cc-stripe" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cc-stripe")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cc-visa" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cc-visa")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-credit-card" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-credit-card")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-credit-card-alt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-credit-card-alt")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-google-wallet" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-google-wallet")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-paypal" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-paypal")])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h4", { staticClass: "page-header" }, [_vm._v("Chart Icons")]),
              _vm._v(" "),
              _c("div", { staticClass: "icon-container" }, [
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-area-chart" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-area-chart")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bar-chart" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bar-chart")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bar-chart-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bar-chart-o(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-line-chart" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-line-chart")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-pie-chart" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-pie-chart")])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h4", { staticClass: "page-header" }, [
                _vm._v("Currency Icons")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "icon-container" }, [
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bitcoin" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bitcoin(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-btc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-btc")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cny" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cny(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-dollar" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-dollar(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-eur" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-eur")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-euro" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-euro(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-gbp" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-gbp")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-gg" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-gg")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-gg-circle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-gg-circle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-ils" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-ils")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-inr" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-inr")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-jpy" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-jpy")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-krw" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-krw")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-money" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-money")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-rmb" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-rmb(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-rouble" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-rouble(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-rub" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-rub")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-ruble" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-ruble(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-rupee" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-rupee(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-shekel" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-shekel(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sheqel" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sheqel(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-try" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-try")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-turkish-lira" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-turkish-lira(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-usd" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-usd")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-won" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-won(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-yen" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-yen(alias)")])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h4", { staticClass: "page-header" }, [
                _vm._v("Text Editor Icons")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "icon-container" }, [
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-align-center" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-align-center")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-align-justify" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-align-justify")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-align-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-align-left")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-align-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-align-right")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bold" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bold")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-chain" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-chain(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-chain-broken" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-chain-broken")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-clipboard" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-clipboard")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-columns" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-columns")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-copy" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-copy(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cut" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cut(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-dedent" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-dedent(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-eraser" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-eraser")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-text" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-text")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-file-text-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-file-text-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-files-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-files-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-floppy-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-floppy-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-font" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-font")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-header" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-header")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-indent" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-indent")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-italic" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-italic")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-link" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-link")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-list" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-list")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-list-alt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-list-alt")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-list-ol" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-list-ol")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-list-ul" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-list-ul")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-outdent" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-outdent")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-paperclip" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-paperclip")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-paragraph" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-paragraph")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-paste" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-paste(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-repeat" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-repeat")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-rotate-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-rotate-left(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-rotate-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-rotate-right(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-save" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-save(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-scissors" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-scissors")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-strikethrough" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-strikethrough")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-subscript" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-subscript")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-superscript" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-superscript")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-table" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-table")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-text-height" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-text-height")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-text-width" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-text-width")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-th" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-th")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-th-large" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-th-large")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-th-list" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-th-list")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-underline" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-underline")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-undo" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-undo")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-unlink" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-unlink(alias)")])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h4", { staticClass: "page-header" }, [
                _vm._v("Directional Icons")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "icon-container" }, [
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-angle-double-down"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-angle-double-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-angle-double-left"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-angle-double-left")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-angle-double-right"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-angle-double-right")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-angle-double-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-angle-double-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-angle-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-angle-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-angle-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-angle-left")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-angle-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-angle-right")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-angle-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-angle-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-arrow-circle-down"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-arrow-circle-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-arrow-circle-left"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-arrow-circle-left")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-arrow-circle-o-down"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-arrow-circle-o-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-arrow-circle-o-left"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-arrow-circle-o-left")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-arrow-circle-o-right"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- arrow-circle-o-right")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-arrow-circle-o-up"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- arrow-circle-o-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-arrow-circle-right"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- arrow-circle-right")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-arrow-circle-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- arrow-circle-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-arrow-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- arrow-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-arrow-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- arrow-left")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-arrow-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- arrow-right")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-arrow-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- arrow-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-arrows" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- arrows")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-arrows-alt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- arrows-alt")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-arrows-h" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- arrows-h")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-arrows-v" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- arrows-v")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-caret-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- caret-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-caret-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- caret-left")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-caret-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- caret-right")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-caret-square-o-down"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- caret-square-o-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-caret-square-o-left"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- caret-square-o-left")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-caret-square-o-right"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-caret-square-o-right")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-caret-square-o-up"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-caret-square-o-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-caret-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-caret-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-chevron-circle-down"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-chevron-circle-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-chevron-circle-left"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-chevron-circle-left")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-chevron-circle-right"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-chevron-circle-right")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-chevron-circle-up"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-chevron-circle-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-chevron-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-chevron-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-chevron-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-chevron-left")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-chevron-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-chevron-right")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-chevron-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-chevron-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-exchange" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-exchange")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-o-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-o-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-o-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-o-left")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-o-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-o-right")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hand-o-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hand-o-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-long-arrow-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-long-arrow-down")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-long-arrow-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-long-arrow-left")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-long-arrow-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-long-arrow-right")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-long-arrow-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-long-arrow-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-toggle-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-toggle-down(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-toggle-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-toggle-left(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-toggle-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-toggle-right(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-toggle-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-toggle-up(alias)")])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h4", { staticClass: "page-header" }, [
                _vm._v("Video Player Icons")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "icon-container" }, [
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-arrows-alt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-arrows-alt")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-backward" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-backward")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-compress" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-compress")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-eject" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-eject")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-expand" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-expand")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-fast-backward" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-fast-backward")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-fast-forward" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-fast-forward")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-forward" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-forward")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-pause" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-pause")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-pause-circle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-pause-circle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-pause-circle-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-pause-circle-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-play" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-play")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-play-circle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-play-circle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-play-circle-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-play-circle-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-random" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-random")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-step-backward" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-step-backward")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-step-forward" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-step-forward")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-stop" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-stop")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-stop-circle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-stop-circle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-stop-circle-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-stop-circle-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-youtube-play" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-youtube-play")])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h4", { staticClass: "page-header" }, [_vm._v("Brand Icons")]),
              _vm._v(" "),
              _c("div", { staticClass: "icon-container" }, [
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-500px" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-500px")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-adn" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- adn")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-amazon" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-amazon")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-android" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-android")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-angellist" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-angellist")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-apple" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-apple")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-behance" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-behance")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-behance-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-behance-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bitbucket" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bitbucket")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bitbucket-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bitbucket-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bitcoin" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bitcoin(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-black-tie" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-black-tie")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bluetooth" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bluetooth")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-bluetooth-b" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-bluetooth-b")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-btc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-btc")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-buysellads" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-buysellads")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cc-amex" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cc-amex")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cc-diners-club" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cc-diners-club")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cc-discover" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cc-discover")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cc-jcb" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cc-jcb")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cc-mastercard" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cc-mastercard")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cc-paypal" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cc-paypal")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cc-stripe" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cc-stripe")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-cc-visa" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-cc-visa")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-chrome" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-chrome")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-codepen" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-codepen")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-codiepie" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-codiepie")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-connectdevelop" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-connectdevelop")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-contao" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-contao")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-css3" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-css3")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-dashcube" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-dashcube")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-delicious" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-delicious")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-deviantart" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-deviantart")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-digg" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-digg")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-dribbble" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-dribbble")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-dropbox" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-dropbox")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-drupal" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-drupal")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-edge" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-edge")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-empire" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-empire")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-envira" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-envira")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-expeditedssl" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-expeditedssl")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-facebook" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-facebook")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-facebook-f" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-facebook-f(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-facebook-official"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-facebook-official")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-facebook-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-facebook-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-firefox" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-firefox")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-flickr" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-flickr")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-fonticons" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-fonticons")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-fort-awesome" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-fort-awesome")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-forumbee" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-forumbee")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-foursquare" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-foursquare")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-ge" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-ge(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-get-pocket" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-get-pocket")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-gg" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-gg")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-gg-circle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-gg-circle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-git" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-git")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-git-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-git-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-github" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-github")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-github-alt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-github-alt")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-github-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-github-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-gitlab" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-gitlab")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-gittip" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-gittip(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-glide" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-glide")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-glide-g" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-glide-g")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-google" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-google")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-google-plus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-google-plus")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-google-plus-square"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- google-plus-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-google-wallet" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- google-wallet")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-gratipay" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- gratipay")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hacker-news" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- hacker-news")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-houzz" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- houzz")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-html5" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- html5")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-instagram" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- instagram")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-internet-explorer"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- internet-explorer")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-ioxhost" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- ioxhost")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-joomla" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- joomla")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-jsfiddle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- jsfiddle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-lastfm" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- lastfm")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-lastfm-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- lastfm-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-leanpub" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- leanpub")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-linkedin" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- linkedin")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-linkedin-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- linkedin-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-linux" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- linux")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-maxcdn" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- maxcdn")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-meanpath" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- meanpath")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-medium" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- medium")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-mixcloud" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- mixcloud")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-modx" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- modx")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-odnoklassniki" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- odnoklassniki")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-odnoklassniki-square"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-odnoklassniki-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-opencart" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-opencart")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-openid" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-openid")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-opera" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-opera")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-optin-monster" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-optin-monster")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-pagelines" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-pagelines")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-paypal" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-paypal")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-pied-piper" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-pied-piper")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-pied-piper-alt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-pied-piper-alt")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-pinterest" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-pinterest")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-pinterest-p" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-pinterest-p")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-pinterest-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-pinterest-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-product-hunt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-product-hunt")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-qq" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-qq")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-ra" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-ra(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-rebel" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-rebel")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-reddit" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-reddit")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-reddit-alien" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-reddit-alien")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-reddit-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-reddit-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-renren" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-renren")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-safari" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-safari")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-scribd" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-scribd")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-sellsy" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-sellsy")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-share-alt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-share-alt")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-share-alt-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-share-alt-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-shirtsinbulk" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-shirtsinbulk")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-simplybuilt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-simplybuilt")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-skyatlas" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-skyatlas")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-skype" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-skype")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-slack" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-slack")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-slideshare" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-slideshare")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-snapchat" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-snapchat")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-snapchat-ghost" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-snapchat-ghost")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-snapchat-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-snapchat-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-soundcloud" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-soundcloud")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-spotify" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-spotify")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-stack-exchange" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-stack-exchange")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-stack-overflow" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-stack-overflow")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-steam" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-steam")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-steam-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-steam-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-stumbleupon" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-stumbleupon")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-stumbleupon-circle"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-stumbleupon-circle")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-tencent-weibo" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-tencent-weibo")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-trello" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-trello")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-tripadvisor" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-tripadvisor")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-tumblr" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-tumblr")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-tumblr-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-tumblr-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-twitch" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-twitch")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-twitter" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-twitter")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-twitter-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-twitter-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-usb" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-usb")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-viacoin" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- viacoin")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-viadeo" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- viadeo")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-viadeo-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- viadeo-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-vimeo" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- vimeo")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-vimeo-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- vimeo-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-vine" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- vine")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-vk" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-vk")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-wechat" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- wechat(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-weibo" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- weibo")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-weixin" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- weixin")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-whatsapp" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- whatsapp")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-wikipedia-w" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- wikipedia-w")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-windows" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- windows")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-wordpress" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- wordpress")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-wpbeginner" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- wpbeginner")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-wpforms" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- wpforms")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-xing" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- xing")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-xing-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- xing-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-y-combinator" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- y-combinator")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-y-combinator-square"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- y-combinator-square(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-yahoo" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- yahoo")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-yc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-yc(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-yc-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- yc-square(alias)")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-yelp" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- yelp")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-youtube" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- youtube")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-youtube-play" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- youtube-play")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-youtube-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa- youtube-square")])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h4", { staticClass: "page-header" }, [
                _vm._v("Medical Icons")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "icon-container" }, [
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-ambulance" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-ambulance")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-h-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-h-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-heart" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-heart")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-heart-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-heart-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-heartbeat" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-heartbeat")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-hospital-o" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-hospital-o")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-medkit" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-medkit")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-plus-square" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-plus-square")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-stethoscope" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-stethoscope")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-user-md" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-user-md")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-wheelchair" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-wheelchair")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-wheelchair-alt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-fa-wheelchair-alt")])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }