<template>
    <form @submit.prevent="submit" id="pageEditForm">
        <tabs class="tabs-simple">
            <tab id="basic" name="Alap adatok">
                <div class="row m-1">
                    <div class="col-8">
                        <div class="card">
                            <div class="card-header">
                                Tartalom
                            </div>
                            <div class="card-body">
                                <editor ref="editor"
                                    :config="editorConfig">
                                </editor>
                            </div>
                        </div>
                    </div>

                    <div class="col-4">
                        <div class="card">
                            <div class="card-header">
                                Közzététel
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label>Állapot:</label>
                                    <select class="form-control" v-model="status">
                                        <option value="draft">Vázlat</option>
                                        <option value="published">Publikus</option>
                                        <option value="timed">Időzített</option>
                                    </select>
                                </div>

                                <div class="form-group" :class="{'collapse': !showPublishTiming}">
                                    <label>
                                        Publikálás időpontja:
                                    </label>

                                    <datetime type="datetime"
                                        v-model="page.published_at"
                                        :min-datetime="(new Date).toISOString()"
                                        format="yyyy-MM-dd HH:mm"
                                        input-class="form-control">
                                    </datetime>
                                </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-body">
                                <div class="form-group">
                                    <label for="titleInput">Cím:</label>
                                    <input type="text"
                                        class="form-control"
                                        id="titleInput"
                                        v-model="page.title">
                                </div>

                                <div class="form-group">
                                    <label for="slugInput">Slug:</label>
                                    <input type="text"
                                        class="form-control"
                                        id="slugInput"
                                        v-model="page.slug"
                                        @input="updateCanonical">
                                </div>

                                <div class="form-group">
                                    <label for="descriptionInput">Meta leírás:</label>
                                    <textarea class="form-control"
                                        id="descriptionInput"
                                        rows="4"
                                        v-model="page.description">
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </tab>

            <tab id="categorization" name="Kategorizálás">
                <div class="card">
                    <div class="card-header">
                        Kategória
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <treeselect v-model="page.category"
                                :options="categories"
                                :multiple="false"
                                :clearable="false"
                                :normalizer="normalizeCategories">
                            </treeselect>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header">
                        Címkék
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <treeselect v-model="page.tags"
                                :options="tags"
                                :multiple="true"
                                :normalizer="normalizeTags">
                            </treeselect>
                        </div>
                    </div>
                </div>
            </tab>

            <tab id="media" name="Média">
                <media-editor :images="page.images"
                    @add="showLibrary(true)">
                </media-editor>
            </tab>
        </tabs>

        <vue-medialibrary-manager :selectable="true"
            v-if="showMediaLibrary"
            @close="showMediaLibrary=false"
            @select="selectImage">
        </vue-medialibrary-manager>
    </form>
</template>

<script>
import Vue from 'vue'
import Editor from 'vue-editor-js'
import ls from '../../services/ls.js'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import MediaLibrary from '../../components/MediaLibrary'
import { Tabs, Tab } from 'vue-tabs-component'
import Treeselect from '@riophae/vue-treeselect'
import toolConfig from '../../helpers/toolConfig.js'
import MediaEditor from '../../components/MediaEditor'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import VariationEditor from '../../components/VariationEditor'


Vue.use(Editor)
Vue.use(MediaLibrary, {
    API_URL: '/api/admin/media'
});

export default {
    props: {
        original: { type: [Object], required: true },
        save: { type: [Function], required: true },
    },
    components: {
        Tab,
        Tabs,
        Datetime,
        Treeselect,
        MediaEditor,
        VariationEditor,
    },
    data () {
        return {
            page: {
                title: 'Új oldal',
                slug: 'uj-oldal',
                description: '',
                published_at: null,
                canonical: 'publications/uj-oldal',
                images: [],
                category: null,
                relations: [],
            },
            loadStatus: 0,
            status: 'draft',
            showPublishTiming: false,
            site: null,
            domain: null,
            tags: [],
            categories: [],
            libraryTarget: 'content',
            showMediaLibrary: false,
            selectCallback: () => {},
            editorConfig: {
                tools: toolConfig.get(this),
                onReady: () => {
                    this.loadStatus++
                },
                data: {},
                autofocus: true,
                placeholder: 'Write your content here'
            },
            tooltips: {
                attributes: {
                    content: 'A megadott azonosítókhoz létre kell hozni egy fordítást is a Vezérlőpult/Fordítások menüpontban'
                }
            }
        }
    },
    methods: {
        showLibrary(gallery=false){
            this.libraryTarget = gallery ? 'gallery' : 'content'
            this.showMediaLibrary = true
        },
        setSelectCallback(f){
            this.selectCallback = f
        },
        selectImage(image){
            if(this.libraryTarget=='gallery'){
                image.highlight = false
                this.page.images.push(image)
            }else{
                this.selectCallback(image)
            }

            this.showMediaLibrary = false
        },
        updateCanonical(){
            this.page.canonical = `${this.domain}/publications/${this.page.slug}`
        },
        loadRelations(){
            if(this.page.relations == undefined){
                this.page.relations = {}
            }

            this.page.images = this.page.relations?.images ? this.page.relations.images : []
        },
        loadEditor(){
            let data = this.page.content.map(o => {
                return o.content
            })

            this.$refs.editor.state.editor.render({
                blocks: data,
                version: '2.8.1',
                time: (new Date).getTime()
            })
        },
        loadCategories(){
            axios.get('/api/admin/categories', {
                params: {
                    with: 'children',
                    filters: {
                        site: this.site,
                        parent: 'pages',
                    }
                }
            })
            .then(results => {
                this.categories = results.data.data
            })
        },
        normalizeCategories(node){
            var cat = {
                id: node.id,
                label: node.name ? node.name : '',
            }

            if(node?.relations?.children.length > 0){
                cat.children = node.relations.children
            }

            return cat
        },
        loadTags(){
            axios.get('/api/admin/tags', {
                params: {
                    filters: {
                        site: this.site,
                    }
                }
            })
            .then(results => {
                this.tags = results.data.data
            })
        },
        normalizeTags(node){
            return {
                id: node.id,
                label: node.name,
            }
        },
        submit(){
            this.$emit('saving', true)

            this.$refs.editor.state.editor.save().then(data => {
                this.save(this.page, data.blocks)
            })
        },
    },
    created() {
        this.site = ls.get('site')
        this.domain = ls.get('siteDomain')
        this.loadCategories()
        this.loadTags()
    },
    watch: {
        status(newValue){
            if(newValue == 'draft'){
                this.page.published_at = null
            }else if(newValue == 'published' || !this.page.published_at){
                this.page.published_at = (new Date).toISOString()
            }

            this.showPublishTiming = newValue == 'timed'
        },
        original(newValue){
            if(!this.original) return

            this.loadStatus++
        },
        loadStatus(newValue){
            if(newValue < 2) return

            this.page = this.original

            this.loadRelations()

            this.loadEditor()

            this.updateCanonical()

            this.page.category = this.page.relations.category
                ? this.page.relations.category.id
                : null

            this.page.tags = this.page.relations.tags
                ? this.page.relations.tags.map(tag => tag.id)
                : []

            if(this.page.published_at != null){
                let date = new Date(this.page.published_at)
                let now = new Date()

                if(date > now){
                    this.status = 'timed'
                    this.page.published_at = date.toISOString()
                }else{
                    this.status = 'published'
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
