var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "table-component",
                {
                  attrs: {
                    data: _vm.getUsers,
                    "sort-by": "row.name",
                    "sort-order": "desc",
                    "table-class": "table"
                  }
                },
                [
                  _c("table-column", {
                    attrs: { show: "name", label: "Name" }
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: { show: "email", label: "Email" }
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: { show: "role", label: "Role" }
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: {
                      show: "created_at",
                      label: "Registered On",
                      "data-type": "date:YYYY-MM-DD h:i:s"
                    }
                  }),
                  _vm._v(" "),
                  _c("table-column", {
                    attrs: { sortable: false, filterable: false, label: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(row) {
                          return [
                            _c(
                              "div",
                              { staticClass: "table__actions" },
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: "/admin/users/profile" } },
                                  [
                                    _c(
                                      "a",
                                      { staticClass: "btn btn-default btn-sm" },
                                      [
                                        _c("i", {
                                          staticClass: "icon-fa icon-fa-search"
                                        }),
                                        _vm._v(" View\n                    ")
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn btn-default btn-sm",
                                    attrs: {
                                      "data-delete": "",
                                      "data-confirmation": "notie"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteUser(row.id)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "icon-fa icon-fa-trash"
                                    }),
                                    _vm._v(" Delete\n                  ")
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Users")]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Users")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [_vm._v("Users")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "page-actions" }, [
        _c("a", { staticClass: "btn btn-primary", attrs: { href: "#" } }, [
          _c("i", { staticClass: "icon-fa icon-fa-plus" }),
          _vm._v(" New User\n      ")
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "btn btn-danger" }, [
          _c("i", { staticClass: "icon-fa icon-fa-trash" }),
          _vm._v(" Delete\n      ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [_vm._v("All Users")]),
      _vm._v(" "),
      _c("div", { staticClass: "card-actions" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }