<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item active">Bejegyzések</li>
            </ol>
            <h1 class="d-inline mr-3">Bejegyzések</h1>
            <button class="btn btn-outline-info btn-sm align-text-bottom">
                <router-link to="./posts/create">
                    <i class="icon-fa icon-fa-plus"/> Új hozzáadása
                </router-link>
            </button>
        </div>
        <div class="card">
            <div class="card-body">
                <cool-table :fields="fields"
                    :data-source="dataSource"
                    :textfilter="true">
                    <template slot="title" slot-scope="props">
                        <router-link :to="`/admin/posts/${props.rowData.id}/edit`">
                            {{ props.rowData.title }}
                        </router-link>
                    </template>

                    <template slot="category" slot-scope="props">
                        <router-link v-if="props.rowData.relations.category"
                            :to="`/admin/categories/${props.rowData.relations.category.id}`">
                            {{ props.rowData.relations.category.name }}
                        </router-link>
                    </template>

                    <template slot="tags" slot-scope="props">
                        <router-link v-for="tag in props.rowData.relations.tags"
                            v-bind:key="tag.id"
                            :to="`/admin/tags/${tag.id}`">
                            {{ tag.name }}
                        </router-link>
                    </template>

                    <template slot="author" slot-scope="props">
                        <router-link v-if="props.rowData.relations.author"
                            :to="`/admin/users/${props.rowData.relations.author.id}`">
                            {{ props.rowData.relations.author.name }}
                        </router-link>
                    </template>
                </cool-table>
            </div>
        </div>
    </div>
</template>

<script>
import ls from '../../services/ls.js'
import CoolTable from '../../components/CoolTable'

export default {
    components: {
        CoolTable
    },
    data () {
        return {
            site: null,
            fields: [
                {
                    name: 'title',
                    title: 'Cím',
                    sortField: 'title'
                },
                {
                    name: 'category',
                    title: 'Kategória',
                    sortField: 'category'
                },
                {
                    name: 'tags',
                    title: 'Címkék',
                    sortField: 'tags'
                },
                {
                    name: 'author',
                    title: 'Szerző',
                    sortField: 'author'
                },
                {
                    name: 'published_at',
                    title: 'Publikálva',
                    sortField: 'published_at'
                }
            ]
        }
    },
    methods: {
        dataSource (sortOrder, pagination, filters) {
            var sort = {
                field: sortOrder[0] ? sortOrder[0].sortField : 'title',
                direction: sortOrder[0] ? sortOrder[0].direction : 'asc',
            };

            return axios.get('/api/admin/publications', {
                params: {
                    with: 'category,tags,author',
                    page: pagination.current_page,
                    filters: {
                        site: this.site,
                        type: 'post',
                        ...filters,
                    },
                    sort: sort.field,
                    order: sort.direction
                }
            })
        }
    },
    created() {
        this.site = ls.get('site')
    }
}
</script>

<style>

</style>
