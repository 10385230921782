<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item"><router-link slot="item-title" to="/admin/carousels">Képváltók</router-link></li>
                <li class="breadcrumb-item active">Új képváltó</li>
            </ol>
            <div class="d-flex align-items-center">
                <h1>Új képváltó</h1>

                <div class="btn-group ml-auto">
                    <button class="btn btn-sm btn-success" form="carouselEditForm">
                        <i class="icon-fa icon-fa-save" v-if="!saving"></i>
                        <i class="icon-fa icon-fa-circle-o-notch icon-fa-spin" v-if="saving"></i>
                        Mentés
                    </button>
                </div>
            </div>
        </div>

        <form @submit.prevent="submit" id="carouselEditForm">
            <div class="form-group">
                <label for="carouselNameInput">Név</label>
                <input type="text" id="carouselNameInput" v-model="carousel.name" class="form-control">
            </div>
        </form>
    </div>
</template>

<script>
import ls from '../../services/ls.js'

export default {
    data () {
        return {
            carousel: {
                name: 'Új képváltó',
            },
            site: null,
            saving: false,
        }
    },
    methods: {
        submit(){
            this.saving = true

            axios.post(`/api/admin/carousels`, {
                ...this.carousel,
                site: this.site,
            }).then(response => {
                this.$router.push(`/admin/carousels/${response.data.id}/edit`)
                toastr.success('Képváltó elmentve', 'Siker')
            }).catch(err => {
                toastr.error('Hiba a mentés közben', 'Hiba')
            }).finally(() => {
                this.saving = false
            })
        }
    },
    created() {
        this.site = ls.get('site')
    },
}
</script>

<style>

</style>
