import { render, staticRenderFns } from "./Users.vue?vue&type=template&id=9af4261c&"
import script from "./Users.vue?vue&type=script&lang=js&"
export * from "./Users.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/media/thed/vol_40/Laravel/bmat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9af4261c')) {
      api.createRecord('9af4261c', component.options)
    } else {
      api.reload('9af4261c', component.options)
    }
    module.hot.accept("./Users.vue?vue&type=template&id=9af4261c&", function () {
      api.rerender('9af4261c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/admin/users/Users.vue"
export default component.exports