var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main-content" }, [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [_vm._v("Form Layouts")]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Forms")])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v("Form Layouts")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("h6", [_vm._v("Basic Form")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("form", [
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c("label", { attrs: { for: "inputFirstName" } }, [
                      _vm._v("First Name")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "inputFirstName",
                        type: "text",
                        placeholder: "First Name"
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c("label", { attrs: { for: "inputLastName" } }, [
                      _vm._v("Password")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "inputLastName",
                        type: "password",
                        placeholder: "Last Name"
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "exampleInputEmail" } }, [
                    _vm._v("Email address")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "form-control",
                    attrs: {
                      id: "exampleInputEmail",
                      type: "email",
                      "aria-describedby": "emailHelp",
                      placeholder: "Enter email"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "small",
                    {
                      staticClass: "form-text text-muted",
                      attrs: { id: "emailHelp" }
                    },
                    [
                      _vm._v(
                        "\n                We'll never share your email with\n                anyone else.\n              "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "inputUserName" } }, [
                    _vm._v("Username")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "form-control",
                    attrs: {
                      id: "inputUserName",
                      type: "text",
                      placeholder: "Username"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Gender")]),
                  _vm._v(" "),
                  _c("div", [
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("input", {
                        staticClass: "form-check-input",
                        attrs: {
                          id: "checkMale",
                          type: "radio",
                          name: "gender",
                          value: "male"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "checkMale" }
                        },
                        [_vm._v("Male")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("input", {
                        staticClass: "form-check-input",
                        attrs: {
                          id: "checkFemale",
                          type: "radio",
                          name: "gender",
                          value: "female"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "checkFemale" }
                        },
                        [_vm._v("Female")]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "exampleInputPassword" } }, [
                    _vm._v("Password")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "form-control",
                    attrs: {
                      id: "exampleInputPassword1",
                      type: "password",
                      placeholder: "Password"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("button", { staticClass: "btn btn-primary" }, [
                  _vm._v("Submit")
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-6" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("h6", [_vm._v("Horizontal Form")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("form", [
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-4 col-form-label",
                      attrs: { for: "firstName" }
                    },
                    [_vm._v("First Name")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "firstName",
                        type: "text",
                        placeholder: "First Name"
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-4 col-form-label",
                      attrs: { for: "lastName" }
                    },
                    [_vm._v("Last Name")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "lastName",
                        type: "text",
                        placeholder: "Last Name"
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-4 col-form-label",
                      attrs: { for: "email" }
                    },
                    [_vm._v("Email")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "email",
                        type: "email",
                        placeholder: "Email"
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-4 col-form-label",
                      attrs: { for: "password" }
                    },
                    [_vm._v("Password")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "password",
                        type: "password",
                        placeholder: "Password"
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("button", { staticClass: "btn btn-success" }, [
                  _vm._v("Save")
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("h6", [_vm._v("Inline Form")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("form", { staticClass: "form-inline" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    { staticClass: "sr-only", attrs: { for: "staticEmail2" } },
                    [_vm._v("Email")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "form-control-plaintext",
                    attrs: {
                      id: "staticEmail2",
                      type: "text",
                      readonly: "",
                      value: "email@example.com"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group mx-sm-3" }, [
                  _c(
                    "label",
                    {
                      staticClass: "sr-only",
                      attrs: { for: "inputPassword2" }
                    },
                    [_vm._v("Password")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "form-control",
                    attrs: {
                      id: "inputPassword2",
                      type: "password",
                      placeholder: "Password"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("button", { staticClass: "btn btn-primary" }, [
                  _vm._v("Confirm identity")
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }