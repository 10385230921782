var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xl-4 mb-4" },
      [
        _c("h5", { staticClass: "section-semi-title" }, [
          _vm._v("SINGLE SELECT")
        ]),
        _vm._v(" "),
        _c("multiselect", {
          attrs: {
            options: _vm.SingleOptions,
            searchable: true,
            "show-labels": false,
            placeholder: "Pick a value"
          },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-xl-4 mb-4" },
      [
        _c("h5", { staticClass: "section-semi-title" }, [
          _vm._v("WITH GROUPS")
        ]),
        _vm._v(" "),
        _c(
          "multiselect",
          {
            attrs: {
              options: _vm.GroupOptions,
              multiple: true,
              "group-values": "city",
              "group-label": "timezone",
              placeholder: "Type to search",
              "track-by": "name",
              label: "name"
            },
            model: {
              value: _vm.GroupValue,
              callback: function($$v) {
                _vm.GroupValue = $$v
              },
              expression: "GroupValue"
            }
          },
          [
            _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
              _vm._v(
                "Oops! No elements found. Consider changing the search query."
              )
            ])
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-xl-4 mb-4" },
      [
        _c("h5", { staticClass: "section-semi-title" }, [
          _vm._v("MULTI SELECT")
        ]),
        _vm._v(" "),
        _c("multiselect", {
          attrs: { options: _vm.MultiOptions, multiple: true },
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }