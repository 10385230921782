var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mailbox-modal" }, [
    _c("div", { staticClass: "mail-header" }, [
      _c("div", { staticClass: "mail-actions" }, [
        _c("ul", { staticClass: "mail-action-list" }, [
          _c("li", { staticClass: "action", on: { click: _vm.closeModal } }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top-center",
                    value: "Go Back",
                    expression: "'Go Back'",
                    modifiers: { "top-center": true }
                  }
                ],
                attrs: { href: "#" }
              },
              [_c("i", { staticClass: "icon-fa icon-fa-arrow-left" })]
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "action" }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top-center",
                    value: "Report",
                    expression: "'Report'",
                    modifiers: { "top-center": true }
                  }
                ],
                attrs: { href: "#" }
              },
              [_c("i", { staticClass: "icon-fa icon-fa-exclamation" })]
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "action" }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top-center",
                    value: "Trash",
                    expression: "'Trash'",
                    modifiers: { "top-center": true }
                  }
                ],
                attrs: { href: "#" }
              },
              [_c("i", { staticClass: "icon-fa icon-fa-trash" })]
            )
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "action" }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top-center",
                    value: "Forward",
                    expression: "'Forward'",
                    modifiers: { "top-center": true }
                  }
                ],
                attrs: { href: "#" }
              },
              [
                _c("i", {
                  staticClass: "icon-fa icon-fa-envelope-open icon-fa-fw"
                })
              ]
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mail-body" },
      [
        _c("h3", { staticClass: "mail-subject" }, [
          _vm._v("Lorem ipsum dolor sit amet, consectetur adipisicing elit.")
        ]),
        _vm._v(" "),
        _vm._l(_vm.messages, function(message) {
          return _c("message", {
            key: message.id,
            attrs: { message: message },
            on: { open: _vm.changeCollapse }
          })
        }),
        _vm._v(" "),
        _vm._m(0)
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message-reply form-group-row" }, [
      _c("textarea", {
        staticClass: "form-control",
        attrs: { id: "reply", name: "comment", cols: "75", rows: "4" }
      }),
      _vm._v(" "),
      _c("button", { staticClass: "btn btn-primary mt-2" }, [
        _vm._v("\n        Reply\n      ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }