var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xl-4 mb-4" },
      [
        _c("h5", { staticClass: "section-semi-title" }, [
          _vm._v("Phone number")
        ]),
        _vm._v(" "),
        _c("masked-input", {
          staticClass: "form-control",
          attrs: {
            mask: [
              "(",
              /[1-9]/,
              /\d/,
              /\d/,
              ")",
              " ",
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/
            ],
            guide: true,
            "keep-char-positions": true,
            "show-mask": true,
            type: "text",
            name: "phone"
          },
          model: {
            value: _vm.phone,
            callback: function($$v) {
              _vm.phone = $$v
            },
            expression: "phone"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-xl-4 mb-4" },
      [
        _c("h5", { staticClass: "section-semi-title" }, [_vm._v("Date")]),
        _vm._v(" "),
        _c("masked-input", {
          staticClass: "form-control",
          attrs: {
            mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
            guide: true,
            "keep-char-positions": true,
            "show-mask": true,
            type: "text",
            name: "date"
          },
          model: {
            value: _vm.date,
            callback: function($$v) {
              _vm.date = $$v
            },
            expression: "date"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-xl-4 mb-4" },
      [
        _c("h5", { staticClass: "section-semi-title" }, [
          _vm._v("Auto Correct Date")
        ]),
        _vm._v(" "),
        _c("masked-input", {
          staticClass: "form-control",
          attrs: {
            mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
            guide: true,
            pipe: _vm.auto,
            "keep-char-positions": true,
            "show-mask": true,
            type: "text",
            name: "dateAuto"
          },
          model: {
            value: _vm.dateAuto,
            callback: function($$v) {
              _vm.dateAuto = $$v
            },
            expression: "dateAuto"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }