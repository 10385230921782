<template>
    <form @submit.prevent="submit" id="saleEditForm">
        <tabs class="tabs-simple">
            <tab id="basic"
                name="Alap adatok">
                <div class="row m-1">
                    <div class="col-12 col-md-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="form-group">
                                    <label for="nameInput">Név:</label>
                                    <input type="text"
                                        class="form-control"
                                        id="nameInput"
                                        v-model="sale.name">
                                </div>

                                <div class="form-group">
                                    <label for="valueInput">Kedvezmény:</label>

                                    <div class="input-group">
                                        <input type="number"
                                            min="0"
                                            step="any"
                                            class="form-control"
                                            id="ValueInput"
                                            v-model="sale.value">

                                        <div class="input-group-append">
                                            <select id="typeInput" class="btn btn-default" v-model="sale.type">
                                                <option value="0">Ft</option>
                                                <option value="1">%</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="form-group">
                                    <label>
                                        Érvényes ettől:
                                    </label>

                                    <datetime type="datetime"
                                        v-model="sale.starts_at"
                                        :min-datetime="(new Date).toISOString()"
                                        format="yyyy-MM-dd HH:mm"
                                        input-class="form-control">
                                    </datetime>
                                </div>

                                <div class="form-group">
                                    <label>
                                        Érvényes eddig:
                                    </label>

                                    <datetime type="datetime"
                                        v-model="sale.ends_at"
                                        :min-datetime="(new Date).toISOString()"
                                        format="yyyy-MM-dd HH:mm"
                                        input-class="form-control">
                                    </datetime>
                                </div>

                                <div class="form-group">
                                    <label for="orderInput"
                                        v-tooltip="'Ha több akció érvényes egy időben, akkor a legnagyobb prioritású lesz aktív.'">
                                        Prioritás
                                        <i class="icon-fa icon-fa-question-circle"></i>
                                    </label>

                                    <div class="input-group input-group-sm spinbox">
                                        <div class="input-group-prepend">
                                            <button class="btn btn-danger" type="button" @click="decreaseOrder()">
                                                <i class="icon-fa icon-fa-fw icon-fa-minus w-100"></i>
                                            </button>
                                        </div>
                                        <input type="number"
                                            min="0"
                                            id="orderInput"
                                            class="form-control"
                                            v-model="sale.order">
                                        <div class="input-group-append">
                                            <button class="btn btn-success" type="button" @click="sale.order++">
                                                <i class="icon-fa icon-fa-fw icon-fa-plus w-100"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </tab>


            <tab id="rules"
                name="Szabályok"
                v-if="sale.id">
                <div class="alert alert-info">
                    A kizárt mezők elsőbbséget élveznek, vagyis a kizárt termékek akkor sem kerülnek az akcióba ha az a hozzáadott termékek listájában szerepel.
                </div>

                <div class="row">
                    <div class="col-12 col-md-6">
                        <h4>Hozzáadás</h4>

                        <div class="form-group">
                            <label>Hozzáadott kategóriák</label>
                            <treeselect v-model="sale.include.categories"
                                :options="categories"
                                :multiple="true"
                                :normalizer="normalizeCategories">
                            </treeselect>
                        </div>

                        <div class="form-group">
                            <label>Hozzáadott címkék</label>
                            <treeselect v-model="sale.include.tags"
                                :options="tags"
                                :multiple="true"
                                :normalizer="normalizeTags">
                            </treeselect>
                        </div>

                        <div class="form-group">
                            <label>Hozzáadott termékek</label>
                            <treeselect v-model="sale.include.products"
                                :load-options="loadProducts"
                                :multiple="true"
                                :async="true"
                                :default-options="defaultProducts('include')">
                            </treeselect>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <h4>Kizárás</h4>

                        <div class="form-group">
                            <label>Kizárt kategóriák</label>
                            <treeselect v-model="sale.exclude.categories"
                                :options="categories"
                                :multiple="true"
                                :normalizer="normalizeCategories">
                            </treeselect>
                        </div>

                        <div class="form-group">
                            <label>Kizárt címkék</label>
                            <treeselect v-model="sale.exclude.tags"
                                :options="tags"
                                :multiple="true"
                                :normalizer="normalizeTags">
                            </treeselect>
                        </div>

                        <div class="form-group">
                            <label>Kizárt termékek</label>
                            <treeselect v-model="sale.exclude.products"
                                :load-options="loadProducts"
                                :multiple="true"
                                :async="true"
                                :default-options="defaultProducts('exclude')">
                            </treeselect>
                        </div>
                    </div>
                </div>
            </tab>

            <tab id="products"
                name="Termékek"
                v-if="sale.id">

                <div class="alert alert-info">
                    A beállított szabályok <strong>{{ sale.relations.products.length }} db</strong> termékre érvényesek.
                    <button class="btn btn-info btn-sm" type="button" @click="requestReload">
                        <i class="icon-fa icon-fa-refresh"></i>
                        Frissítés
                    </button>
                </div>

                <router-link v-for="product in sale.relations.products"
                    :key="product.product_id"
                    :to="`/admin/products/${product.product_id}/edit`">
                    <span class="badge badge-secondary p-2 m-1">
                        {{ product.title }}
                    </span>
                </router-link>
            </tab>
        </tabs>
    </form>
</template>

<script>
import ls from '../../services/ls.js'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import { Tabs, Tab } from 'vue-tabs-component'
import { Treeselect, ASYNC_SEARCH } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
    props: {
        original: { type: [Object], required: true },
        save: { type: [Function], required: true },
    },
    components: {
        Tab,
        Tabs,
        Datetime,
        Treeselect,
    },
    data () {
        return {
            sale: {
                name: 'Új akció',
                type: 0,
                value: 0,
                include: {
                    categories: [],
                    tags: [],
                    products: [],
                },
                exclude: {
                    categories: [],
                    tags: [],
                    products: [],
                },
                order: 0,
                starts_at: (new Date).toISOString(),
                ends_at: null,
                relations: {}
            },
            loadStatus: 0,
            site: null,
            tags: [],
            categories: [],
        }
    },
    methods: {
        loadCategories(){
            axios.get('/api/admin/categories', {
                params: {
                    with: 'children',
                    filters: {
                        site: this.site,
                        parent: 'products',
                    },
                    size: 0,
                }
            })
            .then(results => {
                this.categories = results.data.data
            })
        },
        normalizeCategories(node){
            var cat = {
                id: node.id,
                label: node.name ? node.name : '',
            }

            if(node?.relations?.children.length > 0){
                cat.children = node.relations.children
            }

            return cat
        },
        loadTags(){
            axios.get('/api/admin/tags', {
                params: {
                    filters: {
                        site: this.site,
                    },
                    size: 0,
                }
            })
            .then(results => {
                this.tags = results.data.data
            })
        },
        normalizeTags(node){
            return {
                id: node.id,
                label: node.name,
            }
        },
        loadProducts({action, searchQuery, callback}){
            if(action == ASYNC_SEARCH){
                axios.get(`/api/admin/publications`, {
                    params: {
                        //with: ''
                        filters: {
                            type: 'product',
                            text: searchQuery,
                        },
                        size: 0,
                    }
                }).then(response => {
                    let data = response.data.data.map(product => {
                        return {
                            id: product.product_id,
                            label: product.title,
                        }
                    })

                    callback(null, data)
                })

            }
        },
        defaultProducts(type){
            return this.sale.relations.ruleProducts[type].map(product => {
                return {
                    id: product.product_id,
                    label: product.title,
                }
            })
        },
        decreaseOrder(){
            this.sale.order = this.sale.order<=0
                ? 0
                : this.sale.order-1
        },
        submit(){
            this.save(this.sale)
        },
        requestReload(){
            this.$emit('requestReload')
        }
    },
    created() {
        this.site = ls.get('site')
        this.loadCategories()
        this.loadTags()
    },
    watch: {
        original(newValue){
            if(!this.original) return

            this.loadStatus++
        },
        loadStatus(newValue){
            if(newValue < 1) return

            this.sale = this.original
        }
    }
}
</script>

<style scoped>
.table td{
    border-top: none;
}

.spinbox input::-webkit-outer-spin-button,
.spinbox input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.spinbox input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
