<template>
    <sweet-modal ref="copyModal" overlay-theme="dark" modal-theme="dark">
        <form class="text-left">
            <div class="form-group">
                <label for="targetSelect">Cél domain</label>
                <select name="target"
                    id="targetSelect"
                    class="form-control"
                    v-model="copy.site"
                    @change="setCopyNames"
                    @input="setCopyNames">
                    <option :value="option.id"
                        v-for="option in sites"
                        :key="option.id">
                        {{ option.domain }}
                        <span v-if="option.id == site">
                            &mdash; aktuális domain
                        </span>
                    </option>
                </select>
            </div>

            <div class="form-group" v-if="copy.title">
                <label for="copyTitleInput">Másolat név</label>
                <input type="text" class="form-control" id="copyTitleInput" v-model="copy.title">
            </div>

            <div class="form-group" v-if="copy.slug">
                <label for="copySlugInput">Másolat slug</label>
                <input type="text" class="form-control" id="copySlugInput" v-model="copy.slug">
            </div>
        </form>

        <button slot="button"
            type="button"
            class="btn btn-success"
            @click="copyItem">
            Másolás
        </button>
    </sweet-modal>
</template>

<script>
import ls from '../services/ls.js'
import { SweetModal } from 'sweet-modal-vue'


export default {
    components: {
        SweetModal
    },
    props: {
        original: { type: [Object] }
    },
    data () {
        return {
            copy: {},
            sites: [],
            site: null,
        }
    },
    methods: {
        open(){
            this.copy.site = this.site
            this.loadSites()
            this.setCopyNames()
            this.$refs.copyModal.open()
        },
        loadSites(){
            axios.get('/api/admin/sites')
                .then(results => {
                    this.sites = results.data.data
                })
        },
        setCopyNames(){
            let title = this.original.title ? this.original.title : this.original.name

            if(this.site == this.copy.site){
                this.copy.title = `${title} másolat`
                this.copy.slug = `${this.original.slug}-masolat`
            }else{
                this.copy.title = title
                this.copy.slug = this.original.slug
            }
            this.$forceUpdate()
        },
        copyItem(){
            axios.post(`/api/admin/publications/${this.original.id}/copy`, this.copy)
                .then(response => {
                    this.$refs.copyModal.close()
                    toastr.success('Másolat elmentve. Kattints a megtekintéshez', 'Siker')
                }).catch(err => {
                    console.log(err.message)
                    toastr.error('Hiba a mentés közben', 'Hiba')
                })
        }
    },
    created() {
        this.site = ls.get('site')
    }
}
</script>
