var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: { id: "postEditForm" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      }
    },
    [
      _c(
        "tabs",
        { staticClass: "tabs-simple" },
        [
          _c("tab", { attrs: { id: "basic", name: "Alap adatok" } }, [
            _c("div", { staticClass: "row m-1" }, [
              _c("div", { staticClass: "col-8" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-header" }, [
                    _vm._v(
                      "\n                            Tartalom\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("editor", {
                        ref: "editor",
                        attrs: { config: _vm.editorConfig }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-4" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-header" }, [
                    _vm._v(
                      "\n                            Közzététel\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Állapot:")]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.status,
                              expression: "status"
                            }
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.status = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "draft" } }, [
                            _vm._v("Vázlat")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "published" } }, [
                            _vm._v("Publikus")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "timed" } }, [
                            _vm._v("Időzített")
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        class: { collapse: !_vm.showPublishTiming }
                      },
                      [
                        _c("label", [
                          _vm._v(
                            "\n                                    Publikálás időpontja:\n                                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("datetime", {
                          attrs: {
                            type: "datetime",
                            "min-datetime": new Date().toISOString(),
                            format: "yyyy-MM-dd HH:mm",
                            "input-class": "form-control"
                          },
                          model: {
                            value: _vm.post.published_at,
                            callback: function($$v) {
                              _vm.$set(_vm.post, "published_at", $$v)
                            },
                            expression: "post.published_at"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "titleInput" } }, [
                        _vm._v("Cím:")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.post.title,
                            expression: "post.title"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", id: "titleInput" },
                        domProps: { value: _vm.post.title },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.post, "title", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "slugInput" } }, [
                        _vm._v("Slug:")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.post.slug,
                            expression: "post.slug"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", id: "slugInput" },
                        domProps: { value: _vm.post.slug },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.post, "slug", $event.target.value)
                            },
                            _vm.updateCanonical
                          ]
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "descriptionInput" } }, [
                        _vm._v("Meta leírás:")
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.post.description,
                            expression: "post.description"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { id: "descriptionInput", rows: "4" },
                        domProps: { value: _vm.post.description },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.post,
                              "description",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "tab",
            { attrs: { id: "categorization", name: "Kategorizálás" } },
            [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v("\n                    Kategória\n                ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("treeselect", {
                        attrs: {
                          options: _vm.categories,
                          multiple: false,
                          clearable: false,
                          normalizer: _vm.normalizeCategories
                        },
                        model: {
                          value: _vm.post.category,
                          callback: function($$v) {
                            _vm.$set(_vm.post, "category", $$v)
                          },
                          expression: "post.category"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v("\n                    Címkék\n                ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("treeselect", {
                        attrs: {
                          options: _vm.tags,
                          multiple: true,
                          normalizer: _vm.normalizeTags
                        },
                        model: {
                          value: _vm.post.tags,
                          callback: function($$v) {
                            _vm.$set(_vm.post, "tags", $$v)
                          },
                          expression: "post.tags"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "tab",
            { attrs: { id: "media", name: "Média" } },
            [
              _c("media-editor", {
                attrs: { images: _vm.post.images },
                on: {
                  add: function($event) {
                    return _vm.showLibrary(true)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showMediaLibrary
        ? _c("vue-medialibrary-manager", {
            attrs: { selectable: true },
            on: {
              close: function($event) {
                _vm.showMediaLibrary = false
              },
              select: _vm.selectImage
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }