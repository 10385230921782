<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item active">Kategóriák</li>
            </ol>
            <h1 class="d-inline mr-3">Kategóriák</h1>
            <button class="btn btn-outline-info btn-sm align-text-bottom" @click="createCategory">
                <i class="icon-fa icon-fa-plus"/> Új hozzáadása
            </button>
        </div>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-body inline-tree-container">
                        <treeselect ref="treeselect"
                            v-model="category"
                            :options="categories"
                            :multiple="false"
                            :clearable="false"
                            :normalizer="normalizer"
                            :show-count="true"
                            show-count-of="LEAF_CHILDREN"
                            :always-open="true"
                            @select="changeCategory">
                        </treeselect>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <form v-if="editBuffer" @submit.prevent="saveCategory">
                            <div class="form-group">
                                <label for="nameInput">Név</label>
                                <input type="text" class="form-control" v-model="editBuffer.name">
                            </div>

                            <div class="form-group">
                                <label for="slugInput">Slug</label>
                                <input type="text" class="form-control" v-model="editBuffer.slug">
                            </div>

                            <div class="form-group">
                                <label>Szülő kategória</label>
                                <treeselect ref="parentselect"
                                    v-model="editBuffer.parent"
                                    :options="parents"
                                    :multiple="false"
                                    :normalizer="normalizer">
                                </treeselect>
                            </div>

                            <div class="form-group">
                                <label for="descriptionInput">Leírás</label>
                                <editor ref="editor"
                                    :config="editorConfig">
                                </editor>
                            </div>

                            <div class="w-100 overflow-hidden" style="max-height: 300px;">
                                <img :src="editBuffer.relations.image.dataUrl" v-if="editBuffer.relations.image" class="img-thumbnail w-100">
                            </div>

                            <button class="btn btn-primary btn-sm mt-3">
                                <i class="icon-fa icon-fa-save" v-if="!saving"></i>
                                <i class="icon-fa icon-fa-spin icon-fa-circle-o-notch" v-if="saving"></i>
                                Mentés
                            </button>

                            <button class="btn btn-sm btn-dark mt-3 ml-auto pull-right" type="button" @click="showMediaLibrary=true">
                                <i class="icon-fa icon-fa-image"></i>
                                Kép beállítása
                            </button>
                        </form>

                        <div v-if="!editBuffer">
                            <p>Nincs kijelölve szerkeszthető elem!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <vue-medialibrary-manager :selectable="true"
            v-if="showMediaLibrary"
            @close="showMediaLibrary=false"
            @select="selectImage">
        </vue-medialibrary-manager>

    </div>
</template>

<script>
import Vue from 'vue'
import ls from '../../services/ls.js'
import Editor from 'vue-editor-js/src/index'
import Treeselect from '@riophae/vue-treeselect'
import toolConfig from '../../helpers/slimToolConfig.js'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import MediaLibrary from '../../components/MediaLibrary'

Vue.use(Editor)
Vue.use(MediaLibrary, {
    API_URL: '/api/admin/media'
});

export default {
    components: {
        Treeselect
    },
    data () {
        return {
            category: null,
            categories: [],
            site: null,
            editBuffer: null,
            saving: false,
            enableDescription: false,
            showMediaLibrary: false,
            editorConfig: {
                tools: toolConfig.get(this),
                onReady: () => {
                    this.enableDescription = true
                    this.loadDescription()
                },
                data: {},
                autofocus: true,
                placeholder: 'Leírás'
            }
        }
    },
    computed: {
        parents(){
            var selectedId = this.editBuffer.id
            var categories = JSON.parse(JSON.stringify(this.categories))

            return categories.filter(function f(o){
                if(o.id == selectedId || o.id == 'new'){
                    return false
                }

                if(o.relations && o.relations.children){
                    var children = o.relations.children.filter(f)
                    if(children.length > 0){
                        o.children = o.relations.children = children
                    }else{
                        o.relations = []

                        if(o.children){
                            delete o.children
                        }
                    }
                }

                o.collapsed = true
                return true
            })
        }
    },
    methods: {
        loadCategories(updateLoader=false){
            if(updateLoader){
                this.$refs.treeselect.rootOptionsStates.isLoading = true
            }

            axios.get('/api/admin/categories', {
                params: {
                    with: 'children,parent,description,image',
                    filters: {
                        site: this.site,
                        parent: 'root'
                    }
                }
            })
            .then(results => {
                this.categories = results.data.data

                if(this.categories.length){
                    this.category = this.categories[0].id
                    this.editBuffer = this.categories[0]
                    this.editBuffer.parent = this.categories[0].relations.parent ? this.categories[0].relations.parent.id : null
                    this.loadDescription()
                }

                if(updateLoader){
                    this.$refs.treeselect.rootOptionsStates.isLoading = false
                }
            })
        },
        normalizer (node) {
            node.label = node.name
            node.isDefaultExpanded = node.collapsed ? false : true

            if(node.relations && node.relations.children && node.relations.children.length > 0){
                node.children = node.relations.children
            }

            return node
        },
        loadDescription(){
            if(!this.enableDescription){
                return
            }

            let data = this.editBuffer.relations.description.map(o => {
                return o.content
            })

            if(data.length==0){
                data = [{"data": {"text": ""}, "type": "paragraph"}]
            }

            this.$refs.editor.state.editor.render({
                blocks: data,
                version: '2.8.1',
                time: (new Date).getTime()
            })
        },
        createCategory(){
            this.categories.push({
                id: 'new',
                name: 'Új kategória',
                slug: 'new',
                relations: {
                    description: [],
                }
            })
        },
        changeCategory (node) {
            this.editBuffer = node
            this.editBuffer.parent = node.relations.parent ? node.relations.parent.id : null
            this.loadDescription()
        },
        saveCategory(){
            this.saving = true

            this.$refs.editor.state.editor.save().then(description => {
                if(this.editBuffer.id == 'new'){
                    let data = {
                        site: this.site,
                        description: description.blocks,
                        ...this.editBuffer
                    }
                    axios.post(`/api/admin/categories`, data)
                        .then(result => {
                            toastr.success('A kategória létrehozva', 'Siker')
                            this.editBuffer.id = result.data.data.id
                            this.saving = false
                            this.loadCategories(true)
                        })
                        .catch(err => {
                            toastr.error('Nem sikerült elmenteni a kategóriát', 'Hiba')
                        })
                }else{
                    if(!this.editBuffer.parent){
                        this.editBuffer.parent = null
                    }

                    this.editBuffer.description = description.blocks

                    axios.patch(`/api/admin/categories/${this.editBuffer.id}`, this.editBuffer)
                        .then(result => {
                            toastr.success('A kategória frissítve', 'Siker')
                            this.saving = false
                            this.loadCategories(true)
                        })
                        .catch(err => {
                            toastr.error('Nem sikerült elmenteni a kategóriát', 'Hiba')
                        })
                }
            })
        },
        selectImage(image){
            this.editBuffer.relations.image = image
            this.showMediaLibrary = false
        }
    },
    created() {
        this.site = ls.get('site')

        this.loadCategories()
    }
}
</script>

<style>
.inline-tree-container .vue-treeselect__menu-container,
.inline-tree-container .vue-treeselect__menu{
    position: static;
}
</style>
