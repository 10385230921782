var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "mb-4" },
              [
                _c("h5", { staticClass: "section-semi-title" }, [
                  _vm._v("\n              Line Chart\n            ")
                ]),
                _vm._v(" "),
                _c("line-chart", {
                  attrs: {
                    labels: [
                      "January",
                      "February",
                      "March",
                      "April",
                      "May",
                      "June",
                      "July"
                    ],
                    values: [65, 59, 80, 81, 56, 55, 40]
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-4" },
              [
                _c("h5", { staticClass: "section-semi-title" }, [
                  _vm._v("\n              Bar Chart\n            ")
                ]),
                _vm._v(" "),
                _c("bar-chart", {
                  attrs: {
                    labels: [
                      "January",
                      "February",
                      "March",
                      "April",
                      "May",
                      "June",
                      "July"
                    ],
                    values: [65, 59, 80, 81, 56, 55, 40]
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-6 mb-4" },
                [
                  _c("h5", { staticClass: "section-semi-title" }, [
                    _vm._v("\n                Pie Chart\n              ")
                  ]),
                  _vm._v(" "),
                  _c("pie-chart", {
                    attrs: {
                      labels: _vm.pieAndDoughtnut.labels,
                      values: _vm.pieAndDoughtnut.data,
                      "bg-colors": _vm.pieAndDoughtnut.bgColors,
                      "hover-bg-colors": _vm.pieAndDoughtnut.hoverBgColors
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-md-6 mb-4" },
                [
                  _c("h5", { staticClass: "section-semi-title" }, [
                    _vm._v("\n                Doughnut Chart\n              ")
                  ]),
                  _vm._v(" "),
                  _c("doughnut-chart", {
                    attrs: {
                      labels: _vm.pieAndDoughtnut.labels,
                      values: _vm.pieAndDoughtnut.data,
                      "bg-colors": _vm.pieAndDoughtnut.bgColors,
                      "hover-bg-colors": _vm.pieAndDoughtnut.hoverBgColors
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Chart JS")]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Charts")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Chart JS")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [_vm._v("Chartjs")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }