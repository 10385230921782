<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item"><router-link slot="item-title" to="/admin/products">Termékek</router-link></li>
                <li class="breadcrumb-item active">{{ product.title }}</li>
            </ol>
            <div class="d-flex align-items-center">
                <h1>Termék szerkesztése</h1>

                <div class="btn-group ml-auto">
                    <v-dropdown active-url="/" theme-light>
                        <a slot="activator" href="#" @click.prevent>
                            <button class="btn btn-sm btn-light dropdown-toggle"
                                type="button">
                                Műveletek
                            </button>
                        </a>

                        <v-dropdown-item>
                            <a href="#" @click.prevent="$refs.copyModal.open()">Másolás</a>
                        </v-dropdown-item>
                    </v-dropdown>

                    <button class="btn btn-sm btn-success" form="productEditForm">
                        <i class="icon-fa icon-fa-save" v-if="!saving"></i>
                        <i class="icon-fa icon-fa-circle-o-notch icon-fa-spin" v-if="saving"></i>
                        Mentés
                    </button>
                </div>
            </div>
        </div>

        <product-editor :original="product"
            :save="saveCallback">
        </product-editor>

        <copy-modal :original="product" ref="copyModal"></copy-modal>
    </div>
</template>

<script>
import ls from '../../services/ls.js'
import store from '../../services/vuex'
import ProductEditor from './ProductEditor'
import CopyModal from '../../components/CopyModal'

export default {
    components: {
        CopyModal,
        ProductEditor,
    },
    data () {
        return {
            site: null,
        }
    },
    computed: {
        product(){
            return store.state.products.product
        },
        saving(){
            return store.state.products.saving
        }
    },
    methods: {
        saveCallback(product, content){
            product.site = this.site
            product.content = content

            store.dispatch('products/updateProduct', product)
        }
    },
    created() {
        this.site = ls.get('site')

        store.dispatch('products/getProduct', {
            id: this.$route.params.product
        })
    },
}
</script>

<style scoped>
.table td{
    border-top: none;
}
</style>
