var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main-content" }, [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [_vm._v("Typography")]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Basic UI")])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v("Typography")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("h6", [_vm._v("Headings")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body buttons-demo" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("h5", { staticClass: "section-semi-title" }, [
                    _vm._v("Default Headings")
                  ]),
                  _vm._v(" "),
                  _c("h1", [_vm._v("h1. Bootstrap heading")]),
                  _vm._v(" "),
                  _c("h2", [_vm._v("h2. Bootstrap heading")]),
                  _vm._v(" "),
                  _c("h3", [_vm._v("h3. Bootstrap heading")]),
                  _vm._v(" "),
                  _c("h4", [_vm._v("h4. Bootstrap heading")]),
                  _vm._v(" "),
                  _c("h5", [_vm._v("h5. Bootstrap heading")]),
                  _vm._v(" "),
                  _c("h6", [_vm._v("h6. Bootstrap heading")]),
                  _vm._v(" "),
                  _c("h3", [
                    _vm._v("Fancy display heading\n                "),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v("With faded secondary text")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("h5", { staticClass: "section-semi-title" }, [
                    _vm._v("Display Headings")
                  ]),
                  _vm._v(" "),
                  _c("h1", { staticClass: "display-1" }, [_vm._v("Display 1")]),
                  _vm._v(" "),
                  _c("h1", { staticClass: "display-2" }, [_vm._v("Display 2")]),
                  _vm._v(" "),
                  _c("h1", { staticClass: "display-3" }, [_vm._v("Display 3")]),
                  _vm._v(" "),
                  _c("h1", { staticClass: "display-4" }, [_vm._v("Display 4")])
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("h6", [_vm._v("Other")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("h5", { staticClass: "section-semi-title" }, [
                    _vm._v("Paragraph")
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis corporis eum libero\n              minima minus obcaecati quae sit soluta tempora, totam."
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("h5", { staticClass: "section-semi-title" }, [
                    _vm._v("Paragraph Lead")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "lead" }, [
                    _vm._v(
                      "\n                Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Duis mollis, est\n                non commodo luctus.\n              "
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-4" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("h5", { staticClass: "section-semi-title" }, [
                    _vm._v("Inline Text Elements")
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("You can use the mark tag to\n                "),
                    _c("mark", [_vm._v("highlight")]),
                    _vm._v("\n                text.\n              ")
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("del", [
                      _vm._v(
                        "This line of text is meant to be treated as deleted text."
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("s", [
                      _vm._v(
                        "This line of text is meant to be treated as no longer accurate."
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("ins", [
                      _vm._v(
                        "This line of text is meant to be treated as an addition to the document."
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("u", [
                      _vm._v("This line of text will render as underlined")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("small", [
                      _vm._v(
                        "This line of text is meant to be treated as fine print."
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("strong", [_vm._v("This line rendered as bold text.")])
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("em", [_vm._v("This line rendered as italicized text.")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("h5", { staticClass: "section-semi-title" }, [
                    _vm._v("Block Quotes")
                  ]),
                  _vm._v(" "),
                  _c("blockquote", { staticClass: "blockquote" }, [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer\n                posuere erat a ante."
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-4" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("h5", { staticClass: "section-semi-title" }, [
                    _vm._v("Unordered List")
                  ]),
                  _vm._v(" "),
                  _c("ul", [
                    _c("li", [_vm._v("Lorem ipsum dolor sit amet")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Consectetur adipiscing elit")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Integer molestie lorem at massa")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Facilisis in pretium nisl aliquet")]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "Nulla volutpat aliquam velit\n                  "
                      ),
                      _c("ul", [
                        _c("li", [_vm._v("Phasellus iaculis neque")]),
                        _vm._v(" "),
                        _c("li", [_vm._v("Purus sodales ultricies")]),
                        _vm._v(" "),
                        _c("li", [_vm._v("Vestibulum laoreet porttitor sem")]),
                        _vm._v(" "),
                        _c("li", [_vm._v("Ac tristique libero volutpat at")])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Faucibus porta lacus fringilla vel")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Aenean sit amet erat nunc")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Eget porttitor lorem")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("h5", { staticClass: "section-semi-title" }, [
                    _vm._v("Ordered List")
                  ]),
                  _vm._v(" "),
                  _c("ol", [
                    _c("li", [_vm._v("Lorem ipsum dolor sit amet")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Consectetur adipiscing elit")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Integer molestie lorem at massa")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Facilisis in pretium nisl aliquet")]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "Nulla volutpat aliquam velit\n                  "
                      ),
                      _c("ul", [
                        _c("li", [_vm._v("Phasellus iaculis neque")]),
                        _vm._v(" "),
                        _c("li", [_vm._v("Purus sodales ultricies")]),
                        _vm._v(" "),
                        _c("li", [_vm._v("Vestibulum laoreet porttitor sem")]),
                        _vm._v(" "),
                        _c("li", [_vm._v("Ac tristique libero volutpat at")])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Faucibus porta lacus fringilla vel")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Aenean sit amet erat nunc")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Eget porttitor lorem")])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-4" }, [
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("h5", { staticClass: "section-semi-title" }, [
                    _vm._v("Unstyled List")
                  ]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "list-unstyled" }, [
                    _c("li", [_vm._v("Lorem ipsum dolor sit amet")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Consectetur adipiscing elit")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Integer molestie lorem at massa")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Facilisis in pretium nisl aliquet")]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "Nulla volutpat aliquam velit\n                  "
                      ),
                      _c("ul", [
                        _c("li", [_vm._v("Phasellus iaculis neque")]),
                        _vm._v(" "),
                        _c("li", [_vm._v("Purus sodales ultricies")]),
                        _vm._v(" "),
                        _c("li", [_vm._v("Vestibulum laoreet porttitor sem")]),
                        _vm._v(" "),
                        _c("li", [_vm._v("Ac tristique libero volutpat at")])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Faucibus porta lacus fringilla vel")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Aenean sit amet erat nunc")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Eget porttitor lorem")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-6" }, [
                  _c("h5", { staticClass: "section-semi-title" }, [
                    _vm._v("Inline List")
                  ]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "list-inline" }, [
                    _c("li", { staticClass: "list-inline-item" }, [
                      _vm._v("Lorem ipsum")
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-inline-item" }, [
                      _vm._v("Phasellus iaculis")
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-inline-item" }, [
                      _vm._v("Nulla volutpat")
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }