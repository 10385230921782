var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content buttons-demo" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _vm._m(3),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("h5", { staticClass: "section-semi-title" }, [
                  _vm._v("Toolbar")
                ]),
                _vm._v(" "),
                _vm._m(4),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn-group",
                      attrs: {
                        role: "group",
                        "aria-label": "Button group with nested dropdown"
                      }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-default",
                          attrs: { type: "button" }
                        },
                        [_vm._v("\n                    1\n                  ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-default",
                          attrs: { type: "button" }
                        },
                        [_vm._v("\n                    2\n                  ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "btn-group", attrs: { role: "group" } },
                        [
                          _c(
                            "v-dropdown",
                            {
                              attrs: { "show-arrow": false, "theme-light": "" }
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-outline-default dropdown-toggle",
                                  attrs: {
                                    slot: "activator",
                                    id: "btnGroupDrop1",
                                    type: "button",
                                    "data-toggle": "dropdown",
                                    "aria-haspopup": "true",
                                    "aria-expanded": "false"
                                  },
                                  slot: "activator"
                                },
                                [
                                  _vm._v(
                                    "\n                        Dropdown\n                      "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-dropdown-item", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { href: "#" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                          Dropdown link\n                        "
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-dropdown-item", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { href: "#" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                          Dropdown link\n                        "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm._m(5)
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("h5", { staticClass: "section-semi-title" }, [
                  _vm._v("Vertical Group")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "btn-group-vertical",
                    attrs: {
                      role: "group",
                      "aria-label": "Button group with nested dropdown"
                    }
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-primary",
                        attrs: { type: "button" }
                      },
                      [_vm._v("\n                  1\n                ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-primary",
                        attrs: { type: "button" }
                      },
                      [_vm._v("\n                  2\n                ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "btn-group", attrs: { role: "group" } },
                      [
                        _c(
                          "v-dropdown",
                          { attrs: { "show-arrow": false, "theme-light": "" } },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-outline-primary dropdown-toggle",
                                attrs: {
                                  slot: "activator",
                                  id: "btnGroupDrop1",
                                  type: "button",
                                  "data-toggle": "dropdown",
                                  "aria-haspopup": "true",
                                  "aria-expanded": "false"
                                },
                                slot: "activator"
                              },
                              [
                                _vm._v(
                                  "\n                      Dropdown\n                    "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("v-dropdown-item", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" }
                                },
                                [
                                  _vm._v(
                                    "\n                        Dropdown link\n                      "
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("v-dropdown-item", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-item",
                                  attrs: { href: "#" }
                                },
                                [
                                  _vm._v(
                                    "\n                        Dropdown link\n                      "
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _vm._m(6)
              ]),
              _vm._v(" "),
              _vm._m(7)
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm._m(8)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Buttons")]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Basic UI")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [_vm._v("Buttons")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h6", [_vm._v("General Buttons")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("h5", { staticClass: "section-semi-title" }, [
                    _vm._v("Default Buttons")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-theme" }, [
                    _vm._v("\n                  Theme\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-primary" }, [
                    _vm._v("\n                  Primary\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-secondary" }, [
                    _vm._v("\n                  Secondary\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-info" }, [
                    _vm._v("\n                  Info\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-danger" }, [
                    _vm._v("\n                  Danger\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-success" }, [
                    _vm._v("\n                  Success\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-warning" }, [
                    _vm._v("\n                  Warning\n                ")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("h5", { staticClass: "section-semi-title" }, [
                  _vm._v("Outline Buttons")
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-theme",
                    attrs: { type: "button" }
                  },
                  [_vm._v("\n                Theme\n              ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-primary",
                    attrs: { type: "button" }
                  },
                  [_vm._v("\n                Primary\n              ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-secondary",
                    attrs: { type: "button" }
                  },
                  [_vm._v("\n                Secondary\n              ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-success",
                    attrs: { type: "button" }
                  },
                  [_vm._v("\n                Success\n              ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-info",
                    attrs: { type: "button" }
                  },
                  [_vm._v("\n                Info\n              ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-warning",
                    attrs: { type: "button" }
                  },
                  [_vm._v("\n                Warning\n              ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-danger",
                    attrs: { type: "button" }
                  },
                  [_vm._v("\n                Danger\n              ")]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-4" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("h5", { staticClass: "section-semi-title" }, [
                  _vm._v("Sizes")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("button", { staticClass: "btn btn-primary btn-xs" }, [
                    _vm._v("\n                  Extra Small\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-primary btn-sm" }, [
                    _vm._v("\n                  Small\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-primary" }, [
                    _vm._v("\n                  Default\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-primary btn-lg" }, [
                    _vm._v("\n                  Large\n                ")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("h5", { staticClass: "section-semi-title" }, [
                  _vm._v("Rounded Buttons")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("button", { staticClass: "btn btn-theme btn-rounded" }, [
                    _vm._v("\n                  Theme\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-primary btn-rounded" }, [
                    _vm._v("\n                  Primary\n                ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "btn btn-secondary btn-rounded" },
                    [_vm._v("\n                  Secondary\n                ")]
                  ),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-info btn-rounded" }, [
                    _vm._v("\n                  Info\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-danger btn-rounded" }, [
                    _vm._v("\n                  Danger\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-success btn-rounded" }, [
                    _vm._v("\n                  Success\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-warning btn-rounded" }, [
                    _vm._v("\n                  Warning\n                ")
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row mt-4" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("h5", { staticClass: "section-semi-title" }, [
                  _vm._v("Icon Buttons")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("button", { staticClass: "btn btn-theme" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-plane" }),
                    _vm._v(" Theme\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-primary" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-star" }),
                    _vm._v(" Primary\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-secondary" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-map-marker" }),
                    _vm._v(" Mark\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-info" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-envelope" }),
                    _vm._v(" Notify\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-danger" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-trash" }),
                    _vm._v(" Delete\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-success" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-user" }),
                    _vm._v(" Login\n                ")
                  ]),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-warning" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-warning" }),
                    _vm._v(" Warn\n                ")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("h5", { staticClass: "section-semi-title" }, [
                  _vm._v("Pressable Buttons")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("button", { staticClass: "btn btn-theme btn-pressable" }, [
                    _c("i", { staticClass: "icon-fa icon-fa-plane" }),
                    _vm._v(" Theme\n                ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "btn btn-primary btn-pressable" },
                    [_vm._v("\n                  Primary\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "btn btn-secondary btn-pressable" },
                    [_vm._v("\n                  Secondary\n                ")]
                  ),
                  _vm._v(" "),
                  _c("button", { staticClass: "btn btn-info btn-pressable" }, [
                    _vm._v("\n                  Info\n                ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "btn btn-danger btn-pressable" },
                    [_vm._v("\n                  Danger\n                ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "btn btn-success btn-pressable" },
                    [_vm._v("\n                  Warning\n                ")]
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [_vm._v("Button Groups")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-6" }, [
      _c("h5", { staticClass: "section-semi-title" }, [_vm._v("Variations")]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "div",
          {
            staticClass: "btn-group btn-group-sm",
            attrs: { role: "group", "aria-label": "Basic example" }
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-default",
                attrs: { type: "button" }
              },
              [_vm._v("\n                    Left\n                  ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-default",
                attrs: { type: "button" }
              },
              [_vm._v("\n                    Middle\n                  ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-default",
                attrs: { type: "button" }
              },
              [_vm._v("\n                    Right\n                  ")]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "div",
          {
            staticClass: "btn-group",
            attrs: { role: "group", "aria-label": "Basic example" }
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary",
                attrs: { type: "button" }
              },
              [_vm._v("\n                    Left\n                  ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary",
                attrs: { type: "button" }
              },
              [_vm._v("\n                    Middle\n                  ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary",
                attrs: { type: "button" }
              },
              [_vm._v("\n                    Right\n                  ")]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "div",
          {
            staticClass: "btn-group btn-group-lg",
            attrs: { role: "group", "aria-label": "Basic example" }
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-danger",
                attrs: { type: "button" }
              },
              [_vm._v("\n                    Left\n                  ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-danger",
                attrs: { type: "button" }
              },
              [_vm._v("\n                    Middle\n                  ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-danger",
                attrs: { type: "button" }
              },
              [_vm._v("\n                    Right\n                  ")]
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c(
        "div",
        {
          staticClass: "btn-toolbar",
          attrs: { role: "toolbar", "aria-label": "Toolbar with button groups" }
        },
        [
          _c(
            "div",
            {
              staticClass: "btn-group mr-2",
              attrs: { role: "group", "aria-label": "First group" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-default",
                  attrs: { type: "button" }
                },
                [_vm._v("\n                      1\n                    ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-default",
                  attrs: { type: "button" }
                },
                [_vm._v("\n                      2\n                    ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-default",
                  attrs: { type: "button" }
                },
                [_vm._v("\n                      3\n                    ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-default",
                  attrs: { type: "button" }
                },
                [_vm._v("\n                      4\n                    ")]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btn-group mr-2",
              attrs: { role: "group", "aria-label": "Second group" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-default",
                  attrs: { type: "button" }
                },
                [_vm._v("\n                      5\n                    ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-default",
                  attrs: { type: "button" }
                },
                [_vm._v("\n                      6\n                    ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-default",
                  attrs: { type: "button" }
                },
                [_vm._v("\n                      7\n                    ")]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btn-group",
              attrs: { role: "group", "aria-label": "Third group" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-default",
                  attrs: { type: "button" }
                },
                [_vm._v("\n                      8\n                    ")]
              )
            ]
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c(
        "div",
        {
          staticClass: "btn-toolbar",
          attrs: { role: "toolbar", "aria-label": "Toolbar with button groups" }
        },
        [
          _c(
            "div",
            {
              staticClass: "btn-group mr-2",
              attrs: { role: "group", "aria-label": "First group" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-icon btn-info",
                  attrs: { type: "button" }
                },
                [_c("i", { staticClass: "icon-fa icon-fa-align-left" })]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-icon btn-info",
                  attrs: { type: "button" }
                },
                [_c("i", { staticClass: "icon-fa icon-fa-align-center" })]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-icon btn-info",
                  attrs: { type: "button" }
                },
                [_c("i", { staticClass: "icon-fa icon-fa-align-right" })]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btn-group mr-2",
              attrs: { role: "group", "aria-label": "First group" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-icon btn-info",
                  attrs: { type: "button" }
                },
                [_c("i", { staticClass: "icon-fa icon-fa-bold" })]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-icon btn-info",
                  attrs: { type: "button" }
                },
                [_c("i", { staticClass: "icon-fa icon-fa-italic" })]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-icon btn-info",
                  attrs: { type: "button" }
                },
                [_c("i", { staticClass: "icon-fa icon-fa-underline" })]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btn-group mr-2",
              attrs: { role: "group", "aria-label": "First group" }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-icon btn-outline-info",
                  attrs: { type: "button" }
                },
                [_c("i", { staticClass: "icon-fa icon-fa-file-image-o" })]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-icon btn-outline-info",
                  attrs: { type: "button" }
                },
                [_c("i", { staticClass: "icon-fa icon-fa-folder" })]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-icon btn-outline-info",
                  attrs: { type: "button" }
                },
                [_c("i", { staticClass: "icon-fa icon-fa-pencil" })]
              )
            ]
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "btn-group-vertical",
        attrs: {
          role: "group",
          "aria-label": "Button group with nested dropdown"
        }
      },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-icon btn-outline-primary",
            attrs: { type: "button" }
          },
          [_c("i", { staticClass: "icon-fa icon-fa-file" })]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-icon btn-outline-primary",
            attrs: { type: "button" }
          },
          [_c("i", { staticClass: "icon-fa icon-fa-pencil" })]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-icon btn-outline-primary",
            attrs: { type: "button" }
          },
          [_c("i", { staticClass: "icon-fa icon-fa-image" })]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-6" }, [
      _c("h5", { staticClass: "section-semi-title" }, [
        _vm._v("Justified Button Group")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "btn-group btn-group-justified" }, [
        _c("div", { staticClass: "btn-group", attrs: { role: "group" } }, [
          _c(
            "button",
            { staticClass: "btn btn-primary", attrs: { type: "button" } },
            [
              _c("i", {
                staticClass: "icon-fa icon-fa-list",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "text-uppercase hidden-xs" }, [
                _vm._v("Listing")
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "btn-group", attrs: { role: "group" } }, [
          _c(
            "button",
            { staticClass: "btn btn-info", attrs: { type: "button" } },
            [
              _c("i", {
                staticClass: "icon-fa icon-fa-star",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "text-uppercase hidden-xs" }, [
                _vm._v("Review")
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "btn-group", attrs: { role: "group" } }, [
          _c(
            "button",
            { staticClass: "btn btn-success", attrs: { type: "button" } },
            [
              _c("i", {
                staticClass: "icon-fa icon-fa-rocket",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "text-uppercase hidden-xs" }, [
                _vm._v("Details")
              ])
            ]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h6", [_vm._v("Ladda Buttons")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body ladda-buttons-demo" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("h5", { staticClass: "section-semi-title" }, [
                  _vm._v("Expand")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary ladda-button",
                      attrs: { "data-style": "expand-left" }
                    },
                    [
                      _c("span", { staticClass: "ladda-label" }, [
                        _vm._v("expand-left")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary ladda-button",
                      attrs: { "data-style": "expand-right" }
                    },
                    [
                      _c("span", { staticClass: "ladda-label" }, [
                        _vm._v("expand-right")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary ladda-button",
                      attrs: { "data-style": "expand-up" }
                    },
                    [
                      _c("span", { staticClass: "ladda-label" }, [
                        _vm._v("expand-up")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary ladda-button",
                      attrs: { "data-style": "expand-down" }
                    },
                    [
                      _c("span", { staticClass: "ladda-label" }, [
                        _vm._v("expand-down")
                      ])
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("h5", { staticClass: "section-semi-title" }, [
                  _vm._v("Zoom")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info ladda-button",
                      attrs: { "data-style": "zoom-in" }
                    },
                    [
                      _c("span", { staticClass: "ladda-label" }, [
                        _vm._v("zoom-in")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info ladda-button",
                      attrs: { "data-style": "zoom-out" }
                    },
                    [
                      _c("span", { staticClass: "ladda-label" }, [
                        _vm._v("zoom-out")
                      ])
                    ]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("h5", { staticClass: "section-semi-title" }, [
                  _vm._v("Progress Bar")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group progress-demo" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning ladda-button",
                      attrs: { "data-style": "expand-left" }
                    },
                    [
                      _c("span", { staticClass: "ladda-label" }, [
                        _vm._v("expand-left")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning ladda-button",
                      attrs: { "data-style": "expand-right" }
                    },
                    [
                      _c("span", { staticClass: "ladda-label" }, [
                        _vm._v("expand-right")
                      ])
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("h5", { staticClass: "section-semi-title" }, [
                  _vm._v("Slide")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger ladda-button",
                      attrs: { "data-style": "slide-left" }
                    },
                    [
                      _c("span", { staticClass: "ladda-label" }, [
                        _vm._v("slide-left")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger ladda-button",
                      attrs: { "data-style": "slide-right" }
                    },
                    [
                      _c("span", { staticClass: "ladda-label" }, [
                        _vm._v("slide-right")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger ladda-button",
                      attrs: { "data-style": "slide-up" }
                    },
                    [
                      _c("span", { staticClass: "ladda-label" }, [
                        _vm._v("slide-up")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-danger ladda-button",
                      attrs: { "data-style": "slide-down" }
                    },
                    [
                      _c("span", { staticClass: "ladda-label" }, [
                        _vm._v("slide-down")
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }