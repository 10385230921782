<template>
  <header class="site-header">
    <a href="/admin/dashboard" class="brand-main">
        <span class="d-none d-md-inline h2">ContentHub</span>
        <span class="d-md-none h2">HUB</span>
    </a>
    <a
      href="#"
      class="nav-toggle"
      @click="onNavToggle"
    >
      <div class="hamburger hamburger--arrowturn">
        <div class="hamburger-box">
          <div class="hamburger-inner"/>
        </div>
      </div>
    </a>
    <ul class="action-list">
      <li>
        <v-dropdown :show-arrow="false">
          <a slot="activator" href="#">
            <i class="icon-fa icon-fa-plus"/>
          </a>
          <v-dropdown-item>
            <router-link to="/admin/posts/create">
              <i class="icon-fa icon-fa-edit"/> Új bejegyzés
            </router-link>
          </v-dropdown-item>
          <v-dropdown-item>
            <router-link to="/admin/categories">
              <i class="icon-fa icon-fa-reorder"/> Új kategória
            </router-link>
          </v-dropdown-item>
          <v-dropdown-item>
            <router-link to="/admin/tags">
              <i class="icon-fa icon-fa-tag"/> Új címke
            </router-link>
          </v-dropdown-item>
          <v-dropdown-item>
            <router-link to="/admin/products/create">
              <i class="icon-fa icon-fa-shopping-basket"/> Új termék
            </router-link>
          </v-dropdown-item>
        </v-dropdown>
      </li>
      <li v-if="false">
        <v-dropdown :show-arrow="false">
          <a
            slot="activator"
            href="#"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="icon-fa icon-fa-bell"/>
          </a>
          <div class="notification-dropdown">
            <h6 class="dropdown-header">
              Notifications
            </h6>
            <v-dropdown-item>
              <a class="dropdown-item" href="#">
                <i class="icon-fa icon-fa-user"/> New User was Registered
              </a>
            </v-dropdown-item>
            <v-dropdown-item>
              <a class="dropdown-item" href="#">
                <i class="icon-fa icon-fa-comment"/> A Comment has been posted.
              </a>
            </v-dropdown-item>
          </div>
        </v-dropdown>
      </li>
      <li>
        <v-dropdown :show-arrow="false">
          <a slot="activator" href="#">
            <i class="icon-fa icon-fa-user"/>
          </a>

          <v-dropdown-item>
            <router-link class="dropdown-item" to="/admin/users/profile">
              <i class="icon-fa icon-fa-user"/> Profil szerkesztése
            </router-link>
          </v-dropdown-item>

          <v-dropdown-item>
            <a
              href="#"
              class="dropdown-item"
              @click.prevent="logout"
            >
              <i class="icon-fa icon-fa-sign-out"/> Kijelentkezés
            </a>
          </v-dropdown-item>
        </v-dropdown>
      </li>
    </ul>
  </header>
</template>
<script type="text/babel">
import Auth from '../../../services/auth'

export default {
  methods: {
    onNavToggle () {
      this.$utils.toggleSidebar()
    },
    logout () {
      Auth.logout().then(() => {
        this.$router.replace('/login')
      })
    }
  }
}
</script>
