<template>
    <div class="main-content" v-if="order">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item"><router-link slot="item-title" to="/admin/orders">Rendelések</router-link></li>
                <li class="breadcrumb-item active">{{ order.code }}</li>
            </ol>
            <div class="d-flex align-items-center">
                <h1>Rendelés adatlap</h1>

                <div class="btn-group ml-auto">
                    <!--<v-dropdown active-url="/" theme-light>
                        <a slot="activator" href="#" @click.prevent>
                            <button class="btn btn-sm btn-light dropdown-toggle"
                                type="button">
                                Műveletek
                            </button>
                        </a>

                        <v-dropdown-item>
                            <a href="#" @click.prevent="$refs.copyModal.open()">Másolás</a>
                        </v-dropdown-item>
                    </v-dropdown>-->

                    <button class="btn btn-sm btn-success" form="orderEditForm">
                        <i class="icon-fa icon-fa-save" v-if="!saving.global"></i>
                        <i class="icon-fa icon-fa-circle-o-notch icon-fa-spin" v-if="saving.global"></i>
                        Mentés
                    </button>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <div class="alert alert-danger" v-if="order.relations.statusWarning">
                    <strong>Figyelem!</strong>
                    {{ statusWarning }}
                </div>

                <tabs class="tabs-simple">
                    <form @submit.prevent="submit" id="orderEditForm">
                        <tab id="basic"
                            name="Alap adatok">
                            <div class="row pl-2" v-if="subpage=='details'">
                                <div class="col-6">
                                    <h6 class="text-uppercase text-center">Rendelés</h6>

                                    <div class="form-group">
                                        <label for="codeInput" class="mb-0">Státusz
                                            <small>&mdash;</small>
                                            <button class="btn-link btn btn-sm pl-0"
                                                type="button"
                                                @click="subpage='statusHistory'">
                                                    <small>
                                                        {{ formatUtcDate(order.relations.status.created_at) }} óta
                                                    </small>
                                            </button>
                                        </label>

                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text"
                                                    :class="{
                                                        'bg-success': saving.status==2,
                                                        'bg-danger': saving.status==3,
                                                        'text-white': saving.status==2 || saving.status==3
                                                    }">
                                                    <i class="icon-fa icon-fa-fw icon-fa-signal" v-if="saving.status==0"></i>
                                                    <i class="icon-fa icon-fa-fw icon-fa-spin icon-fa-circle-o-notch" v-if="saving.status==1"></i>
                                                    <i class="icon-fa icon-fa-fw icon-fa-check" v-if="saving.status==2"></i>
                                                    <i class="icon-fa icon-fa-fw icon-fa-exclamation" v-if="saving.status==3"></i>
                                                </span>
                                            </div>
                                            <select class="form-control" v-model="order.status" @change="changeStatus">
                                                <option v-for="(status, code) in statuses"
                                                    :key="code"
                                                    :value="code">
                                                    {{ status }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="codeInput">Azonosító</label>

                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="icon-fa icon-fa-hashtag"></i>
                                                </span>
                                            </div>
                                            <input type="text"
                                                class="form-control"
                                                readonly="readonly"
                                                :value="order.code">
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="codeInput">Rendelés időpontja</label>

                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="icon-fa icon-fa-calendar"></i>
                                                </span>
                                            </div>
                                            <input type="text"
                                                class="form-control"
                                                readonly="readonly"
                                                :value="formatUtcDate(order.created_at)">
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label>Megjegyzés</label>

                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="icon-fa icon-fa-comment-o"></i>
                                                </span>
                                            </div>
                                            <textarea class="form-control" :readonly="readonly" v-model="order.note"></textarea>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label>Belső megjegyzés csak az adminoknak</label>

                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="icon-fa icon-fa-comment"></i>
                                                </span>
                                            </div>
                                            <textarea class="form-control" v-model="order.adminNote"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <h6 class="text-uppercase text-center">
                                        Megrendelő
                                        <router-link :to="`/admin/contacts/${order.relations.contact.id}`" class="ml-2">
                                            <i class="icon-fa icon-fa-external-link"></i>
                                        </router-link>
                                    </h6>

                                    <div class="form-group">
                                        <label for="nameInput">Név</label>

                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="icon-fa icon-fa-user"></i>
                                                </span>
                                            </div>
                                            <input type="text"
                                                class="form-control"
                                                :readonly="readonly"
                                                v-model="order.relations.contact.name">
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="emailInput">E-mail cím</label>

                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="icon-fa icon-fa-at"></i>
                                                </span>
                                            </div>
                                            <input type="email"
                                                class="form-control"
                                                :readonly="readonly"
                                                v-model="order.relations.contact.email">
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="phoneInput">Telefonszám</label>

                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="icon-fa icon-fa-phone"></i>
                                                </span>
                                            </div>
                                            <input type="text"
                                                class="form-control"
                                                :readonly="readonly"
                                                v-model="order.relations.contact.phone">
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="addressInput">Cím</label>

                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="icon-fa icon-fa-map-marker"></i>
                                                </span>
                                            </div>
                                            <textarea id="addressInput"
                                                class="form-control"
                                                :readonly="readonly"
                                                v-model="order.relations.contact.address"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-else class="pl-2">
                                <button class="btn btn-link btn-sm mb-3" type="button" @click="subpage='details'">
                                    <i class="icon-fa icon-fa-arrow-left"></i>
                                    Vissza
                                </button>
                                <h6 class="text-uppercase">Státusz előzmények</h6>

                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Státusz</th>
                                            <th>Ki állította be?</th>
                                            <th>Mikor?</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(status, key) in order.relations.statusHistory" :key="key">
                                            <td>{{ statuses[status.status] }}</td>
                                            <td>{{ status.user ? status.user.name : '' }}</td>
                                            <td>{{ formatUtcDate(status.created_at) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </tab>

                        <tab id="products"
                            name="Termékek">
                            <div class="mb-3" v-if="!readonlyProducts">
                                <button class="btn btn-link btn-sm"
                                    type="button"
                                    @click="addForm=!addForm">
                                    <i class="icon-fa icon-fa-plus" v-if="!addForm"></i>
                                    <i class="icon-fa icon-fa-caret-up" v-if="addForm"></i>
                                    {{ addForm ? 'Elrejtés' : 'Termék hozzáadása' }}
                                </button>
                                <div v-if="addForm" class="bg-light p-2">
                                    <multiselect v-model="selectedProduct"
                                        :options="products"
                                        label="title"
                                        track-by="id"
                                        deselect-label="Kiválasztott"
                                        placeholder="Válassz ki egy terméket"
                                        select-label="Enter a kiválasztáshoz"
                                        selected-label="Kiválasztott"
                                        :allow-empty="false"
                                        @search-change="loadProducts">
                                    </multiselect>

                                    <multiselect v-if="selectedProduct && selectedProduct.product_type == 2"
                                        v-model="selectedVariation"
                                        :options="selectedProduct.relations.variations"
                                        label="title"
                                        track-by="id"
                                        deselect-label="Kiválasztott"
                                        placeholder="Válassz ki egy variációt"
                                        select-label="Enter a kiválasztáshoz"
                                        selected-label="Kiválasztott"
                                        :allow-empty="false">
                                    </multiselect>

                                    <button type="button"
                                        class="btn btn-sm btn-info nowrap mt-2"
                                        @click="addProduct()">
                                        <i class="icon-fa icon-fa-plus"></i>
                                        Hozzáadás
                                    </button>
                                </div>
                            </div>

                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Név</th>
                                        <th>Mennyiség</th>
                                        <th>Egységár</th>
                                        <th>Ár</th>
                                    </tr>
                                </thead>

                                <tbody v-for="(product, key) in order.relations.products" :key="key">
                                    <tr>
                                        <td>
                                            <button v-if="product.product_type == 1"
                                                type="button"
                                                class="btn btn-sm"
                                                @click="toggleProductRow(key)">
                                                <i class="icon-fa icon-fa-fw"
                                                    :class="opened==key ? 'icon-fa-caret-down' : 'icon-fa-caret-right'"></i>
                                            </button>
                                            <span class="mr-4 ml-3" v-else></span>
                                            <i class="icon-fa icon-fa-fw"
                                                :class="types[product.product_type].icon"
                                                v-tooltip="{content: `${types[product.product_type].name}` }">
                                            </i>
                                            <router-link :to="`/admin/products/${product.publication_id}/edit`">
                                                {{ product.title }}
                                            </router-link>
                                        </td>
                                        <td style="width:12em">
                                            <div class="form-group mb-0" v-if="!readonlyProducts">
                                                <div class="input-group input-group-sm spinbox">
                                                    <div class="input-group-prepend">
                                                        <button class="btn btn-danger"
                                                            type="button"
                                                            v-tooltip="'Termék eltávolítása'"
                                                            @click="remove(product.id)">
                                                            <i class="icon-fa icon-fa-fw icon-fa-trash w-100"></i>
                                                        </button>
                                                        <button class="btn btn-danger"
                                                            type="button"
                                                            v-tooltip="'Mennyiség csökkentése'"
                                                            @click="decrease(product.id)">
                                                            <i class="icon-fa icon-fa-fw icon-fa-minus w-100"></i>
                                                        </button>
                                                    </div>
                                                    <input type="number"
                                                        min="0"
                                                        step="any"
                                                        class="form-control"
                                                        v-model="product.amount">
                                                    <div class="input-group-append">
                                                        <button class="btn btn-success"
                                                            type="button"
                                                            v-tooltip="'Mennyiség növelése'"
                                                            @click="increase(product.id)">
                                                            <i class="icon-fa icon-fa-fw icon-fa-plus w-100"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>{{ product.amount }}</div>
                                        </td>

                                        <!-- unit price column -->
                                        <td v-if="!readonlyProducts">
                                            <!-- Egyedi ár -->
                                            <button class="btn btn-sm btn-info"
                                                type="button"
                                                v-if="hasCustomPrice(product)"
                                                v-tooltip="`Egyedi ár van beállítva`"
                                                @click="setPriceModal(product)">
                                                {{ usedPrice(product) }} Ft
                                            </button>

                                            <!-- Semmilyen ár nincsen beállítva -->
                                            <button class="btn btn-sm btn-danger"
                                                type="button"
                                                v-else-if="product.priceWhenOrdered === null && product.price === null"
                                                v-tooltip="`Nincs ár beállítva`"
                                                @click="setPriceModal(product)">
                                                <i class="icon-fa icon-fa-exclamation-triangle"></i>
                                                Nincs Ár!!
                                            </button>

                                            <!-- Rendeléskor nem volt ár -->
                                            <button class="btn btn-sm btn-danger"
                                                type="button"
                                                v-else-if="product.priceWhenOrdered === null"
                                                v-tooltip="`Rendeléskor nem volt ár beállítva`"
                                                @click="setPriceModal(product)">
                                                <i class="icon-fa icon-fa-exclamation-triangle"></i>
                                                {{ usedPrice(product) }} Ft
                                            </button>

                                            <!-- Rendelés óta megváltozott -->
                                            <button class="btn btn-sm btn-warning"
                                                type="button"
                                                v-else-if="product.priceWhenOrdered != product.price"
                                                v-tooltip="`Rendelés óta megváltozott az ár`"
                                                @click="setPriceModal(product)">
                                                {{ usedPrice(product) }} Ft
                                            </button>

                                            <!-- Minden ok -->
                                            <button class="btn btn-sm btn-primary"
                                                type="button"
                                                v-else
                                                @click="setPriceModal(product)">
                                                {{ usedPrice(product) }} Ft
                                            </button>
                                        </td>
                                        <td v-else>{{ usedPrice(product) }} Ft</td>

                                        <!-- Price sum column -->
                                        <td>{{ usedPrice(product) * product.amount }} Ft</td>
                                    </tr>

                                    <template v-if="key==opened && product.product_type==1">
                                        <tr v-for="bundle in product.products"
                                            :key="bundle.id">
                                            <td>
                                                <i class="icon-fa icon-fa-fw"
                                                    :class="types[bundle.product_type].icon"
                                                    v-tooltip="{content: `${types[bundle.product_type].name}` }">
                                                </i>
                                                {{ bundle.title }}
                                            </td>
                                            <td>
                                                {{ bundle.amount }}
                                            </td>
                                            <td>
                                                {{ bundle.price }} Ft
                                            </td>
                                            <td>
                                                {{ bundle.price * bundle.amount }} Ft
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>

                                <tfoot>
                                    <tr class="border-top">
                                        <th colspan="3">Összesen</th>
                                        <th v-if="!validPriceSum">
                                            <span v-if="!validPriceSum" class="text-danger">
                                                <i class="icon-fa icon-fa-exclamation-triangle"></i>
                                                Érvénytelen
                                            </span>
                                        </th>
                                        <th v-else-if="hasPriceWarning"
                                            v-tooltip="'Egy vagy több tételnél megváltozott az ár a rendelés óta'"
                                            class="text-warning">
                                            <i class="icon-fa icon-fa-exclamation-triangle"></i>
                                            {{ priceSum }} Ft
                                        </th>
                                        <th v-else>{{ priceSum }} Ft</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </tab>
                    </form>
                </tabs>
            </div>
        </div>

        <set-price-modal ref="setPriceModal" :product="modalProduct"></set-price-modal>
    </div>
</template>

<script>
import ls from '../../services/ls.js'
import store from '../../services/vuex'
import Multiselect from 'vue-multiselect'
import { Tabs, Tab } from 'vue-tabs-component'
import SetPriceModal from '../modals/SetPriceModal'

export default {
    components: {
        Tab,
        Tabs,
        Multiselect,
        SetPriceModal,
    },
    data () {
        return {
            site: null,
            order: null,
            statuses: {
                0: 'Új rendelés',
                1: 'Egyeztetés alatt',
                2: 'Ajánlatkészítés',
                3: 'Ajánlat kiküldve',
                4: 'Ajánlat elfogadva',
                5: 'Fizetve',
                6: 'Sikeresen lezárult',
                7: 'Meghiúsult rendelés',
            },
            types: [
                {
                    name: 'Normál termék',
                    icon: 'icon-fa-cube'
                },
                {
                    name: 'Csoportosított termék',
                    icon: 'icon-fa-cubes'
                },
                {
                    name: 'Variálható termék',
                    icon: 'icon-fa-clone'
                },
                {
                    name: 'Termékvariáció',
                    icon: 'icon-fa-clone'
                },
            ],
            opened: null,
            modalProduct: null,
            products: [],
            selectedProduct: null,
            selectedVariation: null,
            subpage: 'details',
            addForm: false,
        }
    },
    computed: {
        originalOrder(){
            return store.state.orders.order
        },
        saving(){
            return store.state.orders.saving
        },
        statusVersion(){
            return store.state.orders.version.status
        },
        readonly(){
            return this.order.status >= 6
        },
        readonlyProducts(){
            return this.order.status >= 4
        },
        validPriceSum(){
            return this.order.relations.products.every(product => {
                return this.hasCustomPrice(product)
                    || product.priceWhenOrdered !== null
                    || product.price !== null
            })
        },
        hasPriceWarning(){
            return this.order.relations.products.some(product => {
                return !this.hasCustomPrice(product)
                    && product.priceWhenOrdered != product.price
            })
        },
        priceSum(){
            return this.order.relations.products.reduce((total, current) => {
                if(this.hasCustomPrice(current)){
                    var price = current.customPrice
                }else{
                    var price = current.priceWhenOrdered !== null
                        ? current.priceWhenOrdered
                        : current.price
                }

                return total += price * current.amount
            }, 0)
        },
        statusWarning(){
            let warning = this.order.relations.statusWarning

            if(!warning){
                return ''
            }

            let target = this.statuses[warning.target]

            return `A megrendelést ${warning.age} napja a "${target}" státuszra kellett volna váltani, kérlek ellenőrizd!`
        }
    },
    methods: {
        changeStatus(event){
            store.dispatch('orders/updateStatus', event.currentTarget.value)
        },
        submit(){
            store.dispatch('orders/updateOrder', this.order)
        },
        getStatus(status){
            return this.statuses[status]
        },
        toggleProductRow(key){
            this.opened = key==this.opened ? null : key
        },
        getProduct(id){
            return this.order.relations.products.find(product => {
                return product.id == id
            })
        },
        remove(id){
            this.order.relations.products = this.order.relations.products.filter(product => {
                return product.id != id
            })
        },
        decrease(id){
            let product = this.getProduct(id)

            if(product){
                if(product.amount <= 1){
                    this.remove(id)
                    return
                }

                product.amount--
            }
        },
        increase(id){
            let product = this.getProduct(id)

            if(product){
                product.amount++
            }
        },
        hasCustomPrice(product){
            return product.customPrice !== null
                && product.customPrice !== ''
        },
        usedPrice(product){
            if(this.hasCustomPrice(product)){
                return product.customPrice
            }

            return product.priceWhenOrdered
                ? product.priceWhenOrdered
                : product.price
        },
        setPriceModal(product){
            this.modalProduct = product
            this.$refs.setPriceModal.open()
        },
        loadProducts(text){
            axios.get('/api/admin/publications', {
                params: {
                    filters: {
                        site: this.site,
                        type: 'product',
                        text: text,
                    },
                    with: 'currentPrice,variations',
                    sort: 'published_at',
                    order: 'desc'
                }
            }).then(result => {
                this.products = result.data.data
            })
        },
        addProduct(){
            let existing = this.selectedProduct.product_type == 2
                ? this.getProduct(this.selectedVariation.id)
                : this.getProduct(this.selectedProduct.product_id)

            if(existing){
                this.increase(existing.id)
                return
            }

            let product = this.selectedProduct.product_type == 2
                ? JSON.parse(JSON.stringify(this.selectedVariation))
                : JSON.parse(JSON.stringify(this.selectedProduct))

            if(this.selectedProduct.product_type == 2){
                Vue.set(product, 'id', product.id)
                Vue.set(product, 'product_type', 3)
                Vue.set(product, 'title', `${this.selectedProduct.title} // ${this.selectedVariation.title}`)
                Vue.set(product, 'price', product.currentPrice
                    ? product.currentPrice.price
                    : null)
            }else{
                Vue.set(product, 'id', product.product_id)
                Vue.set(product, 'price', product.relations.currentPrice
                    ? product.relations.currentPrice.price
                    : null)
            }

            Vue.set(product, 'amount', 1)
            Vue.set(product, 'customPrice', product.price)
            Vue.set(product, 'priceWhenOrdered', null)

            this.order.relations.products.push(product)
        },
    },
    created() {
        this.site = ls.get('site')

        store.dispatch('orders/getOrder', {
            id: this.$route.params.order
        })

        this.loadProducts()
    },
    watch: {
        originalOrder(newValue){
            if(newValue){
                this.order = store.state.orders.order
                this.order.status = this.order.relations.status.status
            }
        },
        statusVersion(newValue){
            this.order.status = this.order.relations.status.status
        }
    }
}
</script>

<style scoped>
.table td{
    border-top: none;
}

.spinbox input::-webkit-outer-spin-button,
.spinbox input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.spinbox input[type=number] {
  -moz-appearance: textfield;
}
</style>
