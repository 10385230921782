var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main-content" }, [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [_vm._v("General Elements")]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Forms")])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v("General Elements")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("h6", [_vm._v("Basic Inputs")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("Horizontal")
              ]),
              _vm._v(" "),
              _c("form", [
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 col-form-label",
                      attrs: { for: "inputEmail1" }
                    },
                    [_vm._v("Email")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "inputEmail1",
                        type: "email",
                        placeholder: "Email"
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 col-form-label",
                      attrs: { for: "inputPassword1" }
                    },
                    [_vm._v("Password")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        id: "inputPassword1",
                        type: "password",
                        placeholder: "Password"
                      }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("h5", { staticClass: "section-semi-title mt-4" }, [
                _vm._v("Vertical")
              ]),
              _vm._v(" "),
              _c("form", [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "exampleFormControlInput1" } }, [
                    _vm._v("Email address")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "form-control",
                    attrs: {
                      id: "exampleFormControlInput1",
                      type: "email",
                      placeholder: "name@example.com"
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "exampleFormControlSelect1" } }, [
                    _vm._v("Example select")
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      staticClass: "form-control",
                      attrs: { id: "exampleFormControlSelect1" }
                    },
                    [
                      _c("option", [_vm._v("1")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("2")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("3")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("4")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("5")])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "exampleFormControlSelect2" } }, [
                    _vm._v("Example multiple select")
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      staticClass: "form-control",
                      attrs: { id: "exampleFormControlSelect2", multiple: "" }
                    },
                    [
                      _c("option", [_vm._v("1")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("2")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("3")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("4")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("5")])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    { attrs: { for: "exampleFormControlTextarea1" } },
                    [_vm._v("Example textarea")]
                  ),
                  _vm._v(" "),
                  _c("textarea", {
                    staticClass: "form-control",
                    attrs: { id: "exampleFormControlTextarea1", rows: "3" }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "exampleFormControlFile1" } }, [
                    _vm._v("Example file input")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "form-control-file",
                    attrs: { id: "exampleFormControlFile1", type: "file" }
                  }),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(
                      "This is some placeholder block-level help text for the above\n              input. It's a bit lighter and easily wraps to a new line.\n              "
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("h5", { staticClass: "section-semi-title mt-4" }, [
                _vm._v("\n            Validation States\n          ")
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "was-validated",
                  attrs: { id: "needs-validation", novalidate: "" }
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "firstName" } }, [
                      _vm._v("First name")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "form-control is-valid",
                      attrs: {
                        id: "firstName",
                        type: "text",
                        placeholder: "First name",
                        value: "Mark",
                        required: ""
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "lastName" } }, [
                      _vm._v("Last name")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "form-control is-valid",
                      attrs: { id: "lastName", type: "text", required: "" }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(
                        "\n                Please provide a last name.\n              "
                      )
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("h5", { staticClass: "section-semi-title mt-4" }, [
                _vm._v(
                  "\n            Supported elements for Validation States\n          "
                )
              ]),
              _vm._v(" "),
              _c("form", { staticClass: "was-validated" }, [
                _c(
                  "div",
                  { staticClass: "custom-control custom-checkbox mb-3" },
                  [
                    _c("input", {
                      staticClass: "custom-control-input",
                      attrs: {
                        id: "customControlValidation1",
                        type: "checkbox",
                        required: ""
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: { for: "customControlValidation1" }
                      },
                      [
                        _vm._v(
                          "\n                Check this customcheckbox\n              "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v("Example invalid feedback text")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "custom-control custom-radio" }, [
                  _c("input", {
                    staticClass: "custom-control-input",
                    attrs: {
                      id: "customControlValidation2",
                      type: "radio",
                      name: "radio-stacked",
                      required: ""
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "custom-control-label",
                      attrs: { for: "customControlValidation2" }
                    },
                    [
                      _vm._v(
                        "\n                Toggle this customradio\n              "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "custom-control custom-radio mb-3" }, [
                  _c("input", {
                    staticClass: "custom-control-input",
                    attrs: {
                      id: "customControlValidation3",
                      type: "radio",
                      name: "radio-stacked",
                      required: ""
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "custom-control-label",
                      attrs: { for: "customControlValidation3" }
                    },
                    [
                      _vm._v(
                        "\n                Or toggle this other custom radio\n              "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v("More example invalid feedback text")
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "select",
                    { staticClass: "custom-select", attrs: { required: "" } },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v("Open this select menu")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1" } }, [_vm._v("One")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "2" } }, [_vm._v("Two")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "3" } }, [_vm._v("Three")])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v("Example invalid custom select feedback")
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "custom-file" }, [
                  _c("input", {
                    staticClass: "custom-file-input",
                    attrs: {
                      id: "validatedCustomFile",
                      type: "file",
                      required: ""
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "custom-file-label",
                      attrs: { for: "validatedCustomFile" }
                    },
                    [_vm._v("Choose file...")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v("Example invalid custom file feedback")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("h5", { staticClass: "section-semi-title mt-4" }, [
                _vm._v("\n            Static Controls\n          ")
              ]),
              _vm._v(" "),
              _c("form", [
                _c("div", { staticClass: "form-group row" }, [
                  _c("label", { staticClass: "col-sm-2 form-control-label" }, [
                    _vm._v("Email")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("p", { staticClass: "form-control-plaintext" }, [
                      _vm._v("mohit@laraspace.in")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group row" }, [
                  _c("label", { staticClass: "col-sm-2 form-control-label" }, [
                    _vm._v("username")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c("p", { staticClass: "form-control-plaintext" }, [
                      _vm._v("laraspace")
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("h6", [_vm._v("Checkbox & Radios")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("h5", { staticClass: "section-semi-title" }, [
                    _vm._v("\n                Checkbox\n              ")
                  ]),
                  _vm._v(" "),
                  _c("form", [
                    _c("div", { staticClass: "form-check" }, [
                      _c("input", {
                        staticClass: "form-check-input",
                        attrs: {
                          type: "checkbox",
                          value: "",
                          id: "defaultCheck1"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "defaultCheck1" }
                        },
                        [
                          _vm._v(
                            "\n                    Option one is this and that—be sure to include why it's great\n                  "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-check" }, [
                      _c("input", {
                        staticClass: "form-check-input",
                        attrs: {
                          type: "checkbox",
                          value: "",
                          id: "defaultCheck2",
                          disabled: ""
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "defaultCheck2" }
                        },
                        [
                          _vm._v(
                            "\n                    Option two is disabled\n                  "
                          )
                        ]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("h5", { staticClass: "section-semi-title" }, [
                    _vm._v("Inline Checkbox")
                  ]),
                  _vm._v(" "),
                  _c("form", [
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("input", {
                        staticClass: "form-check-input",
                        attrs: {
                          id: "inlineCheckbox1",
                          type: "checkbox",
                          value: "option1"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "inlineCheckbox1" }
                        },
                        [_vm._v("1")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("input", {
                        staticClass: "form-check-input",
                        attrs: {
                          id: "inlineCheckbox2",
                          type: "checkbox",
                          value: "option2"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "inlineCheckbox2" }
                        },
                        [_vm._v("2")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("input", {
                        staticClass: "form-check-input",
                        attrs: {
                          id: "inlineCheckbox3",
                          type: "checkbox",
                          value: "option3",
                          disabled: ""
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "inlineCheckbox3" }
                        },
                        [_vm._v("3 (disabled)")]
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("h5", { staticClass: "section-semi-title mt-4" }, [
                    _vm._v("\n                Radio\n              ")
                  ]),
                  _vm._v(" "),
                  _c("form", [
                    _c("div", { staticClass: "form-check" }, [
                      _c("input", {
                        staticClass: "form-check-input",
                        attrs: {
                          id: "exampleRadios1",
                          type: "radio",
                          name: "exampleRadios",
                          value: "option1",
                          checked: ""
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "exampleRadios1" }
                        },
                        [
                          _vm._v(
                            "\n                    Default radio\n                  "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-check" }, [
                      _c("input", {
                        staticClass: "form-check-input",
                        attrs: {
                          id: "exampleRadios2",
                          type: "radio",
                          name: "exampleRadios",
                          value: "option2"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "exampleRadios2" }
                        },
                        [
                          _vm._v(
                            "\n                    Second default radio\n                  "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-check disabled" }, [
                      _c("input", {
                        staticClass: "form-check-input",
                        attrs: {
                          id: "exampleRadios3",
                          type: "radio",
                          name: "exampleRadios",
                          value: "option3",
                          disabled: ""
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "exampleRadios3" }
                        },
                        [
                          _vm._v(
                            "\n                    Disabled radio\n                  "
                          )
                        ]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("h5", { staticClass: "section-semi-title mt-4" }, [
                    _vm._v("Inline Radio")
                  ]),
                  _vm._v(" "),
                  _c("form", [
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("input", {
                        staticClass: "form-check-input",
                        attrs: {
                          id: "inlineRadio1",
                          type: "radio",
                          name: "inlineRadioOptions",
                          value: "option1"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "inlineRadio1" }
                        },
                        [_vm._v("1")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("input", {
                        staticClass: "form-check-input",
                        attrs: {
                          id: "inlineRadio2",
                          type: "radio",
                          name: "inlineRadioOptions",
                          value: "option2"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "inlineRadio2" }
                        },
                        [_vm._v("2")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("input", {
                        staticClass: "form-check-input",
                        attrs: {
                          id: "inlineRadio3",
                          type: "radio",
                          name: "inlineRadioOptions",
                          value: "option3",
                          disabled: ""
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "inlineRadio3" }
                        },
                        [_vm._v("3 (disabled)")]
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }