var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        { staticClass: "card-body  my-gallery" },
        [
          _c("gallery", {
            attrs: {
              images: _vm.images,
              index: _vm.VidIndex,
              options: {
                closeOnEscape: true,
                closeOnSlideClick: true,
                closeOnSwipeUpOrDown: true,
                fullScreen: false,
                youTubeVideoIdProperty: "youtube",
                youTubeClickToPlay: false
              }
            },
            on: {
              close: function($event) {
                _vm.index = null
              }
            }
          }),
          _vm._v(" "),
          _c("div", {}, [
            _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.images, function(image, vindex) {
                return _c(
                  "figure",
                  {
                    key: vindex,
                    staticClass: "col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12"
                  },
                  [
                    _c("img", {
                      staticClass: "demo image img-fluid",
                      attrs: { src: image.poster },
                      on: {
                        click: function($event) {
                          _vm.VidIndex = vindex
                        }
                      }
                    })
                  ]
                )
              }),
              0
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Video Gallery")]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Gallery")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Video Gallery")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }