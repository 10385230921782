var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("h4", [_vm._v("Basic Cards")]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("h4", { staticClass: "mt-4" }, [_vm._v("Card With Actions")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._m(3),
                _vm._v(" "),
                _c("div", { staticClass: "actions" }, [
                  _c(
                    "div",
                    { staticClass: "btn-group", attrs: { role: "group" } },
                    [
                      _c(
                        "v-dropdown",
                        { attrs: { "theme-light": "" } },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-sm btn-outline-primary dropdown-toggle",
                              attrs: {
                                slot: "activator",
                                id: "cardDropButton",
                                type: "button"
                              },
                              slot: "activator"
                            },
                            [
                              _vm._v(
                                "\n                      Filters\n                    "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-dropdown-item", [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" }
                              },
                              [
                                _vm._v(
                                  "\n                        Update\n                      "
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-dropdown-item", [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item",
                                attrs: { href: "#" }
                              },
                              [
                                _vm._v(
                                  "\n                        Delete\n                      "
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._m(4)
            ])
          ])
        ]),
        _vm._v(" "),
        _c("h4", { staticClass: "mt-4" }, [_vm._v("Colorful Header")]),
        _vm._v(" "),
        _vm._m(5),
        _vm._v(" "),
        _c("h4", { staticClass: "mt-4" }, [_vm._v("Background Inverse")]),
        _vm._v(" "),
        _vm._m(6)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Cards")]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Basic UI")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [_vm._v("Cards")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h5", [_vm._v("Default Card")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.\n              Alias enim excepturi exercitationem ipsum labore provident quam ut velit vero\n              voluptatum!"
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h5", [_vm._v("Card with header")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("p", [
              _vm._v(
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.\n              Alias enim excepturi exercitationem ipsum labore provident quam ut velit vero\n              voluptatum!"
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("h5", [_vm._v("Card with Footer")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi, molestias."
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-footer" }, [
            _vm._v("\n              Card footer\n            ")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h6", [
              _c("i", { staticClass: "icon-fa icon-fa-star" }),
              _vm._v(" Card With Icon")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("p", [
              _vm._v(
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.\n              Alias enim excepturi exercitationem ipsum labore provident quam ut velit vero\n              voluptatum!"
              )
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header" }, [
        _c("div", { staticClass: "caption" }, [
          _c("h6", [_vm._v("Card with Button Actions")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "actions" }, [
          _c("button", { staticClass: "btn btn-primary btn-sm" }, [
            _c("i", { staticClass: "icon-fa icon-fa-plus" }),
            _vm._v(" Add New\n                ")
          ]),
          _vm._v(" "),
          _c("button", { staticClass: "btn btn-danger btn-sm" }, [
            _c("i", { staticClass: "icon-fa icon-fa-trash" }),
            _vm._v(" Delete\n                ")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("p", [
          _vm._v(
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit.\n              Alias enim excepturi exercitationem ipsum labore provident quam ut velit vero voluptatum!"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "caption" }, [
      _c("h6", [_vm._v("Card with Dropdown Button")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-body" }, [
      _c("p", [
        _vm._v(
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit.\n              Alias enim excepturi exercitationem ipsum labore provident quam ut velit vero voluptatum!"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header bg-primary" }, [
            _c("h6", [
              _c("i", { staticClass: "icon-fa icon-fa-star" }),
              _vm._v(" Primary")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("p", [
              _vm._v(
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.\n              Alias enim excepturi exercitationem ipsum labore provident quam ut velit vero\n              voluptatum!"
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header bg-info" }, [
            _c("h6", [
              _c("i", { staticClass: "icon-fa icon-fa-info" }),
              _vm._v(" Info")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("p", [
              _vm._v(
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.\n              Alias enim excepturi exercitationem ipsum labore provident quam ut velit vero\n              voluptatum!"
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header bg-danger" }, [
            _c("h6", [
              _c("i", { staticClass: "icon-fa icon-fa-ambulance" }),
              _vm._v(" Danger")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("p", [
              _vm._v(
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.\n              Alias enim excepturi exercitationem ipsum labore provident quam ut velit vero\n              voluptatum!"
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header bg-warning" }, [
            _c("h6", [
              _c("i", { staticClass: "icon-fa icon-fa-warning" }),
              _vm._v(" Warning")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("p", [
              _vm._v(
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.\n              Alias enim excepturi exercitationem ipsum labore provident quam ut velit vero\n              voluptatum!"
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header bg-success" }, [
            _c("h6", [
              _c("i", { staticClass: "icon-fa icon-fa-check" }),
              _vm._v(" Success")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("p", [
              _vm._v(
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.\n              Alias enim excepturi exercitationem ipsum labore provident quam ut velit vero\n              voluptatum!"
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header bg-dark" }, [
            _c("h6", [
              _c("i", { staticClass: "icon-fa icon-fa-cube" }),
              _vm._v(" Dark")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("p", [
              _vm._v(
                "Lorem ipsum dolor sit amet, consectetur adipisicing elit.\n              Alias enim excepturi exercitationem ipsum labore provident quam ut velit vero\n              voluptatum!"
              )
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
        _c(
          "div",
          { staticClass: "card text-white bg-primary text-xs-center" },
          [
            _c("div", { staticClass: "card-body" }, [
              _c("blockquote", { staticClass: "card-bodyquote" }, [
                _c("p", [
                  _vm._v(
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a\n                ante."
                  )
                ]),
                _vm._v(" "),
                _c("footer", [
                  _vm._v("Someone famous in "),
                  _c("cite", { attrs: { title: "Source Title" } }, [
                    _vm._v("Source Title")
                  ])
                ])
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
        _c(
          "div",
          { staticClass: "card text-white bg-success text-xs-center" },
          [
            _c("div", { staticClass: "card-body" }, [
              _c("blockquote", { staticClass: "card-bodyquote" }, [
                _c("p", [
                  _vm._v(
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a\n                ante."
                  )
                ]),
                _vm._v(" "),
                _c("footer", [
                  _vm._v("Someone famous in "),
                  _c("cite", { attrs: { title: "Source Title" } }, [
                    _vm._v("Source Title")
                  ])
                ])
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
        _c("div", { staticClass: "card text-white bg-info text-xs-center" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("blockquote", { staticClass: "card-bodyquote" }, [
              _c("p", [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a\n                ante."
                )
              ]),
              _vm._v(" "),
              _c("footer", [
                _vm._v("Someone famous in "),
                _c("cite", { attrs: { title: "Source Title" } }, [
                  _vm._v("Source Title")
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
        _c(
          "div",
          { staticClass: "card text-white bg-warning text-xs-center" },
          [
            _c("div", { staticClass: "card-body" }, [
              _c("blockquote", { staticClass: "card-bodyquote" }, [
                _c("p", [
                  _vm._v(
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a\n                ante."
                  )
                ]),
                _vm._v(" "),
                _c("footer", [
                  _vm._v("Someone famous in "),
                  _c("cite", { attrs: { title: "Source Title" } }, [
                    _vm._v("Source Title")
                  ])
                ])
              ])
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6 col-lg-3" }, [
        _c("div", { staticClass: "card text-white bg-danger text-xs-center" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("blockquote", { staticClass: "card-bodyquote" }, [
              _c("p", [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a\n                ante."
                )
              ]),
              _vm._v(" "),
              _c("footer", [
                _vm._v("Someone famous in "),
                _c("cite", { attrs: { title: "Source Title" } }, [
                  _vm._v("Source Title")
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }