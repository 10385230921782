import { render, staticRenderFns } from "./VCollapse.vue?vue&type=template&id=121a0968&"
import script from "./VCollapse.vue?vue&type=script&lang=js&"
export * from "./VCollapse.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/media/thed/vol_40/Laravel/bmat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('121a0968')) {
      api.createRecord('121a0968', component.options)
    } else {
      api.reload('121a0968', component.options)
    }
    module.hot.accept("./VCollapse.vue?vue&type=template&id=121a0968&", function () {
      api.rerender('121a0968', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/collapse/VCollapse.vue"
export default component.exports