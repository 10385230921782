var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/dashboard" },
                slot: "item-title"
              },
              [_vm._v("Vezérlőpult")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/sites" },
                slot: "item-title"
              },
              [_vm._v("Domainek")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Új domain")
        ])
      ]),
      _vm._v(" "),
      _c("h1", [_vm._v("Új domain")])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.submit($event)
              }
            }
          },
          [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "domainInput" } }, [
                _vm._v("Domain (pl.: itzen.hu):")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.domain,
                    expression: "domain"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "domainInput" },
                domProps: { value: _vm.domain },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.domain = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _vm._m(0)
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "btn btn-success" }, [
      _c("i", { staticClass: "icon-fa icon-fa-save" }),
      _vm._v("Mentés\n                ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }