var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "todo-container mx-auto col-lg-6 " }, [
          _c("h5", { staticClass: "todo-title" }, [_vm._v("TODOS APP")]),
          _vm._v(" "),
          _c("p", { staticClass: "text-sm-center" }, [
            _vm._v(
              "This Demo showcases a simple workflow with VueJS , Vue Resource and Laravel. "
            )
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newTodo.title,
                expression: "newTodo.title"
              }
            ],
            staticClass: "form-control todo-field",
            attrs: { type: "text", placeholder: "New Todo" },
            domProps: { value: _vm.newTodo.title },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.addTodo($event)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.newTodo, "title", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "todo-block scroll-pane" }, [
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.todos.length,
                    expression: "todos.length"
                  }
                ],
                staticClass: "todo-list"
              },
              _vm._l(_vm.todos, function(todo, index) {
                return _c("li", { key: index }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: todo.completed,
                        expression: "todo.completed"
                      }
                    ],
                    staticClass: "toggle",
                    attrs: {
                      id: index,
                      "true-value": 1,
                      "false-value": 0,
                      type: "checkbox"
                    },
                    domProps: {
                      checked: Array.isArray(todo.completed)
                        ? _vm._i(todo.completed, null) > -1
                        : _vm._q(todo.completed, 1)
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = todo.completed,
                            $$el = $event.target,
                            $$c = $$el.checked ? 1 : 0
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(todo, "completed", $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  todo,
                                  "completed",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(todo, "completed", $$c)
                          }
                        },
                        function($event) {
                          return _vm.toggleTodoComplete(todo)
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: index } }, [
                    _vm._v(_vm._s(todo.title))
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "remove-link",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.removeTodo(todo)
                        }
                      }
                    },
                    [_c("i", { staticClass: "icon-fa icon-fa-close" })]
                  )
                ])
              }),
              0
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [
        _vm._v("Todos\n      "),
        _c("small", { staticClass: "text-muted" }, [
          _vm._v("Built with Vue JS")
        ])
      ]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Todos")])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }