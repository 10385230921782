var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c("div", { staticClass: "page-header" }, [
        _vm.site
          ? _c("ol", { staticClass: "breadcrumb" }, [
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        slot: "item-title",
                        to: "/admin/sites/" + this.site.id
                      },
                      slot: "item-title"
                    },
                    [_vm._v("Vezérlőpult")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        slot: "item-title",
                        to: "/admin/sites/" + this.site.id
                      },
                      slot: "item-title"
                    },
                    [_vm._v("Beállítások")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.site
                ? _c("li", { staticClass: "breadcrumb-item active" }, [
                    _vm._v(_vm._s(_vm.site.domain))
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("h1", [
          _vm._v(_vm._s(_vm.site ? _vm.site.domain : "Site") + " beállításai")
        ])
      ]),
      _vm._v(" "),
      _vm.site
        ? _c(
            "tabs",
            [
              _c("tab", { attrs: { id: "data", name: "Adatok" } }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.saveData($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "domainInput" } }, [
                        _vm._v("Domain (pl.: itzen.hu):")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.site.domain,
                            expression: "site.domain"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", id: "domainInput" },
                        domProps: { value: _vm.site.domain },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.site, "domain", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("button", { staticClass: "btn btn-success" }, [
                      _c("i", { staticClass: "icon-fa icon-fa-save" }),
                      _vm._v("Mentés\n                ")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("tab", { attrs: { id: "api", name: "API" } }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.saveApi($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "keyInput" } }, [
                        _vm._v("API kulcs")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-group" }, [
                        _vm.keyInputType() === "checkbox"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.site.api_key,
                                  expression: "site.api_key"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "keyInput",
                                readonly: "",
                                type: "checkbox"
                              },
                              domProps: {
                                checked: Array.isArray(_vm.site.api_key)
                                  ? _vm._i(_vm.site.api_key, null) > -1
                                  : _vm.site.api_key
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.site.api_key,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.site,
                                          "api_key",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.site,
                                          "api_key",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.site, "api_key", $$c)
                                  }
                                }
                              }
                            })
                          : _vm.keyInputType() === "radio"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.site.api_key,
                                  expression: "site.api_key"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "keyInput",
                                readonly: "",
                                type: "radio"
                              },
                              domProps: {
                                checked: _vm._q(_vm.site.api_key, null)
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(_vm.site, "api_key", null)
                                }
                              }
                            })
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.site.api_key,
                                  expression: "site.api_key"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "keyInput",
                                readonly: "",
                                type: _vm.keyInputType()
                              },
                              domProps: { value: _vm.site.api_key },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.site,
                                    "api_key",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group-append" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-dark",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  _vm.visibleKey = !_vm.visibleKey
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "icon-fa",
                                class: _vm.visibleKey
                                  ? "icon-fa-eye-slash"
                                  : "icon-fa-eye"
                              })
                            ]
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("button", { staticClass: "btn btn-success" }, [
                      _c("i", { staticClass: "icon-fa icon-fa-refresh" }),
                      _vm._v("Új API kulcs generálása\n                ")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("tab", { attrs: { id: "sitemap", name: "Sitemap" } }, [
                _vm.sitemap
                  ? _c("div", [
                      _c("p", [
                        _vm._v(
                          "\n                    A generált sitemap url-ét bele kell másolni a domain-en található robots.txt fájlba.\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("pre", { staticClass: "mb-0" }, [
                        _c("code", [
                          _vm._v("Sitemap: " + _vm._s(_vm.sitemap.url))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(
                          "Létrehozva: " +
                            _vm._s(_vm.formatUtcDate(_vm.created_at))
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.sitemap
                  ? _c("div", [
                      _vm._v("\n                Nincs sitemap!\n            ")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success mt-4",
                    attrs: { type: "button" },
                    on: { click: _vm.generateSitemap }
                  },
                  [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-refresh",
                      class: { "icon-fa-spin": _vm.loading }
                    }),
                    _vm._v(
                      "\n                Sitemap létrehozása\n            "
                    )
                  ]
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }