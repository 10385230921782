var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main-content" }, [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [_vm._v("Settings")]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Settings")])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("h6", [_vm._v("Easy Site Settings API")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("p", [
            _vm._v(
              "Laraspace provides easy way to store and retrieve your Site Settings"
            )
          ]),
          _vm._v(" "),
          _c("p", [_c("code", [_vm._v("Setting::setSetting('key','value')")])]),
          _vm._v(" "),
          _c("p", [_c("code", [_vm._v("Setting::getSetting('key')")])])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12 col-lg-8" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("h6", [_vm._v("Example Social Settings")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  attrs: {
                    action: "/api/admin/settings/social",
                    method: "post"
                  }
                },
                [
                  _c("div", { staticClass: "form-body" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-md-4 col-lg-2 form-control-label" },
                        [_vm._v("Facebook Page URL")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-8 col-lg-10" }, [
                        _c("div", { staticClass: "input-group mb-3" }, [
                          _c("div", { staticClass: "input-group-prepend" }, [
                            _c(
                              "span",
                              {
                                staticClass: "input-group-text",
                                attrs: { id: "basic-addon2" }
                              },
                              [
                                _c("i", {
                                  staticClass: "icon-fa icon-fa-facebook"
                                })
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            staticClass: "form-control",
                            attrs: { type: "text", name: "facebook", value: "" }
                          })
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-md-4 col-lg-2 form-control-label" },
                        [_vm._v("Google Plus URL")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-8 col-lg-10" }, [
                        _c("div", { staticClass: "input-group mb-3" }, [
                          _c("div", { staticClass: "input-group-prepend" }, [
                            _c(
                              "span",
                              {
                                staticClass: "input-group-text",
                                attrs: { id: "basic-addon2" }
                              },
                              [
                                _c("i", {
                                  staticClass: "icon-fa icon-fa-google-plus"
                                })
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            staticClass: "form-control",
                            attrs: { type: "text", name: "google", value: "" }
                          })
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        { staticClass: "col-md-4 col-lg-2 form-control-label" },
                        [_vm._v("Twitter URL")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-8 col-lg-10" }, [
                        _c("div", { staticClass: "input-group mb-3" }, [
                          _c("div", { staticClass: "input-group-prepend" }, [
                            _c(
                              "span",
                              {
                                staticClass: "input-group-text",
                                attrs: { id: "basic-addon2" }
                              },
                              [
                                _c("i", {
                                  staticClass: "icon-fa icon-fa-twitter"
                                })
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            staticClass: "form-control",
                            attrs: { type: "text", name: "twitter", value: "" }
                          })
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit" }
                    },
                    [_vm._v("\n              Save\n            ")]
                  )
                ]
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }