<template>
    <div class="card my-1 mx-3" v-if="product.id">

        <div class="card-header d-flex align-items-center">
            <span v-tooltip="'A csoportosított termékbe tartozó termékek. Több termék összefogható egy csomagba és a csomag külön termékként árusítható.'">
                Csoport termékei
                <i class="icon-fa icon-fa-question-circle"></i>
            </span>
        </div>

        <div class="card-body">
            <div class="d-flex">
                <multiselect v-model="bundleProduct"
                    :options="products"
                    label="title"
                    track-by="id"
                    deselect-label="Kiválasztott"
                    placeholder="Válassz ki egy terméket"
                    select-label="Enter a kiválasztáshoz"
                    selected-label="Kiválasztott"
                    :allow-empty="false"
                    @search-change="loadProducts">
                </multiselect>

                <button type="button"
                    class="btn btn-sm btn-info nowrap"
                    @click="addProduct()">
                    <i class="icon-fa icon-fa-plus"></i>
                    Hozzáadás
                </button>
            </div>

            <table class="table table-striped mt-1" v-if="product.relations.bundles && product.relations.bundles.length">
                <thead>
                    <tr>
                        <th v-tooltip="'A termék neve'">Termék</th>
                        <th v-tooltip="'Mennyi van a csomagban ebből a termékből'">Mennyiség</th>
                        <th v-tooltip="'Az egyedi termék aktuális egységára.'">Egységár</th>
                        <th v-tooltip="'Az egyedi termék összege'">Összeg</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="bundle in product.relations.bundles" :key="bundle.product.id">
                        <td>{{ bundle.product.title }}</td>

                        <td style="width:10em">
                            <div class="form-group mb-0" >
                                <div class="input-group input-group-sm">
                                    <div class="input-group-prepend">
                                        <button class="btn btn-danger" type="button" @click="decrease(bundle.product.id)">
                                            <i class="icon-fa icon-fa-fw icon-fa-trash w-100"
                                                v-if="bundle.quantity <= 1"></i>
                                            <i class="icon-fa icon-fa-fw icon-fa-minus w-100"
                                                v-else></i>
                                        </button>
                                    </div>
                                    <input type="number"
                                        min="0"
                                        step="any"
                                        class="form-control"
                                        v-model="bundle.quantity">
                                    <div class="input-group-append">
                                        <button class="btn btn-success" type="button" @click="increase(bundle.product.id)">
                                            <i class="icon-fa icon-fa-fw icon-fa-plus w-100"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </td>

                        <td style="width:10em">
                            <span v-if="bundle.product.relations.currentPrice">
                                {{ bundle.product.relations.currentPrice.price }}
                            </span>
                            <span v-else class="text-danger">Nincs ár beállítva</span>
                        </td>

                        <td style="width:10em">
                            <span v-if="bundle.product.relations.currentPrice">
                                {{ bundle.product.relations.currentPrice.price * bundle.quantity }}
                            </span>
                            <span v-else class="text-danger">Nincs ár beállítva</span>
                        </td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <td colspan="3">Összesen</td>
                        <td>{{ bundleSum }}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
import ls from '../services/ls'
import Multiselect from 'vue-multiselect'

export default {
    props: {
        product: { type: [Object], required: true }
    },
    components: {
        Multiselect,
    },
    data () {
        return {
            site: null,
            products: [],
            bundleProduct: null,
        }
    },
    computed: {
        bundleSum(){
            return this.product.relations.bundles.reduce((total, current) => {
                return current.product.relations.currentPrice
                    ? total + current.product.relations.currentPrice.price * current.quantity
                    : total
            }, 0)
        }
    },
    methods: {
        loadProducts(text){
            axios.get('/api/admin/publications', {
                params: {
                    filters: {
                        site: this.site,
                        type: 'product',
                        product_type: 0,
                        text: text,
                    },
                    with: 'currentPrice',
                    sort: 'published_at',
                    order: 'desc'
                }
            }).then(result => {
                this.products = result.data.data
            })
        },
        getProduct(id){
            return this.product.relations.bundles.find(bundle => {
                return bundle.product.id == id
            })
        },
        addProduct(){
            let product = this.getProduct(this.bundleProduct.id)

            if(!product){
                this.product.relations.bundles.push({
                    quantity: 1,
                    product: this.bundleProduct,
                })
            }
        },
        increase(bundleId){
            let product = this.getProduct(bundleId)

            if(product){
                product.quantity++
            }
        },
        decrease(bundleId){
            let product = this.getProduct(bundleId)

            if(product){
                if(product.quantity <= 1){
                    this.product.relations.bundles = this.product.relations.bundles.filter(bundle => {
                        return bundle.product.id != bundleId
                    })
                }

                product.quantity--
            }
        }
    },
    created() {
        this.site = ls.get('site')
        this.loadProducts()
    }
}
</script>

<style>
.nowrap{
    white-space: nowrap;
}
</style>
