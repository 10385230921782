var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.validateBeforeSubmit($event)
        }
      }
    },
    [
      _c(
        "div",
        {
          class: [
            "form-group",
            { "is-invalid": _vm.$v.registerData.email.$error }
          ]
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim.lazy",
                value: _vm.registerData.email,
                expression: "registerData.email",
                modifiers: { trim: true, lazy: true }
              }
            ],
            staticClass: "form-control",
            class: {
              "is-invalid": _vm.$v.registerData.email.$error,
              "form-group--loading": _vm.$v.registerData.email.$pending
            },
            attrs: { placeholder: "Enter Email", type: "email" },
            domProps: { value: _vm.registerData.email },
            on: {
              change: [
                function($event) {
                  _vm.$set(
                    _vm.registerData,
                    "email",
                    $event.target.value.trim()
                  )
                },
                function($event) {
                  return _vm.$v.registerData.email.$touch()
                }
              ],
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          !_vm.$v.registerData.email.required
            ? _c("span", { staticClass: "invalid-feedback" }, [
                _vm._v("\n      Email is required.\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.$v.registerData.email.email
            ? _c("span", { staticClass: "invalid-feedback" }, [
                _vm._v("\n      Please verify your email.\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.$v.registerData.email.isUnique
            ? _c("span", { staticClass: "invalid-feedback" }, [
                _vm._v("\n      This email is already registered.\n    ")
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "form-group",
            { "is-invalid": _vm.$v.registerData.password.$error }
          ]
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.registerData.password,
                expression: "registerData.password",
                modifiers: { trim: true }
              }
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.$v.registerData.password.$error },
            attrs: { placeholder: "Enter Password", type: "password" },
            domProps: { value: _vm.registerData.password },
            on: {
              change: function($event) {
                return _vm.$v.registerData.password.$touch()
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.registerData,
                  "password",
                  $event.target.value.trim()
                )
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          !_vm.$v.registerData.password.required
            ? _c("span", { staticClass: "invalid-feedback" }, [
                _vm._v("\n      Password is required.\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.$v.registerData.password.minLength
            ? _c("span", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n      Password must have at least " +
                    _vm._s(_vm.$v.registerData.password.$params.minLength.min) +
                    " letters.\n    "
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "form-group",
            { "is-invalid": _vm.$v.registerData.password_confirmation.$error }
          ]
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.registerData.password_confirmation,
                expression: "registerData.password_confirmation",
                modifiers: { trim: true }
              }
            ],
            staticClass: "form-control",
            class: {
              "is-invalid": _vm.$v.registerData.password_confirmation.$error
            },
            attrs: { placeholder: "Retype Password", type: "password" },
            domProps: { value: _vm.registerData.password_confirmation },
            on: {
              change: function($event) {
                return _vm.$v.registerData.password_confirmation.$touch()
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.registerData,
                  "password_confirmation",
                  $event.target.value.trim()
                )
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          !_vm.$v.registerData.password_confirmation.sameAsPassword
            ? _c("span", { staticClass: "invalid-feedback" }, [
                _vm._v("\n      Passwords must be identical.\n    ")
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("button", { staticClass: "btn btn-login btn-full" }, [
        _vm._v("Register")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }