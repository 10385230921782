<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item"><router-link slot="item-title" to="/admin/products">Termékek</router-link></li>
                <li class="breadcrumb-item active">Új termék</li>
            </ol>
            <div class="d-flex align-items-center">
                <h1>Termék szerkesztése</h1>

                <div class="btn-group ml-auto">
                    <button class="btn btn-sm btn-success" form="productEditForm">
                        <i class="icon-fa icon-fa-save" v-if="!saving"></i>
                        <i class="icon-fa icon-fa-circle-o-notch icon-fa-spin" v-if="saving"></i>
                        Mentés
                    </button>
                </div>
            </div>
        </div>

        <product-editor :original="product"
            :save="saveCallback">
        </product-editor>
    </div>
</template>

<script>
import ls from '../../services/ls.js'
import store from '../../services/vuex'
import ProductEditor from './ProductEditor'

export default {
    components: {
        ProductEditor,
    },
    data () {
        return {
            product: {
                title: 'Új termék',
                slug: 'uj-termek',
                description: '',
                published_at: null,
                canonical: 'products/uj-bejegyzes',
                images: [],
                affiliate: null,
                product_type: 0,
                category: null,
                relations: [],
            },
            site: null,
        }
    },
    computed: {
        saving(){
            return store.state.products.saving
        }
    },
    methods: {
        saveCallback(product, content){
            product.site = this.site
            product.content = content

            store.dispatch('products/storeProduct', product)
                .then(response => {
                    this.$router.push(`/admin/products/${response.data.data.id}/edit`)
                })
        }
    },
    created() {
        this.site = ls.get('site')
    },
}
</script>

<style scoped>
.table td{
    border-top: none;
}
</style>
