var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "site-header" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "nav-toggle",
        attrs: { href: "#" },
        on: { click: _vm.onNavToggle }
      },
      [_vm._m(1)]
    ),
    _vm._v(" "),
    _c("ul", { staticClass: "action-list" }, [
      _c(
        "li",
        [
          _c(
            "v-dropdown",
            { attrs: { "show-arrow": false } },
            [
              _c(
                "a",
                { attrs: { slot: "activator", href: "#" }, slot: "activator" },
                [_c("i", { staticClass: "icon-fa icon-fa-plus" })]
              ),
              _vm._v(" "),
              _c(
                "v-dropdown-item",
                [
                  _c("router-link", { attrs: { to: "/admin/posts/create" } }, [
                    _c("i", { staticClass: "icon-fa icon-fa-edit" }),
                    _vm._v(" Új bejegyzés\n          ")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-dropdown-item",
                [
                  _c("router-link", { attrs: { to: "/admin/categories" } }, [
                    _c("i", { staticClass: "icon-fa icon-fa-reorder" }),
                    _vm._v(" Új kategória\n          ")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-dropdown-item",
                [
                  _c("router-link", { attrs: { to: "/admin/tags" } }, [
                    _c("i", { staticClass: "icon-fa icon-fa-tag" }),
                    _vm._v(" Új címke\n          ")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-dropdown-item",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/admin/products/create" } },
                    [
                      _c("i", {
                        staticClass: "icon-fa icon-fa-shopping-basket"
                      }),
                      _vm._v(" Új termék\n          ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      false
        ? _c(
            "li",
            [
              _c("v-dropdown", { attrs: { "show-arrow": false } }, [
                _c(
                  "a",
                  {
                    attrs: {
                      slot: "activator",
                      href: "#",
                      "data-toggle": "dropdown",
                      "aria-haspopup": "true",
                      "aria-expanded": "false"
                    },
                    slot: "activator"
                  },
                  [_c("i", { staticClass: "icon-fa icon-fa-bell" })]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "notification-dropdown" },
                  [
                    _c("h6", { staticClass: "dropdown-header" }, [
                      _vm._v("\n            Notifications\n          ")
                    ]),
                    _vm._v(" "),
                    _c("v-dropdown-item", [
                      _c(
                        "a",
                        { staticClass: "dropdown-item", attrs: { href: "#" } },
                        [
                          _c("i", { staticClass: "icon-fa icon-fa-user" }),
                          _vm._v(" New User was Registered\n            ")
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("v-dropdown-item", [
                      _c(
                        "a",
                        { staticClass: "dropdown-item", attrs: { href: "#" } },
                        [
                          _c("i", { staticClass: "icon-fa icon-fa-comment" }),
                          _vm._v(" A Comment has been posted.\n            ")
                        ]
                      )
                    ])
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "li",
        [
          _c(
            "v-dropdown",
            { attrs: { "show-arrow": false } },
            [
              _c(
                "a",
                { attrs: { slot: "activator", href: "#" }, slot: "activator" },
                [_c("i", { staticClass: "icon-fa icon-fa-user" })]
              ),
              _vm._v(" "),
              _c(
                "v-dropdown-item",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "dropdown-item",
                      attrs: { to: "/admin/users/profile" }
                    },
                    [
                      _c("i", { staticClass: "icon-fa icon-fa-user" }),
                      _vm._v(" Profil szerkesztése\n          ")
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-dropdown-item", [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.logout($event)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "icon-fa icon-fa-sign-out" }),
                    _vm._v(" Kijelentkezés\n          ")
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "brand-main", attrs: { href: "/admin/dashboard" } },
      [
        _c("span", { staticClass: "d-none d-md-inline h2" }, [
          _vm._v("ContentHub")
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "d-md-none h2" }, [_vm._v("HUB")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hamburger hamburger--arrowturn" }, [
      _c("div", { staticClass: "hamburger-box" }, [
        _c("div", { staticClass: "hamburger-inner" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }