<template>
    <div class="card my-1 mx-3" v-if="product.id">

        <div class="card-header d-flex align-items-center">
            <span v-tooltip="'A termékhez tartozó variációk. Az egyes variációk árait a tulajdonságok mentése után lehet megadni.'">
                Variációk
                <i class="icon-fa icon-fa-question-circle"></i>
            </span>

            <button type="button"
                class="btn btn-sm btn-info ml-auto"
                @click="addItem()">
                <i class="icon-fa icon-fa-plus"></i>
                Új variáció hozzáadása
            </button>
        </div>

        <div class="card-body">
            <p v-if="product.relations.variations.length == 0">Nincs variáció!</p>
            <div class="accordion" v-if="product.relations.variations.length">
                <div class="card mb-0"
                    v-for="(variation, key) in product.relations.variations"
                    :key="key">
                    <div class="card-header">
                        <button type="button"
                            class="btn"
                            @click="toggle(key)">
                            <i class="icon-fa icon-fa-fw" :class="opened==key ? 'icon-fa-caret-down' : 'icon-fa-caret-right'"></i>
                        </button>

                        <span>
                            {{ variation.title }}
                            <small v-if="variation.currentPrice">{{ variation.currentPrice.price }} Ft</small>
                        </span>


                        <button class="btn btn-danger pull-right"
                            type="button"
                            v-tooltip="'Variáció eltávolítása'"
                            @click="removeItem(key)">
                            <i class="icon-fa icon-fa-trash w-100"></i>
                        </button>
                    </div>

                    <div class="card-body" v-if="opened==key">
                        <div class="form-group">
                            <label>Variáció neve</label>
                            <input type="text" class="form-control" v-model="variation.title">
                        </div>

                        <tabs>
                            <tab :id="`properties-${key}`" name="Tulajdonságok" v-if="product.id">
                                <attribute-editor v-if="variation.attributes"
                                    :attributes="variation.attributes"
                                    :units="units"
                                    :tooltipHeader="tooltipHeader">
                                </attribute-editor>
                            </tab>

                            <tab :id="`prices-${key}`" name="Árak" v-if="variation.id">
                                <price-editor :prices="variation.prices"
                                    :current="variation.currentPrice"
                                    :product="product"
                                    :variation="variation"
                                    @updateCurrent="updateCurrentPrice">
                                </price-editor>
                            </tab>
                        </tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PriceEditor from './PriceEditor'
import { Tabs, Tab } from 'vue-tabs-component'
import AttributeEditor from './AttributeEditor'

export default {
    components: {
        Tab,
        Tabs,
        PriceEditor,
        AttributeEditor,
    },
    props: {
        product: { type: [Object], required: true },
        tooltipHeader: { type: [String,Object], default: () => '' },
        units: { type: [Object, null], default: () => {} },
    },
    data(){
        return {
            opened: null
        }
    },
    methods: {
        toggle(key){
            this.opened = key==this.opened ? null : key
        },
        addItem(){
            this.product.relations.variations.push({
                title: 'Új variáció',
                attributes: [],
                prices: [],
                currentPrice: {
                    id: 0,
                    price: 0,
                },
            })
        },
        removeItem(index){
            this.product.relations.variations.splice(index, 1)
        },
        updateCurrentPrice(price, variation){
            this.product.relations.variations.map(o => {
                if(o.id == variation.id){
                    o.currentPrice = price
                }
            })
        }
    }
}
</script>

<style>

</style>
