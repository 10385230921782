const Products = {
    namespaced: true,
    state: {
        product: {
            title: 'Új termék',
            slug: 'uj-termek',
            description: '',
            published_at: null,
            canonical: 'products/uj-bejegyzes',
            images: [],
            affiliate: null,
            product_type: 0,
            category: null,
            relations: [],
        },
        meta: {},
        saving: false,
    },
    mutations: {
        setProduct(state, payload){
            state.product = payload
        },
        setMeta(state, payload){
            state.meta = payload
        },
        setVariations(state, payload){
            state.product.relations.variations = payload
        },
        setSaveState(state, payload){
            state.saving = payload
        }
    },
    actions: {
        getProduct({ commit }, product){
            axios.get(`/api/admin/publications/${product.id}`, {
                params: {
                    with: 'category,tags,content,images,attributes,variations,sale,prices,currentPrice,stock,stockChanges'
                }
            }).then(response => {
                commit('setProduct', response.data.data)
                commit('setMeta', response.data.meta)
            })
        },
        storeProduct({ commit }, product){
            commit('setSaveState', true)

            return new Promise((resolve, reject) => {
                axios.post(`/api/admin/publications`, {
                    ...product,
                    type: 'product',
                }).then(response => {
                    resolve(response)

                    toastr.success('Termék elmentve', 'Siker')
                }).catch(err => {
                    reject()

                    toastr.error('Hiba a mentés közben', 'Hiba')
                }).finally(() => {
                    commit('setSaveState', false)
                })
            })
        },
        updateProduct({ commit }, product){
            commit('setSaveState', true)

            axios.patch(`/api/admin/publications/${product.id}`, product)
                .then(response => {
                    commit('setProduct', product)
                    commit('setVariations', response.data.data.relations.variations)

                    toastr.success('Termék elmentve', 'Siker')
                }).catch(error => {
                    toastr.error('Hiba a mentés közben', 'Hiba')
                }).finally(() => {
                    commit('setSaveState', false)
                })
        },
        updateStock({ commit, dispatch }, payload){
            commit('setSaveState', true)

            axios.patch(`/api/admin/products/${payload.id}/stock`, payload)
                .then(response => {
                    dispatch('getProduct', payload)
                    toastr.success('A készlet frissítve', 'Siker')
                }).catch(error => {
                    toastr.error('Hiba a mentés közben', 'Hiba')
                }).finally(() => {
                    commit('setSaveState', false)
                })
        }
    }
  }

  export default Products
