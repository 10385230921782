const Orders = {
    namespaced: true,
    state: {
        order: null,
        saving: {
            global: false,
            status: 0,
        },
        version: {
            status: 0,
        },
    },
    mutations: {
        setOrder(state, payload){
            state.order = payload
        },
        setSaveState(state, payload){
            state.saving[payload[0]] = payload[1]
        },
        bump(state, type){
            state.version[type]++
        }
    },
    actions: {
        getOrder({ commit }, order){
            axios.get(`/api/admin/orders/${order.id}`, {
                params: {
                    with: 'contact,status,statusHistory,products,statusWarning'
                }
            }).then(response => {
                commit('setOrder', response.data.data)
            })
        },
        updateStatus({ commit, state }, status){
            commit('setSaveState', ['status', 1])

            axios.patch(`/api/admin/orders/${state.order.id}/status`, {
                status: status,
                with: 'contact,status,statusHistory,products,statusWarning',
            }).then(response => {
                commit('setSaveState', ['status', 2])
                commit('setOrder', response.data.data)
            }).catch(error => {
                commit('setSaveState', ['status', 3])
                commit('bump', 'status')

                toastr.error('Hiba a státusz mentése közben', 'Hiba')
            }).finally(() => {
                setTimeout(() => {
                    commit('setSaveState', ['status', 0])
                }, 2000)
            })
        },
        updateOrder({ commit }, order){
            commit('setSaveState', ['global', true])
            order.with = 'contact,status,statusHistory,products'

            axios.patch(`/api/admin/orders/${order.id}`, order)
                .then(response => {
                    commit('setOrder', order)

                    toastr.success('Termék elmentve', 'Siker')
                }).catch(error => {
                    toastr.error('Hiba a mentés közben', 'Hiba')
                }).finally(() => {
                    commit('setSaveState', ['global', false])
                })
        }
    }
  }

  export default Orders
