<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item"><router-link slot="item-title" to="/admin/pages">Oldalak</router-link></li>
                <li class="breadcrumb-item active">{{ page.title }}</li>
            </ol>
            <div class="d-flex align-items-center">
                <h1>Oldal szerkesztése</h1>

                <div class="btn-group ml-auto">
                    <v-dropdown active-url="/" theme-light>
                        <a slot="activator" href="#" @click.prevent>
                            <button class="btn btn-sm btn-light dropdown-toggle"
                                type="button">
                                Műveletek
                            </button>
                        </a>

                        <v-dropdown-item>
                            <a href="#" @click.prevent="$refs.copyModal.open()">Másolás</a>
                        </v-dropdown-item>
                    </v-dropdown>

                    <button class="btn btn-sm btn-success" form="pageEditForm">
                        <i class="icon-fa icon-fa-save" v-if="!saving"></i>
                        <i class="icon-fa icon-fa-circle-o-notch icon-fa-spin" v-if="saving"></i>
                        Mentés
                    </button>
                </div>
            </div>
        </div>

        <page-editor :original="page"
            :save="saveCallback"
            @saving="onSaveStart">
        </page-editor>

        <copy-modal :original="page" ref="copyModal"></copy-modal>
    </div>
</template>

<script>
import ls from '../../services/ls.js'
import pageEditor from './PageEditor'
import CopyModal from '../../components/CopyModal'

export default {
    components: {
        CopyModal,
        pageEditor,
    },
    data () {
        return {
            page: {
                title: 'Új oldal',
                slug: 'uj-oldal',
                description: '',
                published_at: null,
                canonical: 'publications/uj-oldal',
                images: [],
                category: null,
                relations: [],
            },
            site: null,
            saving: false,
        }
    },
    methods: {
        loadPage(){
            axios.get(`/api/admin/publications/${this.$route.params.page}`, {
                params: {
                    with: 'category,content,images'
                }
            }).then(results => {
                this.page = results.data
            })
        },
        onSaveStart(){
            this.saving = true
        },
        saveCallback(page, content){
            page.site = this.site
            page.content = content

            axios.patch(`/api/admin/publications/${this.page.id}`, page)
                .then(response => {
                    this.page = page
                    toastr.success('Oldal elmentve', 'Siker')
                }).catch(err => {
                    toastr.error('Hiba a mentés közben', 'Hiba')
                }).finally(() => {
                    this.saving = false
                })
        }
    },
    created() {
        this.site = ls.get('site')

        this.loadPage()
    },

}
</script>

<style>

</style>
