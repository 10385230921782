<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/contacts'">Megrendelők</router-link></li>
                <li class="breadcrumb-item active">Megrendelő adatlap</li>
            </ol>
            <h1 class="d-inline mr-3" v-if="contact">{{ contact.name }}</h1>
        </div>
        <div v-if="!contact" class="text-center">
            <i class="icon-fa icon-fa-circle-o-notch icon-fa-spin icon-fa-4x"></i>
        </div>
        <div class="card" v-if="contact">
            <div class="card-body">
                <tabs class="tabs-simple">
                    <tab id="data" name="Adatok">
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <th>Név</th>
                                    <td>{{ contact.name }}</td>
                                </tr>
                                <tr>
                                    <th>Email cím</th>
                                    <td>{{ contact.email }}</td>
                                </tr>
                                <tr>
                                    <th>Telefonszám</th>
                                    <td>{{ contact.phone }}</td>
                                </tr>
                                <tr>
                                    <th>Cím</th>
                                    <td>{{ contact.address }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </tab>
                    <tab id="orders" name="Rendelések">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Azonosító</th>
                                    <th>Státusz</th>
                                    <th>Dátum</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="order in contact.relations.orders" :key="order.id">
                                    <td>
                                        <router-link :to="`/admin/orders/${order.id}`">
                                            {{ order.code }}
                                        </router-link>
                                    </td>
                                    <td>{{ getStatus(order.relations.status) }}</td>
                                    <td>{{ formatUtcDate(order.created_at) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </tab>
                </tabs>
            </div>
        </div>
    </div>
</template>

<script>
import ls from '../../services/ls'
import { Tabs, Tab } from 'vue-tabs-component'

export default {
    components: {
        Tab,
        Tabs,
    },
    data () {
        return {
            contact: null,
            site: null,
        }
    },
    methods: {
        load(){
            axios.get(`/api/admin/contacts/${this.$route.params.contact}`, {
                params: {
                    with: 'orders,status',
                }
            }).then(response => {
                this.contact = response.data.data
            })
        },
        getStatus(status){
            let statuses = {
                0: 'Új rendelés',
                1: 'Egyeztetés alatt',
                2: 'Ajánlatkészítés',
                3: 'Ajánlat kiküldve',
                4: 'Ajánlat elfogadva',
                5: 'Fizetve',
                6: 'Sikeresen lezárult',
                7: 'Meghiúsult rendelés',
            }

            if(status == null){
                return statuses[0]
            }

            return statuses[status.status]
        }
    },
    created() {
        this.site = ls.get('site')

        this.load()
    }
}
</script>

<style>

</style>
