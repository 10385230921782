<template>
    <sweet-modal ref="setPriceModal"
        overlay-theme="dark"
        modal-theme="dark"
        title="Egységár beállítása">
        <div v-if="product">

            <form v-if="product.priceWhenOrdered === null && product.price === null">
                <div class="alert alert-danger">Nincs ár beállítva a termékhez!</div>

                <div class="form-group">
                    <label>Ár beállítása</label>
                    <input type="number" class="form-control" v-model="product.customPrice">
                </div>
            </form>


            <form v-else-if="product.priceWhenOrdered === null">
                <div class="alert alert-warning">A rendeléskor nem volt ár beállítva!</div>

                <div class="btn-group btn-group-sm mb-3 btn-block">
                    <button class="btn btn-primary"
                        type="button"
                        @click="product.customPrice = product.price">
                        Aktuális termék ár beállítása
                    </button>
                </div>

                <div class="form-group">
                    <label>Egyedi ár beállítása</label>
                    <input type="number" class="form-control" v-model="product.customPrice">
                </div>
            </form>


            <form v-else-if="product.priceWhenOrdered != product.price">
                <div class="alert alert-warning">Az ár megváltozott a rendelés óta.</div>

                <div class="btn-group btn-group-sm mb-3 btn-block">
                    <button class="btn btn-primary"
                        type="button"
                        @click="product.customPrice = product.priceWhenOrdered">
                        Rendeléskor érvényes ár beállítása
                    </button>
                    <button class="btn btn-primary"
                        type="button"
                        @click="product.customPrice = product.price">
                        Aktuális termék ár beállítása
                    </button>
                </div>

                <div class="form-group">
                    <label>Ár beállítása</label>
                    <input type="number" class="form-control" v-model="product.customPrice">
                </div>
            </form>

            <form v-else>
                <div class="form-group">
                    <label>Ár beállítása</label>
                    <input type="number" class="form-control" v-model="product.customPrice">
                </div>
            </form>

            <div class="text-center">
                <p>Használt ár</p>
                <div class="display-4">{{ usedPrice }} Ft</div>
            </div>
        </div>
    </sweet-modal>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'

export default {
    components: {
        SweetModal,
    },
    props: {
        product: { type: [Object] }
    },
    computed: {
        usedPrice(){
            return this.product.customPrice
                ? this.product.customPrice
                : this.product.price
        }
    },
    methods: {
        open(){
            this.$refs.setPriceModal.open()
        },
        savePrice(){
            this.$refs.setPriceModal.close()
        }
    }
}
</script>

<style>

</style>
