var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content buttons-demo" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c("h5", { staticClass: "section-semi-title" }, [
              _vm._v("Reactive content")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Tooltip Text")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.tooltipText,
                    expression: "tooltipText"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.tooltipText },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.tooltipText = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top-center",
                    value: _vm.tooltipText,
                    expression: "tooltipText",
                    modifiers: { "top-center": true }
                  }
                ],
                staticClass: "btn btn-primary"
              },
              [_vm._v("Hover me")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6" }, [
            _c("h5", { staticClass: "section-semi-title" }, [
              _vm._v("Position")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Select a Position")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.position,
                      expression: "position"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { name: "position" },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.position = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "top" } }, [_vm._v("Top")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "right" } }, [
                    _vm._v("Right")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "bottom" } }, [
                    _vm._v("Bottom")
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "left" } }, [_vm._v("Left")])
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: {
                      content: _vm.tooltipText,
                      placement: _vm.position + "-center"
                    },
                    expression:
                      "{\n              content: tooltipText,\n              placement: position + '-center',\n            }"
                  }
                ],
                staticClass: "btn btn-primary"
              },
              [_vm._v("\n            Hover me\n          ")]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-5" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c("h5", { staticClass: "section-semi-title" }, [
              _vm._v("Show/Hide Delay")
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: {
                      content: _vm.tooltipText,
                      delay: {
                        show: 500,
                        hide: 300
                      }
                    },
                    expression:
                      "{\n              content: tooltipText,\n              delay: {\n                show: 500,\n                hide: 300,\n              },\n            }"
                  }
                ],
                staticClass: "btn btn-primary"
              },
              [_vm._v("\n            Hover me\n          ")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6" }, [
            _c("h5", { staticClass: "section-semi-title" }, [
              _vm._v("Offset & custom classes")
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: {
                      content: _vm.tooltipText,
                      classes: ["info"],
                      targetClasses: ["it-has-a-tooltip"],
                      offset: 30
                    },
                    expression:
                      "{\n              content: tooltipText,\n              classes: ['info'],\n              targetClasses: ['it-has-a-tooltip'],\n              offset: 30,\n            }"
                  }
                ],
                staticClass: "btn btn-primary"
              },
              [_vm._v("\n            Hover me\n          ")]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-5" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("h5", { staticClass: "section-semi-title" }, [
                  _vm._v("Manual Mode")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.isVisible,
                          expression: "isVisible"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "enableCheckbox",
                        name: "open",
                        type: "checkbox",
                        value: "option1"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.isVisible)
                          ? _vm._i(_vm.isVisible, "option1") > -1
                          : _vm.isVisible
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.isVisible,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "option1",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.isVisible = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.isVisible = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.isVisible = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "enableCheckbox" }
                      },
                      [_vm._v("Enable")]
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm.isVisible
                  ? [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          { staticClass: "form-check form-check-inline" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.isOpen,
                                  expression: "isOpen"
                                }
                              ],
                              staticClass: "form-check-input",
                              attrs: { id: "showRadio", type: "radio" },
                              domProps: {
                                value: true,
                                checked: _vm._q(_vm.isOpen, true)
                              },
                              on: {
                                change: function($event) {
                                  _vm.isOpen = true
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "form-check-label",
                                attrs: { for: "showRadio" }
                              },
                              [_vm._v("Show")]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-check form-check-inline" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.isOpen,
                                  expression: "isOpen"
                                }
                              ],
                              staticClass: "form-check-input",
                              attrs: { id: "hideRadio", type: "radio" },
                              domProps: {
                                value: false,
                                checked: _vm._q(_vm.isOpen, false)
                              },
                              on: {
                                change: function($event) {
                                  _vm.isOpen = false
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "form-check-label",
                                attrs: { for: "hideRadio" }
                              },
                              [_vm._v("Hide")]
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: _vm.tooltipText,
                                show: _vm.isOpen,
                                trigger: "manual",
                                placement: "bottom"
                              },
                              expression:
                                "{\n                  content: tooltipText,\n                  show: isOpen,\n                  trigger: 'manual',\n                  placement: 'bottom',\n                }"
                            }
                          ],
                          staticClass: "btn btn-primary"
                        },
                        [_vm._v("\n                A button\n              ")]
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6" }, [
            _c("h5", { staticClass: "section-semi-title" }, [_vm._v("Groups")]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "close-popover",
                      rawName: "v-close-popover.all",
                      modifiers: { all: true }
                    }
                  ],
                  staticClass: "btn btn-warning"
                },
                [_vm._v("Close All")]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row align-items-start" },
              [
                _c(
                  "v-popover",
                  {
                    staticClass: "col-sm-auto",
                    attrs: { "auto-hide": false, "open-group": "group1" }
                  },
                  [
                    _c("button", { staticClass: "btn btn-primary" }, [
                      _vm._v("Click Group 1")
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "popover" }, [
                      _c("p", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.tooltipText) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "close-popover",
                              rawName: "v-close-popover"
                            }
                          ],
                          staticClass: "btn btn-theme btn-sm"
                        },
                        [_vm._v("Close")]
                      )
                    ])
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "v-popover",
                  {
                    staticClass: "col-sm-auto",
                    attrs: { "auto-hide": false, "open-group": "group1" }
                  },
                  [
                    _c("button", { staticClass: "btn btn-primary" }, [
                      _vm._v("Click Group 1")
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "popover" }, [
                      _c("p", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.tooltipText) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "close-popover",
                              rawName: "v-close-popover"
                            }
                          ],
                          staticClass: "btn btn-theme btn-sm"
                        },
                        [_vm._v("Close")]
                      )
                    ])
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "v-popover",
                  {
                    staticClass: "col-sm-auto",
                    attrs: { "auto-hide": false, "open-group": "group2" }
                  },
                  [
                    _c("button", { staticClass: "btn btn-primary" }, [
                      _vm._v("Click Group 2")
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "popover" }, [
                      _c("p", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.tooltipText) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "close-popover",
                              rawName: "v-close-popover"
                            }
                          ],
                          staticClass: "btn btn-theme btn-sm"
                        },
                        [_vm._v("Close")]
                      )
                    ])
                  ],
                  2
                )
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-5" }, [
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("h5", { staticClass: "section-semi-title" }, [
                  _vm._v("Custom components for popover")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.isEnabled,
                          expression: "isEnabled"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: { id: "enableCheck", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.isEnabled)
                          ? _vm._i(_vm.isEnabled, null) > -1
                          : _vm.isEnabled
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.isEnabled,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.isEnabled = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.isEnabled = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.isEnabled = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "enableCheck" }
                      },
                      [_vm._v("\n                  Enable\n                ")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.autohide,
                          expression: "autohide"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: { id: "autoHideCheck", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.autohide)
                          ? _vm._i(_vm.autohide, null) > -1
                          : _vm.autohide
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.autohide,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.autohide = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.autohide = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.autohide = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "autoHideCheck" }
                      },
                      [
                        _vm._v(
                          "\n                  Hide on clicking outside (autohide)\n                "
                        )
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "v-popover",
                  {
                    attrs: {
                      disabled: !_vm.isEnabled,
                      "auto-hide": _vm.autohide
                    }
                  },
                  [
                    _c("button", { staticClass: "btn btn-primary" }, [
                      _vm._v("Click me")
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "popover" }, [
                      _c("p", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.tooltipText) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "close-popover",
                              rawName: "v-close-popover"
                            }
                          ],
                          staticClass: "btn btn-theme btn-sm"
                        },
                        [_vm._v("Close")]
                      )
                    ])
                  ],
                  2
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6" }, [
            _c("h5", { staticClass: "section-semi-title" }, [
              _vm._v("Async content")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: _vm.asyncContent,
                        loadingContent: "<i>Loading...</i>"
                      },
                      expression:
                        "{\n                content: asyncContent,\n                loadingContent: '<i>Loading...</i>',\n              }"
                    }
                  ],
                  staticClass: "btn btn-primary"
                },
                [_vm._v("\n              Hover me\n            ")]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Tooltips")]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Components")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Tooltips")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }