var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    slot: "item-title",
                    to: "/admin/sites/" + this.site
                  },
                  slot: "item-title"
                },
                [_vm._v("Vezérlőpult")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                {
                  attrs: { slot: "item-title", to: "/admin/products" },
                  slot: "item-title"
                },
                [_vm._v("Termékek")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.product.title))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("h1", [_vm._v("Termék szerkesztése")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-group ml-auto" },
            [
              _c(
                "v-dropdown",
                { attrs: { "active-url": "/", "theme-light": "" } },
                [
                  _c(
                    "a",
                    {
                      attrs: { slot: "activator", href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                        }
                      },
                      slot: "activator"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-light dropdown-toggle",
                          attrs: { type: "button" }
                        },
                        [
                          _vm._v(
                            "\n                            Műveletek\n                        "
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-dropdown-item", [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.$refs.copyModal.open()
                          }
                        }
                      },
                      [_vm._v("Másolás")]
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-success",
                  attrs: { form: "productEditForm" }
                },
                [
                  !_vm.saving
                    ? _c("i", { staticClass: "icon-fa icon-fa-save" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.saving
                    ? _c("i", {
                        staticClass:
                          "icon-fa icon-fa-circle-o-notch icon-fa-spin"
                      })
                    : _vm._e(),
                  _vm._v("\n                    Mentés\n                ")
                ]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("product-editor", {
        attrs: { original: _vm.product, save: _vm.saveCallback }
      }),
      _vm._v(" "),
      _c("copy-modal", { ref: "copyModal", attrs: { original: _vm.product } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }