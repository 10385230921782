export default class MediaLibraryImage {
    constructor({ data, api, config }) {
        this.api = api
        this.config = config
        this.data = data
    }

    static get toolbox() {
        return {
            title: 'Image',
            icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>'
        };
    }

    render() {
        var me = this
        var container = document.createElement('div')
        let button = document.createElement('button')
        let image = document.createElement('img')

        if (me.data && me.data.url) {
            image.setAttribute('src', me.data.url)
            image.classList.add('w-100')
        } else {
            image.setAttribute('src', 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgLTAuODkxIC0yMi4xODQgMTQxLjczMiAxNDEuNzMyIiBoZWlnaHQ9IjE0MS43MzJweCIgaWQ9IkxpdmVsbG9fMSIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSItMC44OTEgLTIyLjE4NCAxNDEuNzMyIDE0MS43MzIiIHdpZHRoPSIxNDEuNzMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnIGlkPSJMaXZlbGxvXzQ2Ij48cGF0aCBkPSJNMzIuOTA5LDI0LjQ3MmMwLTUuNjIxLTQuNTM1LTEwLjE4MS0xMC4xMjktMTAuMTgxYy01LjU5NCwwLTEwLjEyNyw0LjU2LTEwLjEyNywxMC4xODFTMTcuMTg2LDM0LjY1LDIyLjc4LDM0LjY1ICAgQzI4LjM3NCwzNC42NSwzMi45MDksMzAuMDk0LDMyLjkwOSwyNC40NzIgTTkuNzE3LDg3Ljk2OWMwLDAtMC4wOTgsMC4xMzQtMC4yMzksMC4zNTRoMTIwLjk5NiAgIGMtMC4wMTEtMC4wMTMtMC4wMTktMC4wMjEtMC4wMjctMC4wMzdMODkuMTUxLDMyLjA2OWMwLDAtNC44OTYtNi42NjQtOS43ODksMEw1My44MDcsNjYuODZsLTkuNC0xMi44MDVjMCwwLTQuODk1LTYuNjYyLTkuNzg3LDAgICBMOS43MTcsODcuOTY5eiBNMTM0LjU2Nyw5MS45NTZINS4zODJWNS40MDloMTI5LjE4NlY5MS45NTZMMTM0LjU2Nyw5MS45NTZ6IE0xMzkuOTUxLDk0LjY2VjIuNzA1YzAtMS40OTQtMS4yMDUtMi43MDUtMi42OS0yLjcwNSAgIEgyLjY5MkMxLjIwNiwwLDAsMS4yMTEsMCwyLjcwNVY5NC42NmMwLDEuNDk0LDEuMjA1LDIuNzA1LDIuNjkxLDIuNzA1aDEzNC41N0MxMzguNzQ2LDk3LjM2NSwxMzkuOTUxLDk2LjE1NCwxMzkuOTUxLDk0LjY2Ii8+PC9nPjxnIGlkPSJMaXZlbGxvXzFfMV8iLz48L3N2Zz4=')
        }

        container.appendChild(image)

        if (me.data && me.data.url) {
            let caption = document.createElement('input')
            caption.classList = 'form-control'
            caption.value = me.data.caption
            container.appendChild(caption)
        }

        button.innerText = 'Médiatár megnyitása'
        button.classList = 'btn btn-dark btn-block btn-sm'
        button.setAttribute('type', 'button')
        button.onclick = function (e) {
            me.showLibrary()
        }

        me.config.selectCallback(({ dataUrl, name }) => {
            me.data.url = dataUrl
            me.data.caption = name

            let index = me._currentBlockIndex ? me._currentBlockIndex : 0
            me.api.blocks.insert('image', me.data, null, index + 1, true)
            me.api.blocks.delete(index)
        })

        container.classList = 'text-center'
        container.appendChild(button)
        return container
    }

    showLibrary() {
        this._currentBlockIndex = this.api.blocks.getCurrentBlockIndex()
        this.config.showCallback()
    }

    validate(saveData) {
        return saveData.invalid ? false : true
    }

    save(content) {
        const image = content.querySelector('img')
        const input = content.querySelector('input')

        if (!input) {
            return {
                invalid: true
            }
        }

        return {
            url: image.getAttribute('src'),
            caption: input.value
        }
    }
}
