<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" to="/admin/dashboard">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item"><router-link slot="item-title" to="/admin/sites">Domainek</router-link></li>
                <li class="breadcrumb-item active">Új domain</li>
            </ol>
            <h1>Új domain</h1>
        </div>
        <div class="card">
            <div class="card-body">
                <form @submit.prevent="submit">
                    <div class="form-group">
                        <label for="domainInput">Domain (pl.: itzen.hu):</label>
                        <input type="text" class="form-control" id="domainInput" v-model="domain">
                    </div>

                    <button class="btn btn-success">
                        <i class="icon-fa icon-fa-save"/>Mentés
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data () {
        return {
            domain: '',
        }
    },
    methods: {
        submit () {
            axios.post('/api/admin/sites', {domain: this.domain})
                .then(response => {
                    toastr.success('Site létrehozva', 'Siker')
                    this.$router.push(`/admin/sites/${response.data.data.id}/edit`)
                })
                .catch(err => {
                    toastr.error('Hiba a létrehozás közben', 'Hiba')
                })
        }
    }
}
</script>

<style>

</style>
