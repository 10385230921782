var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/sites/" + this.site },
                slot: "item-title"
              },
              [_vm._v("Vezérlőpult")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [_vm._v("Akciók")])
      ]),
      _vm._v(" "),
      _c("h1", { staticClass: "d-inline mr-3" }, [_vm._v("Akciók")]),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-outline-info btn-sm align-text-bottom" },
        [
          _c("router-link", { attrs: { to: "/admin/sales/create" } }, [
            _c("i", { staticClass: "icon-fa icon-fa-plus" }),
            _vm._v(" Új hozzáadása\n            ")
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("cool-table", {
            attrs: {
              fields: _vm.fields,
              "data-source": _vm.dataSource,
              textfilter: true,
              selectfilters: _vm.filters,
              "row-class": _vm.getRowClass
            },
            scopedSlots: _vm._u([
              {
                key: "name",
                fn: function(props) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: "/admin/sales/" + props.rowData.id + "/edit"
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(props.rowData.name) +
                            "\n                    "
                        )
                      ]
                    )
                  ]
                }
              },
              {
                key: "value",
                fn: function(props) {
                  return [
                    _vm._v(
                      "\n                    " +
                        _vm._s(props.rowData.value) +
                        " " +
                        _vm._s(props.rowData.type == 0 ? "Ft" : "%") +
                        "\n                "
                    )
                  ]
                }
              },
              {
                key: "starts_at",
                fn: function(props) {
                  return [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.getTooltip(props.rowData),
                            expression: "getTooltip(props.rowData)"
                          }
                        ]
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.formatUtcDate(props.rowData.starts_at)) +
                            "\n                    "
                        )
                      ]
                    )
                  ]
                }
              },
              {
                key: "ends_at",
                fn: function(props) {
                  return [
                    props.rowData.ends_at
                      ? _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: _vm.getTooltip(props.rowData),
                                expression: "getTooltip(props.rowData)"
                              }
                            ]
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.formatUtcDate(props.rowData.ends_at)
                                ) +
                                "\n                    "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }