import Vue from 'vue'
import Vuex from 'vuex'
import App from './stores/App'
import Sales from './stores/Sales'
import Orders from './stores/Orders'
import Products from './stores/Products'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        products: Products,
        orders: Orders,
        sales: Sales,
        app: App,
    }
})

export default store
