<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item active">Oldalak</li>
            </ol>
            <h1 class="d-inline mr-3">Oldalak</h1>
            <button class="btn btn-outline-info btn-sm align-text-bottom">
                <router-link to="./pages/create">
                    <i class="icon-fa icon-fa-plus"/> Új hozzáadása
                </router-link>
            </button>
        </div>
        <div class="card">
            <div class="card-body">
                <cool-table :fields="fields"
                    :data-source="dataSource"
                    :textfilter="true">
                    <template slot="title" slot-scope="props">
                        <router-link :to="`/admin/pages/${props.rowData.id}/edit`">
                            {{ props.rowData.title }}
                        </router-link>
                    </template>
                </cool-table>
            </div>
        </div>
    </div>
</template>

<script>
import ls from '../../services/ls.js'
import CoolTable from '../../components/CoolTable'

export default {
    components: {
        CoolTable
    },
    data () {
        return {
            site: null,
            fields: [
                {
                    name: 'title',
                    title: 'Név',
                    sortField: 'title'
                },
                {
                    name: 'published_at',
                    title: 'Publikálva',
                    sortField: 'published_at'
                }
            ]
        }
    },
    methods: {
        dataSource (sortOrder, pagination, filters) {
            var sort = {
                field: sortOrder[0] ? sortOrder[0].sortField : 'title',
                direction: sortOrder[0] ? sortOrder[0].direction : 'asc',
            };

            return axios.get('/api/admin/publications', {
                params: {
                    with: 'category,author',
                    page: pagination.current_page,
                    filters: {
                        site: this.site,
                        type: 'page',
                        ...filters,
                    },
                    sort: sort.field,
                    order: sort.direction
                }
            })
        }
    },
    created() {
        this.site = ls.get('site')
    }
}
</script>

<style>

</style>
