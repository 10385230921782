<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item active">Termékek</li>
            </ol>
            <h1 class="d-inline mr-3">Termékek</h1>
            <button class="btn btn-outline-info btn-sm align-text-bottom">
                <router-link to="./products/create">
                    <i class="icon-fa icon-fa-plus"/> Új hozzáadása
                </router-link>
            </button>
        </div>
        <div class="card">
            <div class="card-body">
                <cool-table :fields="fields"
                    :data-source="dataSource"
                    :textfilter="true"
                    :selectfilters="filters">
                    <template slot="product_type" slot-scope="props">
                        <i class="icon-fa icon-fa-fw"
                            :class="types[props.rowData.product_type].icon"
                            v-tooltip="{content: `${types[props.rowData.product_type].name} termék` }">
                        </i>
                        <span class="d-none d-md-inline">
                            {{ types[props.rowData.product_type].name }}
                        </span>
                    </template>
                    <template slot="title" slot-scope="props">
                        <router-link :to="`/admin/products/${props.rowData.id}/edit`">
                            {{ props.rowData.title }}
                        </router-link>
                    </template>
                </cool-table>
            </div>
        </div>
    </div>
</template>

<script>
import ls from '../../services/ls'
import CoolTable from '../../components/CoolTable'

export default {
    components: {
        CoolTable
    },
    data () {
        return {
            site: null,
            fields: [
                {
                    name: 'product_type',
                    title: 'Típus'
                },
                {
                    name: 'title',
                    title: 'Név',
                    sortField: 'title'
                },
                {
                    name: 'published_at',
                    title: 'Publikálva',
                    sortField: 'published_at'
                }
            ],
            filters: {
                'product_type': {
                    name: 'Típus',
                    value: -1,
                    options: {
                        '-1': 'Mindegy',
                        '0': 'Normál',
                        '1': 'Csoportosított',
                        '2': 'Variálható',
                    }
                }
            },
            types: [
                {
                    name: 'Normál',
                    icon: 'icon-fa-cube'
                },
                {
                    name: 'Csoportosított',
                    icon: 'icon-fa-cubes'
                },
                {
                    name: 'Variálható',
                    icon: 'icon-fa-clone'
                }
            ]
        }
    },
    methods: {
        dataSource (sortOrder, pagination, filters) {
            var sort = {
                field: sortOrder[0] ? sortOrder[0].sortField : 'published_at',
                direction: sortOrder[0] ? sortOrder[0].direction : 'desc',
            };

            return axios.get('/api/admin/publications', {
                params: {
                    page: pagination.current_page,
                    filters: {
                        site: this.site,
                        type: 'product',
                        ...filters,
                    },
                    sort: sort.field,
                    order: sort.direction
                }
            })
        }
    },
    created() {
        this.site = ls.get('site')
    }
}
</script>

<style>

</style>
