<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item active">Címkék</li>
            </ol>
            <h1 class="d-inline mr-3">Címkék</h1>
            <button class="btn btn-outline-info btn-sm align-text-bottom" @click="createTag">
                <i class="icon-fa icon-fa-plus"/> Új hozzáadása
            </button>
        </div>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-body inline-tree-container">
                        <treeselect ref="treeselect"
                            v-model="tag"
                            :options="tags"
                            :multiple="false"
                            :clearable="false"
                            :normalizer="normalizer"
                            :always-open="true"
                            @select="changeTag">
                        </treeselect>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <form v-if="editBuffer" @submit.prevent="saveTag">
                            <div class="form-group">
                                <label for="nameInput">Név</label>
                                <input type="text" class="form-control" v-model="editBuffer.name">
                            </div>

                            <div class="form-group">
                                <label for="slugInput">Slug</label>
                                <input type="text" class="form-control" v-model="editBuffer.slug">
                            </div>

                            <div class="form-group">
                                <label for="descriptionInput">Leírás</label>
                                <editor ref="editor"
                                    :config="editorConfig">
                                </editor>
                            </div>

                            <button class="btn btn-info">
                                <i class="icon-fa icon-fa-save" v-if="!saving"></i>
                                <i class="icon-fa icon-fa-spin icon-fa-circle-o-notch" v-if="saving"></i>
                                Mentés
                            </button>
                        </form>

                        <div v-if="!editBuffer">
                            <p>Nincs kijelölve szerkeszthető elem!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </div>
</template>

<script>
import Vue from 'vue'
import ls from '../../services/ls.js'
import Editor from 'vue-editor-js/src/index'
import Treeselect from '@riophae/vue-treeselect'
import toolConfig from '../../helpers/slimToolConfig.js'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

Vue.use(Editor)

export default {
    components: {
        Treeselect
    },
    data () {
        return {
            tag: null,
            tags: [],
            site: null,
            editBuffer: null,
            saving: false,
            enableDescription: false,
            editorConfig: {
                tools: toolConfig.get(this),
                onReady: () => {
                    this.enableDescription = true
                    this.loadDescription()
                },
                data: {},
                autofocus: true,
                placeholder: 'Leírás'
            }
        }
    },
    methods: {
        loadTags(updateLoader=false){
            if(updateLoader){
                this.$refs.treeselect.rootOptionsStates.isLoading = true
            }

            axios.get('/api/admin/tags', {
                params: {
                    with: 'publications,description',
                    filters: {
                        site: this.site,
                    }
                }
            })
            .then(results => {
                this.tags = results.data.data

                if(this.tags.length){
                    this.tag = this.tags[0].id
                    this.editBuffer = this.tags[0]
                    this.editBuffer.parent = this.tags[0].relations.parent ? this.tags[0].relations.parent.id : null
                    this.loadDescription()
                }

                if(updateLoader){
                    this.$refs.treeselect.rootOptionsStates.isLoading = false
                }
            })
        },
        normalizer (node) {
            node.label = node.name

            return node
        },
        loadDescription(){
            if(!this.enableDescription){
                return
            }

            let data = this.editBuffer.relations.description.map(o => {
                return o.content
            })

            if(data.length==0){
                data = [{"data": {"text": ""}, "type": "paragraph"}]
            }

            this.$refs.editor.state.editor.render({
                blocks: data,
                version: '2.8.1',
                time: (new Date).getTime()
            })
        },
        createTag(){
            this.tags.push({
                id: 'new',
                name: 'Új címke',
                slug: 'new',
                relations: {
                    description: [],
                },
            })
        },
        changeTag (node) {
            this.editBuffer = node
            this.loadDescription()
        },
        saveTag(){
            this.saving = true

            this.$refs.editor.state.editor.save().then(description => {
                if(this.editBuffer.id == 'new'){
                    let data = {
                        site: this.site,
                        description: description.blocks,
                        ...this.editBuffer
                    }
                    axios.post(`/api/admin/tags`, data)
                        .then(result => {
                            toastr.success('A címke létrehozva', 'Siker')
                            this.editBuffer.id = result.data.data.id
                            this.saving = false
                            this.loadTags(true)
                        })
                        .catch(err => {
                            toastr.error('Nem sikerült elmenteni a címkét', 'Hiba')
                        })
                }else{
                    if(!this.editBuffer.parent){
                        this.editBuffer.parent = null
                    }

                    this.editBuffer.description = description.blocks

                    axios.patch(`/api/admin/tags/${this.editBuffer.id}`, this.editBuffer)
                        .then(result => {
                            toastr.success('A címke frissítve', 'Siker')
                            this.saving = false
                            this.loadTags(true)
                        })
                        .catch(err => {
                            toastr.error('Nem sikerült elmenteni a címkét', 'Hiba')
                        })
                }
            })
        }
    },
    created() {
        this.site = ls.get('site')

        this.loadTags()
    }
}
</script>

<style>
.inline-tree-container .vue-treeselect__menu-container,
.inline-tree-container .vue-treeselect__menu{
    position: static;
}
</style>
