var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("Basic")
              ]),
              _vm._v(" "),
              _c(
                "carousel",
                {
                  attrs: {
                    "min-swipe-distance": 2,
                    "per-page-custom": [
                      [480, 2],
                      [768, 3],
                      [992, 4]
                    ]
                  }
                },
                [
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/1.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/2.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/3.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/4.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/5.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/6.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/7.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/8.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/9.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/10.jpg" }
                    })
                  ])
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-5" }, [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("Responsive")
              ]),
              _vm._v(" "),
              _c(
                "carousel",
                {
                  attrs: {
                    "scroll-per-page": true,
                    "per-page-custom": [
                      [320, 2],
                      [768, 3],
                      [992, 4]
                    ]
                  }
                },
                [
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/11.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/12.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/9.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/4.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/3.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/6.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/2.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/8.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/1.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/10.jpg" }
                    })
                  ])
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-5" }, [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("Auto Play")
              ]),
              _vm._v(" "),
              _c(
                "carousel",
                {
                  attrs: {
                    autoplay: true,
                    loop: true,
                    "per-page-custom": [
                      [480, 2],
                      [768, 3],
                      [992, 4]
                    ]
                  }
                },
                [
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/9.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/5.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/1.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/7.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/3.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/8.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/2.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/6.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/4.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/10.jpg" }
                    })
                  ])
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mb-5" }, [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("Navigation")
              ]),
              _vm._v(" "),
              _c(
                "carousel",
                {
                  staticClass: "with-nav",
                  attrs: {
                    "navigation-enabled": true,
                    "navigation-click-target-size": 5,
                    "per-page-custom": [
                      [480, 2],
                      [768, 3],
                      [992, 4]
                    ]
                  }
                },
                [
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/4.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/8.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/10.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/12.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/5.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/11.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/2.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/8.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/1.jpg" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("slide", [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: "/assets/img/demo/gallery/6.jpg" }
                    })
                  ])
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Vue Carousel")]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Components")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Vue Carousel")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }