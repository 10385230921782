var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card my-1 mx-3" }, [
    _c("div", { staticClass: "card-header d-flex align-items-center" }, [
      _c(
        "span",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.tooltipHeader,
              expression: "tooltipHeader"
            }
          ]
        },
        [
          _vm._v("\n            " + _vm._s(_vm.textTitle) + "\n            "),
          _c("i", { staticClass: "icon-fa icon-fa-question-circle" })
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-info ml-auto",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.addItem()
            }
          }
        },
        [
          _c("i", { staticClass: "icon-fa icon-fa-plus" }),
          _vm._v("\n            " + _vm._s(_vm.textNew) + "\n        ")
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c("table", { staticClass: "table table-striped" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.attributes, function(attribute, key) {
            return _c("tr", { key: key }, [
              _c("td", [
                _c(
                  "div",
                  { staticClass: "form-group mb-0" },
                  [
                    _vm.availableNames
                      ? _c("multiselect", {
                          staticClass: "form-control p-0 border-0",
                          class: {
                            "is-invalid": _vm.hasDuplicate(attribute.name, key)
                          },
                          attrs: {
                            options: _vm.availableNames,
                            multiple: false,
                            taggable: true,
                            "allow-empty": false
                          },
                          on: {
                            tag: function($event) {
                              var i = arguments.length,
                                argsArray = Array(i)
                              while (i--) argsArray[i] = arguments[i]
                              return _vm.createAttributeName.apply(
                                void 0,
                                [attribute].concat(argsArray)
                              )
                            },
                            input: function($event) {
                              var i = arguments.length,
                                argsArray = Array(i)
                              while (i--) argsArray[i] = arguments[i]
                              return _vm.updateAttributeUnit.apply(
                                void 0,
                                [attribute].concat(argsArray)
                              )
                            }
                          },
                          model: {
                            value: attribute.name,
                            callback: function($$v) {
                              _vm.$set(attribute, "name", $$v)
                            },
                            expression: "attribute.name"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasDuplicate(attribute.name, key)
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(
                            "\n                                Duplikált kulcs!\n                            "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "align-top" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: attribute.value,
                      expression: "attribute.value"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: attribute.value },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(attribute, "value", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "align-top" }, [
                _vm.getUnitTypeForAttribute(attribute.name) != "object"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: attribute.unit,
                          expression: "attribute.unit"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        disabled:
                          _vm.getUnitTypeForAttribute(attribute.name) !=
                          "custom"
                      },
                      domProps: { value: attribute.unit },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(attribute, "unit", $event.target.value)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.getUnitTypeForAttribute(attribute.name) == "object"
                  ? _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: attribute.unit,
                            expression: "attribute.unit"
                          }
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              attribute,
                              "unit",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      _vm._l(_vm.units[attribute.name], function(unit) {
                        return _c(
                          "option",
                          { key: unit, domProps: { value: unit } },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(unit) +
                                "\n                            "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "align-top" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.removeItem(key)
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-trash",
                      staticStyle: { width: "auto" }
                    })
                  ]
                )
              ])
            ])
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "50%" } }, [_vm._v("Azonosító")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "50%" } }, [_vm._v("Érték")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "3em" } }, [_vm._v("Mértékegység")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "1.5em" } })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }