var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content", attrs: { id: "dashboardPage" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12 col-xl-12 mt-2" }, [
          _c("div", { staticClass: "card with-tabs" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c(
                  "tabs",
                  { staticClass: "tabs-simple" },
                  [
                    _c(
                      "tab",
                      { attrs: { id: "balanceSummry", name: "Daily" } },
                      [
                        _c("line-chart", {
                          attrs: {
                            labels: [
                              "Oct 10",
                              "Oct 11",
                              "Oct 12",
                              "Oct 13",
                              "Oct 14",
                              "Oct 15",
                              "Oct 16"
                            ],
                            values: [200, 1000, 300, 551, 516, 225, 40]
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "tab",
                      { attrs: { id: "monthlyProfit", name: "Monthly" } },
                      [
                        _c("line-chart", {
                          attrs: {
                            labels: [
                              "January",
                              "February",
                              "March",
                              "April",
                              "May",
                              "June",
                              "July"
                            ],
                            values: [5000, 6000, 2000, 7000, 1000, 3000, 5000]
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "tab",
                      { attrs: { id: "yearly", name: "Yearly" } },
                      [
                        _c("line-chart", {
                          attrs: {
                            labels: [
                              "2011",
                              "2012",
                              "2013",
                              "2014",
                              "2015",
                              "2016",
                              "2017"
                            ],
                            values: [
                              10000,
                              20000,
                              12444,
                              15000,
                              17000,
                              14000,
                              20000
                            ]
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12 col-xl-6 mt-2" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "actions" }, [
                _c(
                  "div",
                  { staticClass: "btn-group", attrs: { role: "group" } },
                  [
                    _c(
                      "v-dropdown",
                      { attrs: { "show-arrow": false } },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-sm btn-outline-primary dropdown-toggle",
                            attrs: { slot: "activator", type: "button" },
                            slot: "activator"
                          },
                          [
                            _vm._v(
                              "\n                  Filters\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("v-dropdown-item", [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" }
                            },
                            [
                              _vm._v(
                                "\n                    October\n                  "
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-dropdown-item", [
                          _c(
                            "a",
                            {
                              staticClass: "dropdown-item",
                              attrs: { href: "#" }
                            },
                            [
                              _vm._v(
                                "\n                    September\n                  "
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("pie-chart", {
                  attrs: {
                    labels: ["Revenue", "Expense", "Profit"],
                    values: [300, 50, 250],
                    "bg-colors": ["#5BBFDE", "#f35a3d", "#4fc47f"],
                    "hover-bg-colors": ["#5BBFDE", "#f35a3d", "#4fc47f"]
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-12 col-xl-6 mt-2" }, [
          _c("div", { staticClass: "card" }, [
            _vm._m(3),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("bar-chart", {
                  attrs: {
                    labels: [
                      "January",
                      "February",
                      "March",
                      "April",
                      "May",
                      "June",
                      "July"
                    ],
                    values: [5000, 6000, 2000, 7000, 1000, 3000, 5000]
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12 col-lg-6" }, [
        _c(
          "a",
          {
            staticClass: "dashbox dashbox-circle-progress",
            attrs: { href: "#" }
          },
          [
            _c("span", { staticClass: "desc" }, [
              _vm._v("\n          Sales\n        ")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "title text-primary" }, [
              _vm._v("\n          $ 3500\n        ")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "easy-pie-chart",
                attrs: { "data-percent": "20", "data-color": "#007dcc" }
              },
              [_c("span", { staticClass: "percent text-primary" })]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12 col-lg-6" }, [
        _c(
          "a",
          {
            staticClass: "dashbox dashbox-circle-progress",
            attrs: { href: "#" }
          },
          [
            _c("span", { staticClass: "desc" }, [
              _vm._v("\n          Profit\n        ")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "title text-success" }, [
              _vm._v("\n          $ 1000\n        ")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "easy-pie-chart",
                attrs: { "data-percent": "50", "data-color": "#4fc47f" }
              },
              [_c("span", { staticClass: "percent text-success" })]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12 col-lg-6" }, [
        _c(
          "a",
          {
            staticClass: "dashbox dashbox-circle-progress",
            attrs: { href: "#" }
          },
          [
            _c("span", { staticClass: "desc" }, [
              _vm._v("\n          Expense\n        ")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "title text-danger" }, [
              _vm._v("\n          $ 200\n        ")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "easy-pie-chart",
                attrs: { "data-percent": "70", "data-color": "#f35a3d" }
              },
              [_c("span", { staticClass: "percent text-danger" })]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12 col-lg-6" }, [
        _c(
          "a",
          {
            staticClass: "dashbox dashbox-circle-progress",
            attrs: { href: "#" }
          },
          [
            _c("span", { staticClass: "desc" }, [
              _vm._v("\n          Revenue\n        ")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "title text-info" }, [
              _vm._v("\n          $ 5000\n        ")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "easy-pie-chart",
                attrs: { "data-percent": "80", "data-color": "#5BBFDE" }
              },
              [_c("span", { staticClass: "percent text-info" })]
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "caption" }, [
        _c("h6", [
          _c("i", { staticClass: "icon-fa icon-fa-bar-chart text-success" }),
          _vm._v(" Total Expenses")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "caption" }, [
      _c("h6", [
        _c("i", { staticClass: "icon-fa icon-fa-credit-card text-primary" }),
        _vm._v(" Summary")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "caption" }, [
        _c("h6", [
          _c("i", { staticClass: "icon-fa icon-fa-shopping-cart text-danger" }),
          _vm._v(" Total Revenue")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "actions" }, [
        _c("button", { staticClass: "btn btn-danger btn-sm" }, [
          _vm._v(" Today")
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "btn btn-outline-danger btn-sm" }, [
          _vm._v(" Past Month")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }