var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _vm.tablePagination
      ? _c("div", { staticClass: "col-md-6 col-12" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.tablePagination.from) +
              "-" +
              _vm._s(_vm.tablePagination.to) +
              "\n        (" +
              _vm._s(_vm.tablePagination.to - _vm.tablePagination.from + 1) +
              " db) sor.\n        Összesen " +
              _vm._s(_vm.tablePagination.total) +
              " db\n    "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6 col-12" }, [
      _c("nav", { attrs: { "aria-label": "Table Navigation" } }, [
        _c(
          "ul",
          { staticClass: "pagination mb-0 justify-content-end" },
          [
            _c("li", { class: { disabled: _vm.isOnFirstPage } }, [
              _c(
                "a",
                {
                  staticClass: "page-link",
                  attrs: { href: "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.loadPage(1)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "left icon-fa icon-fa-angle-double-left"
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "page-item",
                class: { disabled: _vm.isOnFirstPage }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "page-link",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.loadPage("prev")
                      }
                    }
                  },
                  [_vm._m(0)]
                )
              ]
            ),
            _vm._v(" "),
            _vm.notEnoughPages
              ? _vm._l(_vm.totalPage, function(n) {
                  return _c(
                    "li",
                    {
                      key: n,
                      staticClass: "page-item",
                      class: { active: _vm.isCurrentPage(n) }
                    },
                    [
                      _c("a", {
                        staticClass: "page-link",
                        attrs: { href: "" },
                        domProps: { innerHTML: _vm._s(n) },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.loadPage(n)
                          }
                        }
                      })
                    ]
                  )
                })
              : _vm._l(_vm.windowSize, function(n) {
                  return _c(
                    "li",
                    {
                      key: n,
                      staticClass: "page-item",
                      class: {
                        active: _vm.isCurrentPage(_vm.windowStart + n - 1)
                      }
                    },
                    [
                      _c("a", {
                        staticClass: "page-link",
                        attrs: { href: "" },
                        domProps: {
                          innerHTML: _vm._s(_vm.windowStart + n - 1)
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.loadPage(_vm.windowStart + n - 1)
                          }
                        }
                      })
                    ]
                  )
                }),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "page-item",
                class: { disabled: _vm.isOnLastPage }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "page-link",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.loadPage("next")
                      }
                    }
                  },
                  [_vm._m(1)]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "li",
              {
                staticClass: "page-item",
                class: { disabled: _vm.isOnLastPage }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "page-link",
                    attrs: { href: "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.loadPage(_vm.totalPage)
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "icon-fa icon-fa-angle-double-right"
                    })
                  ]
                )
              ]
            )
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("i", { staticClass: "icon-fa icon-fa-angle-left" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("i", { staticClass: "icon-fa icon-fa-angle-right" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }