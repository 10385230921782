<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item"><router-link slot="item-title" to="/admin/carousels">Képváltók</router-link></li>
                <li class="breadcrumb-item active">Szerkesztés</li>
            </ol>
            <div class="d-flex align-items-center" v-if="carousel">
                <h1>{{ carousel.name }} szerkesztése</h1>

                <div class="btn-group ml-auto">
                    <button class="btn btn-sm btn-success" form="carouselEditForm">
                        <i class="icon-fa icon-fa-save" v-if="!saving"></i>
                        <i class="icon-fa icon-fa-circle-o-notch icon-fa-spin" v-if="saving"></i>
                        Mentés
                    </button>
                </div>
            </div>
        </div>

        <tabs class="tabs-simple" v-if="carousel">
            <tab id="basic" name="Beállítások">
                <form @submit.prevent="submit" id="carouselEditForm">
                    <div class="form-group">
                        <label for="carouselId">Azonosító</label>
                        <input type="text" id="carouselId" readonly :value="carousel.id" class="form-control">
                    </div>

                    <div class="form-group">
                        <label for="carouselNameInput">Név</label>
                        <input type="text" id="carouselNameInput" v-model="carousel.name" class="form-control">
                    </div>
                </form>
            </tab>

            <tab id="images" name="Képek">
                <media-editor :images="carousel.relations.images"
                    :highlights="false"
                    @add="showLibrary(true)">
                </media-editor>
            </tab>
        </tabs>

        <vue-medialibrary-manager :selectable="true"
            v-if="showMediaLibrary"
            @close="showMediaLibrary=false"
            @select="selectImage">
        </vue-medialibrary-manager>
    </div>
</template>

<script>
import Vue from 'vue'
import ls from '../../services/ls.js'
import { Tabs, Tab } from 'vue-tabs-component'
import MediaEditor from '../../components/MediaEditor'
import MediaLibrary from '../../components/MediaLibrary'

Vue.use(MediaLibrary, {
    API_URL: '/api/admin/media'
});

export default {
    components: {
        Tab,
        Tabs,
        MediaEditor,
    },
    data () {
        return {
            carousel: null,
            saving: false,
            site: null,
            showMediaLibrary: false,
            state: '',
        }
    },
    methods: {
        submit(){
            this.saving = true

            axios.patch(`/api/admin/carousels/${this.carousel.id}`, this.carousel)
                .then(response => {
                    toastr.success('Képváltó elmentve', 'Siker')
                }).catch(err => {
                    toastr.error('Hiba a mentés közben', 'Hiba')
                }).finally(() => {
                    this.saving = false
                })
        },
        load(){
            axios.get(`/api/admin/carousels/${this.$route.params.carousel}`, {
                params: {
                    with: 'images'
                }
            }).then(results => {
                this.carousel = results.data.data
                this.carousel.images = this.carousel.relations.images
            })
        },
        showLibrary(){
            this.showMediaLibrary = true
        },
        selectImage(image){
            this.carousel.images.push(image)
            this.showMediaLibrary = false
        },
    },
    created() {
        this.site = ls.get('site')
        this.load()
    },
}
</script>

<style>

</style>
