var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content vue-tabs-demo" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-6 mt-2" }, [
        _c("h5", { staticClass: "section-semi-title" }, [_vm._v("Default")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "panel-group" },
          [
            _c("v-collapse", [
              _c(
                "div",
                { staticClass: "panel panel-default" },
                [
                  _c("v-collapse-item", [
                    _c(
                      "a",
                      {
                        staticClass: "panel-title",
                        attrs: { slot: "item-title", href: "#collapseOne" },
                        slot: "item-title"
                      },
                      [
                        _vm._v(
                          "\n                Collapsible Group Item #1\n              "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "panel-collapse panel-content",
                        attrs: { id: "collapseOne" }
                      },
                      [
                        _vm._v(
                          "\n                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.\n                3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt\n                laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin\n                coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes\n                anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings\n                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard\n                of them accusamus labore sustainable VHS.\n              "
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "panel panel-default" },
                [
                  _c("v-collapse-item", [
                    _c(
                      "a",
                      {
                        staticClass: "panel-title",
                        attrs: { slot: "item-title", href: "#collapseTwo" },
                        slot: "item-title"
                      },
                      [
                        _vm._v(
                          "\n                Collapsible Group Item #2\n              "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "panel-collapse",
                        attrs: { id: "collapseTwo" }
                      },
                      [
                        _vm._v(
                          "\n                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.\n                3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt\n                laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin\n                coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes\n                anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings\n                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard\n                of them accusamus labore sustainable VHS.\n              "
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "panel panel-default" },
                [
                  _c("v-collapse-item", [
                    _c(
                      "a",
                      {
                        staticClass: "panel-title",
                        attrs: { slot: "item-title", href: "#collapseThree" },
                        slot: "item-title"
                      },
                      [
                        _vm._v(
                          "\n                Collapsible Group Item #3\n              "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "panel-collapse panel-content",
                        attrs: { id: "collapseThree" }
                      },
                      [
                        _vm._v(
                          "\n                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.\n                3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt\n                laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin\n                coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes\n                anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings\n                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard\n                of them accusamus labore sustainable VHS.\n              "
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-6 mt-2" }, [
        _c("h5", { staticClass: "section-semi-title" }, [_vm._v("Accordion")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "panel-group" },
          [
            _c("v-collapse", { attrs: { accordion: "" } }, [
              _c(
                "div",
                { staticClass: "panel panel-default" },
                [
                  _c("v-collapse-item", [
                    _c(
                      "a",
                      {
                        staticClass: "panel-title",
                        attrs: { slot: "item-title", href: "#collapseOne" },
                        slot: "item-title"
                      },
                      [
                        _vm._v(
                          "\n                Collapsible Group Item #1\n              "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "panel-collapse panel-content",
                        attrs: { id: "collapseOne" }
                      },
                      [
                        _vm._v(
                          "\n                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.\n                3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt\n                laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin\n                coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes\n                anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings\n                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard\n                of them accusamus labore sustainable VHS.\n              "
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "panel panel-default" },
                [
                  _c("v-collapse-item", [
                    _c(
                      "a",
                      {
                        staticClass: "panel-title",
                        attrs: { slot: "item-title", href: "#collapseTwo" },
                        slot: "item-title"
                      },
                      [
                        _vm._v(
                          "\n                Collapsible Group Item #2\n              "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "panel-collapse panel-content",
                        attrs: { id: "collapseTwo" }
                      },
                      [
                        _vm._v(
                          "\n                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.\n                3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt\n                laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin\n                coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes\n                anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings\n                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard\n                of them accusamus labore sustainable VHS.\n              "
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "panel panel-default" },
                [
                  _c("v-collapse-item", [
                    _c(
                      "a",
                      {
                        staticClass: "panel-title",
                        attrs: { slot: "item-title", href: "#collapseThree" },
                        slot: "item-title"
                      },
                      [
                        _vm._v(
                          "\n                Collapsible Group Item #3\n              "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "panel-collapse panel-content",
                        attrs: { id: "collapseThree" }
                      },
                      [
                        _vm._v(
                          "\n                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.\n                3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt\n                laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin\n                coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes\n                anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings\n                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard\n                of them accusamus labore sustainable VHS.\n              "
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Vue Collapse")]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Components")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Vue Collapse")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }