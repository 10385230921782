var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/sites/" + this.site },
                slot: "item-title"
              },
              [_vm._v("Vezérlőpult")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Bejegyzések")
        ])
      ]),
      _vm._v(" "),
      _c("h1", { staticClass: "d-inline mr-3" }, [_vm._v("Bejegyzések")]),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-outline-info btn-sm align-text-bottom" },
        [
          _c("router-link", { attrs: { to: "./posts/create" } }, [
            _c("i", { staticClass: "icon-fa icon-fa-plus" }),
            _vm._v(" Új hozzáadása\n            ")
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("cool-table", {
            attrs: {
              fields: _vm.fields,
              "data-source": _vm.dataSource,
              textfilter: true
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function(props) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: "/admin/posts/" + props.rowData.id + "/edit"
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(props.rowData.title) +
                            "\n                    "
                        )
                      ]
                    )
                  ]
                }
              },
              {
                key: "category",
                fn: function(props) {
                  return [
                    props.rowData.relations.category
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to:
                                "/admin/categories/" +
                                props.rowData.relations.category.id
                            }
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(props.rowData.relations.category.name) +
                                "\n                    "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              },
              {
                key: "tags",
                fn: function(props) {
                  return _vm._l(props.rowData.relations.tags, function(tag) {
                    return _c(
                      "router-link",
                      { key: tag.id, attrs: { to: "/admin/tags/" + tag.id } },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(tag.name) +
                            "\n                    "
                        )
                      ]
                    )
                  })
                }
              },
              {
                key: "author",
                fn: function(props) {
                  return [
                    props.rowData.relations.author
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to:
                                "/admin/users/" +
                                props.rowData.relations.author.id
                            }
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(props.rowData.relations.author.name) +
                                "\n                    "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }