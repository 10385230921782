var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropdown,
          expression: "closeDropdown"
        }
      ],
      class: [
        "dropdown-group",
        { active: _vm.toggle || _vm.isActive() },
        { "has-child": _vm.hasChild },
        { "toggle-arrow": _vm.hasChild && _vm.showArrow },
        { "dropdown-light": _vm.themeLight }
      ]
    },
    [
      _c(
        "div",
        { staticClass: "dropdown-activator", on: { click: _vm.showDropdown } },
        [_vm._t("activator")],
        2
      ),
      _vm._v(" "),
      _vm.hasChild
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.toggle && _vm.closeOnSelect,
                  expression: "(toggle)&&(closeOnSelect)"
                }
              ],
              ref: "dropdownItems",
              class: ["dropdown-container", { "align-right": _vm.rightAlign }],
              on: { click: _vm.closeDropdown }
            },
            [_vm._t("default")],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }