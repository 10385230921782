var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "message",
      class: { collapsed: _vm.message.collapsed },
      on: { click: _vm.openCollapsedMessage }
    },
    [
      _c("div", { staticClass: "message-heading" }, [
        _c("div", { staticClass: "message-from" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "from-text" }, [
            _c("div", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.message.name))
            ]),
            _vm._v(" "),
            _vm._m(1)
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "message-heading-right" }, [
          _c("span", { staticClass: "time" }, [
            _vm._v(_vm._s(_vm.message.time))
          ]),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _vm._m(3)
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.message.collapsed,
              expression: "!message.collapsed"
            }
          ],
          staticClass: "message-content"
        },
        [
          _c("div", { staticClass: "message-description" }, [
            _vm._v("\n      " + _vm._s(_vm.message.description) + "\n    ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "message-attachment" }, [
            _vm._m(4),
            _vm._v(" "),
            _c(
              "ul",
              _vm._l(_vm.message.attachments, function(attachment) {
                return _c("li", { key: attachment.name }, [
                  _c("span", { staticClass: "name" }, [
                    _vm._v(_vm._s(attachment.name))
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "size" }, [
                    _vm._v("(" + _vm._s(attachment.size) + ")")
                  ]),
                  _vm._v(" "),
                  _vm._m(5, true)
                ])
              }),
              0
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.message.collapsed,
              expression: "message.collapsed"
            }
          ],
          staticClass: "message-excerpt"
        },
        [_vm._v("\n    " + _vm._s(_vm.message.excerpt) + "\n  ")]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "avatar" }, [
      _c("img", {
        staticClass: "avatar-img",
        attrs: { src: "/assets/img/avatars/avatar.png", alt: "avatar" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "email" }, [
      _c("a", { attrs: { href: "#" } }, [
        _vm._v("\n            johndeo@gmail.com\n          ")
      ]),
      _vm._v("\n          to\n          "),
      _c("a", { attrs: { href: "#" } }, [
        _vm._v("\n            me\n          ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "btn btn-light btn-icon btn-sm" }, [
      _c("i", { staticClass: "icon-fa icon-fa-star-o" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "btn btn-light btn-icon btn-sm" }, [
      _c("i", { staticClass: "icon-fa icon-fa-mail-reply" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("i", { staticClass: "icon-fa icon-fa-paperclip" }),
      _vm._v("\n        Attachments |\n        "),
      _c("a", { attrs: { href: "#" } }, [_vm._v("Download All")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "btn btn-light btn-icon btn-sm" }, [
      _c("i", { staticClass: "icon-fa icon-fa-cloud-download" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }