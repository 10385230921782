var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.name,
                expression: "name",
                modifiers: { trim: true }
              }
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.$v.name.$error },
            domProps: { value: _vm.name },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.name = $event.target.value.trim()
                },
                function($event) {
                  return _vm.$v.name.$touch()
                }
              ],
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _vm.$v.name.$error
            ? _c("div", [
                !_vm.$v.name.required
                  ? _c("span", { staticClass: "help-block help-block-error" }, [
                      _vm._v("\n            Field is required\n          ")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$v.name.minLength
                  ? _c("span", { staticClass: "help-block help-block-error" }, [
                      _vm._v(
                        "\n            Name must have at least " +
                          _vm._s(_vm.$v.name.$params.minLength.min) +
                          " letters.\n          "
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("pre", { staticClass: "pre-format" }, [
          _vm._v("name: " + _vm._s(_vm.$v.name))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Age")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.age,
                expression: "age",
                modifiers: { trim: true }
              }
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.$v.age.$error },
            domProps: { value: _vm.age },
            on: {
              blur: [
                function($event) {
                  return _vm.$v.age.$touch()
                },
                function($event) {
                  return _vm.$forceUpdate()
                }
              ],
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.age = $event.target.value.trim()
              }
            }
          }),
          _vm._v(" "),
          _vm.$v.age.$error
            ? _c("div", [
                !_vm.$v.age.between
                  ? _c("span", { staticClass: "help-block help-block-error" }, [
                      _vm._v(
                        "\n            Must be between " +
                          _vm._s(_vm.$v.age.$params.between.min) +
                          " and " +
                          _vm._s(_vm.$v.age.$params.between.max) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("pre", { staticClass: "pre-format" }, [
          _vm._v("age: " + _vm._s(_vm.$v.age))
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _vm._m(2),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Password")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.password,
                expression: "password",
                modifiers: { trim: true }
              }
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.$v.password.$error },
            domProps: { value: _vm.password },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.password = $event.target.value.trim()
                },
                function($event) {
                  return _vm.$v.password.$touch()
                }
              ],
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _vm.$v.password.$error
            ? _c("div", [
                !_vm.$v.password.required
                  ? _c("span", { staticClass: "help-block help-block-error" }, [
                      _vm._v("\n            Password is required.\n          ")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$v.password.minLength
                  ? _c("span", { staticClass: "help-block help-block-error" }, [
                      _vm._v(
                        "\n            Password must have at least " +
                          _vm._s(_vm.$v.password.$params.minLength.min) +
                          " letters.\n          "
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Repeat password")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.repeatPassword,
                expression: "repeatPassword",
                modifiers: { trim: true }
              }
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.$v.repeatPassword.$error },
            domProps: { value: _vm.repeatPassword },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.repeatPassword = $event.target.value.trim()
                },
                function($event) {
                  return _vm.$v.repeatPassword.$touch()
                }
              ],
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _vm.$v.repeatPassword.$error
            ? _c("div", [
                !_vm.$v.repeatPassword.sameAsPassword
                  ? _c("span", { staticClass: "help-block help-block-error" }, [
                      _vm._v(
                        "\n            Passwords must be identical.\n          "
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("pre", { staticClass: "pre-format" }, [
          _vm._v(
            "password: " +
              _vm._s(_vm.$v.password) +
              "repeatPassword: " +
              _vm._s(_vm.$v.repeatPassword)
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _vm._m(3),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Email")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim.lazy",
                value: _vm.email,
                expression: "email",
                modifiers: { trim: true, lazy: true }
              }
            ],
            staticClass: "form-control",
            class: {
              "is-invalid": _vm.$v.email.$error,
              "form-group--loading": _vm.$v.email.$pending
            },
            domProps: { value: _vm.email },
            on: {
              change: [
                function($event) {
                  _vm.email = $event.target.value.trim()
                },
                function($event) {
                  return _vm.$v.email.$touch()
                }
              ],
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _vm._v(" "),
          _vm.$v.email.$error
            ? _c("div", [
                !_vm.$v.email.required
                  ? _c("span", { staticClass: "help-block help-block-error" }, [
                      _vm._v("\n            Email is required.\n          ")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$v.email.isUnique
                  ? _c("span", { staticClass: "help-block help-block-error" }, [
                      _vm._v(
                        "\n            This email is already registered.\n          "
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _c("pre", { staticClass: "pre-format" }, [
          _vm._v("email: " + _vm._s(_vm.$v.email))
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Vuelidate Validation")]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Forms")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Vuelidate")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [
        _vm._v("\n        Basic Form\n        "),
        _c(
          "a",
          {
            staticClass: "source-link",
            attrs: {
              href: "https://monterail.github.io/vuelidate/#sub-basic-usage",
              target: "_blank"
            }
          },
          [_vm._v("\n          source\n        ")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [
        _vm._v("Contextified validators\n        "),
        _c(
          "a",
          {
            staticClass: "source-link",
            attrs: {
              href:
                "https://monterail.github.io/vuelidate/#sub-contextified-validators",
              target: "_blank"
            }
          },
          [_vm._v("\n          source\n        ")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [
        _vm._v("Asynchronous validation\n        "),
        _c(
          "a",
          {
            staticClass: "source-link",
            attrs: {
              href:
                "https://monterail.github.io/vuelidate/#sub-asynchronous-validation",
              target: "_blank"
            }
          },
          [_vm._v("\n          source\n        ")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n        The above input validation checks the database in realtime to make sure that user enters a unique email on blur event. You can test with "
      ),
      _c("code", [_vm._v("admin@laraspace.in")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }