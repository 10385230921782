var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-box" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-12 text-sm-center" },
        [
          _c("h1", [_vm._v("404")]),
          _vm._v(" "),
          _c("h5", [_vm._v("Whoops! You got Lost!")]),
          _vm._v(" "),
          _c(
            "router-link",
            { staticClass: "btn btn-lg bg-yellow", attrs: { to: "/blog" } },
            [
              _vm._v("\n        Go to Blog page "),
              _c("i", { staticClass: "icon-fa icon-fa-arrow-right" })
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }