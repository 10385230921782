var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card gauge-charts" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [_c("basic-gauge", { attrs: { "gauge-size": "sm" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [_c("basic-gauge", { attrs: { "gauge-size": "md" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [_c("basic-gauge", { attrs: { "gauge-size": "lg" } })],
                1
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card gauge-charts" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [_c("donut-gauge", { attrs: { "gauge-size": "sm" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [_c("donut-gauge", { attrs: { "gauge-size": "md" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [_c("donut-gauge", { attrs: { "gauge-size": "lg" } })],
                1
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card gauge-charts" }, [
          _vm._m(3),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [_c("color-gauge", { attrs: { "gauge-size": "sm" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [_c("color-gauge", { attrs: { "gauge-size": "md" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [_c("color-gauge", { attrs: { "gauge-size": "lg" } })],
                1
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Gauge Charts")]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Charts")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Gauge Charts")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h5", [_vm._v("Basic Gauges")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [_vm._v("Donut")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h5", [_vm._v("Color Gauges")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }