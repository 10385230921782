<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item active">Fordítás linkek</li>
            </ol>
            <h1 class="d-inline mr-3">Fordítás linkek</h1>
            <button class="btn btn-outline-info btn-sm align-text-bottom"
                type="button"
                @click="addItem">
                <i class="icon-fa icon-fa-plus"></i>
                Új hozzáadása
            </button>
        </div>

        <div class="card">
            <div class="card-body">
                <p>Itt lehet megadni a nyelvválasztóban látható linkeket.<br>
                    Mindig csak az adott domain teljes url-jét kell megadni / végződéssel (pl: https://itzen.hu/).
                </p>
                <form>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Főoldal URL</th>
                                <th>Címke</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(translation, key) in translations"
                                v-bind:key="key">
                                <td>
                                    <div class="form-group mb-0">
                                        <input type="text"
                                            class="form-control"
                                            :class="{'is-invalid': isInvalid(translation.url, key)}"
                                            v-model="translation.url">
                                        <div class="invalid-feedback"
                                            v-if="hasDuplicate(translation.url, key)">
                                            Duplikált URL!
                                        </div>
                                        <div class="invalid-feedback"
                                            v-if="isInvalidUrl(translation.url)">
                                            Hibás URL!
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <input type="text"
                                        class="form-control"
                                        v-model="translation.label">
                                </td>

                                <td class="remove-button">
                                    <button class="btn btn-danger"
                                        type="button"
                                        @click="removeItem(key)">
                                        <i class="icon-fa icon-fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn btn-success btn-sm"
                        type="button"
                        @click="save">
                        <i class="icon-fa icon-fa-save" v-if="!saving"></i>
                        <i class="icon-fa icon-fa-spin icon-fa-circle-o-notch" v-if="saving"></i>
                        Linkek mentése
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import ls from '../../services/ls'

export default {
    data () {
        return {
            saving: false,
            translations: []
        }
    },
    methods: {
        isInvalid(url, index){
            return this.isInvalidUrl(url) || this.hasDuplicate(url, index)
        },
        hasDuplicate (url, index) {
            for (const translation in this.translations) {
                if(index != translation && this.translations[translation].url == url){
                    return true
                }
            }

            return false
        },
        isInvalidUrl(url){
            return url.slice(-1) != '/'
                || (
                    url.slice(0, 7) != 'http://'
                    && url.slice(0, 8) != 'https://'
                );
        },
        addItem(){
            this.translations.push({
                url: '',
                label: ''
            })
        },
        removeItem(index){
            this.translations.splice(index, 1)
        },
        save(){
            this.saving = true

            var data = {}
            this.translations.forEach(o => {
                if(o.url != '' && o.label != ''){
                    data[o.url] = o.label
                }
            })

            axios.patch(`/api/admin/translation-links/${this.site}`, {content: data})
                .then(() => {
                    toastr.success('Adatok elmentve', 'Siker')
                })
                .catch(err => {
                    toastr.error('Nem sikerült elmenteni az adatokat', 'Hiba')
                })
                .finally(() => {
                    this.saving = false
                })

        }
    },
    created() {
        this.site = ls.get('site')

        axios.get(`/api/admin/translation-links/${this.site}`)
            .then(result => {
                for (const i in result.data) {
                    this.translations.push({
                        url: i,
                        label: result.data[i]
                    })
                }
            })
    }
}
</script>

<style scoped>
.remove-button{
    flex-grow: 0;
}
.remove-button .btn i{
    width: auto;
}
</style>
