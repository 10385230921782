var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content", attrs: { id: "dashboardPage" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12 col-xl-6 mt-2" }, [
          _c("div", { staticClass: "card" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("line-graph", {
                  attrs: {
                    labels: ["Jan", "Feb", "Mar", "June"],
                    values: [20, 30, 40, 60]
                  }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-12 col-xl-6 mt-2" }, [
          _c("div", { staticClass: "card" }, [
            _vm._m(2),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("bar-graph", {
                  attrs: {
                    labels: ["Jan", "Feb", "Mar", "June"],
                    values: [20, 30, 40, 60]
                  }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _vm._m(3)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12 col-lg-6 col-xl-3" }, [
        _c("a", { staticClass: "dashbox", attrs: { href: "#" } }, [
          _c("i", { staticClass: "icon-fa icon-fa-envelope text-primary" }),
          _vm._v(" "),
          _c("span", { staticClass: "title" }, [
            _vm._v("\n          35\n        ")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "desc" }, [
            _vm._v("\n          Mails\n        ")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12 col-lg-6 col-xl-3" }, [
        _c("a", { staticClass: "dashbox", attrs: { href: "#" } }, [
          _c("i", { staticClass: "icon-fa icon-fa-ticket text-success" }),
          _vm._v(" "),
          _c("span", { staticClass: "title" }, [
            _vm._v("\n          200\n        ")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "desc" }, [
            _vm._v("\n          Pending Tickets\n        ")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12 col-lg-6 col-xl-3" }, [
        _c("a", { staticClass: "dashbox", attrs: { href: "#" } }, [
          _c("i", { staticClass: "icon-fa icon-fa-shopping-cart text-danger" }),
          _vm._v(" "),
          _c("span", { staticClass: "title" }, [
            _vm._v("\n          100\n        ")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "desc" }, [
            _vm._v("\n          New Orders\n        ")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12 col-lg-6 col-xl-3" }, [
        _c("a", { staticClass: "dashbox", attrs: { href: "#" } }, [
          _c("i", { staticClass: "icon-fa icon-fa-comments text-info" }),
          _vm._v(" "),
          _c("span", { staticClass: "title" }, [
            _vm._v("\n          59\n        ")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "desc" }, [
            _vm._v("\n          Comments\n        ")
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [
        _c("i", { staticClass: "icon-fa icon-fa-line-chart text-warning" }),
        _vm._v("Traffic Stats")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [
        _c("i", { staticClass: "icon-fa icon-fa-bar-chart text-success" }),
        _vm._v("Sales Chart")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-6 mt-2" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h6", [
              _c("i", {
                staticClass: "icon-fa icon-fa-shopping-cart text-danger"
              }),
              _vm._v("Recent Orders")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Customer Name")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Date")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Amount")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Actions")])
                ])
              ]),
              _vm._v(" "),
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v("Walter White")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("05/12/2016")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("555$")]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-default btn-xs",
                        attrs: { href: "#" }
                      },
                      [_vm._v("View")]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Hank Hill")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("05/12/2016")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("222$")]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-default btn-xs",
                        attrs: { href: "#" }
                      },
                      [_vm._v("View")]
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-12 col-xl-6 mt-2" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h6", [
              _c("i", { staticClass: "icon-fa icon-fa-users text-info" }),
              _vm._v("New Customers")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Customer Name")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Date")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Amount")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Actions")])
                ])
              ]),
              _vm._v(" "),
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v("Walter White")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("05/12/2016")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("555$")]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-default btn-xs",
                        attrs: { href: "#" }
                      },
                      [_vm._v("View")]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Hank Hill")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("05/12/2016")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("222$")]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-default btn-xs",
                        attrs: { href: "#" }
                      },
                      [_vm._v("View")]
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }