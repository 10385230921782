<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item"><router-link slot="item-title" to="/admin/sales">Akciók</router-link></li>
                <li class="breadcrumb-item active">Akció szerkesztése</li>
            </ol>
            <div class="d-flex align-items-center">
                <h1>Akció szerkesztése</h1>

                <div class="btn-group ml-auto">
                    <button class="btn btn-sm btn-success" form="saleEditForm">
                        <i class="icon-fa icon-fa-save" v-if="!saving"></i>
                        <i class="icon-fa icon-fa-circle-o-notch icon-fa-spin" v-if="saving"></i>
                        Mentés
                    </button>
                </div>
            </div>

            <div class="alert alert-info" v-if="sale.relations.isActive">Jelenleg ez az akció van érvényben.</div>
            <div class="alert alert-info" v-else-if="isActiveInTime">
                Ez az akció aktív, de egy nagyobb prioritású van érvényben.
            </div>
        </div>

        <sale-editor :original="sale"
            :save="saveCallback"
            @requestReload="reloadSale">
        </sale-editor>
    </div>
</template>

<script>
import ls from '../../services/ls.js'
import store from '../../services/vuex'
import SaleEditor from './SaleEditor'

export default {
    components: {
        SaleEditor,
    },
    data () {
        return {
            site: null,
        }
    },
    computed: {
        sale(){
            return store.state.sales.sale
        },
        saving(){
            return store.state.sales.saving
        },
        isActiveInTime(){
            let now = new Date

            return (new Date(this.sale.starts_at) < now)
                &&
                (this.sale.ends_at == null || new Date(this.sale.ends_at) > now)
        },
    },
    methods: {
        saveCallback(sale){
            store.dispatch('sales/updateSale', sale)
            this.reloadSale()
        },
        reloadSale(){
            store.dispatch('sales/getSale', {
                id: this.$route.params.sale
            })
        }
    },
    created() {
        this.site = ls.get('site')
        store.dispatch('sales/getSale', {
            id: this.$route.params.sale
        })
    },
}
</script>

<style scoped>
.table td{
    border-top: none;
}
</style>
