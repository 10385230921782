var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "media-library" }, [
    _c("div", { staticClass: "media-library__inner" }, [
      _c("header", { staticClass: "media-library__header" }, [
        _vm._v("\n            Médiatár\n\n            "),
        _c(
          "span",
          { staticClass: "media-library__close", on: { click: _vm.close } },
          [_c("app-icon", { attrs: { icon: "times" } })],
          1
        )
      ]),
      _vm._v(" "),
      _vm.loading.wrapper
        ? _c(
            "div",
            { staticClass: "media-library__loader" },
            [_c("app-medialibrary-loader")],
            1
          )
        : _vm.error
        ? _c(
            "div",
            { staticClass: "media-library__error" },
            [
              _c("span", { staticClass: "media-library__error__ttl" }, [
                _vm._v("Oh Dear!")
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "media-library__error__msg" }, [
                _vm._v(
                  "There was a serious problem... This is likely because something isn't setup properly. Please consult the documentation."
                )
              ]),
              _vm._v(" "),
              _c("app-svg-error")
            ],
            1
          )
        : _c("div", { staticClass: "media-library__manager" }, [
            _c("div", { staticClass: "media-library__manager__nav" }, [
              _c("div", [
                _vm.types.images
                  ? _c(
                      "span",
                      {
                        staticClass: "media-library__manager__nav__option",
                        class: {
                          active: _vm.type == "images",
                          inactive: _vm.type != "images"
                        },
                        on: {
                          click: function($event) {
                            _vm.openFile = null
                            _vm.type = "images"
                          }
                        }
                      },
                      [
                        _vm._v("Képek "),
                        _c("strong", [
                          _vm._v("(" + _vm._s(_vm.store.images.total) + ")")
                        ])
                      ]
                    )
                  : _c(
                      "span",
                      {
                        staticClass:
                          "media-library__manager__nav__option deactive"
                      },
                      [
                        _vm._v("Képek "),
                        _c("strong", [
                          _vm._v("(" + _vm._s(_vm.store.images.total) + ")")
                        ])
                      ]
                    ),
                _vm._v(" "),
                _vm.types.files
                  ? _c(
                      "span",
                      {
                        staticClass: "media-library__manager__nav__option",
                        class: {
                          active: _vm.type == "files",
                          inactive: _vm.type != "files"
                        },
                        on: {
                          click: function($event) {
                            _vm.openFile = null
                            _vm.type = "files"
                          }
                        }
                      },
                      [
                        _vm._v("Fájlok "),
                        _c("strong", [
                          _vm._v("(" + _vm._s(_vm.store.files.total) + ")")
                        ])
                      ]
                    )
                  : _c(
                      "span",
                      {
                        staticClass:
                          "media-library__manager__nav__option deactive"
                      },
                      [
                        _vm._v("Fájlok "),
                        _c("strong", [
                          _vm._v("(" + _vm._s(_vm.store.files.total) + ")")
                        ])
                      ]
                    )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "media-library__manager__nav__push" }, [
                _c(
                  "span",
                  {
                    staticClass: "media-library__manager__nav__button",
                    class: { active: _vm.toggles.upload },
                    on: {
                      click: function($event) {
                        _vm.toggles.upload = !_vm.toggles.upload
                      }
                    }
                  },
                  [_vm._v("Feltöltés")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search,
                      expression: "search"
                    }
                  ],
                  staticClass: "media-library__manager__nav__search",
                  attrs: {
                    id: "medialibrary-search",
                    type: "text",
                    placeholder: "Keresés..."
                  },
                  domProps: { value: _vm.search },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.search = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "media-library__manager__content",
                staticStyle: { height: "0%" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "media-library__manager__content__images",
                    class: { open: _vm.toggles.file }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "media-library__manager__content__images__inner"
                      },
                      [
                        _vm.toggles.upload
                          ? _c(
                              "div",
                              { staticClass: "media-library__manager__upload" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "media-library__manager__upload__zone"
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "media-library__manager__upload__zone__button",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            return _vm.$refs.file.click()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    Hozzáadás\n                                "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      ref: "file",
                                      attrs: {
                                        name: "files[]",
                                        type: "file",
                                        multiple: "true"
                                      },
                                      on: { change: _vm.uploadFilesByButton }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "media-library__manager__upload__zone__text"
                                      },
                                      [_vm._v("vagy dobd ide a fájlokat")]
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.store[_vm.type].data.length == 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "media-library__error media-library__error--sml"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "media-library__error__ttl" },
                                  [_vm._v("Na bumm!")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  { staticClass: "media-library__error__msg" },
                                  [_vm._v("Még nincs feltöltve semmi")]
                                ),
                                _vm._v(" "),
                                _c("app-svg-media", {
                                  staticClass: "background"
                                })
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "media-library__manager__content__images__grid"
                                  },
                                  [
                                    _vm.type == "images"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "media-library__manager__content__images__grid__images"
                                          },
                                          _vm._l(
                                            _vm.store.images.data,
                                            function(m, i) {
                                              return _c(
                                                "div",
                                                {
                                                  key: i,
                                                  staticClass: "grid-item",
                                                  class: {
                                                    "grid-item--active":
                                                      _vm.store.images.selected.indexOf(
                                                        m.id
                                                      ) > -1
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.selectManual(
                                                        "images",
                                                        m.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "grid-item__inner",
                                                    style:
                                                      "background: url('" +
                                                      m.dataUrl +
                                                      "'); background-repeat: no-repeat; background-size: cover;"
                                                  })
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm.type == "files"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "media-library__manager__content__images__grid__files"
                                          },
                                          [
                                            _c(
                                              "table",
                                              {
                                                staticClass:
                                                  "media-library-table"
                                              },
                                              [
                                                _c(
                                                  "tbody",
                                                  _vm._l(
                                                    _vm.store.files.data,
                                                    function(f, i) {
                                                      return _c(
                                                        "tr",
                                                        {
                                                          key: i,
                                                          class: {
                                                            active:
                                                              _vm.store.files.selected.indexOf(
                                                                f.id
                                                              ) > -1
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.selectManual(
                                                                "files",
                                                                f.id
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("td", [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.store
                                                                      .files
                                                                      .selectedModel[
                                                                      f.id
                                                                    ],
                                                                  expression:
                                                                    "store.files.selectedModel[f.id]"
                                                                }
                                                              ],
                                                              attrs: {
                                                                type: "checkbox"
                                                              },
                                                              domProps: {
                                                                checked: Array.isArray(
                                                                  _vm.store
                                                                    .files
                                                                    .selectedModel[
                                                                    f.id
                                                                  ]
                                                                )
                                                                  ? _vm._i(
                                                                      _vm.store
                                                                        .files
                                                                        .selectedModel[
                                                                        f.id
                                                                      ],
                                                                      null
                                                                    ) > -1
                                                                  : _vm.store
                                                                      .files
                                                                      .selectedModel[
                                                                      f.id
                                                                    ]
                                                              },
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  var $$a =
                                                                      _vm.store
                                                                        .files
                                                                        .selectedModel[
                                                                        f.id
                                                                      ],
                                                                    $$el =
                                                                      $event.target,
                                                                    $$c = $$el.checked
                                                                      ? true
                                                                      : false
                                                                  if (
                                                                    Array.isArray(
                                                                      $$a
                                                                    )
                                                                  ) {
                                                                    var $$v = null,
                                                                      $$i = _vm._i(
                                                                        $$a,
                                                                        $$v
                                                                      )
                                                                    if (
                                                                      $$el.checked
                                                                    ) {
                                                                      $$i < 0 &&
                                                                        _vm.$set(
                                                                          _vm
                                                                            .store
                                                                            .files
                                                                            .selectedModel,
                                                                          f.id,
                                                                          $$a.concat(
                                                                            [
                                                                              $$v
                                                                            ]
                                                                          )
                                                                        )
                                                                    } else {
                                                                      $$i >
                                                                        -1 &&
                                                                        _vm.$set(
                                                                          _vm
                                                                            .store
                                                                            .files
                                                                            .selectedModel,
                                                                          f.id,
                                                                          $$a
                                                                            .slice(
                                                                              0,
                                                                              $$i
                                                                            )
                                                                            .concat(
                                                                              $$a.slice(
                                                                                $$i +
                                                                                  1
                                                                              )
                                                                            )
                                                                        )
                                                                    }
                                                                  } else {
                                                                    _vm.$set(
                                                                      _vm.store
                                                                        .files
                                                                        .selectedModel,
                                                                      f.id,
                                                                      $$c
                                                                    )
                                                                  }
                                                                }
                                                              }
                                                            })
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("td", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                f.name
                                                              )
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c("td", {
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                f.humanSize
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "infinite-loading",
                                  {
                                    staticStyle: { "margin-bottom": "1.5rem" },
                                    on: { infinite: _vm.scroll }
                                  },
                                  [
                                    _c("div", {
                                      attrs: { slot: "no-more" },
                                      slot: "no-more"
                                    }),
                                    _vm._v(" "),
                                    _c("div", {
                                      attrs: { slot: "no-results" },
                                      slot: "no-results"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.toggles.file && _vm.openFile
                  ? _c(
                      "div",
                      { staticClass: "media-library__manager__content__info" },
                      [
                        _vm.loading.info
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "media-library__manager__content__info__loading"
                              },
                              [_c("app-medialibrary-loader")],
                              1
                            )
                          : _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "media-library__manager__content__info__section"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "media-library__manager__content__info__image"
                                    },
                                    [
                                      _vm.type == "images"
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "auto",
                                              height: "auto",
                                              "max-width": "150px",
                                              "max-height": "150px"
                                            },
                                            attrs: { src: _vm.openFile.dataUrl }
                                          })
                                        : _vm._e()
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.type == "images"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "media-library__manager__content__info__text media-library__manager__content__info__text--secondary"
                                        },
                                        [
                                          _vm._v(
                                            "Méretek: " +
                                              _vm._s(_vm.openFile.image.width) +
                                              " × " +
                                              _vm._s(_vm.openFile.image.height)
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        margin: "0.75rem -0.25rem 0 -0.25rem"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "flex-grow": "1",
                                            padding: "0 0.2rem"
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "media-library__manager__content__info__button",
                                              attrs: {
                                                href: _vm.openFile.downloadUrl
                                              }
                                            },
                                            [_vm._v("Letöltés")]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "flex-grow": "1",
                                            padding: "0 0.2rem"
                                          }
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "media-library__manager__content__info__button media-library__manager__content__info__button--delete",
                                              on: { click: _vm.deleteSelected }
                                            },
                                            [_vm._v("Törlés")]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.type == "images"
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "media-library__manager__content__info__section"
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "media-library__manager__content__info__label",
                                            attrs: { for: "alt-text" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    Név\n                                "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.openFile.name,
                                              expression: "openFile.name"
                                            }
                                          ],
                                          staticClass:
                                            "media-library__manager__content__info__input",
                                          attrs: {
                                            id: "alt-text",
                                            type: "text"
                                          },
                                          domProps: {
                                            value: _vm.openFile.name
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.openFile,
                                                "name",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "media-library__manager__content__info__section",
                                  staticStyle: {
                                    display: "flex",
                                    margin: "0 -0.5rem"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: { padding: "0 0.5rem" },
                                      style:
                                        "width: " +
                                        (_vm.type != "images" ? 100 : 50) +
                                        "%;"
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "media-library__manager__content__info__button media-library__manager__content__info__button--close",
                                          on: {
                                            click: function($event) {
                                              _vm.toggles.file = false
                                              _vm.clearSelected("images")
                                            }
                                          }
                                        },
                                        [_vm._v("Bezárás")]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.type == "images"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "50%",
                                            padding: "0 0.5rem"
                                          }
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "media-library__manager__content__info__button media-library__manager__content__info__button--save",
                                              on: { click: _vm.save }
                                            },
                                            [_vm._v("Mentés")]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _vm._v(" "),
                              _vm.selectable
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "media-library__manager__content__info__section",
                                      staticStyle: {
                                        display: "flex",
                                        margin: "0 -0.5rem"
                                      }
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "media-library__manager__content__info__button media-library__manager__content__info__button--success",
                                          on: {
                                            click: function($event) {
                                              return _vm.select(_vm.openFile)
                                            }
                                          }
                                        },
                                        [_vm._v("Beszúrás")]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.toggles.file && !_vm.openFile
                  ? _c(
                      "div",
                      { staticClass: "media-library__manager__content__files" },
                      [
                        _vm.loading.info
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "media-library__manager__content__files__loading"
                              },
                              [_c("app-medialibrary-loader")],
                              1
                            )
                          : _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "media-library__manager__content__info__section"
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "block",
                                        "margin-bottom": "1rem"
                                      }
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.store[_vm.type].selected.length
                                          )
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s(_vm.type) + " kiválasztva."
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "media-library__manager__content__info__button media-library__manager__content__info__button--delete",
                                      on: { click: _vm.deleteSelected }
                                    },
                                    [_vm._v("Törlés")]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex media-library__manager__content__info__section",
                                  staticStyle: { margin: "0 -0.5rem" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        padding: "0 0.5rem",
                                        width: "100%"
                                      }
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "media-library__manager__content__info__button media-library__manager__content__info__button--close",
                                          on: {
                                            click: function($event) {
                                              _vm.toggles.file = false
                                              _vm.clearSelected("images")
                                            }
                                          }
                                        },
                                        [_vm._v("Bezárás / Kijelölés törlése")]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                      ]
                    )
                  : _vm._e()
              ]
            )
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }