<template>
    <div class="row">
        <div class="col-md-6 col-12" v-if="tablePagination">
            {{ tablePagination.from }}-{{ tablePagination.to }}
            ({{ tablePagination.to - tablePagination.from + 1 }} db) sor.
            Összesen {{ tablePagination.total }} db
        </div>
        <div class="col-md-6 col-12">
            <nav aria-label="Table Navigation">
                <ul class="pagination mb-0 justify-content-end">

                    <li :class="{'disabled': isOnFirstPage}">
                        <a href=""
                            class="page-link"
                            @click.prevent="loadPage(1)">
                            <i class="left icon-fa icon-fa-angle-double-left"></i>
                        </a>
                    </li>

                    <li :class="{'disabled': isOnFirstPage}"
                        class="page-item">
                        <a href=""
                            class="page-link"
                            @click.prevent="loadPage('prev')">
                            <span><i class="icon-fa icon-fa-angle-left"/></span>
                        </a>
                    </li>

                    <template v-if="notEnoughPages">
                        <li v-for="n in totalPage"
                            :key="n"
                            :class="{'active': isCurrentPage(n)}"
                            class="page-item">
                            <a class="page-link"
                                href=""
                                @click.prevent="loadPage(n)"
                                v-html="n"/>
                        </li>
                    </template>
                    <template v-else>
                        <li v-for="n in windowSize"
                            :key="n"
                            :class="{'active': isCurrentPage(windowStart+n-1)}"
                            class="page-item">
                            <a class="page-link"
                                href=""
                                @click.prevent="loadPage(windowStart+n-1)"
                                v-html="windowStart+n-1"/>
                        </li>
                    </template>

                    <li :class="{'disabled': isOnLastPage}"
                        class="page-item">
                            <a href=""
                                class="page-link"
                                @click.prevent="loadPage('next')">
                                <span><i class="icon-fa icon-fa-angle-right"/></span>
                            </a>
                    </li>

                    <li :class="{'disabled': isOnLastPage}"
                        class="page-item">
                        <a href=""
                            class="page-link"
                            @click.prevent="loadPage(totalPage)">
                            <i class="icon-fa icon-fa-angle-double-right"/>
                        </a>
                    </li>

                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import { VuetablePaginationMixin } from 'vuetable-2'

export default {
    mixins: [
        VuetablePaginationMixin
    ]
}
</script>
