var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/sites/" + this.site },
                slot: "item-title"
              },
              [_vm._v("Vezérlőpult")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [_vm._v("Címkék")])
      ]),
      _vm._v(" "),
      _c("h1", { staticClass: "d-inline mr-3" }, [_vm._v("Címkék")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-outline-info btn-sm align-text-bottom",
          on: { click: _vm.createTag }
        },
        [
          _c("i", { staticClass: "icon-fa icon-fa-plus" }),
          _vm._v(" Új hozzáadása\n        ")
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body inline-tree-container" },
            [
              _c("treeselect", {
                ref: "treeselect",
                attrs: {
                  options: _vm.tags,
                  multiple: false,
                  clearable: false,
                  normalizer: _vm.normalizer,
                  "always-open": true
                },
                on: { select: _vm.changeTag },
                model: {
                  value: _vm.tag,
                  callback: function($$v) {
                    _vm.tag = $$v
                  },
                  expression: "tag"
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _vm.editBuffer
              ? _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.saveTag($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "nameInput" } }, [
                        _vm._v("Név")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editBuffer.name,
                            expression: "editBuffer.name"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.editBuffer.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.editBuffer,
                              "name",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "slugInput" } }, [
                        _vm._v("Slug")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editBuffer.slug,
                            expression: "editBuffer.slug"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.editBuffer.slug },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.editBuffer,
                              "slug",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "descriptionInput" } }, [
                          _vm._v("Leírás")
                        ]),
                        _vm._v(" "),
                        _c("editor", {
                          ref: "editor",
                          attrs: { config: _vm.editorConfig }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("button", { staticClass: "btn btn-info" }, [
                      !_vm.saving
                        ? _c("i", { staticClass: "icon-fa icon-fa-save" })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.saving
                        ? _c("i", {
                            staticClass:
                              "icon-fa icon-fa-spin icon-fa-circle-o-notch"
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n                            Mentés\n                        "
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.editBuffer
              ? _c("div", [
                  _c("p", [_vm._v("Nincs kijelölve szerkeszthető elem!")])
                ])
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }