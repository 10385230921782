var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content", attrs: { id: "dashboardPage" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12 mt-2" }, [
          _c("div", { staticClass: "card" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("line-graph", {
                  attrs: {
                    labels: ["Jan", "Feb", "Mar", "June"],
                    values: [20, 30, 40, 60]
                  }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _vm._m(2)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12 col-lg-6 col-xl-3" }, [
        _c(
          "a",
          {
            staticClass: "dashbox dashbox-line-progress",
            attrs: { href: "#" }
          },
          [
            _c("i", { staticClass: "icon-fa icon-fa-tags text-primary" }),
            _vm._v(" "),
            _c("span", { staticClass: "desc" }, [
              _vm._v("\n          Categories\n        ")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "title text-primary" }, [
              _vm._v("\n          35\n        ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "progress" }, [
              _c("div", {
                staticClass: "progress-bar",
                staticStyle: { width: "25%" },
                attrs: {
                  role: "progressbar",
                  "aria-valuenow": "25",
                  "aria-valuemin": "0",
                  "aria-valuemax": "100"
                }
              })
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12 col-lg-6 col-xl-3" }, [
        _c(
          "a",
          {
            staticClass: "dashbox dashbox-line-progress",
            attrs: { href: "#" }
          },
          [
            _c("i", { staticClass: "icon-fa icon-fa-star text-success" }),
            _vm._v(" "),
            _c("span", { staticClass: "desc" }, [
              _vm._v("\n          Products\n        ")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "title text-success" }, [
              _vm._v("\n          2000\n        ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "progress" }, [
              _c("div", {
                staticClass: "progress-bar bg-success",
                staticStyle: { width: "50%" },
                attrs: {
                  role: "progressbar",
                  "aria-valuenow": "50",
                  "aria-valuemin": "0",
                  "aria-valuemax": "100"
                }
              })
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12 col-lg-6 col-xl-3" }, [
        _c(
          "a",
          {
            staticClass: "dashbox dashbox-line-progress",
            attrs: { href: "#" }
          },
          [
            _c("i", {
              staticClass: "icon-fa icon-fa-shopping-cart text-danger"
            }),
            _vm._v(" "),
            _c("span", { staticClass: "desc" }, [
              _vm._v("\n          New Orders\n        ")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "title text-danger" }, [
              _vm._v("\n          100\n        ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "progress" }, [
              _c("div", {
                staticClass: "progress-bar bg-danger",
                staticStyle: { width: "70%" },
                attrs: {
                  role: "progressbar",
                  "aria-valuenow": "70",
                  "aria-valuemin": "0",
                  "aria-valuemax": "100"
                }
              })
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12 col-lg-6 col-xl-3" }, [
        _c(
          "a",
          {
            staticClass: "dashbox dashbox-line-progress",
            attrs: { href: "#" }
          },
          [
            _c("i", { staticClass: "icon-fa icon-fa-comments text-info" }),
            _vm._v(" "),
            _c("span", { staticClass: "desc" }, [
              _vm._v("\n          Reviews\n        ")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "title text-info" }, [
              _vm._v("\n          59\n        ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "progress" }, [
              _c("div", {
                staticClass: "progress-bar bg-info",
                staticStyle: { width: "80%" },
                attrs: {
                  role: "progressbar",
                  "aria-valuenow": "80",
                  "aria-valuemin": "0",
                  "aria-valuemax": "100"
                }
              })
            ])
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [
        _c("i", { staticClass: "icon-fa icon-fa-line-chart text-primary" }),
        _vm._v(" Monthly Sales")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-xl-6 mt-2" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h6", [
              _c("i", {
                staticClass: "icon-fa icon-fa-shopping-cart text-danger"
              }),
              _vm._v(" Pending Orders")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Customer Name")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Date")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Amount")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Actions")])
                ])
              ]),
              _vm._v(" "),
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v("Walter White")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("05/12/2016")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("555$")]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-default btn-xs",
                        attrs: { href: "#" }
                      },
                      [_vm._v("View")]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Hank Hill")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("05/12/2016")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("222$")]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-default btn-xs",
                        attrs: { href: "#" }
                      },
                      [_vm._v("View")]
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-12 col-xl-6 mt-2" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h6", [
              _c("i", { staticClass: "icon-fa icon-fa-users text-info" }),
              _vm._v(" New Customers")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("Customer Name")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Date")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Amount")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Actions")])
                ])
              ]),
              _vm._v(" "),
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v("Walter White")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("05/12/2016")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("555$")]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-default btn-xs",
                        attrs: { href: "#" }
                      },
                      [_vm._v("View")]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Hank Hill")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("05/12/2016")]),
                  _vm._v(" "),
                  _c("td", [_vm._v("222$")]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-default btn-xs",
                        attrs: { href: "#" }
                      },
                      [_vm._v("View")]
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }