<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb" v-if="site">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site.id">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site.id">Beállítások</router-link></li>
                <li class="breadcrumb-item active" v-if="site">{{ site.domain }}</li>
            </ol>
            <h1>{{ site ? site.domain : 'Site' }} beállításai</h1>
        </div>

        <tabs v-if="site">
            <tab id="data" name="Adatok">
                <form @submit.prevent="saveData">
                    <div class="form-group">
                        <label for="domainInput">Domain (pl.: itzen.hu):</label>
                        <input type="text" class="form-control" id="domainInput" v-model="site.domain">
                    </div>

                    <button class="btn btn-success">
                        <i class="icon-fa icon-fa-save"/>Mentés
                    </button>
                </form>
            </tab>

            <tab id="api" name="API">
                <form @submit.prevent="saveApi">
                    <div class="form-group">
                        <label for="keyInput">API kulcs</label>

                        <div class="input-group">
                            <input :type="keyInputType()"
                                v-model="site.api_key"
                                id="keyInput"
                                class="form-control"
                                readonly>
                            <div class="input-group-append">
                                <button class="btn btn-dark" type="button" @click="visibleKey = !visibleKey">
                                    <i class="icon-fa" :class="visibleKey ? 'icon-fa-eye-slash' : 'icon-fa-eye'"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <button class="btn btn-success">
                        <i class="icon-fa icon-fa-refresh"/>Új API kulcs generálása
                    </button>
                </form>
            </tab>

            <tab id="sitemap" name="Sitemap">
                <div v-if="sitemap">
                    <p>
                        A generált sitemap url-ét bele kell másolni a domain-en található robots.txt fájlba.
                    </p>
                    <pre class="mb-0"><code>Sitemap: {{ sitemap.url }}</code></pre>
                    <small>Létrehozva: {{ formatUtcDate(created_at) }}</small>
                </div>

                <div v-if="!sitemap">
                    Nincs sitemap!
                </div>

                <button class="btn btn-success mt-4" type="button" @click="generateSitemap">
                    <i class="icon-fa icon-fa-refresh" :class="{'icon-fa-spin': loading}"></i>
                    Sitemap létrehozása
                </button>
            </tab>
        </tabs>
    </div>
</template>

<script>
import ls from '../../services/ls.js'
import { Tabs, Tab } from 'vue-tabs-component'

export default {
    components: {
        Tab,
        Tabs
    },
    data () {
        return {
            site: null,
            sitemap: null,
            visibleKey: false,
            loading: false,
        }
    },
    created(){
        this.loadSite()
        this.loadSitemap()
    },
    methods: {
        keyInputType () {
            return this.visibleKey ? 'text' : 'password'
        },
        loadSite(){
            axios.get(`/api/admin/sites/${this.$route.params.site}`)
                .then(response => {
                    this.site = response.data.data
                    ls.set('site', this.site.id)
                    ls.set('siteDomain', this.site.domain)
                    this.$root.$emit('siteChange')
                })
                .catch(err => {
                    toastr.error('Hiba az adatok betöltése közben', 'Hiba')
                })
        },
        loadSitemap(){
            axios.get(`/api/admin/sitemap/${this.$route.params.site}`)
                .then(response => {
                    this.sitemap = response.data.data
                })
                .catch(err => {
                    toastr.error('Hiba az adatok betöltése közben', 'Hiba')
                })
        },
        generateSitemap(){
            this.loading = true

            axios.patch(`/api/admin/sitemap/${this.$route.params.site}`)
                .then(response => {
                    this.sitemap = response.data.data
                    toastr.success('Sitemap frissítve', 'Siker')
                }).catch(err => {
                    toastr.error('Hiba a sitemap létrehozása közben', 'Hiba')
                }).finally(() => {
                    this.loading = false
                })
        },
        saveData () {
            this.submit({
                domain: this.site.domain
            })
        },
        saveApi () {
            this.submit({
                api_key: 1
            })
        },
        submit (data) {
            axios.patch(`/api/admin/sites/${this.site.id}`, data)
                .then(response => {
                    toastr.success('Site frissítve', 'Siker')
                })
                .catch(err => {
                    toastr.error('Hiba a mentés közben', 'Hiba')
                })
        }
    },
    watch: {
        visibleKey(newValue){
            if(newValue){
                setTimeout(() => {
                    this.visibleKey = false
                }, 10000)
            }
        }
    }
}
</script>

<style>

</style>
