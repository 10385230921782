<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" to="/admin/dashboard">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item active">Domainek</li>
            </ol>
            <h1 class="d-inline mr-3">Domainek</h1>
            <button class="btn btn-outline-info btn-sm align-text-bottom">
                <router-link to="./sites/create">
                    <i class="icon-fa icon-fa-plus"/> Új hozzáadása
                </router-link>
            </button>
        </div>
        <div class="card">
            <div class="card-body">
                <cool-table :fields="fields"
                    :data-source="dataSource"
                    :textfilter="true">
                    <template slot="name" slot-scope="props">
                        {{ props.rowData.domain }}
                    </template>
                    <template slot-scope="props" slot="actions">
                        <div class="table__actions">
                            <router-link :to="`/admin/sites/${props.rowData.id}`" class="btn btn-secondary btn-sm">
                                Dashboard
                            </router-link>
                            <router-link :to="`/admin/sites/${props.rowData.id}/edit`" class="btn btn-secondary btn-sm">
                                Edit
                            </router-link>
                        </div>
                    </template>
                </cool-table>
            </div>
        </div>
    </div>
</template>

<script>
import ls from '../../services/ls.js'
import CoolTable from '../../components/CoolTable'

export default {
    components: {
        CoolTable
    },

    data() {
        return {
            fields: [
                {
                    name: 'domain',
                    title: 'Domain',
                    sortField: 'domain'
                },
                {
                    name: 'actions',
                    title: ''
                }
            ]
        }
    },

    methods:{
        dataSource (sortOrder, pagination, filters) {
            var sort = {
                field: sortOrder[0] ? sortOrder[0].sortField : 'deadline',
                direction: sortOrder[0] ? sortOrder[0].direction : 'asc',
            };

            return axios.get('/api/admin/sites', {
                params: {
                    page: pagination.current_page,
                    filters: filters,
                    with: 'authors,categories,tags,publications',
                    sort: sort.field,
                    order: sort.direction
                }
            })
        }
    },
    created () {
        ls.remove('site')
        this.$root.$emit('siteChange')
    }
}
</script>

<style>

</style>
