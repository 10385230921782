import ls from '../ls'

const App = {
    namespaced: true,
    state: {
        version: null,
        notifications: [],
        updateNotificationCursor: 'closed',
        counters: {
            orders: 0,
        },
    },
    mutations: {
        setVersion(state, payload){
            state.version = payload
        },
        setNotifications(state, payload){
            state.notifications = payload

            if(state.updateNotificationCursor == 'update'){
                state.updateNotificationCursor = 'closed'
            }
        },
        updateNotificationCursor(state, payload){
            state.updateNotificationCursor = payload
        },
        setCounters(state, payload){
            state.counters = payload
        }
    },
    actions: {
        getBeat({ state, commit }){
            axios.get('/api/admin/beat', {
                params: {
                    update: state.updateNotificationCursor == 'update',
                    site: ls.get('site')
                }
            }).then(response => {
                commit('setNotifications', response.data.notifications)
                commit('setCounters', response.data.counters)
            })
        },
    },
}

export default App
