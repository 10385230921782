const Sales = {
    namespaced: true,
    state: {
        sale: {
            name: 'Új akció',
            type: 0,
            value: 0,
            include: {
                tags: [],
                categories: [],
                products: [],
            },
            exclude: {
                tags: [],
                categories: [],
                products: [],
            },
            order: 0,
            starts_at: (new Date).toISOString(),
            ends_at: null,
            relations: {}
        },
        //meta: {},
        saving: false,
    },
    mutations: {
        setSale(state, payload){
            state.sale = payload
        },
        /*setMeta(state, payload){
            state.meta = payload
        },*/
        setSaveState(state, payload){
            state.saving = payload
        }
    },
    actions: {
        getSale({ commit }, sale){
            axios.get(`/api/admin/sales/${sale.id}`, {
                params: {
                    with: 'isActive,products,ruleProducts',
                }
            }).then(response => {
                commit('setSale', response.data.data)
                //commit('setMeta', response.data.meta)
            })
        },
        storeSale({ commit }, sale){
            commit('setSaveState', true)

            return new Promise((resolve, reject) => {
                axios.post(`/api/admin/sales`, {
                    ...sale,
                }).then(response => {
                    resolve(response)

                    toastr.success('Akció elmentve', 'Siker')
                }).catch(err => {
                    reject()

                    toastr.error('Hiba a mentés közben', 'Hiba')
                }).finally(() => {
                    commit('setSaveState', false)
                })
            })
        },
        updateSale({ commit }, sale){
            commit('setSaveState', true)

            axios.patch(`/api/admin/sales/${sale.id}`, sale)
                .then(response => {
                    commit('setSale', sale)

                    toastr.success('Akció elmentve', 'Siker')
                }).catch(error => {
                    toastr.error('Hiba a mentés közben', 'Hiba')
                }).finally(() => {
                    commit('setSaveState', false)
                })
        }
    }
  }

  export default Sales
