<template>
  <div class="main-content page-profile">
    <div class="page-header">
      <h3 class="page-title">Profil</h3>
    </div>
    <div class="row" v-if="user">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <tabs class="tabs-default">
              <tab id="profile" name="Adatok">
                <h4>{{ user.name }}</h4>
                <p class="detail-row"><i class="icon-fa icon-fa-envelope"/> {{ user.email }}</p>
                <p class="detail-row" v-tooltip="'Létrehozás ideje'"><i class="icon-fa icon-fa-star"/> {{ user.created_at }}</p>
              </tab>

              <tab id="profile-password" name="Jelszó">
                <form method="post" @submit.prevent="savePassword">
                    <div class="form-group">
                        <label for="currentPasswordInput">Jelenlegi jelszó</label>
                        <input type="password" class="form-control" id="currentPasswordInput" v-model="password.current" >
                    </div>
                    <div class="form-group">
                        <label for="passwordInput">Új jelszó</label>
                        <input type="password" class="form-control" id="passwordInput" v-model="password.new">
                    </div>
                    <div class="form-group">
                        <label for="passwordRetypeInput">Új jelszó még egyszer</label>
                        <input type="password" class="form-control" id="passwordRetypeInput" v-model="password.retype">
                    </div>

                    <button class="btn btn-primary" type="submit">Mentés</button>
                </form>
              </tab>
            </tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Tabs, Tab } from 'vue-tabs-component'

export default {
  components: {
    'tabs': Tabs,
    'tab': Tab
  },
  data(){
    return {
        user: null,
        password: {
            current: '',
            new: '',
            retype: '',
        }
    }
  },
  methods: {
    savePassword(){
        axios.patch('/api/admin/users/password', this.password)
            .then(() => {
                toastr.success('Az új jelszó sikeresen elmentve', 'Siker')
            })
            .catch(() => {
                toastr.error('Nem sikerült elmenteni az új jelszót', 'Hiba')
            })
    }
  },
  created(){
    axios.get('/api/admin/users/profile')
        .then(response => {
            this.user = response.data.data
        })
  }
}
</script>
