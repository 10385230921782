var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar-left" }, [
    _c("div", { staticClass: "sidebar-body scroll-pane" }, [
      _c(
        "div",
        { staticClass: "side-nav" },
        [
          _c(
            "v-collapse",
            { attrs: { accordion: "" } },
            [
              !_vm.site
                ? _c(
                    "v-collapse-item",
                    { attrs: { "active-url": "/admin/dashboard" } },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { slot: "item-title", to: "/admin/dashboard" },
                          slot: "item-title"
                        },
                        [
                          _c("i", { staticClass: "icon-fa icon-fa-dashboard" }),
                          _vm._v("Vezérlőpult\n                    ")
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.site
                ? _c(
                    "v-collapse-item",
                    { attrs: { "active-url": "/admin/sites" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "menu-title",
                          attrs: { slot: "item-title" },
                          slot: "item-title"
                        },
                        [
                          _c("i", { staticClass: "icon-fa icon-fa-globe" }),
                          _vm._v("Domainek\n                    ")
                        ]
                      ),
                      _vm._v(" "),
                      _c("router-link", { attrs: { to: "/admin/sites" } }, [
                        _vm._v(
                          "\n                        Összes domain\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/sites/create" } },
                        [
                          _vm._v(
                            "\n                        Új hozzáadása\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.site
                ? _c(
                    "v-collapse-item",
                    { attrs: { "active-url": "/admin/sites/" + _vm.site } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "menu-title",
                          attrs: { slot: "item-title" },
                          slot: "item-title"
                        },
                        [
                          _c("i", { staticClass: "icon-fa icon-fa-dashboard" }),
                          _vm._v(
                            "\n                        Vezérlőpult\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/sites/" + _vm.site } },
                        [
                          _vm._v(
                            "\n                        Kezdőlap\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/translations" } },
                        [
                          _vm._v(
                            "\n                        Fordítások\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/translation-links" } },
                        [
                          _vm._v(
                            "\n                        Fordítás linkek\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/sites/" + _vm.site + "/edit" } },
                        [
                          _vm._v(
                            "\n                        Beállítások\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.site
                ? _c(
                    "v-collapse-item",
                    { attrs: { "active-url": "/admin/posts" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "menu-title",
                          attrs: { slot: "item-title" },
                          slot: "item-title"
                        },
                        [
                          _c("i", { staticClass: "icon-fa icon-fa-edit" }),
                          _vm._v(
                            "\n                        Bejegyzések\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("router-link", { attrs: { to: "/admin/posts" } }, [
                        _vm._v(
                          "\n                        Összes bejegyzés\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/posts/create" } },
                        [
                          _vm._v(
                            "\n                        Új hozzáadása\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.site
                ? _c(
                    "v-collapse-item",
                    { attrs: { "active-url": "/admin/pages" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "menu-title",
                          attrs: { slot: "item-title" },
                          slot: "item-title"
                        },
                        [
                          _c("i", { staticClass: "icon-fa icon-fa-file-o" }),
                          _vm._v(
                            "\n                        Oldalak\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("router-link", { attrs: { to: "/admin/pages" } }, [
                        _vm._v(
                          "\n                        Összes Oldal\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/pages/create" } },
                        [
                          _vm._v(
                            "\n                        Új hozzáadása\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.site
                ? _c(
                    "v-collapse-item",
                    { attrs: { "active-url": "/admin/carousels" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "menu-title",
                          attrs: { slot: "item-title" },
                          slot: "item-title"
                        },
                        [
                          _c("i", { staticClass: "icon-fa icon-fa-image" }),
                          _vm._v(
                            "\n                        Képváltók\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("router-link", { attrs: { to: "/admin/carousels" } }, [
                        _vm._v(
                          "\n                        Összes képváltó\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/carousels/create" } },
                        [
                          _vm._v(
                            "\n                        Új hozzáadása\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.site
                ? _c(
                    "v-collapse-item",
                    { attrs: { "active-url": "/admin/products" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "menu-title",
                          attrs: { slot: "item-title" },
                          slot: "item-title"
                        },
                        [
                          _c("i", {
                            staticClass: "icon-fa icon-fa-shopping-basket"
                          }),
                          _vm._v(
                            "\n                        Termékek\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("router-link", { attrs: { to: "/admin/products" } }, [
                        _vm._v(
                          "\n                        Összes termék\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/products/create" } },
                        [
                          _vm._v(
                            "\n                        Új hozzáadása\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("router-link", { attrs: { to: "/admin/sales" } }, [
                        _vm._v(
                          "\n                        Akciók\n                    "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.site
                ? _c(
                    "v-collapse-item",
                    { attrs: { "active-url": "/admin/timber" } },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { slot: "item-title", to: "/admin/timber" },
                          slot: "item-title"
                        },
                        [
                          _c("i", { staticClass: "icon-fa icon-fa-tree" }),
                          _vm._v(
                            "\n                        Fűrészáru\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.site ? _c("hr") : _vm._e(),
              _vm._v(" "),
              _vm.site
                ? _c(
                    "v-collapse-item",
                    { attrs: { "active-url": "/admin/categories" } },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            slot: "item-title",
                            to: "/admin/categories"
                          },
                          slot: "item-title"
                        },
                        [
                          _c("i", { staticClass: "icon-fa icon-fa-sitemap" }),
                          _vm._v(
                            "\n                        Kategóriák\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.site
                ? _c(
                    "v-collapse-item",
                    { attrs: { "active-url": "/admin/tags" } },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { slot: "item-title", to: "/admin/tags" },
                          slot: "item-title"
                        },
                        [
                          _c("i", { staticClass: "icon-fa icon-fa-tag" }),
                          _vm._v(
                            "\n                        Címkék\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.site ? _c("hr") : _vm._e(),
              _vm._v(" "),
              _vm.site
                ? _c(
                    "v-collapse-item",
                    { attrs: { "active-url": "/admin/orders" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "menu-title",
                          attrs: { slot: "item-title" },
                          slot: "item-title"
                        },
                        [
                          _c("i", { staticClass: "icon-fa icon-fa-truck" }),
                          _vm._v(
                            "\n                        Rendelések\n\n                        "
                          ),
                          _vm.counters.orders
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: "Új rendelések száma",
                                      expression: "'Új rendelések száma'"
                                    }
                                  ],
                                  staticClass: "badge badge-info"
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.counters.orders) +
                                      "\n                        "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c("router-link", { attrs: { to: "/admin/orders" } }, [
                        _vm._v(
                          "\n                        Összes rendelés\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _c("router-link", { attrs: { to: "/admin/contacts" } }, [
                        _vm._v(
                          "\n                        Megrendelők\n                    "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.site ? _c("hr") : _vm._e(),
              _vm._v(" "),
              _vm.site
                ? _c("v-collapse-item", [
                    _c(
                      "a",
                      {
                        attrs: {
                          slot: "item-title",
                          href: "//" + _vm.domain,
                          target: "_blank"
                        },
                        slot: "item-title"
                      },
                      [
                        _c("i", {
                          staticClass: "icon-fa icon-fa-external-link"
                        }),
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.domain) +
                            " megtekintése\n                    "
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.site
                ? _c(
                    "v-collapse-item",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { slot: "item-title", to: "/admin/sites" },
                          slot: "item-title"
                        },
                        [
                          _c("i", {
                            staticClass: "icon-fa icon-fa-arrow-left"
                          }),
                          _vm._v(
                            "\n                        Vissza\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }