var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["gauge", "gauge-" + _vm.gaugeSize] }, [
    _c("canvas", {
      staticClass: "donut-gauge",
      attrs: { id: "donut", height: "250", width: "500" }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "gauge-label middle-label" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }