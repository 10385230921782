<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item active">Fordítások</li>
            </ol>
            <h1 class="d-inline mr-3">Fordítások</h1>
            <button class="btn btn-outline-info btn-sm align-text-bottom"
                type="button"
                @click="addItem">
                <i class="icon-fa icon-fa-plus"></i>
                Új hozzáadása
            </button>
        </div>

        <div class="card">
            <div class="card-body">
                <form>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Kulcs</th>
                                <th>Fordítás</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(translation, key) in translations"
                                v-bind:key="key">
                                <td>
                                    <div class="form-group mb-0">
                                        <input type="text"
                                            class="form-control"
                                            :class="{'is-invalid': hasDuplicate(translation.name, key)}"
                                            v-model="translation.name">
                                        <div class="invalid-feedback"
                                            v-if="hasDuplicate(translation.name, key)">
                                            Duplikált kulcs!
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <input type="text"
                                        class="form-control"
                                        v-model="translation.value">
                                </td>

                                <td class="remove-button">
                                    <button class="btn btn-danger"
                                        type="button"
                                        @click="removeItem(key)">
                                        <i class="icon-fa icon-fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn btn-success btn-sm"
                        type="button"
                        @click="save">
                        <i class="icon-fa icon-fa-save" v-if="!saving"></i>
                        <i class="icon-fa icon-fa-spin icon-fa-circle-o-notch" v-if="saving"></i>
                        Fordítások mentése
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import ls from '../../services/ls'

export default {
    data () {
        return {
            saving: false,
            translations: []
        }
    },
    methods: {
        hasDuplicate (name, index) {
            for (const translation in this.translations) {
                if(index != translation && this.translations[translation].name == name){
                    return true
                }
            }

            return false
        },
        addItem(){
            this.translations.push({
                name: '',
                value: ''
            })
        },
        removeItem(index){
            this.translations.splice(index, 1)
        },
        save(){
            this.saving = true

            var data = {}
            this.translations.forEach(o => {
                if(o.name != '' && o.value != ''){
                    data[o.name] = o.value
                }
            })

            axios.patch(`/api/admin/translations/${this.site}`, {content: data})
                .then(() => {
                    toastr.success('Adatok elmentve', 'Siker')
                })
                .catch(err => {
                    toastr.error('Nem sikerült elmenteni az adatokat', 'Hiba')
                })
                .finally(() => {
                    this.saving = false
                })

        }
    },
    created() {
        this.site = ls.get('site')

        axios.get(`/api/admin/translations/${this.site}`)
            .then(result => {
                for (const i in result.data) {
                    this.translations.push({
                        name: i,
                        value: result.data[i]
                    })
                }
            })
    }
}
</script>

<style scoped>
.remove-button{
    flex-grow: 0;
}
.remove-button .btn i{
    width: auto;
}
</style>
