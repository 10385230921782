<template>
    <div id="dashboardPage"
        class="main-content">

        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <form @submit.prevent="submit">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" v-tooltip="'Szűrés kezdő és záró dátuma'">
                                        <i class="icon-fa icon-fa-calendar"></i>
                                    </span>
                                </div>

                                <datetime type="date"
                                    v-model="filter.from"
                                    format="yyyy-MM-dd"
                                    input-class="form-control rounded-0">
                                </datetime>

                                <datetime type="date"
                                    v-model="filter.to"
                                    format="yyyy-MM-dd"
                                    input-class="form-control rounded-0">
                                </datetime>

                                <button class="btn btn-primary">
                                    <i class="icon-fa icon-fa-refresh icon-fa-spin w-auto" v-if="loading"></i>
                                    <i class="icon-fa icon-fa-filter" v-else></i>
                                    Szűrés
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>

        <div class="row" v-if="stats">
            <div class="col-md-12 col-lg-6 col-xl-3">
                <a class="dashbox" href="#">
                    <i class="icon-fa icon-fa-users text-primary"/>
                    <span class="title">
                        {{ stats.indicators.new_orders }}
                    </span>
                    <span class="desc">
                        Új rendelések
                    </span>
                </a>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <a class="dashbox" href="#">
                    <i class="icon-fa icon-fa-file text-success"/>
                    <span class="title">
                        {{ stats.indicators.open_orders }}
                    </span>
                    <span class="desc">
                        Lezáratlan rendelések
                    </span>
                </a>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <a class="dashbox" href="#">
                    <i class="icon-fa icon-fa-shopping-cart text-danger"/>
                    <span class="title">
                        {{ stats.indicators.open_value }}
                    </span>
                    <span class="desc">
                        Nyitott rendelések értéke
                    </span>
                </a>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <a class="dashbox" href="#">
                    <i class="icon-fa icon-fa-comments text-info"/>
                    <span class="title">
                        {{ stats.indicators.closed_value }}
                    </span>
                    <span class="desc">
                        Lezárt rendelések értéke
                    </span>
                </a>
            </div>
        </div>

        <div class="row" v-if="stats">
            <div class="col-lg-12 col-xl-6 mt-2">
                <div class="card">
                    <div class="card-header">
                        <h6><i class="icon-fa icon-fa-line-chart text-warning"/>Traffic Stats</h6>
                    </div>
                    <div class="card-body">
                        <line-graph :labels="['Jan','Feb','Mar','June']" :values="[20,30,40,60]"/>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-xl-6 mt-2">
                <div class="card">
                    <div class="card-header">
                        <h6><i class="icon-fa icon-fa-bar-chart text-success"/>Sales Chart</h6>
                    </div>
                    <div class="card-body">
                        <bar-graph :labels="['Jan','Feb','Mar','June']" :values="[20,30,40,60]"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="stats">
            <div class="col-lg-12 col-xl-6 mt-2">
                <div class="card">
                    <div class="card-header">
                        <h6><i class="icon-fa icon-fa-shopping-cart text-danger"/>Utolsó rendelések</h6>
                    </div>
                    <div class="card-body">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Megrendelő</th>
                                    <th>Összeg</th>
                                    <th>Időpont</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="order in stats.lists.last_orders" :key="order.id">
                                    <td>
                                        <router-link :to="`/admin/orders/${order.id}`">{{ order.code }}</router-link>
                                    </td>
                                    <td>
                                        <router-link :to="`/admin/contacts/${order.contact_id}`">{{ order.contact.name }}</router-link>
                                    </td>
                                    <td>{{ order.value }} Ft</td>
                                    <td>{{ formatUtcDate(order.created_at) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-xl-6 mt-2">
                <div class="card">
                    <div class="card-header">
                        <h6><i class="icon-fa icon-fa-users text-info"/>Legújabb megrendelők</h6>
                    </div>
                    <div class="card-body">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Név</th>
                                    <th>Időpont</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="contact in stats.lists.last_contacts" :key="contact.id">
                                    <td>
                                        <router-link :to="`/admin/contacts/${contact.id}`">{{ contact.name }}</router-link>
                                    </td>
                                    <td>{{ formatUtcDate(contact.created_at) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">
import ls from '../../services/ls.js'
import { Datetime } from 'vue-datetime'
import BarGraph from '../../components/chartjs/BarChart.vue'
import LineGraph from '../../components/chartjs/LineChart.vue'
import moment from 'moment'

export default {
    components: {
        LineGraph,
        BarGraph,
        Datetime,
    },
    data () {
        return {
            stats: null,
            filter: {
                from: new moment().date(1).toISOString(),
                to: new moment().toISOString(),
            },
            loading: false,
        }
    },
    methods: {
        submit(){
            this.loading = true
            axios.get(`/api/admin/stats/${this.$route.params.site}`, {
                params: this.filter
            }).then(results => {
                this.stats = results.data
                ls.set('site', this.stats.site)
                ls.set('siteDomain', this.stats.siteDomain)
                this.$root.$emit('siteChange')
            }).finally(() => {
                this.loading = false
            })
        }
    },
    created () {
        this.submit()
    }
}
</script>
