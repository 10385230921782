var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/sites/" + this.site },
                slot: "item-title"
              },
              [_vm._v("Vezérlőpult")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Rendelések")
        ])
      ]),
      _vm._v(" "),
      _c("h1", { staticClass: "d-inline mr-3" }, [_vm._v("Rendelések")])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("cool-table", {
            attrs: {
              fields: _vm.fields,
              "data-source": _vm.dataSource,
              textfilter: true,
              selectfilters: _vm.selectFilters
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "code",
                  fn: function(props) {
                    return [
                      _c(
                        "router-link",
                        { attrs: { to: "/admin/orders/" + props.rowData.id } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                props.rowData.code
                                  ? props.rowData.code
                                  : "Nincs rendelésszám!"
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "contact",
                  fn: function(props) {
                    return [
                      _vm._v(
                        "\n                    " +
                          _vm._s(props.rowData.relations.contact.name) +
                          "\n                "
                      )
                    ]
                  }
                },
                {
                  key: "status",
                  fn: function(props) {
                    return props.rowData.relations.status
                      ? [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.getStatus(
                                  props.rowData.relations.status.status
                                )
                              ) +
                              "\n                    "
                          ),
                          props.rowData.relations.statusWarning
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: _vm.getStatusWarning(
                                        props.rowData
                                      ),
                                      expression:
                                        "getStatusWarning(props.rowData)"
                                    }
                                  ]
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "icon-fa icon-fa-clock-o text-danger"
                                  })
                                ]
                              )
                            : _vm._e()
                        ]
                      : undefined
                  }
                },
                {
                  key: "created_at",
                  fn: function(props) {
                    return [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.formatUtcDate(props.rowData.created_at)) +
                          "\n                "
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }