<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item"><router-link slot="item-title" to="/admin/pages">Oldalak</router-link></li>
                <li class="breadcrumb-item active">Új oldal</li>
            </ol>
            <div class="d-flex align-items-center">
                <h1>Új oldal</h1>

                <div class="btn-group ml-auto">
                    <button class="btn btn-sm btn-success" form="pageEditForm">
                        <i class="icon-fa icon-fa-save" v-if="!saving"></i>
                        <i class="icon-fa icon-fa-circle-o-notch icon-fa-spin" v-if="saving"></i>
                        Mentés
                    </button>
                </div>
            </div>
        </div>

        <page-editor :original="page"
            :save="saveCallback"
            @saving="onSaveStart">
        </page-editor>
    </div>
</template>

<script>
import ls from '../../services/ls.js'
import pageEditor from './PageEditor'

export default {
    data () {
        return {
            page: {
                title: 'Új oldal',
                slug: 'uj-oldal',
                description: '',
                published_at: null,
                canonical: 'publications/uj-oldal',
                images: [],
                category: null,
                relations: [],
            },
            site: null,
            saving: false,
        }
    },
    components: {
        pageEditor,
    },
    methods: {
        onSaveStart(){
            this.saving = true
        },
        saveCallback(page, content){
            axios.post(`/api/admin/publications`, {
                ...page,
                type: 'page',
                site: this.site,
                content: content,
            }).then(response => {
                this.$router.push(`/admin/pages/${response.data.id}/edit`)
                toastr.success('Oldal elmentve', 'Siker')
            }).catch(err => {
                toastr.error('Hiba a mentés közben', 'Hiba')
            }).finally(() => {
                this.saving = false
            })
        }
    },
    created() {
        this.site = ls.get('site')
    }
}
</script>

<style>

</style>
