var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "mailbox",
        class: { "is-open": _vm.isLeftSidebarVisible }
      },
      [
        _c("mailbox-sidebar", {
          attrs: {
            "selected-category": _vm.selectedCategory,
            categories: _vm.categories,
            "is-left-sidebar-visible": _vm.isLeftSidebarVisible
          },
          on: {
            selected: _vm.selectCategory,
            toggle: function($event) {
              _vm.isLeftSidebarVisible = !_vm.isLeftSidebarVisible
            }
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mailbox-content" },
          [
            _c("div", { staticClass: "mailbox-content-header" }, [
              _c(
                "div",
                { staticClass: "mailbox-actions" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "custom-control custom-checkbox mailbox-action",
                      staticStyle: { display: "inline-block" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectAll,
                            expression: "selectAll"
                          }
                        ],
                        staticClass: "custom-control-input",
                        attrs: { id: "customCheckAll", type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.selectAll)
                            ? _vm._i(_vm.selectAll, null) > -1
                            : _vm.selectAll
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.selectAll,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.selectAll = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.selectAll = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.selectAll = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", {
                        staticClass: "custom-control-label",
                        attrs: { for: "customCheckAll" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-dropdown",
                    {
                      staticClass: "mailbox-action",
                      attrs: {
                        "active-url": "/admin/dashboard",
                        "theme-light": ""
                      }
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: { slot: "activator", href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                            }
                          },
                          slot: "activator"
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light dropdown-toggle",
                              attrs: { type: "button" }
                            },
                            [
                              _vm._v(
                                "\n                Actions\n              "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-dropdown-item", [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [_vm._v("Edit")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-dropdown-item", [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [_vm._v("Delete")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-dropdown-item", [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [_vm._v("Forward")]
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._m(1)
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mailbox-filters" }, [
                _c("div", { staticClass: "mail-search" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchText,
                        expression: "searchText"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "inputEmailTo",
                      type: "email",
                      placeholder: "Search"
                    },
                    domProps: { value: _vm.searchText },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.searchText = $event.target.value
                      }
                    }
                  })
                ])
              ])
            ]),
            _vm._v(" "),
            _c("table", { staticClass: "table table-hover" }, [
              _c(
                "tbody",
                _vm._l(_vm.filteredMails, function(mail, index) {
                  return _c(
                    "tr",
                    {
                      key: index,
                      class: { read: mail.read },
                      on: {
                        click: function($event) {
                          return _vm.openMailModal(mail)
                        }
                      }
                    },
                    [
                      _c(
                        "td",
                        {
                          staticClass: "cell-checkbox",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "custom-control custom-checkbox" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedMails,
                                    expression: "selectedMails"
                                  }
                                ],
                                staticClass: "custom-control-input",
                                attrs: { id: index, type: "checkbox" },
                                domProps: {
                                  value: mail.id,
                                  checked: Array.isArray(_vm.selectedMails)
                                    ? _vm._i(_vm.selectedMails, mail.id) > -1
                                    : _vm.selectedMails
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.selectedMails,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = mail.id,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.selectedMails = $$a.concat([
                                            $$v
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.selectedMails = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.selectedMails = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("label", {
                                staticClass: "custom-control-label",
                                attrs: { for: index }
                              })
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "cell-fav" }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn-favorite",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                mail.favorite = !mail.favorite
                              }
                            }
                          },
                          [
                            mail.favorite
                              ? _c("i", {
                                  staticClass: "icon-fa icon-fa-star active"
                                })
                              : _c("i", {
                                  staticClass: "icon-fa icon-fa-star-o"
                                })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "cell-user" }, [
                        _c("a", { staticClass: "user" }, [
                          _c("img", {
                            staticClass: "user-avatar",
                            attrs: { src: mail.from.avatar }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "cell-content" }, [
                        _c("div", { staticClass: "content" }, [
                          _c("div", { staticClass: "content-name" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(mail.from.name) +
                                "\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "content-subject" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(mail.title) +
                                "\n                "
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _vm._m(2, true),
                      _vm._v(" "),
                      _c("td", { staticClass: "cell-time" }, [
                        _vm._v("\n              2 hours ago\n            ")
                      ])
                    ]
                  )
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("mailbox-footer")
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "transition",
          { attrs: { name: "fade" } },
          [
            _c("mailbox-modal", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isModalVisible,
                  expression: "isModalVisible"
                }
              ],
              attrs: { "is-visible": _vm.isModalVisible },
              on: { close: _vm.closeMailModal }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("mailbox-compose-modal", { ref: "compose" })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Mailbox")]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Apps")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [_vm._v("Mailbox")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-icon btn-light mailbox-action",
        attrs: { type: "button" }
      },
      [_c("i", { staticClass: "icon-fa icon-fa-tag" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "cell-attachment" }, [
      _c("i", { staticClass: "icon-fa icon-fa-paperclip attachment-icon" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }