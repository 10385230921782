var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["collapse-group-item", { active: _vm.isCollapseOpen }] },
    [
      _c(
        "div",
        {
          staticClass: "collapse-item-title",
          on: { click: _vm.toggleCollapse }
        },
        [_vm._t("item-title")],
        2
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { duration: { enter: 0 }, name: "slide" },
          on: { "after-enter": _vm.afterEnter, "after-leave": _vm.afterLeave }
        },
        [
          _vm.hasChild
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isCollapseOpen,
                      expression: "isCollapseOpen"
                    }
                  ],
                  ref: "collapseItems",
                  staticClass: "collapse-group-items",
                  style: "max-height:" + _vm.height + "px"
                },
                [_vm._t("default")],
                2
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }