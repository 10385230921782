import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthService from './services/auth'

/*
 |--------------------------------------------------------------------------
 | Admin Views
 |--------------------------------------------------------------------------|
 */

import Basic from './views/admin/dashboard/Basic.vue'
import Ecommerce from './views/admin/dashboard/Ecommerce.vue'
import Finance from './views/admin/dashboard/Finance.vue'

import AdminSites from './views/sites/Sites.vue'
import SiteDashboard from './views/sites/SiteDashboard.vue'
import AdminSitesEdit from './views/sites/SitesEdit.vue'
import AdminSitesCreate from './views/sites/SitesCreate.vue'

import AdminPages from './views/page/Pages.vue'
import AdminPageEdit from './views/page/PageEdit.vue'
import AdminPageCreate from './views/page/PageCreate.vue'

import AdminPost from './views/post/Post.vue'
import AdminPostEdit from './views/post/PostEdit.vue'
import AdminPostCreate from './views/post/PostCreate.vue'

import AdminCarousels from './views/carousel/Carousels.vue'
import AdminCarouselCreate from './views/carousel/CarouselCreate.vue'
import AdminCarouselEdit from './views/carousel/CarouselEdit.vue'

import AdminCategory from './views/category/Category.vue'
import AdminTags from './views/tag/Tags.vue'

import AdminProductIndex from './views/product/Index.vue'
import AdminProductEdit from './views/product/Edit.vue'
import AdminProductCreate from './views/product/Create.vue'

import AdminSaleIndex from './views/sale/Index.vue'
import AdminSaleCreate from './views/sale/Create.vue'
import AdminSaleEdit from './views/sale/Edit.vue'

import AdminOrderIndex from './views/order/Index.vue'
import AdminOrderEdit from './views/order/Edit.vue'

import AdminContactIndex from './views/contact/Index.vue'
import AdminContactShow from './views/contact/Show.vue'

import AdminTranslations from './views/admin/Translations.vue'
import AdminTranslationLinks from './views/admin/TranslationLinks.vue'

import AdminTimber from './views/timber/Index.vue'

// Layouts
import LayoutBasic from './views/layouts/LayoutBasic.vue'
import LayoutLoginCustom from './views/layouts/LayoutLoginCustom.vue'
import LayoutFront from './views/layouts/LayoutFront.vue'

// Basic UI
import Buttons from './views/admin/basic-ui/Buttons.vue'
import Cards from './views/admin/basic-ui/Cards.vue'
import Typography from './views/admin/basic-ui/Typography.vue'
import Tables from './views/admin/basic-ui/Tables.vue'
import ProgressBar from './views/admin/basic-ui/ProgressBars.vue'

// Components
import Notifications from './views/admin/components/Notifications.vue'
import SweetModals from './views/admin/components/SweetModals.vue'
import VueCarousel from './views/admin/components/VueCarousel.vue'
import VueDropzone from './views/admin/components/VueDropzone.vue'
import VueTable from './views/admin/components/VueTables.vue'
import VueTabs from './views/admin/components/VueTabs.vue'
import VueTooltips from './views/admin/components/VueTooltips.vue'
import VueCollapse from './views/admin/components/VueCollapse.vue'

// Charts
import Chartjs from './views/admin/charts/Chartjs.vue'
import Gauges from './views/admin/charts/Gauge.vue'

// Icons
import Fontawesome from './views/admin/icons/Fontawesome.vue'
import Fpsline from './views/admin/icons/FpsLine.vue'
import IcoMoon from './views/admin/icons/IcoMoon.vue'
import Line from './views/admin/icons/Line.vue'
import Meteo from './views/admin/icons/Meteo.vue'

// Forms
import General from './views/admin/forms/General.vue'
import Advanced from './views/admin/forms/Advanced.vue'
import Layouts from './views/admin/forms/FormLayouts.vue'
import VeeValidate from './views/admin/forms/VeeValidate.vue'
import Vuelidate from './views/admin/forms/Vuelidate.vue'


// Gallery
import ImageGallery from './views/admin/gallery/ImageGallery.vue'
import VideoGallery from './views/admin/gallery/VideoGallery.vue'

// Apps
import TodosApp from './views/admin/apps/todos/TodosApp.vue'
import MailboxApp from './views/admin/apps/mailbox/MailBoxApp.vue'

// Users
import Users from './views/admin/users/Users.vue'
import Profile from './views/admin/users/Profile.vue'

// Settings
import Settings from './views/admin/Settings.vue'

// Auth
import Login from './views/auth/Login.vue'
import Register from './views/auth/Register.vue'
import NotFoundPage from './views/errors/404.vue'

/*
 |--------------------------------------------------------------------------
 | Frontend Views
 |--------------------------------------------------------------------------|
 */

import Home from './views/front/Home.vue'

Vue.use(VueRouter)

const routes = [

    /*
     |--------------------------------------------------------------------------
     | Frontend Routes
     |--------------------------------------------------------------------------|
     */

    {
        path: '/',
        component: LayoutFront,
        children: [
        {
            path: '/',
            component: Home,
            name: 'home'
        }
        ]
    },

    /*
     |--------------------------------------------------------------------------
     | Admin Backend Routes
     |--------------------------------------------------------------------------|
     */
    {
        path: '/admin',
        component: LayoutBasic, // Change the desired Layout here
        meta: { requiresAuth: true },
        children: [
            // Dashboard
            {
                path: 'dashboard',
                component: Basic,
                name: 'dashboard'
            },
            {
                path: 'dashboard/ecommerce',
                component: Ecommerce
            },
            {
                path: 'dashboard/finance',
                component: Finance
            },

            // Sites
            {
                path: 'sites',
                component: AdminSites
            },
            {
                path: 'sites/create',
                component: AdminSitesCreate
            },
            {
                path: 'sites/:site/edit',
                component: AdminSitesEdit
            },
            {
                path: 'sites/:site',
                component: SiteDashboard
            },

            // Pages
            {
                path: 'pages',
                component: AdminPages
            },
            {
                path: 'pages/:page/edit',
                component: AdminPageEdit
            },
            {
                path: 'pages/create',
                component: AdminPageCreate
            },

            // Posts
            {
                path: 'posts',
                component: AdminPost
            },
            {
                path: 'posts/:post/edit',
                component: AdminPostEdit
            },
            {
                path: 'posts/create',
                component: AdminPostCreate
            },

            {
                path: 'carousels',
                component: AdminCarousels
            },
            {
                path: 'carousels/create',
                component: AdminCarouselCreate
            },
            {
                path: 'carousels/:carousel/edit',
                component: AdminCarouselEdit
            },

            // Categories
            {
                path: 'categories',
                component: AdminCategory
            },

            // Tags
            {
                path: 'tags',
                component: AdminTags
            },

            // Products
            {
                path: 'products',
                component: AdminProductIndex
            },
            {
                path: 'products/:product/edit',
                component: AdminProductEdit
            },
            {
                path: 'products/create',
                component: AdminProductCreate
            },

            // Sales
            {
                path: 'sales',
                component: AdminSaleIndex
            },
            {
                path: 'sales/:sale/edit',
                component: AdminSaleEdit,
            },
            {
                path: 'sales/create',
                component: AdminSaleCreate
            },

            // Timber setup
            {
                path: 'timber',
                component: AdminTimber,
            },

            // Orders
            {
                path: 'orders',
                component: AdminOrderIndex,
            },
            {
                path: 'orders/:order',
                component: AdminOrderEdit,
            },

            // Contacts
            {
                path: 'contacts',
                component: AdminContactIndex,
            },
            {
                path: 'contacts/:contact',
                component: AdminContactShow,
            },

            // Basic UI
            {
                path: 'basic-ui/buttons',
                component: Buttons
            },
            {
                path: 'basic-ui/cards',
                component: Cards
            },
            {
                path: 'basic-ui/typography',
                component: Typography
            },
            {
                path: 'basic-ui/tables',
                component: Tables
            },

            {
                path: 'basic-ui/progress-bars',
                component: ProgressBar
            },

            // Components
            {
                path: 'components/vue-tabs',
                component: VueTabs
            },
            {
                path: 'components/vue-collapse',
                component: VueCollapse
            },
            {
                path: 'components/notifications',
                component: Notifications
            },
            {
                path: 'components/vue-dropzone',
                component: VueDropzone
            },
            {
                path: 'components/sweet-modals',
                component: SweetModals
            },
            {
                path: 'components/vue-tables',
                component: VueTable
            },
            {
                path: 'components/vue-carousel',
                component: VueCarousel
            },
            {
                path: 'components/tooltips',
                component: VueTooltips
            },

            // Charts
            {
                path: 'charts/chartjs',
                component: Chartjs
            },
            {
                path: 'charts/gauges',
                component: Gauges
            },

            //  Icons
            {
                path: 'icons/fontawesome',
                component: Fontawesome
            },
            {
                path: 'icons/fpsline',
                component: Fpsline
            },
            {
                path: 'icons/icomoon',
                component: IcoMoon
            },
            {
                path: 'icons/line',
                component: Line
            },
            {
                path: 'icons/meteo',
                component: Meteo
            },

            // Forms
            {
                path: 'forms/general',
                component: General
            },
            {
                path: 'forms/advanced',
                component: Advanced
            },
            {
                path: 'forms/layouts',
                component: Layouts
            },
            {
                path: 'forms/vuelidate',
                component: Vuelidate
            },
            {
                path: 'forms/vee-validate',
                component: VeeValidate
            },

            // Gallery
            {
                path: 'gallery/image',
                component: ImageGallery
            },
            {
                path: 'gallery/video',
                component: VideoGallery
            },

            // Users
            {
                path: 'users/profile',
                component: Profile
            },
            {
                path: 'users/all',
                component: Users
            },

            // Apps
            {
                path: 'apps/mailbox',
                component: MailboxApp
            },
            {
                path: 'apps/todos',
                component: TodosApp
            },

            // Settings
            {
                path: 'settings',
                component: Settings
            },

            {
                path: 'translations',
                component: AdminTranslations
            },
            {
                path: 'translation-links',
                component: AdminTranslationLinks
            }
        ]
    },

    /*
     |--------------------------------------------------------------------------
     | Auth & Registration Routes
     |--------------------------------------------------------------------------|
     */

    {
        path: '/',
        component: LayoutLoginCustom,
        children: [
            {
                path: 'login',
                component: Login,
                name: 'login'
            },
            {
                path: 'register',
                component: Register,
                name: 'register'
            }
        ]
    },

    //  DEFAULT ROUTE
    { path: '*', component: NotFoundPage }
]

const router = new VueRouter({
    routes,
    mode: 'history',
    linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
    //  If the next route is requires user to be Logged IN
    if (to.matched.some(m => m.meta.requiresAuth)) {
        return AuthService.check().then(authenticated => {
            if (!authenticated) {
                return next({ path: '/login' })
            }

            return next()
        })
    }

    return next()
})

export default router
