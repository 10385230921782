var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "svg",
      {
        staticClass: "media-library-media-svg",
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          viewBox: "0 0 777.21 727.86"
        }
      },
      [
        _c(
          "defs",
          [
            _c(
              "linearGradient",
              {
                attrs: {
                  id: "162af490-344a-43d2-868f-e2d375e78928",
                  x1: "425.06",
                  y1: "600.05",
                  x2: "425.06",
                  y2: "128.87",
                  gradientUnits: "userSpaceOnUse"
                }
              },
              [
                _c("stop", {
                  attrs: {
                    offset: "0",
                    "stop-color": "gray",
                    "stop-opacity": "0.25"
                  }
                }),
                _c("stop", {
                  attrs: {
                    offset: "0.54",
                    "stop-color": "gray",
                    "stop-opacity": "0.12"
                  }
                }),
                _c("stop", {
                  attrs: {
                    offset: "1",
                    "stop-color": "gray",
                    "stop-opacity": "0.1"
                  }
                })
              ],
              1
            ),
            _c("linearGradient", {
              attrs: {
                id: "cd333915-18a2-41bb-84e7-969423cc6509",
                x1: "258.38",
                y1: "294.87",
                x2: "258.38",
                y2: "252.24",
                "xlink:href": "#162af490-344a-43d2-868f-e2d375e78928"
              }
            }),
            _c("linearGradient", {
              attrs: {
                id: "3bc77771-41d7-4cfb-a8ab-2b296e6cb456",
                x1: "659.41",
                y1: "203.43",
                x2: "659.41",
                y2: "169.83",
                "xlink:href": "#162af490-344a-43d2-868f-e2d375e78928"
              }
            }),
            _c("linearGradient", {
              attrs: {
                id: "89202326-e19a-43be-8be7-b4f2bb732d86",
                x1: "345.38",
                y1: "208.38",
                x2: "345.38",
                y2: "161.13",
                "xlink:href": "#162af490-344a-43d2-868f-e2d375e78928"
              }
            }),
            _c("linearGradient", {
              attrs: {
                id: "43906a67-8705-4c83-b97d-76216b252165",
                x1: "483.32",
                y1: "183.35",
                x2: "483.32",
                y2: "142.38",
                "xlink:href": "#162af490-344a-43d2-868f-e2d375e78928"
              }
            }),
            _c("linearGradient", {
              attrs: {
                id: "261008f7-0041-41fb-b56a-8539a53b2afe",
                x1: "708.87",
                y1: "119.9",
                x2: "748.2",
                y2: "119.9",
                "xlink:href": "#162af490-344a-43d2-868f-e2d375e78928"
              }
            }),
            _c("linearGradient", {
              attrs: {
                id: "ab0efae6-0bd2-4a14-9ba9-bd41b41698aa",
                x1: "964.11",
                y1: "297.58",
                x2: "964.11",
                y2: "98.89",
                "xlink:href": "#162af490-344a-43d2-868f-e2d375e78928"
              }
            }),
            _c("linearGradient", {
              attrs: {
                id: "94d1e193-2c6b-46d4-b37d-7a903e0b29da",
                x1: "463.31",
                y1: "448",
                x2: "463.31",
                y2: "277.64",
                gradientTransform: "translate(8.03 217.52)",
                "xlink:href": "#162af490-344a-43d2-868f-e2d375e78928"
              }
            }),
            _c("linearGradient", {
              attrs: {
                id: "3a29d9b6-8e61-4496-a6b5-5d679eb163f7",
                x1: "661.99",
                y1: "448.69",
                x2: "661.99",
                y2: "278.33",
                gradientTransform: "translate(8.03 216.83)",
                "xlink:href": "#162af490-344a-43d2-868f-e2d375e78928"
              }
            }),
            _c("linearGradient", {
              attrs: {
                id: "b72660a5-d964-4b97-8da5-9d342e2aedd1",
                x1: "860.68",
                y1: "449.38",
                x2: "860.68",
                y2: "279.02",
                gradientTransform: "translate(8.03 216.15)",
                "xlink:href": "#162af490-344a-43d2-868f-e2d375e78928"
              }
            }),
            _c("linearGradient", {
              attrs: {
                id: "3a01118b-2985-4be0-b440-2f096e175d1e",
                x1: "463.33",
                y1: "240.45",
                x2: "463.33",
                y2: "70.09",
                gradientTransform: "translate(7.31 217.52)",
                "xlink:href": "#162af490-344a-43d2-868f-e2d375e78928"
              }
            }),
            _c("linearGradient", {
              attrs: {
                id: "709a1cf8-cfb3-4f81-afc1-9e781697792d",
                x1: "859.34",
                y1: "238.27",
                x2: "859.34",
                y2: "67.91",
                gradientTransform: "translate(7.3 216.15)",
                "xlink:href": "#162af490-344a-43d2-868f-e2d375e78928"
              }
            }),
            _c("linearGradient", {
              attrs: {
                id: "7d15d707-8f67-4e66-8d49-1c2771fce49c",
                x1: "661.68",
                y1: "239.65",
                x2: "661.68",
                y2: "69.29",
                gradientTransform: "translate(7.31 216.84)",
                "xlink:href": "#162af490-344a-43d2-868f-e2d375e78928"
              }
            }),
            _c("linearGradient", {
              attrs: {
                id: "bc3ac81e-e376-4546-b1a7-de8dd566bde2",
                x1: "431.12",
                y1: "727.86",
                x2: "431.12",
                y2: "429.15",
                "xlink:href": "#162af490-344a-43d2-868f-e2d375e78928"
              }
            }),
            _c("linearGradient", {
              attrs: {
                id: "54f2380e-aa08-4cab-b2db-70f5d4ca6305",
                x1: "296.27",
                y1: "470.97",
                x2: "296.27",
                y2: "446.14",
                "xlink:href": "#162af490-344a-43d2-868f-e2d375e78928"
              }
            }),
            _c("linearGradient", {
              attrs: {
                id: "6a27cddf-db9d-4050-9f4d-1fd9e794acb5",
                x1: "543.51",
                y1: "470.97",
                x2: "543.51",
                y2: "446.14",
                "xlink:href": "#162af490-344a-43d2-868f-e2d375e78928"
              }
            }),
            _c("linearGradient", {
              attrs: {
                id: "60689c16-abac-4c9a-af50-67b87b3c402d",
                x1: "432.11",
                y1: "661.16",
                x2: "432.11",
                y2: "495.04",
                "xlink:href": "#162af490-344a-43d2-868f-e2d375e78928"
              }
            }),
            _c("linearGradient", {
              attrs: {
                id: "20aac6d0-8ce9-4ca1-bfa8-28c9e4d79ce9",
                x1: "432.11",
                y1: "637.09",
                x2: "432.11",
                y2: "519.1",
                "xlink:href": "#162af490-344a-43d2-868f-e2d375e78928"
              }
            })
          ],
          1
        ),
        _c("rect", {
          attrs: {
            x: "72.92",
            y: "128.87",
            width: "704.29",
            height: "471.18",
            fill: "url(#162af490-344a-43d2-868f-e2d375e78928)"
          }
        }),
        _c("rect", {
          attrs: {
            x: "80.36",
            y: "133.83",
            width: "689.41",
            height: "17.36",
            fill: "#fff"
          }
        }),
        _c("rect", {
          attrs: {
            x: "80.36",
            y: "151.19",
            width: "689.41",
            height: "439.77",
            fill: "#f5f5f5"
          }
        }),
        _c("circle", {
          attrs: { cx: "94.3", cy: "142.93", r: "4.85", fill: "#ff5252" }
        }),
        _c("circle", {
          attrs: { cx: "107.64", cy: "142.93", r: "4.85", fill: "#ff0" }
        }),
        _c("circle", {
          attrs: { cx: "120.97", cy: "142.93", r: "4.85", fill: "#69f0ae" }
        }),
        _c("path", {
          attrs: {
            d:
              "M254.18,263.38l2.3,3.39,5.42-3.69,3.47,6.56-4.74,3.22,11.52,16.94-7.45,5.07-11.52-16.94-3.39,2.3-4.15-6.1,3.39-2.3-2.3-3.39s-5-6,.35-11.14l7.45-5.07,3.69,5.42-3.39,2.3A2.41,2.41,0,0,0,254.18,263.38Z",
            transform: "translate(-244.61 -98.89)",
            fill: "url(#cd333915-18a2-41bb-84e7-969423cc6509)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M255.82,260.92l2.3,3.39,5.42-3.69,3.47,6.56-4.74,3.22,11.52,16.94-7.45,5.07-11.52-16.94-3.39,2.3-4.15-6.1,3.39-2.3-2.3-3.39s-5-6,.35-11.14l7.45-5.07,3.69,5.42-3.39,2.3A2.41,2.41,0,0,0,255.82,260.92Z",
            transform: "translate(-244.61 -98.89)",
            fill: "#3b5998"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M675.79,175.56l4.1-.82s-3.44,3.85-4.1,4.1h0a16.48,16.48,0,0,0,0,2.46c-7.87,33.76-36.87,18.85-36.87,18.85,10.57.25,11.47-4.1,11.47-4.1-6.15-.9-7.37-4.92-7.37-4.92a2.53,2.53,0,0,0,3.28-.82c-6.64-1.72-5.74-8.19-5.74-8.19a8.52,8.52,0,0,0,4,1.56c-.74-.9-7-8.36-3.2-12.21,0,0,4.59,8.19,16.63,8.93l.82-.16a6.43,6.43,0,0,1-.16-1.8,8.6,8.6,0,0,1,8.6-8.6c5.74,0,7,3.28,7,3.28l4.1-2.46C678.25,170.65,677.84,174.74,675.79,175.56Z",
            transform: "translate(-244.61 -98.89)",
            fill: "url(#3bc77771-41d7-4cfb-a8ab-2b296e6cb456)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M677.43,173.11l4.1-.82s-3.44,3.85-4.1,4.1h0a16.48,16.48,0,0,0,0,2.46c-7.87,33.76-36.87,18.85-36.87,18.85,10.57.25,11.47-4.1,11.47-4.1-6.15-.9-7.37-4.92-7.37-4.92a2.53,2.53,0,0,0,3.28-.82c-6.64-1.72-5.74-8.19-5.74-8.19a8.52,8.52,0,0,0,4,1.56c-.74-.9-7-8.36-3.2-12.21,0,0,4.59,8.19,16.63,8.93l.82-.16a6.43,6.43,0,0,1-.16-1.8,8.6,8.6,0,0,1,8.6-8.6c5.74,0,7,3.28,7,3.28l4.1-2.46C679.89,168.19,679.48,172.29,677.43,173.11Z",
            transform: "translate(-244.61 -98.89)",
            fill: "#00aced"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M354.46,206.09,359,190.67s.07-3.48-3-4.21-4.27-.56-6.17.68L345,203.34,337.16,201l7.11-24.39,7.87,2.29-1.05,3.62s4.06-4.28,9.22-2.35c4.79,1.74,7.53,5.78,6.46,12.38l.16,0-4.59,15.73Zm-18.72-35.33a4.92,4.92,0,1,1,6.1-3.34A4.93,4.93,0,0,1,335.74,170.76Zm-4.09,28.68-7.87-2.29,7.11-24.39,7.87,2.29Z",
            transform: "translate(-244.61 -98.89)",
            fill: "url(#89202326-e19a-43be-8be7-b4f2bb732d86)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M356.1,203.63l4.49-15.42s.07-3.48-3-4.21-4.27-.56-6.17.68l-4.72,16.2-7.87-2.29,7.11-24.39,7.87,2.29-1.05,3.62s4.06-4.28,9.22-2.35c4.79,1.74,7.53,5.78,6.46,12.38l.16,0L364,205.93Zm-18.72-35.33a4.92,4.92,0,1,1,6.1-3.34A4.93,4.93,0,0,1,337.38,168.31ZM333.29,197l-7.87-2.29,7.11-24.39,7.87,2.29Z",
            transform: "translate(-244.61 -98.89)",
            fill: "#0077b5"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M498.81,158.77c-.9,4.34-1.56,10.24-10.65,12.29,0,0-5.33,1.39-7-2.46-1.07,4.18-2.13,8-2.79,9a51.14,51.14,0,0,1-4.1,5.74s.74-7.37.82-9c.08-2.54,2.46-13.93,2.46-13.93s-2.21-8,3.28-9c0,0,4.51-1.07,3.28,4.92,0,0-.9,3.93-2,8.44l.41.57a4.61,4.61,0,0,0,3.28,2.46c2.21.25,8-3.69,8.19-10.65s-1.31-8.93-7.37-10.65-11.23,1.23-13.11,6.55c-2.13,6.06-.41,7.54.82,9l-.82,4.1s-5.9-2-5.74-8.19,3.69-15.57,16.39-15.57S499.71,154.34,498.81,158.77Z",
            transform: "translate(-244.61 -98.89)",
            fill: "url(#43906a67-8705-4c83-b97d-76216b252165)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M500.45,156.31c-.9,4.34-1.56,10.24-10.65,12.29,0,0-5.33,1.39-7-2.46-1.07,4.18-2.13,8-2.79,9a51.14,51.14,0,0,1-4.1,5.74s.74-7.37.82-9c.08-2.54,2.46-13.93,2.46-13.93s-2.21-8,3.28-9c0,0,4.51-1.07,3.28,4.92,0,0-.9,3.93-2,8.44l.41.57a4.61,4.61,0,0,0,3.28,2.46c2.21.25,8-3.69,8.19-10.65s-1.31-8.93-7.37-10.65-11.23,1.23-13.11,6.55c-2.13,6.06-.41,7.54.82,9l-.82,4.1s-5.9-2-5.74-8.19,3.69-15.57,16.39-15.57S501.35,151.88,500.45,156.31Z",
            transform: "translate(-244.61 -98.89)",
            fill: "#bd081c"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M747,120.31h-5.33v5.33a1.26,1.26,0,0,1-1.23,1.23h-.82a1.26,1.26,0,0,1-1.23-1.23v-5.33H733a1.26,1.26,0,0,1-1.23-1.23v-.82A1.26,1.26,0,0,1,733,117h5.33V111.7a1.26,1.26,0,0,1,1.23-1.23h.82a1.26,1.26,0,0,1,1.23,1.23V117H747a1.26,1.26,0,0,1,1.23,1.23v.82A1.26,1.26,0,0,1,747,120.31Zm-18.52-14.42a8.2,8.2,0,0,1,1.72,5,8.66,8.66,0,0,1-5.57,7.87c.25.82,1.07,1.72,3.11,3.2,3.93,2.87,4.1,8.19,4.1,8.19h0v.41c0,4.26-5.16,7.78-11.47,7.78s-11.47-3.52-11.47-7.78c0-4.1,4.67-7.46,10.65-7.78V119.4c-4.59-.57-8.19-4.18-8.19-8.52,0-4.75,4.18-8.6,9.42-8.6h.41s9.42-.74,10.65-.82.82,1.64.82,1.64ZM714.6,130.14c0,2.7,2.79,4.92,6.15,4.92s6.15-2.21,6.15-4.92-2.79-4.92-6.15-4.92S714.6,127.43,714.6,130.14Zm10-20.07c-.66-3.11-2.95-5.33-5.08-4.92s-3.28,3.36-2.62,6.47,2.95,5.33,5.08,4.92S725.26,113.26,724.6,110.06Z",
            transform: "translate(-244.61 -98.89)",
            fill: "url(#261008f7-0041-41fb-b56a-8539a53b2afe)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M748.61,117.85h-5.33v5.33a1.26,1.26,0,0,1-1.23,1.23h-.82a1.26,1.26,0,0,1-1.23-1.23v-5.33h-5.33a1.26,1.26,0,0,1-1.23-1.23v-.82a1.26,1.26,0,0,1,1.23-1.23H740v-5.33a1.26,1.26,0,0,1,1.23-1.23h.82a1.26,1.26,0,0,1,1.23,1.23v5.33h5.33a1.26,1.26,0,0,1,1.23,1.23v.82A1.26,1.26,0,0,1,748.61,117.85Zm-18.52-14.42a8.2,8.2,0,0,1,1.72,5,8.66,8.66,0,0,1-5.57,7.87c.25.82,1.07,1.72,3.11,3.2,3.93,2.87,4.1,8.19,4.1,8.19h0v.41c0,4.26-5.16,7.78-11.47,7.78s-11.47-3.52-11.47-7.78c0-4.1,4.67-7.46,10.65-7.78v-3.36c-4.59-.57-8.19-4.18-8.19-8.52,0-4.75,4.18-8.6,9.42-8.6h.41s9.42-.74,10.65-.82.82,1.64.82,1.64Zm-13.85,24.25c0,2.7,2.79,4.92,6.15,4.92s6.15-2.21,6.15-4.92-2.79-4.92-6.15-4.92S716.24,125,716.24,127.68Zm10-20.07c-.66-3.11-2.95-5.33-5.08-4.92s-3.28,3.36-2.62,6.47,2.95,5.33,5.08,4.92S726.9,110.8,726.24,107.61Z",
            transform: "translate(-244.61 -98.89)",
            fill: "#d34836"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d:
              "M1005.13,209.09a1.08,1.08,0,0,0,.11-1.11c-2.7-6.2-5.41-4.65-13.41-22.37-8.51-18.85-6.39-25.16-9.58-28.76-1.28-1.44-2.55-6.25-4.26-7.09l-1.11-5.08a5.06,5.06,0,0,0,2.93-.52,12.58,12.58,0,0,0,4.29-4.32,34.16,34.16,0,0,0,4.38-8.53,26.27,26.27,0,0,0,1.27-7h0c0-.34,0-.68,0-1h0c0-.85,0-1.7-.09-2.54a11.84,11.84,0,0,0,5.43-2.63,11.31,11.31,0,0,0,3.45-7.09h0c0-.28.06-.55.07-.83s0-.66,0-1a11.18,11.18,0,0,0-4.34-8.44,11,11,0,0,0-7.55-1.8,29.42,29.42,0,0,0-7.61,2.1,24.3,24.3,0,0,0-2.35.94,15.45,15.45,0,0,0-5.51-1,31.36,31.36,0,0,0-9,1.83l-9.93,3c-2.2.67-4.49,1.41-6.14,3.22a5.77,5.77,0,0,0-.51.67l-.16.26c-.09.16-.18.32-.27.48l-.16.35c-.06.13-.11.27-.16.41s-.11.3-.15.45v0a6.49,6.49,0,0,0-.23,1.44v0a5.57,5.57,0,0,0,0,.69V114a4.24,4.24,0,0,0,.76,2.11,6.08,6.08,0,0,0,2.18,1.68,22,22,0,0,0,3,1.31,20.66,20.66,0,0,0-2.83,10.54c0,.22,0,.43,0,.64s0,.42,0,.64c0,10.38,7.34,18.79,16.4,18.79l.27,0,.48,4.41a39.5,39.5,0,0,0-5.6,9,10.8,10.8,0,0,0-2.74,7.75c-.05,4.91,1.81,11.53,2.51,22.76,0,.78.07,1.59.07,2.42a76.18,76.18,0,0,1-.67,9.26c-.18.09-.36.19-.54.27a12.91,12.91,0,0,1-1.3.47,12.65,12.65,0,0,1-2.74.52l-1.64.14c-1.64.15-3.24.33-4.7.56a25.28,25.28,0,0,0-4.88,1.14,5,5,0,0,0-2.3,1.83,7.64,7.64,0,0,0-.64,1.07q-.14.29-.27.59a12.1,12.1,0,0,0-.44,1.27c0,.13-.07.26-.1.39a24.19,24.19,0,0,1-4.45-.46,12.08,12.08,0,0,0-2.11-.29,8.5,8.5,0,0,1,1.21-.3c-6.67-1-10.42,4-10.37,9a9.6,9.6,0,0,0,2.3,6.64c4.06,4.65,9,53,9,53h2.05v5.08c-8.31.58-16.72,1.75-16.72,4.13a8.13,8.13,0,0,0,2.65,5.91c-.12-.17-.24-.35-.35-.53a9.13,9.13,0,0,0,2.12,1.85h45.1V286.94h.07v-1.29s-1.64,0-4.17.05v-4.56h2.05c1.83-18.9-6.16-43.41-7.88-48.4,2.81,0,5.77,0,8.7-.23,12.44.64,33.82.48,38.12-7.74C1010.94,215.69,1007.1,210.82,1005.13,209.09Zm-62.5,22.73s.94.11,2.54.25c1.7,5.53,2.95,14.62,3.83,23.48A172.85,172.85,0,0,0,942.63,231.82ZM960.68,148a14.45,14.45,0,0,0,3.43.42h.13l.1.94A14.83,14.83,0,0,1,960.68,148Zm-7.81,137.85h-.32v-.19s-1.47,0-3.78,0v-4.56h4.1Z",
            transform: "translate(-244.61 -98.89)",
            fill: "url(#ab0efae6-0bd2-4a14-9ba9-bd41b41698aa)"
          }
        }),
        _c("rect", {
          attrs: {
            x: "696.59",
            y: "177.7",
            width: "10.66",
            height: "11.74",
            fill: "#985454"
          }
        }),
        _c("rect", {
          attrs: {
            x: "710.8",
            y: "177.7",
            width: "10.66",
            height: "11.74",
            fill: "#985454"
          }
        }),
        _c("ellipse", {
          attrs: {
            cx: "720.53",
            cy: "30.93",
            rx: "14.21",
            ry: "18.78",
            fill: "#f8c198"
          }
        }),
        _c("polygon", {
          attrs: {
            points:
              "733.85 57.93 721.42 57.93 719.64 39.15 730.3 39.15 733.85 57.93",
            fill: "#f8c198"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M985.61,208.51s7.1,4.69,1.78,16.43-40.85,7-40.85,7,8.88,28.17,7.1,49.3H939.43s-4.26-48.35-7.78-53c-4.53-6-1.1-17.43,7.78-15.08S985.61,208.51,985.61,208.51Z",
            transform: "translate(-244.61 -98.89)",
            fill: "#263238"
          }
        }),
        _c("g", { attrs: { opacity: "0.2" } }, [
          _c("path", {
            attrs: {
              d:
                "M949.44,231.57a131.26,131.26,0,0,0,13.88.92,130.82,130.82,0,0,1-13.78-.59Z",
              transform: "translate(-244.61 -98.89)",
              fill: "#fff"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M946.34,213.24c13.87-.35,39.27-5.15,39.27-5.15a5.29,5.29,0,0,1,.54.43C978.91,209.82,957,213.57,946.34,213.24Z",
              transform: "translate(-244.61 -98.89)",
              fill: "#fff"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M938.64,212.61c-6,1.21-7.92,10.19-4.08,15.25,3.51,4.65,7.78,53,7.78,53h-2.91s-4.26-48.35-7.78-53C927.26,222.06,930.35,211.12,938.64,212.61Z",
              transform: "translate(-244.61 -98.89)",
              fill: "#fff"
            }
          })
        ]),
        _c("path", {
          attrs: {
            d: "M955.41,286S927,286,927,290.67a8.79,8.79,0,0,0,3.55,7h24.86Z",
            transform: "translate(-244.61 -98.89)",
            fill: "#263238"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M955.13,285.8v1.29c-4.95.05-27.45.51-27.45,4.69A8.58,8.58,0,0,0,929,296.4a8.69,8.69,0,0,1-2.3-5.91C926.72,285.8,955.13,285.8,955.13,285.8Z",
            transform: "translate(-244.61 -98.89)",
            fill: "#fff",
            opacity: "0.2"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M999.81,208.51s7.1,4.69,1.78,16.43-40.85,7-40.85,7,8.88,28.17,7.1,49.3H953.64s-1.78-49.3-7.1-54-1.78-16.43,7.1-14.08S999.81,208.51,999.81,208.51Z",
            transform: "translate(-244.61 -98.89)",
            fill: "#263238"
          }
        }),
        _c("path", {
          attrs: {
            d: "M969.62,286s-28.42,0-28.42,4.69a8.79,8.79,0,0,0,3.55,7h24.86Z",
            transform: "translate(-244.61 -98.89)",
            fill: "#263238"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M972.41,149.83h4.32c1.7,0,2.92,5.65,4.13,7.23,2.76,3.6.93,9.91,8.3,28.75,6.93,17.71,9.27,16.16,11.62,22.36S959,214.07,959,214.07s2.25-11.86,1.78-20.56c-1-17.87-4.49-24.07.2-30.26C960.94,163.25,966,149.83,972.41,149.83Z",
            transform: "translate(-244.61 -98.89)",
            fill: "#fccc63"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M965.14,112.33a10.91,10.91,0,0,1,1.46.1c-7.16,1-12.75,9-12.75,18.68s5.59,17.72,12.75,18.68a10.91,10.91,0,0,1-1.46.1c-7.85,0-14.21-8.41-14.21-18.78S957.3,112.33,965.14,112.33Z",
            transform: "translate(-244.61 -98.89)",
            fill: "#fff",
            opacity: "0.2"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M949.65,109.28c1.42-1.8,3.41-2.54,5.32-3.21l8.6-3a24,24,0,0,1,7.79-1.83,11.83,11.83,0,0,1,4.77,1,19.49,19.49,0,0,1,2-.94,22.9,22.9,0,0,1,6.59-2.1A8.57,8.57,0,0,1,991.3,101a11.79,11.79,0,0,1,3.76,8.43,12.52,12.52,0,0,1-3.05,8.92A9.77,9.77,0,0,1,987.3,121a30.68,30.68,0,0,1-1,10.57,36.33,36.33,0,0,1-3.79,8.52,11.89,11.89,0,0,1-3.71,4.32,3.47,3.47,0,0,1-4.66-.76,9.8,9.8,0,0,1-1.38-4.45c-.9-6.34-1.8-13.62-6-17-2.6-2.12-5.51-1.91-8.42-1.89a14.11,14.11,0,0,1-7.54-2.28,5.43,5.43,0,0,1-1.88-1.68C947.63,114.31,948.22,111.08,949.65,109.28Z",
            transform: "translate(-244.61 -98.89)",
            fill: "#ff5252"
          }
        }),
        _c("g", { attrs: { opacity: "0.2" } }, [
          _c("path", {
            attrs: {
              d:
                "M991.3,103.54a8.57,8.57,0,0,0-6.54-1.79,22.9,22.9,0,0,0-6.59,2.1,19.49,19.49,0,0,0-2,.94,11.83,11.83,0,0,0-4.77-1,24,24,0,0,0-7.79,1.83l-8.6,3c-1.9.67-3.89,1.41-5.32,3.21a7.09,7.09,0,0,0-1.3,3,7.2,7.2,0,0,1,1.3-5.59c1.42-1.8,3.41-2.54,5.32-3.21l8.6-3a24,24,0,0,1,7.79-1.83,11.83,11.83,0,0,1,4.77,1,19.49,19.49,0,0,1,2-.94,22.9,22.9,0,0,1,6.59-2.1A8.57,8.57,0,0,1,991.3,101a11.79,11.79,0,0,1,3.76,8.43,13.47,13.47,0,0,1-.07,1.83A11.59,11.59,0,0,0,991.3,103.54Z",
              transform: "translate(-244.61 -98.89)",
              fill: "#fff"
            }
          }),
          _c("path", {
            attrs: {
              d: "M987.38,123.5c0,.34,0,.68,0,1,0-.33,0-.67-.05-1Z",
              transform: "translate(-244.61 -98.89)",
              fill: "#fff"
            }
          })
        ]),
        _c("path", {
          attrs: {
            d:
              "M960.74,193.74c-1-17.87-4.49-24.07.2-30.26,0,0,5-13.42,11.47-13.42h4.32c.51,0,1,.52,1.42,1.28h-2.83c-6.44,0-11.47,13.42-11.47,13.42-4.69,6.19-1.17,12.39-.2,30.26.38,7-1,16-1.55,19.27H959S961.21,202.44,960.74,193.74Z",
            transform: "translate(-244.61 -98.89)",
            fill: "#fff",
            opacity: "0.2"
          }
        }),
        _c("rect", {
          attrs: {
            x: "965.8",
            y: "168.01",
            width: "15.52",
            height: "14.11",
            transform: "translate(1702.52 251.24) rotate(-180)",
            fill: "#fccc63"
          }
        }),
        _c("g", { attrs: { opacity: "0.2" } }, [
          _c("path", {
            attrs: {
              d:
                "M960.54,213.24c13.87-.35,39.27-5.15,39.27-5.15a5.29,5.29,0,0,1,.54.43C993.11,209.82,971.16,213.57,960.54,213.24Z",
              transform: "translate(-244.61 -98.89)",
              fill: "#fff"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M952.89,212.62c-6.09,1.22-8,10.27-3.44,14.25,5.33,4.69,7.1,54,7.1,54h-2.91s-1.78-49.3-7.1-54C941.36,222.31,944.56,211.11,952.89,212.62Z",
              transform: "translate(-244.61 -98.89)",
              fill: "#fff"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M963.65,231.57a131.25,131.25,0,0,0,13.88.92,130.82,130.82,0,0,1-13.78-.59Z",
              transform: "translate(-244.61 -98.89)",
              fill: "#fff"
            }
          })
        ]),
        _c("path", {
          attrs: {
            d:
              "M980.28,178v22.45c0,8.14-5.11,14.65-11.26,14.33-7.5-.39-16.17-.53-17.15.77-.82,1.09-2.79,3.18-4.66,5.12s-4.63,0-4.43-3.22c.24-3.77,1.14-7.79,3.76-8.94,2.12-.93,5.91-1.49,9.72-1.83,6.54-.58,11.59-7.81,11.59-16.47V178Z",
            transform: "translate(-244.61 -98.89)",
            fill: "#f8c198"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M942.77,217.46c.24-3.77,1.14-7.79,3.76-8.94,2.12-.93,5.91-1.49,9.72-1.83,6.54-.58,11.59-7.81,11.59-16.47V178h12.43v1.28H969.79v12.21c0,8.66-5.06,15.89-11.59,16.47-3.81.34-7.61.9-9.72,1.83-2.63,1.16-3.53,5.17-3.76,8.94a4.42,4.42,0,0,0,.66,2.7C943.92,221.33,942.63,219.69,942.77,217.46Z",
            transform: "translate(-244.61 -98.89)",
            fill: "#fff",
            opacity: "0.2"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M969.69,285.8v1.29c-4.95.05-27.45.51-27.45,4.69a8.58,8.58,0,0,0,1.33,4.63,8.69,8.69,0,0,1-2.3-5.91C941.27,285.8,969.69,285.8,969.69,285.8Z",
            transform: "translate(-244.61 -98.89)",
            fill: "#fff",
            opacity: "0.2"
          }
        }),
        _c("rect", {
          attrs: {
            x: "396.58",
            y: "495.16",
            width: "149.52",
            height: "170.36",
            transform: "translate(-246.62 -97.26) rotate(-0.2)",
            fill: "url(#94d1e193-2c6b-46d4-b37d-7a903e0b29da)"
          }
        }),
        _c("rect", {
          attrs: {
            x: "398.32",
            y: "497.71",
            width: "146.02",
            height: "163.3",
            transform: "translate(-246.61 -97.26) rotate(-0.2)",
            fill: "#fff"
          }
        }),
        _c("rect", {
          attrs: {
            id: "b2ae9909-950a-48f1-9bb3-12cdfe841228",
            "data-name": "<Rectangle>",
            x: "404.9",
            y: "501.52",
            width: "132.22",
            height: "117.81",
            transform: "translate(-246.55 -97.26) rotate(-0.2)",
            fill: "#4299e1",
            opacity: "0.2"
          }
        }),
        _c("rect", {
          attrs: {
            x: "595.26",
            y: "495.16",
            width: "149.52",
            height: "170.36",
            transform: "translate(-246.62 -96.57) rotate(-0.2)",
            fill: "url(#3a29d9b6-8e61-4496-a6b5-5d679eb163f7)"
          }
        }),
        _c("rect", {
          attrs: {
            x: "597.01",
            y: "497.71",
            width: "146.02",
            height: "163.3",
            transform: "translate(-246.61 -96.57) rotate(-0.2)",
            fill: "#fff"
          }
        }),
        _c("rect", {
          attrs: {
            id: "7fdfae29-e77a-475e-b3a8-6f398929fc3d",
            "data-name": "<Rectangle>",
            x: "603.59",
            y: "501.52",
            width: "132.22",
            height: "117.81",
            transform: "translate(-246.55 -96.57) rotate(-0.2)",
            fill: "#4299e1",
            opacity: "0.2"
          }
        }),
        _c("rect", {
          attrs: {
            x: "793.95",
            y: "495.16",
            width: "149.52",
            height: "170.36",
            transform: "translate(-246.61 -95.88) rotate(-0.2)",
            fill: "url(#b72660a5-d964-4b97-8da5-9d342e2aedd1)"
          }
        }),
        _c("rect", {
          attrs: {
            x: "795.7",
            y: "497.71",
            width: "146.02",
            height: "163.3",
            transform: "translate(-246.61 -95.88) rotate(-0.2)",
            fill: "#fff"
          }
        }),
        _c("rect", {
          attrs: {
            id: "6236ce0a-1624-423a-a503-2e1c13798f51",
            "data-name": "<Rectangle>",
            x: "802.27",
            y: "501.52",
            width: "132.22",
            height: "117.81",
            transform: "translate(-246.55 -95.88) rotate(-0.2)",
            fill: "#4299e1",
            opacity: "0.2"
          }
        }),
        _c("rect", {
          attrs: {
            x: "395.88",
            y: "287.61",
            width: "149.52",
            height: "170.36",
            transform: "translate(-245.9 -97.26) rotate(-0.2)",
            fill: "url(#3a01118b-2985-4be0-b440-2f096e175d1e)"
          }
        }),
        _c("rect", {
          attrs: {
            x: "397.63",
            y: "290.15",
            width: "146.02",
            height: "163.3",
            transform: "translate(-245.9 -97.26) rotate(-0.2)",
            fill: "#fff"
          }
        }),
        _c("rect", {
          attrs: {
            id: "667f5701-fa69-4488-83e8-f16effbd10e8",
            "data-name": "<Rectangle>",
            x: "404.2",
            y: "293.96",
            width: "132.22",
            height: "117.81",
            transform: "translate(-245.83 -97.26) rotate(-0.2)",
            fill: "#4299e1",
            opacity: "0.2"
          }
        }),
        _c("rect", {
          attrs: {
            x: "791.88",
            y: "284.06",
            width: "149.52",
            height: "170.36",
            transform: "translate(-245.88 -95.89) rotate(-0.2)",
            fill: "url(#709a1cf8-cfb3-4f81-afc1-9e781697792d)"
          }
        }),
        _c("rect", {
          attrs: {
            x: "793.63",
            y: "286.6",
            width: "146.02",
            height: "163.3",
            transform: "translate(-245.88 -95.89) rotate(-0.2)",
            fill: "#fff"
          }
        }),
        _c("rect", {
          attrs: {
            id: "0b67e3b0-1652-4b35-9f46-d1dfe4a7d2ba",
            "data-name": "<Rectangle>",
            x: "800.2",
            y: "290.42",
            width: "132.22",
            height: "117.81",
            transform: "translate(-245.82 -95.89) rotate(-0.2)",
            fill: "#4299e1",
            opacity: "0.2"
          }
        }),
        _c("rect", {
          attrs: {
            x: "594.23",
            y: "286.13",
            width: "149.52",
            height: "170.36",
            transform: "translate(-245.89 -96.58) rotate(-0.2)",
            fill: "url(#7d15d707-8f67-4e66-8d49-1c2771fce49c)"
          }
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: {
            x: "595.98",
            y: "288.67",
            width: "146.02",
            height: "163.3",
            transform: "translate(-245.89 -96.58) rotate(-0.2)",
            fill: "#fff"
          }
        }),
        _c("rect", {
          attrs: {
            id: "608afd5a-06ad-4bbe-9290-34659b79a621",
            "data-name": "<Rectangle>",
            x: "602.55",
            y: "292.49",
            width: "132.22",
            height: "117.81",
            transform: "translate(-245.82 -96.58) rotate(-0.2)",
            fill: "#4299e1",
            opacity: "0.2"
          }
        }),
        _c("rect", {
          attrs: {
            x: "266.77",
            y: "429.15",
            width: "328.7",
            height: "298.71",
            fill: "url(#bc3ac81e-e376-4546-b1a7-de8dd566bde2)"
          }
        }),
        _c("rect", {
          attrs: {
            x: "275.31",
            y: "438.37",
            width: "314.38",
            height: "279.45",
            fill: "#fff"
          }
        }),
        _c("circle", {
          attrs: {
            cx: "296.27",
            cy: "458.56",
            r: "12.42",
            fill: "url(#54f2380e-aa08-4cab-b2db-70f5d4ca6305)"
          }
        }),
        _c("circle", {
          attrs: { cx: "296.27", cy: "458.56", r: "10.09", fill: "#ff9800" }
        }),
        _c("rect", {
          attrs: {
            x: "511.29",
            y: "446.14",
            width: "64.43",
            height: "24.84",
            fill: "url(#6a27cddf-db9d-4050-9f4d-1fd9e794acb5)"
          }
        }),
        _c("rect", {
          attrs: {
            x: "513.62",
            y: "448.46",
            width: "60.55",
            height: "20.18",
            fill: "#f5f5f5"
          }
        }),
        _c("rect", {
          attrs: {
            x: "274.54",
            y: "511.34",
            width: "315.16",
            height: "133.51",
            fill: "#4299e1"
          }
        }),
        _c("circle", {
          attrs: {
            cx: "432.11",
            cy: "578.1",
            r: "83.06",
            fill: "url(#60689c16-abac-4c9a-af50-67b87b3c402d)"
          }
        }),
        _c("circle", {
          attrs: { cx: "432.11", cy: "578.1", r: "80.73", fill: "#fff" }
        }),
        _c("circle", {
          attrs: {
            cx: "432.11",
            cy: "578.1",
            r: "58.99",
            fill: "url(#20aac6d0-8ce9-4ca1-bfa8-28c9e4d79ce9)"
          }
        }),
        _c("circle", {
          attrs: { cx: "432.11", cy: "578.1", r: "57.44", fill: "#4299e1" }
        }),
        _c("circle", {
          attrs: { cx: "432.11", cy: "579.65", r: "34.15", fill: "#4299e1" }
        }),
        _c("circle", {
          attrs: { cx: "432.11", cy: "579.65", r: "34.15", opacity: "0.2" }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }