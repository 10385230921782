var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { class: { "form-group": true } }, [
          _c("label", [_vm._v("Name ")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              { name: "validate", rawName: "v-validate" },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.basic_validation.name,
                expression: "basic_validation.name"
              }
            ],
            class: [
              "form-control",
              { "is-invalid": _vm.errors.has("basic_validation.name") }
            ],
            attrs: {
              "data-vv-rules": "required",
              name: "basic_validation.name",
              "data-vv-as": "Name",
              type: "text"
            },
            domProps: { value: _vm.basic_validation.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.basic_validation, "name", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("basic_validation.name"),
                  expression: "errors.has('basic_validation.name')"
                }
              ],
              staticClass: "invalid-feedback"
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.errors.first("basic_validation.name")) +
                  "\n        "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { class: { "form-group": true } }, [
          _c("label", [_vm._v("Email Address")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              { name: "validate", rawName: "v-validate" },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.basic_validation.email,
                expression: "basic_validation.email"
              }
            ],
            class: [
              "form-control",
              { "is-invalid": _vm.errors.has("basic_validation.email") }
            ],
            attrs: {
              "data-vv-rules": "required|email",
              name: "basic_validation.email",
              "data-vv-as": "Email",
              type: "text"
            },
            domProps: { value: _vm.basic_validation.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.basic_validation, "email", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("basic_validation.email"),
                  expression: "errors.has('basic_validation.email')"
                }
              ],
              staticClass: "invalid-feedback"
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.errors.first("basic_validation.email")) +
                  "\n        "
              )
            ]
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _vm._m(2),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.validateBeforeSubmit($event)
              }
            }
          },
          [
            _c("div", { class: { "form-group": true } }, [
              _c("label", [_vm._v("Name ")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.initial",
                    value: "required",
                    expression: "'required'",
                    modifiers: { initial: true }
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form_validation.name,
                    expression: "form_validation.name"
                  }
                ],
                class: [
                  "form-control",
                  { "is-invalid": _vm.errors.has("form_validation.name") }
                ],
                attrs: {
                  name: "form_validation.name",
                  "data-vv-as": "Name",
                  type: "text"
                },
                domProps: { value: _vm.form_validation.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form_validation, "name", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("form_validation.name"),
                      expression: "errors.has('form_validation.name')"
                    }
                  ],
                  staticClass: "invalid-feedback"
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.errors.first("form_validation.name")) +
                      "\n          "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { class: { "form-group": true } }, [
              _c("label", [_vm._v("Email Address")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.initial",
                    value: "required|email",
                    expression: "'required|email'",
                    modifiers: { initial: true }
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form_validation.email,
                    expression: "form_validation.email"
                  }
                ],
                class: [
                  "form-control",
                  { "is-invalid": _vm.errors.has("form_validation.email") }
                ],
                attrs: {
                  name: "form_validation.email",
                  "data-vv-as": "Email",
                  type: "text"
                },
                domProps: { value: _vm.form_validation.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form_validation, "email", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("form_validation.email"),
                      expression: "errors.has('form_validation.email')"
                    }
                  ],
                  staticClass: "invalid-feedback"
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.errors.first("form_validation.email")) +
                      "\n          "
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { class: { "form-group": true } }, [
              _c("label", [_vm._v("Password")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  { name: "validate", rawName: "v-validate" },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form_validation.password,
                    expression: "form_validation.password"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "password",
                  type: "password",
                  "data-vv-rules": "required",
                  name: "form_validation.password",
                  "data-vv-as": "Password"
                },
                domProps: { value: _vm.form_validation.password },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.form_validation,
                      "password",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("label", [_vm._v("Confirm Password")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|confirmed:form_validation.password",
                  expression: "'required|confirmed:form_validation.password'"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form_validation.cnfpassword,
                  expression: "form_validation.cnfpassword"
                }
              ],
              staticClass: "form-control",
              class: {
                "is-invalid": _vm.errors.has(
                  "form_validation.password_confirmation"
                )
              },
              attrs: {
                "data-vv-as": "Confirm Password",
                name: "form_validation.password_confirmation",
                "data-vv-rules": "required",
                type: "password"
              },
              domProps: { value: _vm.form_validation.cnfpassword },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.form_validation,
                    "cnfpassword",
                    $event.target.value
                  )
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has(
                      "form_validation.password_confirmation"
                    ),
                    expression:
                      "errors.has('form_validation.password_confirmation')"
                  }
                ],
                staticClass: "invalid-feedback"
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.errors.first("form_validation.password_confirmation")
                    ) +
                    "\n        "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { class: { "form-group": true } }, [
              _c("div", { staticClass: "checkbox checkbox-full" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: "required",
                        expression: "'required'",
                        modifiers: { initial: true }
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form_validation.terms,
                        expression: "form_validation.terms"
                      }
                    ],
                    attrs: {
                      type: "checkbox",
                      name: "terms",
                      "data-vv-rules": ""
                    },
                    domProps: {
                      checked: Array.isArray(_vm.form_validation.terms)
                        ? _vm._i(_vm.form_validation.terms, null) > -1
                        : _vm.form_validation.terms
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.form_validation.terms,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.form_validation,
                                "terms",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.form_validation,
                                "terms",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.form_validation, "terms", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(
                    "\n              I Accept Terms & Conditions\n              "
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("terms"),
                          expression: "errors.has('terms')"
                        }
                      ],
                      staticClass: "invalid-feedback"
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.errors.first("terms")) +
                          "\n              "
                      )
                    ]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [_vm._v("Submit")]
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Vee Validation")]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Forms")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Vee Validation")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [
        _vm._v("Basic Validation\n        "),
        _c(
          "a",
          {
            staticClass: "source-link",
            attrs: {
              href: "http://vee-validate.logaretm.com/#basic-example",
              target: "_blank"
            }
          },
          [_vm._v("\n          source\n        ")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [
        _vm._v("Form Validation\n        "),
        _c(
          "a",
          {
            staticClass: "source-link",
            attrs: {
              href: "http://vee-validate.logaretm.com/examples#validate-form",
              target: "_blank"
            }
          },
          [_vm._v("\n          source\n        ")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }