var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-header" }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { slot: "item-title", to: "/admin/sites/" + this.site },
                slot: "item-title"
              },
              [_vm._v("Vezérlőpult")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Fűrészáru")
        ])
      ]),
      _vm._v(" "),
      _c("h1", { staticClass: "d-inline mr-3" }, [_vm._v("Fűrészáru")]),
      _vm._v(" "),
      _vm.screen == "overview"
        ? _c(
            "button",
            {
              staticClass: "btn btn-outline-info btn-sm align-text-bottom",
              on: {
                click: function($event) {
                  _vm.screen = "handling"
                }
              }
            },
            [
              _c("i", { staticClass: "icon-fa icon-fa-gear" }),
              _vm._v(" Kezelés árai\n        ")
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.screen != "loading" && _vm.screen != "overview"
        ? _c(
            "button",
            {
              staticClass: "btn btn-outline-info btn-sm align-text-bottom",
              on: {
                click: function($event) {
                  _vm.screen = "overview"
                }
              }
            },
            [
              _c("i", { staticClass: "icon-fa icon-fa-arrow-left" }),
              _vm._v(" Vissza\n        ")
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.screen == "overview"
        ? _c(
            "button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value:
                    "Minden fűrészáru minden méretének és a kezelés paramétereinek mentése",
                  expression:
                    "'Minden fűrészáru minden méretének és a kezelés paramétereinek mentése'"
                }
              ],
              staticClass:
                "btn btn-success btn-sm align-text-bottom pull-right",
              attrs: { type: "button" },
              on: { click: _vm.save }
            },
            [
              !_vm.saving
                ? _c("i", { staticClass: "icon-fa icon-fa-save" })
                : _vm._e(),
              _vm._v(" "),
              _vm.saving
                ? _c("i", {
                    staticClass: "icon-fa icon-fa-circle-o-notch icon-fa-spin"
                  })
                : _vm._e(),
              _vm._v("\n            Mentés\n        ")
            ]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.screen == "loading"
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", {
            staticClass:
              "icon-fa icon-fa-2x icon-fa-spin icon-fa-circle-o-notch"
          })
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.screen == "overview"
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v("\n                    Gerendák\n                ")
              ]),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "table mb-0" },
                _vm._l(_vm.beams, function(timber) {
                  return _c("tr", { key: timber.id }, [
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default btn-sm",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.selected = timber.id
                              _vm.screen = "detail"
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(timber.name) +
                              "\n                            "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(Object.keys(timber.prices).length) + " méret"
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v("\n                    Pallók\n                ")
              ]),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "table mb-0" },
                _vm._l(_vm.planks, function(timber) {
                  return _c("tr", { key: timber.id }, [
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default btn-sm",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.selected = timber.id
                              _vm.screen = "detail"
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(timber.name) +
                              "\n                            "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(Object.keys(timber.prices).length) + " méret"
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v("\n                    Deszkák\n                ")
              ]),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "table mb-0" },
                _vm._l(_vm.boards, function(timber) {
                  return _c("tr", { key: timber.id }, [
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default btn-sm",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.selected = timber.id
                              _vm.screen = "detail"
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(timber.name) +
                              "\n                            "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(Object.keys(timber.prices).length) + " méret"
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-6" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v("\n                    Lécek\n                ")
              ]),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "table mb-0" },
                _vm._l(_vm.slats, function(timber) {
                  return _c("tr", { key: timber.id }, [
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default btn-sm",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.selected = timber.id
                              _vm.screen = "detail"
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(timber.name) +
                              "\n                            "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(Object.keys(timber.prices).length) + " méret"
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.screen == "detail"
      ? _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _vm._v(_vm._s(_vm.activeTimber.name))
          ]),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "table mb-0" },
            _vm._l(10, function(i) {
              return _c("tr", { key: i }, [
                _c("td", [
                  _vm._v("\n                    " + _vm._s(i) + " méter")
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "input-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.activeTimber.prices[i],
                          expression: "activeTimber.prices[i]"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "number", min: "0" },
                      domProps: { value: _vm.activeTimber.prices[i] },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.activeTimber.prices,
                            i,
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm._m(0, true)
                  ])
                ])
              ])
            }),
            0
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.screen == "handling"
      ? _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _vm._v("Faanyag kezelés árai")
          ]),
          _vm._v(" "),
          _c("table", { staticClass: "table mb-0" }, [
            _c("tr", [
              _c("td", [_vm._v("Gyalulás")]),
              _vm._v(" "),
              _c("td", [
                _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.handling.planing,
                        expression: "handling.planing",
                        modifiers: { number: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "number", min: "0" },
                    domProps: { value: _vm.handling.planing },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.handling,
                          "planing",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm._m(1)
                ])
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [_vm._v("Impregnálás")]),
              _vm._v(" "),
              _c("td", [
                _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.handling.impregnation,
                        expression: "handling.impregnation",
                        modifiers: { number: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "number", min: "0" },
                    domProps: { value: _vm.handling.impregnation },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.handling,
                          "impregnation",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function($event) {
                        return _vm.$forceUpdate()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm._m(2)
                ])
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _vm._v(
          "\n                                Ft\n                            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _vm._v(
          "\n                                Ft\n                            "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _vm._v(
          "\n                                Ft\n                            "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }