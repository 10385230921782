var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.order
    ? _c(
        "div",
        { staticClass: "main-content" },
        [
          _c("div", { staticClass: "page-header" }, [
            _c("ol", { staticClass: "breadcrumb" }, [
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        slot: "item-title",
                        to: "/admin/sites/" + this.site
                      },
                      slot: "item-title"
                    },
                    [_vm._v("Vezérlőpult")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "breadcrumb-item" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: { slot: "item-title", to: "/admin/orders" },
                      slot: "item-title"
                    },
                    [_vm._v("Rendelések")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("li", { staticClass: "breadcrumb-item active" }, [
                _vm._v(_vm._s(_vm.order.code))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("h1", [_vm._v("Rendelés adatlap")]),
              _vm._v(" "),
              _c("div", { staticClass: "btn-group ml-auto" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-success",
                    attrs: { form: "orderEditForm" }
                  },
                  [
                    !_vm.saving.global
                      ? _c("i", { staticClass: "icon-fa icon-fa-save" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.saving.global
                      ? _c("i", {
                          staticClass:
                            "icon-fa icon-fa-circle-o-notch icon-fa-spin"
                        })
                      : _vm._e(),
                    _vm._v("\n                    Mentés\n                ")
                  ]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _vm.order.relations.statusWarning
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _c("strong", [_vm._v("Figyelem!")]),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.statusWarning) +
                          "\n            "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("tabs", { staticClass: "tabs-simple" }, [
                  _c(
                    "form",
                    {
                      attrs: { id: "orderEditForm" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit($event)
                        }
                      }
                    },
                    [
                      _c(
                        "tab",
                        { attrs: { id: "basic", name: "Alap adatok" } },
                        [
                          _vm.subpage == "details"
                            ? _c("div", { staticClass: "row pl-2" }, [
                                _c("div", { staticClass: "col-6" }, [
                                  _c(
                                    "h6",
                                    {
                                      staticClass: "text-uppercase text-center"
                                    },
                                    [_vm._v("Rendelés")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mb-0",
                                        attrs: { for: "codeInput" }
                                      },
                                      [
                                        _vm._v(
                                          "Státusz\n                                        "
                                        ),
                                        _c("small", [_vm._v("—")]),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn-link btn btn-sm pl-0",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                _vm.subpage = "statusHistory"
                                              }
                                            }
                                          },
                                          [
                                            _c("small", [
                                              _vm._v(
                                                "\n                                                    " +
                                                  _vm._s(
                                                    _vm.formatUtcDate(
                                                      _vm.order.relations.status
                                                        .created_at
                                                    )
                                                  ) +
                                                  " óta\n                                                "
                                              )
                                            ])
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "input-group" }, [
                                      _c(
                                        "div",
                                        { staticClass: "input-group-prepend" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "input-group-text",
                                              class: {
                                                "bg-success":
                                                  _vm.saving.status == 2,
                                                "bg-danger":
                                                  _vm.saving.status == 3,
                                                "text-white":
                                                  _vm.saving.status == 2 ||
                                                  _vm.saving.status == 3
                                              }
                                            },
                                            [
                                              _vm.saving.status == 0
                                                ? _c("i", {
                                                    staticClass:
                                                      "icon-fa icon-fa-fw icon-fa-signal"
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.saving.status == 1
                                                ? _c("i", {
                                                    staticClass:
                                                      "icon-fa icon-fa-fw icon-fa-spin icon-fa-circle-o-notch"
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.saving.status == 2
                                                ? _c("i", {
                                                    staticClass:
                                                      "icon-fa icon-fa-fw icon-fa-check"
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.saving.status == 3
                                                ? _c("i", {
                                                    staticClass:
                                                      "icon-fa icon-fa-fw icon-fa-exclamation"
                                                  })
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.order.status,
                                              expression: "order.status"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          on: {
                                            change: [
                                              function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  _vm.order,
                                                  "status",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                              _vm.changeStatus
                                            ]
                                          }
                                        },
                                        _vm._l(_vm.statuses, function(
                                          status,
                                          code
                                        ) {
                                          return _c(
                                            "option",
                                            {
                                              key: code,
                                              domProps: { value: code }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(status) +
                                                  "\n                                            "
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      { attrs: { for: "codeInput" } },
                                      [_vm._v("Azonosító")]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "input-group" }, [
                                      _c(
                                        "div",
                                        { staticClass: "input-group-prepend" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "input-group-text" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "icon-fa icon-fa-hashtag"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          readonly: "readonly"
                                        },
                                        domProps: { value: _vm.order.code }
                                      })
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      { attrs: { for: "codeInput" } },
                                      [_vm._v("Rendelés időpontja")]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "input-group" }, [
                                      _c(
                                        "div",
                                        { staticClass: "input-group-prepend" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "input-group-text" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "icon-fa icon-fa-calendar"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          readonly: "readonly"
                                        },
                                        domProps: {
                                          value: _vm.formatUtcDate(
                                            _vm.order.created_at
                                          )
                                        }
                                      })
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", [_vm._v("Megjegyzés")]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "input-group" }, [
                                      _c(
                                        "div",
                                        { staticClass: "input-group-prepend" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "input-group-text" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "icon-fa icon-fa-comment-o"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.order.note,
                                            expression: "order.note"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: { readonly: _vm.readonly },
                                        domProps: { value: _vm.order.note },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.order,
                                              "note",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("label", [
                                      _vm._v(
                                        "Belső megjegyzés csak az adminoknak"
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "input-group" }, [
                                      _c(
                                        "div",
                                        { staticClass: "input-group-prepend" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "input-group-text" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "icon-fa icon-fa-comment"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.order.adminNote,
                                            expression: "order.adminNote"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        domProps: {
                                          value: _vm.order.adminNote
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.order,
                                              "adminNote",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-6" }, [
                                  _c(
                                    "h6",
                                    {
                                      staticClass: "text-uppercase text-center"
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Megrendelő\n                                    "
                                      ),
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "ml-2",
                                          attrs: {
                                            to:
                                              "/admin/contacts/" +
                                              _vm.order.relations.contact.id
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "icon-fa icon-fa-external-link"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      { attrs: { for: "nameInput" } },
                                      [_vm._v("Név")]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "input-group" }, [
                                      _c(
                                        "div",
                                        { staticClass: "input-group-prepend" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "input-group-text" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "icon-fa icon-fa-user"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.order.relations.contact.name,
                                            expression:
                                              "order.relations.contact.name"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          readonly: _vm.readonly
                                        },
                                        domProps: {
                                          value:
                                            _vm.order.relations.contact.name
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.order.relations.contact,
                                              "name",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      { attrs: { for: "emailInput" } },
                                      [_vm._v("E-mail cím")]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "input-group" }, [
                                      _c(
                                        "div",
                                        { staticClass: "input-group-prepend" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "input-group-text" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "icon-fa icon-fa-at"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.order.relations.contact.email,
                                            expression:
                                              "order.relations.contact.email"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "email",
                                          readonly: _vm.readonly
                                        },
                                        domProps: {
                                          value:
                                            _vm.order.relations.contact.email
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.order.relations.contact,
                                              "email",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      { attrs: { for: "phoneInput" } },
                                      [_vm._v("Telefonszám")]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "input-group" }, [
                                      _c(
                                        "div",
                                        { staticClass: "input-group-prepend" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "input-group-text" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "icon-fa icon-fa-phone"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.order.relations.contact.phone,
                                            expression:
                                              "order.relations.contact.phone"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          readonly: _vm.readonly
                                        },
                                        domProps: {
                                          value:
                                            _vm.order.relations.contact.phone
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.order.relations.contact,
                                              "phone",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      { attrs: { for: "addressInput" } },
                                      [_vm._v("Cím")]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "input-group" }, [
                                      _c(
                                        "div",
                                        { staticClass: "input-group-prepend" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "input-group-text" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "icon-fa icon-fa-map-marker"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.order.relations.contact
                                                .address,
                                            expression:
                                              "order.relations.contact.address"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "addressInput",
                                          readonly: _vm.readonly
                                        },
                                        domProps: {
                                          value:
                                            _vm.order.relations.contact.address
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.order.relations.contact,
                                              "address",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ])
                                  ])
                                ])
                              ])
                            : _c("div", { staticClass: "pl-2" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-link btn-sm mb-3",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        _vm.subpage = "details"
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "icon-fa icon-fa-arrow-left"
                                    }),
                                    _vm._v(
                                      "\n                                Vissza\n                            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("h6", { staticClass: "text-uppercase" }, [
                                  _vm._v("Státusz előzmények")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  { staticClass: "table table-striped" },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", [_vm._v("Státusz")]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("Ki állította be?")]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("Mikor?")])
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.order.relations.statusHistory,
                                        function(status, key) {
                                          return _c("tr", { key: key }, [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.statuses[status.status]
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  status.user
                                                    ? status.user.name
                                                    : ""
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatUtcDate(
                                                    status.created_at
                                                  )
                                                )
                                              )
                                            ])
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  ]
                                )
                              ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "tab",
                        { attrs: { id: "products", name: "Termékek" } },
                        [
                          !_vm.readonlyProducts
                            ? _c("div", { staticClass: "mb-3" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-link btn-sm",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        _vm.addForm = !_vm.addForm
                                      }
                                    }
                                  },
                                  [
                                    !_vm.addForm
                                      ? _c("i", {
                                          staticClass: "icon-fa icon-fa-plus"
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.addForm
                                      ? _c("i", {
                                          staticClass:
                                            "icon-fa icon-fa-caret-up"
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.addForm
                                            ? "Elrejtés"
                                            : "Termék hozzáadása"
                                        ) +
                                        "\n                            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.addForm
                                  ? _c(
                                      "div",
                                      { staticClass: "bg-light p-2" },
                                      [
                                        _c("multiselect", {
                                          attrs: {
                                            options: _vm.products,
                                            label: "title",
                                            "track-by": "id",
                                            "deselect-label": "Kiválasztott",
                                            placeholder:
                                              "Válassz ki egy terméket",
                                            "select-label":
                                              "Enter a kiválasztáshoz",
                                            "selected-label": "Kiválasztott",
                                            "allow-empty": false
                                          },
                                          on: {
                                            "search-change": _vm.loadProducts
                                          },
                                          model: {
                                            value: _vm.selectedProduct,
                                            callback: function($$v) {
                                              _vm.selectedProduct = $$v
                                            },
                                            expression: "selectedProduct"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _vm.selectedProduct &&
                                        _vm.selectedProduct.product_type == 2
                                          ? _c("multiselect", {
                                              attrs: {
                                                options:
                                                  _vm.selectedProduct.relations
                                                    .variations,
                                                label: "title",
                                                "track-by": "id",
                                                "deselect-label":
                                                  "Kiválasztott",
                                                placeholder:
                                                  "Válassz ki egy variációt",
                                                "select-label":
                                                  "Enter a kiválasztáshoz",
                                                "selected-label":
                                                  "Kiválasztott",
                                                "allow-empty": false
                                              },
                                              model: {
                                                value: _vm.selectedVariation,
                                                callback: function($$v) {
                                                  _vm.selectedVariation = $$v
                                                },
                                                expression: "selectedVariation"
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-sm btn-info nowrap mt-2",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                return _vm.addProduct()
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "icon-fa icon-fa-plus"
                                            }),
                                            _vm._v(
                                              "\n                                    Hozzáadás\n                                "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "table",
                            { staticClass: "table" },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", [_vm._v("Név")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Mennyiség")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Egységár")]),
                                  _vm._v(" "),
                                  _c("th", [_vm._v("Ár")])
                                ])
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.order.relations.products, function(
                                product,
                                key
                              ) {
                                return _c(
                                  "tbody",
                                  { key: key },
                                  [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        [
                                          product.product_type == 1
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass: "btn btn-sm",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.toggleProductRow(
                                                        key
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "icon-fa icon-fa-fw",
                                                    class:
                                                      _vm.opened == key
                                                        ? "icon-fa-caret-down"
                                                        : "icon-fa-caret-right"
                                                  })
                                                ]
                                              )
                                            : _c("span", {
                                                staticClass: "mr-4 ml-3"
                                              }),
                                          _vm._v(" "),
                                          _c("i", {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: {
                                                  content:
                                                    "" +
                                                    _vm.types[
                                                      product.product_type
                                                    ].name
                                                },
                                                expression:
                                                  "{content: `${types[product.product_type].name}` }"
                                              }
                                            ],
                                            staticClass: "icon-fa icon-fa-fw",
                                            class:
                                              _vm.types[product.product_type]
                                                .icon
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to:
                                                  "/admin/products/" +
                                                  product.publication_id +
                                                  "/edit"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(product.title) +
                                                  "\n                                        "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticStyle: { width: "12em" } },
                                        [
                                          !_vm.readonlyProducts
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "form-group mb-0"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-group input-group-sm spinbox"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-group-prepend"
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "tooltip",
                                                                  rawName:
                                                                    "v-tooltip",
                                                                  value:
                                                                    "Termék eltávolítása",
                                                                  expression:
                                                                    "'Termék eltávolítása'"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "btn btn-danger",
                                                              attrs: {
                                                                type: "button"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.remove(
                                                                    product.id
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "icon-fa icon-fa-fw icon-fa-trash w-100"
                                                              })
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "tooltip",
                                                                  rawName:
                                                                    "v-tooltip",
                                                                  value:
                                                                    "Mennyiség csökkentése",
                                                                  expression:
                                                                    "'Mennyiség csökkentése'"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "btn btn-danger",
                                                              attrs: {
                                                                type: "button"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.decrease(
                                                                    product.id
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "icon-fa icon-fa-fw icon-fa-minus w-100"
                                                              })
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              product.amount,
                                                            expression:
                                                              "product.amount"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          type: "number",
                                                          min: "0",
                                                          step: "any"
                                                        },
                                                        domProps: {
                                                          value: product.amount
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              product,
                                                              "amount",
                                                              $event.target
                                                                .value
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-group-append"
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "tooltip",
                                                                  rawName:
                                                                    "v-tooltip",
                                                                  value:
                                                                    "Mennyiség növelése",
                                                                  expression:
                                                                    "'Mennyiség növelése'"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "btn btn-success",
                                                              attrs: {
                                                                type: "button"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.increase(
                                                                    product.id
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "icon-fa icon-fa-fw icon-fa-plus w-100"
                                                              })
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _c("div", [
                                                _vm._v(_vm._s(product.amount))
                                              ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      !_vm.readonlyProducts
                                        ? _c("td", [
                                            _vm.hasCustomPrice(product)
                                              ? _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value:
                                                          "Egyedi ár van beállítva",
                                                        expression:
                                                          "`Egyedi ár van beállítva`"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "btn btn-sm btn-info",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.setPriceModal(
                                                          product
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm.usedPrice(product)
                                                        ) +
                                                        " Ft\n                                        "
                                                    )
                                                  ]
                                                )
                                              : product.priceWhenOrdered ===
                                                  null && product.price === null
                                              ? _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value:
                                                          "Nincs ár beállítva",
                                                        expression:
                                                          "`Nincs ár beállítva`"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "btn btn-sm btn-danger",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.setPriceModal(
                                                          product
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "icon-fa icon-fa-exclamation-triangle"
                                                    }),
                                                    _vm._v(
                                                      "\n                                            Nincs Ár!!\n                                        "
                                                    )
                                                  ]
                                                )
                                              : product.priceWhenOrdered ===
                                                null
                                              ? _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value:
                                                          "Rendeléskor nem volt ár beállítva",
                                                        expression:
                                                          "`Rendeléskor nem volt ár beállítva`"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "btn btn-sm btn-danger",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.setPriceModal(
                                                          product
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "icon-fa icon-fa-exclamation-triangle"
                                                    }),
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm.usedPrice(product)
                                                        ) +
                                                        " Ft\n                                        "
                                                    )
                                                  ]
                                                )
                                              : product.priceWhenOrdered !=
                                                product.price
                                              ? _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName: "v-tooltip",
                                                        value:
                                                          "Rendelés óta megváltozott az ár",
                                                        expression:
                                                          "`Rendelés óta megváltozott az ár`"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "btn btn-sm btn-warning",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.setPriceModal(
                                                          product
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm.usedPrice(product)
                                                        ) +
                                                        " Ft\n                                        "
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-primary",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.setPriceModal(
                                                          product
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm.usedPrice(product)
                                                        ) +
                                                        " Ft\n                                        "
                                                    )
                                                  ]
                                                )
                                          ])
                                        : _c("td", [
                                            _vm._v(
                                              _vm._s(_vm.usedPrice(product)) +
                                                " Ft"
                                            )
                                          ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.usedPrice(product) *
                                              product.amount
                                          ) + " Ft"
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    key == _vm.opened &&
                                    product.product_type == 1
                                      ? _vm._l(product.products, function(
                                          bundle
                                        ) {
                                          return _c("tr", { key: bundle.id }, [
                                            _c("td", [
                                              _c("i", {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: {
                                                      content:
                                                        "" +
                                                        _vm.types[
                                                          bundle.product_type
                                                        ].name
                                                    },
                                                    expression:
                                                      "{content: `${types[bundle.product_type].name}` }"
                                                  }
                                                ],
                                                staticClass:
                                                  "icon-fa icon-fa-fw",
                                                class:
                                                  _vm.types[bundle.product_type]
                                                    .icon
                                              }),
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(bundle.title) +
                                                  "\n                                        "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(bundle.amount) +
                                                  "\n                                        "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(bundle.price) +
                                                  " Ft\n                                        "
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                            " +
                                                  _vm._s(
                                                    bundle.price * bundle.amount
                                                  ) +
                                                  " Ft\n                                        "
                                              )
                                            ])
                                          ])
                                        })
                                      : _vm._e()
                                  ],
                                  2
                                )
                              }),
                              _vm._v(" "),
                              _c("tfoot", [
                                _c("tr", { staticClass: "border-top" }, [
                                  _c("th", { attrs: { colspan: "3" } }, [
                                    _vm._v("Összesen")
                                  ]),
                                  _vm._v(" "),
                                  !_vm.validPriceSum
                                    ? _c("th", [
                                        !_vm.validPriceSum
                                          ? _c(
                                              "span",
                                              { staticClass: "text-danger" },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "icon-fa icon-fa-exclamation-triangle"
                                                }),
                                                _vm._v(
                                                  "\n                                            Érvénytelen\n                                        "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    : _vm.hasPriceWarning
                                    ? _c(
                                        "th",
                                        {
                                          directives: [
                                            {
                                              name: "tooltip",
                                              rawName: "v-tooltip",
                                              value:
                                                "Egy vagy több tételnél megváltozott az ár a rendelés óta",
                                              expression:
                                                "'Egy vagy több tételnél megváltozott az ár a rendelés óta'"
                                            }
                                          ],
                                          staticClass: "text-warning"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "icon-fa icon-fa-exclamation-triangle"
                                          }),
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(_vm.priceSum) +
                                              " Ft\n                                    "
                                          )
                                        ]
                                      )
                                    : _c("th", [
                                        _vm._v(_vm._s(_vm.priceSum) + " Ft")
                                      ])
                                ])
                              ])
                            ],
                            2
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("set-price-modal", {
            ref: "setPriceModal",
            attrs: { product: _vm.modalProduct }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }