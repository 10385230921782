var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sweet-modal", { ref: "modal" }, [
    _c("form", [
      _c("div", { staticClass: "form-group row" }, [
        _c(
          "label",
          {
            staticClass: "col-sm-2 col-form-label",
            attrs: { for: "inputEmailTo" }
          },
          [_vm._v("Email To")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-10" }, [
          _c("input", {
            staticClass: "form-control",
            attrs: { id: "inputEmailTo", type: "email" }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c(
          "label",
          {
            staticClass: "col-sm-2 col-form-label",
            attrs: { for: "inputSubject" }
          },
          [_vm._v("Subject")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-10" }, [
          _c("input", {
            staticClass: "form-control",
            attrs: { id: "inputSubject", type: "text" }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group row" }, [
        _c(
          "label",
          {
            staticClass: "col-sm-2 col-form-label",
            attrs: { for: "inputSubject" }
          },
          [_vm._v("\n        Message\n      ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-10" }, [
          _c("textarea", { staticClass: "form-control" })
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-default",
        attrs: { slot: "button", type: "button", "data-dismiss": "modal" },
        on: { click: _vm.closeModal },
        slot: "button"
      },
      [_vm._v("\n    Cancel\n  ")]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-primary",
        attrs: { slot: "button", type: "button" },
        on: { click: _vm.closeModal },
        slot: "button"
      },
      [_vm._v("\n    Send Mail\n  ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }