<template>
    <div class="card my-1 mx-3">

        <div class="card-header d-flex align-items-center">
            <span v-tooltip="tooltipHeader">
                {{ textTitle }}
                <i class="icon-fa icon-fa-question-circle"></i>
            </span>

            <button type="button"
                class="btn btn-sm btn-info ml-auto"
                @click="addItem()">
                <i class="icon-fa icon-fa-plus"></i>
                {{ textNew }}
            </button>
        </div>

        <div class="card-body">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th style="width:50%">Azonosító</th>
                        <th style="width:50%">Érték</th>
                        <th style="width:3em">Mértékegység</th>
                        <th style="width:1.5em"></th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(attribute, key) in attributes"
                        :key="key">
                        <td>
                            <div class="form-group mb-0">
                                <multiselect v-model="attribute.name"
                                    v-if="availableNames"
                                    :options="availableNames"
                                    :multiple="false"
                                    :taggable="true"
                                    class="form-control p-0 border-0"
                                    :class="{'is-invalid': hasDuplicate(attribute.name, key)}"
                                    @tag="createAttributeName(attribute, ...arguments)"
                                    @input="updateAttributeUnit(attribute, ...arguments)"
                                    :allow-empty="false">
                                </multiselect>

                                <div class="invalid-feedback"
                                    v-if="hasDuplicate(attribute.name, key)">
                                    Duplikált kulcs!
                                </div>
                            </div>
                        </td>
                        <td class="align-top">
                            <input type="text"
                                class="form-control"
                                v-model="attribute.value">
                        </td>
                        <td class="align-top">
                            <input type="text"
                                v-if="getUnitTypeForAttribute(attribute.name) != 'object'"
                                :disabled="getUnitTypeForAttribute(attribute.name) != 'custom'"
                                class="form-control"
                                v-model="attribute.unit">

                            <select class="form-control"
                                v-model="attribute.unit"
                                v-if="getUnitTypeForAttribute(attribute.name) == 'object'">
                                <option v-for="unit in units[attribute.name]"
                                    :key="unit"
                                    :value="unit">
                                    {{ unit }}
                                </option>
                            </select>
                        </td>
                        <td class="align-top">
                            <button class="btn btn-danger"
                                type="button"
                                @click="removeItem(key)">
                                <i class="icon-fa icon-fa-trash" style="width:auto"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    props: {
        attributes: { type: [Array], default: () => [] },
        units: { type: [Object, null], default: () => {} },
        tooltipHeader: { type: [String,Object], default: () => '' },
        textTitle: { type: [String], default: () => 'Tulajdonságok' },
        textNew: { type: [String], default: () => 'Új' },
    },
    components: {
        Multiselect,
    },
    data () {
        return {
            customNames: []
        }
    },
    computed: {
        availableNames(){
            let existing = this.attributes.map(o => {
                return o.name
            }).filter(o => {
                return o != ''
            })

            return Object.keys(this.units)
                .concat(this.customNames)
                .concat(existing)
                .filter((v, i, a) => a.indexOf(v) === i)
        }
    },
    methods: {
        hasDuplicate (name, index) {
            for (const attribute in this.attributes) {
                if(index != attribute && this.attributes[attribute].name == name){
                    return true
                }
            }

            return false
        },
        addItem(){
            this.attributes.push({
                name: '',
                value: '',
                unit: '',
            })
            this.$forceUpdate()
        },
        removeItem(index){
            this.attributes.splice(index, 1)
            this.$forceUpdate()
        },

        createAttributeName(item, name){
            this.customNames.push(name)
            item.name = name
            item.unit = ''
        },
        updateAttributeUnit(item){
            item.unit = this.getUnitTypeForAttribute(item.name) == 'object'
                ? this.units[item.name][0]
                : this.units[item.name]
        },
        getUnitTypeForAttribute(name){
            if(this.units[name]){
                return (typeof this.units[name]).toLowerCase()
            }

            return 'custom'
        },
    }
}
</script>

<style>

</style>
