var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card my-1 mx-3" }, [
    _c("div", { staticClass: "card-header d-flex align-items-center" }, [
      _c("span", [_vm._v("Képgaléria")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-info ml-auto",
          attrs: { type: "button" },
          on: { click: _vm.onAdd }
        },
        [
          _c("i", { staticClass: "icon-fa icon-fa-plus" }),
          _vm._v("\n            Kép hozzáadása\n        ")
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      !_vm.images || _vm.images.length == 0
        ? _c("p", [_vm._v("Nincs kép")])
        : _vm._e(),
      _vm._v(" "),
      _vm.images && _vm.images.length
        ? _c(
            "ul",
            { staticClass: "list-unstyled" },
            _vm._l(_vm.images, function(image, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "media mb-1 p-2",
                  class: {
                    "bg-secondary text-white": _vm.highlights && image.highlight
                  }
                },
                [
                  _c("div", { staticClass: "media-image mr-3 w-25" }, [
                    _c("img", {
                      staticClass: "w-100",
                      attrs: { src: image.dataUrl, alt: image.name }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "media-body" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12 col-lg-6" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Alt szöveg")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: image.name,
                                expression: "image.name"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: image.name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(image, "name", $event.target.value)
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", [_vm._v("Link")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: image.link,
                                expression: "image.link"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "link" },
                            domProps: { value: image.link },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(image, "link", $event.target.value)
                              }
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-12 col-lg-6 d-flex flex-column" },
                        [
                          _c("label", [_vm._v("Felirat")]),
                          _vm._v(" "),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: image.caption,
                                expression: "image.caption"
                              }
                            ],
                            staticClass: "form-control flex-grow-1 mb-3",
                            domProps: { value: image.caption },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(image, "caption", $event.target.value)
                              }
                            }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex" }, [
                      _vm.highlights && !image.highlight
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-light",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.highlightImage(image)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "icon-fa icon-fa-star-o"
                              }),
                              _vm._v(
                                "\n                            Kiemelés\n                        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.highlights && image.highlight
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-light",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.unhighlightImage(image)
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "icon-fa icon-fa-star" }),
                              _vm._v(
                                "\n                            Kiemelés eltávolítása\n                        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger ml-auto",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.removeImage(index)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "icon-fa icon-fa-minus" }),
                          _vm._v(
                            "\n                            Eltávolítás\n                        "
                          )
                        ]
                      )
                    ])
                  ])
                ]
              )
            }),
            0
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }