var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "color-pickers-demo" }, [
    _c(
      "div",
      { staticClass: "demo-div", style: { background: _vm.selectedHex } },
      [_vm._v("\n    Colors applied here\n  ")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xl-7 mb-5" },
        [
          _c("h5", { staticClass: "section-semi-title" }, [
            _vm._v("Photoshop")
          ]),
          _vm._v(" "),
          _c("photoshop-picker", {
            model: {
              value: _vm.colors,
              callback: function($$v) {
                _vm.colors = $$v
              },
              expression: "colors"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-5 mb-5 " },
        [
          _c("h5", { staticClass: "section-semi-title" }, [_vm._v("Swatches")]),
          _vm._v(" "),
          _c("swatches-picker", {
            model: {
              value: _vm.colors,
              callback: function($$v) {
                _vm.colors = $$v
              },
              expression: "colors"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xl-4 mb-5 " },
        [
          _c("h5", { staticClass: "section-semi-title" }, [_vm._v("Sketch")]),
          _vm._v(" "),
          _c("sketch-picker", {
            model: {
              value: _vm.colors,
              callback: function($$v) {
                _vm.colors = $$v
              },
              expression: "colors"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-4 mb-5 " },
        [
          _c("h5", { staticClass: "section-semi-title" }, [_vm._v("Chrome")]),
          _vm._v(" "),
          _c("chrome-picker", {
            model: {
              value: _vm.colors,
              callback: function($$v) {
                _vm.colors = $$v
              },
              expression: "colors"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-4 mb-5 " },
        [
          _c("h5", { staticClass: "section-semi-title" }, [_vm._v("Compact")]),
          _vm._v(" "),
          _c("compact-picker", {
            model: {
              value: _vm.colors,
              callback: function($$v) {
                _vm.colors = $$v
              },
              expression: "colors"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xl-6 mb-5 " },
        [
          _c("h5", { staticClass: "section-semi-title" }, [_vm._v("Slider")]),
          _vm._v(" "),
          _c("slider-picker", {
            model: {
              value: _vm.colors,
              callback: function($$v) {
                _vm.colors = $$v
              },
              expression: "colors"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-3 mb-5 " },
        [
          _c("h5", { staticClass: "section-semi-title" }, [_vm._v("Material")]),
          _vm._v(" "),
          _c("material-picker", {
            model: {
              value: _vm.colors,
              callback: function($$v) {
                _vm.colors = $$v
              },
              expression: "colors"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-xl-3 mb-5 " },
        [
          _c("h5", { staticClass: "section-semi-title" }, [
            _vm._v("Grayscale")
          ]),
          _vm._v(" "),
          _c("grayscale-picker", {
            model: {
              value: _vm.colors,
              callback: function($$v) {
                _vm.colors = $$v
              },
              expression: "colors"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }