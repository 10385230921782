var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-page login-custom" }, [
    _c("div", { staticClass: "site-wrapper" }, [
      _c("div", { staticClass: "login-box" }, [
        _c(
          "div",
          { staticClass: "box-wrapper" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("router-view"),
            _vm._v(" "),
            _c("div", { staticClass: "page-copyright" }, [
              _c("p", [_vm._v("ITZen © " + _vm._s(new Date().getFullYear()))])
            ])
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-main" }, [
      _c("a", { attrs: { href: "/login" } }, [
        _c("img", {
          staticStyle: { width: "200px" },
          attrs: { src: "assets/img/bmat-logo.png", alt: "bmat.hu" }
        }),
        _vm._v(" "),
        _c(
          "h1",
          { staticStyle: { "font-size": "2.5rem", "font-weight": "300" } },
          [_c("b", [_vm._v("Content")]), _vm._v("Hub\n              ")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }