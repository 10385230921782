var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.product.id
    ? _c("div", { staticClass: "card my-1 mx-3" }, [
        _c("div", { staticClass: "card-header d-flex align-items-center" }, [
          _c(
            "span",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value:
                    "A termékhez tartozó variációk. Az egyes variációk árait a tulajdonságok mentése után lehet megadni.",
                  expression:
                    "'A termékhez tartozó variációk. Az egyes variációk árait a tulajdonságok mentése után lehet megadni.'"
                }
              ]
            },
            [
              _vm._v("\n            Variációk\n            "),
              _c("i", { staticClass: "icon-fa icon-fa-question-circle" })
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-sm btn-info ml-auto",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.addItem()
                }
              }
            },
            [
              _c("i", { staticClass: "icon-fa icon-fa-plus" }),
              _vm._v("\n            Új variáció hozzáadása\n        ")
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _vm.product.relations.variations.length == 0
            ? _c("p", [_vm._v("Nincs variáció!")])
            : _vm._e(),
          _vm._v(" "),
          _vm.product.relations.variations.length
            ? _c(
                "div",
                { staticClass: "accordion" },
                _vm._l(_vm.product.relations.variations, function(
                  variation,
                  key
                ) {
                  return _c("div", { key: key, staticClass: "card mb-0" }, [
                    _c("div", { staticClass: "card-header" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.toggle(key)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "icon-fa icon-fa-fw",
                            class:
                              _vm.opened == key
                                ? "icon-fa-caret-down"
                                : "icon-fa-caret-right"
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(variation.title) +
                            "\n                        "
                        ),
                        variation.currentPrice
                          ? _c("small", [
                              _vm._v(
                                _vm._s(variation.currentPrice.price) + " Ft"
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: "Variáció eltávolítása",
                              expression: "'Variáció eltávolítása'"
                            }
                          ],
                          staticClass: "btn btn-danger pull-right",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.removeItem(key)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "icon-fa icon-fa-trash w-100"
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _vm.opened == key
                      ? _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [_vm._v("Variáció neve")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: variation.title,
                                    expression: "variation.title"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: variation.title },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      variation,
                                      "title",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c(
                              "tabs",
                              [
                                _vm.product.id
                                  ? _c(
                                      "tab",
                                      {
                                        attrs: {
                                          id: "properties-" + key,
                                          name: "Tulajdonságok"
                                        }
                                      },
                                      [
                                        variation.attributes
                                          ? _c("attribute-editor", {
                                              attrs: {
                                                attributes:
                                                  variation.attributes,
                                                units: _vm.units,
                                                tooltipHeader: _vm.tooltipHeader
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                variation.id
                                  ? _c(
                                      "tab",
                                      {
                                        attrs: {
                                          id: "prices-" + key,
                                          name: "Árak"
                                        }
                                      },
                                      [
                                        _c("price-editor", {
                                          attrs: {
                                            prices: variation.prices,
                                            current: variation.currentPrice,
                                            product: _vm.product,
                                            variation: variation
                                          },
                                          on: {
                                            updateCurrent:
                                              _vm.updateCurrentPrice
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                }),
                0
              )
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }