var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sweet-modal",
    {
      ref: "copyModal",
      attrs: { "overlay-theme": "dark", "modal-theme": "dark" }
    },
    [
      _c("form", { staticClass: "text-left" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "targetSelect" } }, [
            _vm._v("Cél domain")
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.copy.site,
                  expression: "copy.site"
                }
              ],
              staticClass: "form-control",
              attrs: { name: "target", id: "targetSelect" },
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.copy,
                      "site",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                  _vm.setCopyNames
                ],
                input: _vm.setCopyNames
              }
            },
            _vm._l(_vm.sites, function(option) {
              return _c(
                "option",
                { key: option.id, domProps: { value: option.id } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(option.domain) +
                      "\n                    "
                  ),
                  option.id == _vm.site
                    ? _c("span", [
                        _vm._v(
                          "\n                        — aktuális domain\n                    "
                        )
                      ])
                    : _vm._e()
                ]
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _vm.copy.title
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "copyTitleInput" } }, [
                _vm._v("Másolat név")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.copy.title,
                    expression: "copy.title"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "copyTitleInput" },
                domProps: { value: _vm.copy.title },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.copy, "title", $event.target.value)
                  }
                }
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.copy.slug
          ? _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "copySlugInput" } }, [
                _vm._v("Másolat slug")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.copy.slug,
                    expression: "copy.slug"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", id: "copySlugInput" },
                domProps: { value: _vm.copy.slug },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.copy, "slug", $event.target.value)
                  }
                }
              })
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-success",
          attrs: { slot: "button", type: "button" },
          on: { click: _vm.copyItem },
          slot: "button"
        },
        [_vm._v("\n        Másolás\n    ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }