<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item"><router-link slot="item-title" to="/admin/posts">Bejegyzések</router-link></li>
                <li class="breadcrumb-item active">{{ post.title }}</li>
            </ol>

            <div class="d-flex align-items-center">
                <h1>Bejegyzés szerkesztése</h1>

                <div class="btn-group ml-auto">
                    <v-dropdown active-url="/" theme-light>
                        <a slot="activator" href="#" @click.prevent>
                            <button class="btn btn-sm btn-light dropdown-toggle"
                                type="button">
                                Műveletek
                            </button>
                        </a>

                        <v-dropdown-item>
                            <a href="#" @click.prevent="$refs.copyModal.open()">Másolás</a>
                        </v-dropdown-item>
                    </v-dropdown>

                    <button class="btn btn-sm btn-success" form="postEditForm">
                        <i class="icon-fa icon-fa-save" v-if="!saving"></i>
                        <i class="icon-fa icon-fa-circle-o-notch icon-fa-spin" v-if="saving"></i>
                        Mentés
                    </button>
                </div>
            </div>
        </div>

        <post-editor :original="post"
            :save="saveCallback"
            @saving="onSaveStart">
        </post-editor>

        <copy-modal :original="post" ref="copyModal"></copy-modal>
    </div>
</template>

<script>
import ls from '../../services/ls.js'
import postEditor from './PostEditor'
import CopyModal from '../../components/CopyModal'

export default {
    components: {
        CopyModal,
        postEditor,
    },
    data () {
        return {
            post: {
                title: 'Új bejegyzés',
                slug: 'uj-bejegyzes',
                description: '',
                published_at: null,
                canonical: 'posts/uj-bejegyzes',
                images: [],
                category: null,
                relations: [],
                tags: [],
            },
            site: null,
            saving: false,
        }
    },
    methods: {
        loadPost(){
            axios.get(`/api/admin/publications/${this.$route.params.post}`, {
                params: {
                    with: 'tags,category,content,images'
                }
            }).then(results => {
                this.post = results.data
            })
        },
        onSaveStart(){
            this.saving = true
        },
        saveCallback(post, content){
            post.site = this.site
            post.content = content

            axios.patch(`/api/admin/publications/${this.post.id}`, post)
                .then(response => {
                    this.post = post
                    toastr.success('Oldal elmentve', 'Siker')
                }).catch(err => {
                    toastr.error('Hiba a mentés közben', 'Hiba')
                }).finally(() => {
                    this.saving = false
                })
        }
    },
    created() {
        this.site = ls.get('site')

        this.loadPost()
    }
}
</script>

<style>

</style>
