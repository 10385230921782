var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [_c("multi-select-demo")], 1)
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _vm._m(2),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [_c("text-mask-demo")], 1)
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _vm._m(3),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-xl-4 mb-4" },
            [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("Default Date Picker")
              ]),
              _vm._v(" "),
              _c("datepicker", {
                attrs: {
                  "input-class": "form-control",
                  placeholder: "Select Date"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-4 mb-4" }, [
            _c("h5", { staticClass: "section-semi-title" }, [
              _vm._v("Formate Date Picker")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("Result")]),
                _vm._v(" "),
                _c("datepicker", {
                  attrs: { format: _vm.format, "input-class": "form-control" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("Formats")]),
                _vm._v(" "),
                _c("multi-select", {
                  attrs: {
                    options: _vm.FormatOptions,
                    "allow-empty": false,
                    "show-labels": false,
                    searchable: true,
                    placeholder: "Pick a Format"
                  },
                  model: {
                    value: _vm.format,
                    callback: function($$v) {
                      _vm.format = $$v
                    },
                    expression: "format"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xl-4 mb-4" },
            [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("Inline Date Picker")
              ]),
              _vm._v(" "),
              _c("datepicker", { attrs: { inline: true } })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xl-4 mb-4" }, [
            _c("h5", { staticClass: "section-semi-title" }, [
              _vm._v("Highlited Date")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("From")]),
                _vm._v(" "),
                _c("datepicker", {
                  attrs: { "input-class": "form-control" },
                  on: { selected: _vm.highlightFrom }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("To")]),
                _vm._v(" "),
                _c("datepicker", {
                  attrs: { "input-class": "form-control" },
                  on: { selected: _vm.highlightTo }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("Result")]),
                _vm._v(" "),
                _c("datepicker", {
                  attrs: {
                    highlighted: _vm.highlighted,
                    "input-class": "form-control"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-xl-4 mb-4" }, [
            _c("h5", { staticClass: "section-semi-title" }, [
              _vm._v("Disable Specific Range Of Date")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("From")]),
                _vm._v(" "),
                _c("datepicker", {
                  attrs: { "input-class": "form-control" },
                  on: { selected: _vm.disableFrom }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("To")]),
                _vm._v(" "),
                _c("datepicker", {
                  attrs: { "input-class": "form-control" },
                  on: { selected: _vm.disableTo }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("Result")]),
                _vm._v(" "),
                _c("datepicker", {
                  attrs: {
                    "disabled-dates": _vm.disabledDates,
                    "input-class": "form-control"
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _vm._m(4),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [_c("color-pickers-demo")], 1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Advanced Elements")]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Forms")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Advanced Elements")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [
        _vm._v("Multi Select\n        "),
        _c(
          "a",
          {
            staticClass: "source-link",
            attrs: { href: "https://vue-multiselect.js.org/", target: "_blank" }
          },
          [_vm._v("\n          source\n        ")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [
        _vm._v("Text Mask\n        "),
        _c(
          "a",
          {
            staticClass: "source-link",
            attrs: {
              href: "https://text-mask.github.io/text-mask/",
              target: "_blank"
            }
          },
          [_vm._v("\n          source\n        ")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [
        _vm._v("Date Picker\n        "),
        _c(
          "a",
          {
            staticClass: "source-link",
            attrs: {
              href: "https://github.com/charliekassel/vuejs-datepicker",
              target: "_blank"
            }
          },
          [_vm._v("\n          source\n        ")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [
        _vm._v("Vue Color Picker\n        "),
        _c(
          "a",
          {
            staticClass: "source-link",
            attrs: {
              href: "http://xiaokaike.github.io/vue-color/",
              target: "_blank"
            }
          },
          [_vm._v("\n          source\n        ")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }