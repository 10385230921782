<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item"><router-link slot="item-title" to="/admin/posts">Bejegyzések</router-link></li>
                <li class="breadcrumb-item active">Új bejegyzés</li>
            </ol>
            <div class="d-flex align-items-center">
                <h1>Új bejegyzés</h1>

                <div class="btn-group ml-auto">
                    <button class="btn btn-sm btn-success" form="postEditForm">
                        <i class="icon-fa icon-fa-save" v-if="!saving"></i>
                        <i class="icon-fa icon-fa-circle-o-notch icon-fa-spin" v-if="saving"></i>
                        Mentés
                    </button>
                </div>
            </div>
        </div>

        <post-editor :original="post"
            :save="saveCallback"
            @saving="onSaveStart">
        </post-editor>
    </div>
</template>

<script>
import ls from '../../services/ls.js'
import postEditor from './PostEditor'

export default {
    data () {
        return {
            post: {
                title: 'Új bejegyzés',
                slug: 'uj-bejegyzes',
                description: '',
                published_at: null,
                canonical: 'posts/uj-bejegyzes',
                images: [],
                category: null,
                relations: [],
                tags: [],
            },
            site: null,
            saving: false,
        }
    },
    components: {
        postEditor,
    },
    methods: {
        onSaveStart(){
            this.saving = true
        },
        saveCallback(post, content){
            axios.post(`/api/admin/publications`, {
                ...post,
                type: 'post',
                site: this.site,
                content: content,
            }).then(response => {
                this.$router.push(`/admin/posts/${response.data.id}/edit`)
                toastr.success('Bejegyzés elmentve', 'Siker')
            }).catch(err => {
                toastr.error('Hiba a mentés közben', 'Hiba')
            }).finally(() => {
                this.saving = false
            })
        }
    },
    created() {
        this.site = ls.get('site')
    },
}
</script>

<style>

</style>
