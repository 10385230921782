export default class Slider {
    constructor({ data, api, config }) {
        this.api = api
        this.config = config
        this.data = data
    }

    static get toolbox() {
        return {
            title: 'Slider',
            icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>'
        };
    }

    render() {
        var container = document.createElement('div')
        container.classList = 'input-group'
        var prepend = document.createElement('div')
        prepend.classList = 'input-group-prepend'
        var label = document.createElement('span')
        label.classList = 'input-group-text'
        label.innerText = 'Képváltó azonosító'

        var input = document.createElement('input')
        input.setAttribute('type', 'number')
        input.classList = 'form-control'
        input.value = this.data ? this.data.slider : null

        prepend.appendChild(label)
        container.appendChild(prepend)
        container.appendChild(input)
        return container
    }

    validate(saveData) {
        return !!saveData.slider
    }

    save(content) {
        const input = content.querySelector('input')

        return {
            slider: input ? input.value : null
        }
    }
}
