var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main-content" }, [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [_vm._v("Line Icons")]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Icons")])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Line Icons")])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("h6", [_vm._v("Example Usage")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("pre", { staticClass: "language-html" }, [
                _c("code", [_vm._v('<i class="icon-ln icon-ln-music"></i>')])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "icon-container" }, [
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-music" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-music")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-search" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-search")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-mail" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-mail")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-heart" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-heart")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-star" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-star")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-user" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-user")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-videocam" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-videocam")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-camera" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-camera")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-photo" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-photo")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-attach" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-attach")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-lock" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-lock")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-eye" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-eye")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-tag" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-tag")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-thumbs-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-thumbs-up")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-pencil" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-pencil")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-comment" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-comment")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-location" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-location")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-cup" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-cup")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-trash" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-trash")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-doc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-doc")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-note" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-note")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-cog" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-cog")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-params" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-params")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-calendar" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-calendar")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-sound" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-sound")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-clock" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-clock")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-lightbulb" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-lightbulb")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-tv" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-tv")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-desktop" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-desktop")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-mobile" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-mobile")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-cd" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-cd")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-inbox" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-inbox")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-globe" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-globe")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-cloud" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-cloud")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-paper-plane" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-paper-plane")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-fire" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-fire")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-graduation-cap" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-graduation-cap")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-megaphone" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-megaphone")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-database" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-database")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-key" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-key")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-beaker" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-beaker")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-truck" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-truck")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-money" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-money")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-food" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-food")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-shop" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-shop")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-diamond" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-diamond")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-t-shirt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-t-shirt")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-ln icon-ln-wallet" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-ln-wallet")])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }