var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content", attrs: { id: "dashboardPage" } },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-lg-6" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "input-group" },
                    [
                      _c("div", { staticClass: "input-group-prepend" }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: "Szűrés kezdő és záró dátuma",
                                expression: "'Szűrés kezdő és záró dátuma'"
                              }
                            ],
                            staticClass: "input-group-text"
                          },
                          [_c("i", { staticClass: "icon-fa icon-fa-calendar" })]
                        )
                      ]),
                      _vm._v(" "),
                      _c("datetime", {
                        attrs: {
                          type: "date",
                          format: "yyyy-MM-dd",
                          "input-class": "form-control rounded-0"
                        },
                        model: {
                          value: _vm.filter.from,
                          callback: function($$v) {
                            _vm.$set(_vm.filter, "from", $$v)
                          },
                          expression: "filter.from"
                        }
                      }),
                      _vm._v(" "),
                      _c("datetime", {
                        attrs: {
                          type: "date",
                          format: "yyyy-MM-dd",
                          "input-class": "form-control rounded-0"
                        },
                        model: {
                          value: _vm.filter.to,
                          callback: function($$v) {
                            _vm.$set(_vm.filter, "to", $$v)
                          },
                          expression: "filter.to"
                        }
                      }),
                      _vm._v(" "),
                      _c("button", { staticClass: "btn btn-primary" }, [
                        _vm.loading
                          ? _c("i", {
                              staticClass:
                                "icon-fa icon-fa-refresh icon-fa-spin w-auto"
                            })
                          : _c("i", { staticClass: "icon-fa icon-fa-filter" }),
                        _vm._v(
                          "\n                                Szűrés\n                            "
                        )
                      ])
                    ],
                    1
                  )
                ]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.stats
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12 col-lg-6 col-xl-3" }, [
              _c("a", { staticClass: "dashbox", attrs: { href: "#" } }, [
                _c("i", { staticClass: "icon-fa icon-fa-users text-primary" }),
                _vm._v(" "),
                _c("span", { staticClass: "title" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.stats.indicators.new_orders) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "desc" }, [
                  _vm._v(
                    "\n                    Új rendelések\n                "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-12 col-lg-6 col-xl-3" }, [
              _c("a", { staticClass: "dashbox", attrs: { href: "#" } }, [
                _c("i", { staticClass: "icon-fa icon-fa-file text-success" }),
                _vm._v(" "),
                _c("span", { staticClass: "title" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.stats.indicators.open_orders) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "desc" }, [
                  _vm._v(
                    "\n                    Lezáratlan rendelések\n                "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-12 col-lg-6 col-xl-3" }, [
              _c("a", { staticClass: "dashbox", attrs: { href: "#" } }, [
                _c("i", {
                  staticClass: "icon-fa icon-fa-shopping-cart text-danger"
                }),
                _vm._v(" "),
                _c("span", { staticClass: "title" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.stats.indicators.open_value) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "desc" }, [
                  _vm._v(
                    "\n                    Nyitott rendelések értéke\n                "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-12 col-lg-6 col-xl-3" }, [
              _c("a", { staticClass: "dashbox", attrs: { href: "#" } }, [
                _c("i", { staticClass: "icon-fa icon-fa-comments text-info" }),
                _vm._v(" "),
                _c("span", { staticClass: "title" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.stats.indicators.closed_value) +
                      "\n                "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "desc" }, [
                  _vm._v(
                    "\n                    Lezárt rendelések értéke\n                "
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.stats
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12 col-xl-6 mt-2" }, [
              _c("div", { staticClass: "card" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("line-graph", {
                      attrs: {
                        labels: ["Jan", "Feb", "Mar", "June"],
                        values: [20, 30, 40, 60]
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-12 col-xl-6 mt-2" }, [
              _c("div", { staticClass: "card" }, [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("bar-graph", {
                      attrs: {
                        labels: ["Jan", "Feb", "Mar", "June"],
                        values: [20, 30, 40, 60]
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.stats
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12 col-xl-6 mt-2" }, [
              _c("div", { staticClass: "card" }, [
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("table", { staticClass: "table" }, [
                    _vm._m(3),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.stats.lists.last_orders, function(order) {
                        return _c("tr", { key: order.id }, [
                          _c(
                            "td",
                            [
                              _c(
                                "router-link",
                                { attrs: { to: "/admin/orders/" + order.id } },
                                [_vm._v(_vm._s(order.code))]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: "/admin/contacts/" + order.contact_id
                                  }
                                },
                                [_vm._v(_vm._s(order.contact.name))]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(order.value) + " Ft")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(_vm._s(_vm.formatUtcDate(order.created_at)))
                          ])
                        ])
                      }),
                      0
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-12 col-xl-6 mt-2" }, [
              _c("div", { staticClass: "card" }, [
                _vm._m(4),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c("table", { staticClass: "table" }, [
                    _vm._m(5),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.stats.lists.last_contacts, function(contact) {
                        return _c("tr", { key: contact.id }, [
                          _c(
                            "td",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: { to: "/admin/contacts/" + contact.id }
                                },
                                [_vm._v(_vm._s(contact.name))]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.formatUtcDate(contact.created_at))
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  ])
                ])
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [
        _c("i", { staticClass: "icon-fa icon-fa-line-chart text-warning" }),
        _vm._v("Traffic Stats")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [
        _c("i", { staticClass: "icon-fa icon-fa-bar-chart text-success" }),
        _vm._v("Sales Chart")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [
        _c("i", { staticClass: "icon-fa icon-fa-shopping-cart text-danger" }),
        _vm._v("Utolsó rendelések")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("#")]),
        _vm._v(" "),
        _c("th", [_vm._v("Megrendelő")]),
        _vm._v(" "),
        _c("th", [_vm._v("Összeg")]),
        _vm._v(" "),
        _c("th", [_vm._v("Időpont")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [
        _c("i", { staticClass: "icon-fa icon-fa-users text-info" }),
        _vm._v("Legújabb megrendelők")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Név")]),
        _vm._v(" "),
        _c("th", [_vm._v("Időpont")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }