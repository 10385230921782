<template>
  <form @submit.prevent="validateBeforeSubmit">
    <div :class="['form-group', {'is-invalid': $v.loginData.email.$error}]">
      <input
        :class="{ 'is-invalid': $v.loginData.email.$error }"
        v-model.trim="loginData.email"
        class="form-control"
        placeholder="Enter Email"
        type="email"
        @input="$v.loginData.email.$touch()"
      >
      <span v-if="!$v.loginData.email.required" class="invalid-feedback">
        Kötelező megadni az email címet
      </span>
      <span v-if="!$v.loginData.email.email" class="invalid-feedback">
        Hibás email cím
      </span>
    </div>
    <div :class="['form-group', {'is-invalid': $v.loginData.password.$error}]">
      <input
        :class="{ 'is-invalid': $v.loginData.password.$error }"
        v-model.trim="loginData.password"
        class="form-control"
        placeholder="Enter Password"
        type="password"
        @input="$v.loginData.password.$touch()"
      >
      <span v-if="!$v.loginData.password.required" class="invalid-feedback">
        Kötelező megadni a jelszót
      </span>
      <span v-if="!$v.loginData.password.minLength" class="invalid-feedback">
        A jelszó legalább {{ $v.loginData.password.$params.minLength.min }} karakter hosszú.
      </span>
    </div>
    <div class="other-actions row">
      <div class="col">
        <div class="checkbox">
          <label class="c-input c-checkbox">
            <input
              v-model="loginData.remember"
              type="checkbox"
              name="remember"
            >
            <span class="c-indicator"/>
            Bejelentkezve maradok
          </label>
        </div>
      </div>
      <!--
      <div class="col-sm-6 text-sm-right">
        <a href="#" class="forgot-link">
          Elfelejtett jelszó?
        </a>
      </div>
      -->
    </div>
    <button class="btn btn-theme btn-full">Bejelentkezés</button>
  </form>
</template>

<script type="text/babel">
import {required, minLength, email} from 'vuelidate/lib/validators'
import Auth from '../../services/auth'
import ls from '../../services/ls'

export default {
    data () {
        return {
            loginData: {
                email: '',
                password: '',
                remember: ''
            }
        }
    },
    validations: {
        loginData: {
            password: {
                required,
                minLength: minLength(6)
            },
            email: {
                required,
                email
            }
        }
    },
    methods: {
        validateBeforeSubmit () {
            this.$v.$touch()

            if (!this.$v.$error) {
                Auth.login(this.loginData).then((res) => {
                    if (res) {
                        ls.remove('site')
                        ls.remove('siteDomain')
                        this.$root.$emit('siteChange')
                        this.$router.push('/admin/dashboard')
                    }
                })
            }
        }
    }
}
</script>
