var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main-content" }, [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [_vm._v("IcoMoon")]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Icons")])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("IcoMoon")])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-header" }, [
              _c("h6", [_vm._v("Example Usage")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c("pre", { staticClass: "language-html" }, [
                _c("code", [_vm._v('<i class="icon-im icon-im-home"></i>')])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "icon-container" }, [
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-home" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-home, "),
                      _c("br"),
                      _vm._v("icon-im-house")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-home2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-home2, "),
                      _c("br"),
                      _vm._v("icon-im-house2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-home3" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-home3, "),
                      _c("br"),
                      _vm._v("icon-im-house3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-office" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-office, "),
                      _c("br"),
                      _vm._v("icon-im-buildings")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-newspaper" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-newspaper, "),
                      _c("br"),
                      _vm._v("icon-im-news")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-pencil" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-pencil, "),
                      _c("br"),
                      _vm._v("icon-im-write")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-pencil2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-pencil2, "),
                      _c("br"),
                      _vm._v("icon-im-write2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-quill" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-quill, "),
                      _c("br"),
                      _vm._v("icon-im-feather")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-pen" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-pen, "),
                      _c("br"),
                      _vm._v("icon-im-write3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-blog" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-blog, "),
                      _c("br"),
                      _vm._v("icon-im-pen2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-eyedropper" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-eyedropper, "),
                      _c("br"),
                      _vm._v("icon-im-color")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-droplet" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-droplet, "),
                      _c("br"),
                      _vm._v("icon-im-color2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-paint-format" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-paint-format, "),
                      _c("br"),
                      _vm._v("icon-im-format")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-image" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-image, "),
                      _c("br"),
                      _vm._v("icon-im-picture")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-images" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-images, "),
                      _c("br"),
                      _vm._v("icon-im-pictures")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-camera" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-camera, "),
                      _c("br"),
                      _vm._v("icon-im-photo")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-headphones" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-headphones, "),
                      _c("br"),
                      _vm._v("icon-im-headset")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-music" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-music, "),
                      _c("br"),
                      _vm._v("icon-im-song")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-play" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-play, "),
                      _c("br"),
                      _vm._v("icon-im-video")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-film" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-film, "),
                      _c("br"),
                      _vm._v("icon-im-video2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-video-camera" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-video-camera, "),
                      _c("br"),
                      _vm._v("icon-im-video3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-dice" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-dice, "),
                      _c("br"),
                      _vm._v("icon-im-game")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-pacman" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-pacman, "),
                      _c("br"),
                      _vm._v("icon-im-game2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-spades" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-spades, "),
                      _c("br"),
                      _vm._v("icon-im-cards")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-clubs" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-clubs, "),
                      _c("br"),
                      _vm._v("icon-im-cards2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-diamonds" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-diamonds, "),
                      _c("br"),
                      _vm._v("icon-im-cards3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-bullhorn" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-bullhorn, "),
                      _c("br"),
                      _vm._v("icon-im-megaphone")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-connection" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-connection, "),
                      _c("br"),
                      _vm._v("icon-im-wifi")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-podcast" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-podcast, "),
                      _c("br"),
                      _vm._v("icon-im-broadcast")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-feed" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-feed, "),
                      _c("br"),
                      _vm._v("icon-im-wave")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-mic" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-mic, "),
                      _c("br"),
                      _vm._v("icon-im-microphone")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-book" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-book, "),
                      _c("br"),
                      _vm._v("icon-im-read")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-books" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-books, "),
                      _c("br"),
                      _vm._v("icon-im-library")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-library" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-library2, "),
                      _c("br"),
                      _vm._v("icon-im-bank")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-file-text" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-file-text, "),
                      _c("br"),
                      _vm._v("icon-im-file")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-profile" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-profile, "),
                      _c("br"),
                      _vm._v("icon-im-file2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-file-empty" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-file-empty, "),
                      _c("br"),
                      _vm._v("icon-im-file3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-files-empty" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-files-empty, "),
                      _c("br"),
                      _vm._v("icon-im-files")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-file-text2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", { staticClass: "unit1" }, [
                      _vm._v("icon-file-text2, "),
                      _c("br"),
                      _vm._v("icon-im-file4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-file-picture" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", { staticClass: "unit1" }, [_vm._v("e927")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v("icon-im-file-picture, "),
                      _c("br"),
                      _vm._v("icon-im-file5")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-file-music" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-file-music, "),
                      _c("br"),
                      _vm._v("icon-im-file6")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-file-play" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-file-play, "),
                      _c("br"),
                      _vm._v("icon-im-file7")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-file-video" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-file-video, "),
                      _c("br"),
                      _vm._v("icon-im-file8")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-file-zip" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-file-zip, "),
                      _c("br"),
                      _vm._v("icon-im-file9")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-copy" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-copy, "),
                      _c("br"),
                      _vm._v("icon-im-duplicate")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-paste" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-paste, "),
                      _c("br"),
                      _vm._v("icon-im-clipboard-file")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-stack" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-stack, "),
                      _c("br"),
                      _vm._v("icon-im-layers")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-folder" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-folder, "),
                      _c("br"),
                      _vm._v("icon-im-directory")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-folder-open" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-folder-open, "),
                      _c("br"),
                      _vm._v("icon-im-directory2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-folder-plus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-folder-plus, "),
                      _c("br"),
                      _vm._v("icon-im-directory3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-folder-minus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-folder-minus, "),
                      _c("br"),
                      _vm._v("icon-im-directory4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-folder-download" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-folder-download, "),
                      _c("br"),
                      _vm._v("icon-im-directory5")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-folder-upload" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-folder-upload, "),
                      _c("br"),
                      _vm._v("icon-im-directory6")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-price-tag" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-im-price-tag")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-price-tags" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-im-price-tags")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-barcode" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-im-barcode")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-qrcode" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-im-qrcode")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-ticket" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-ticket, "),
                      _c("br"),
                      _vm._v("icon-im-theater")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-cart" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-cart, "),
                      _c("br"),
                      _vm._v("icon-im-purchase")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-coin-dollar" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-coin-dollar, "),
                      _c("br"),
                      _vm._v("icon-im-money")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-coin-euro" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-coin-euro, "),
                      _c("br"),
                      _vm._v("icon-im-money2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-coin-pound" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-coin-pound, "),
                      _c("br"),
                      _vm._v("icon-im-money3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-coin-yen" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-coin-yen, "),
                      _c("br"),
                      _vm._v("icon-im-money4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-credit-card" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-credit-card, "),
                      _c("br"),
                      _vm._v("icon-im-money5")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-calculator" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-calculator, "),
                      _c("br"),
                      _vm._v("icon-im-compute")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-lifebuoy" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-lifebuoy, "),
                      _c("br"),
                      _vm._v("icon-im-support")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-phone" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-phone, "),
                      _c("br"),
                      _vm._v("icon-im-telephone")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-phone-hang-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-phone-hang-up, "),
                      _c("br"),
                      _vm._v("icon-im-telephone2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-address-book" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-address-book, "),
                      _c("br"),
                      _vm._v("icon-im-contact")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-envelop" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-envelop, "),
                      _c("br"),
                      _vm._v("icon-im-mail")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-pushpin" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-pushpin, "),
                      _c("br"),
                      _vm._v("icon-im-pin")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-location" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-location, "),
                      _c("br"),
                      _vm._v("icon-im-map-marker")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-location2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-location2, "),
                      _c("br"),
                      _vm._v("icon-im-map-marker2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-compass" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-compass, "),
                      _c("br"),
                      _vm._v("icon-im-direction")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-compass2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-compass2, "),
                      _c("br"),
                      _vm._v("icon-im-direction2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-map" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-map, "),
                      _c("br"),
                      _vm._v("icon-im-guide")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-map2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-map2, "),
                      _c("br"),
                      _vm._v("icon-im-guide2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-history" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-history, "),
                      _c("br"),
                      _vm._v("icon-im-time")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-clock" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-clock, "),
                      _c("br"),
                      _vm._v("icon-im-time2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-clock2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-clock2, "),
                      _c("br"),
                      _vm._v("icon-im-time3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-alarm" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-alarm, "),
                      _c("br"),
                      _vm._v("icon-im-time4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-bell" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-bell, "),
                      _c("br"),
                      _vm._v("icon-im-alarm2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-stopwatch" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-stopwatch, "),
                      _c("br"),
                      _vm._v("icon-im-time5")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-calendar" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-calendar, "),
                      _c("br"),
                      _vm._v("icon-im-date")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-printer" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-printer, "),
                      _c("br"),
                      _vm._v("icon-im-print")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-keyboard" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-keyboard, "),
                      _c("br"),
                      _vm._v("icon-im-typing")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-display" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-display, "),
                      _c("br"),
                      _vm._v("icon-im-screen")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-laptop" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-laptop, "),
                      _c("br"),
                      _vm._v("icon-im-computer")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-mobile" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-mobile, "),
                      _c("br"),
                      _vm._v("icon-im-cell-phone")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-mobile2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-mobile2, "),
                      _c("br"),
                      _vm._v("icon-im-cell-phone2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-tablet" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-tablet, "),
                      _c("br"),
                      _vm._v("icon-im-mobile3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-tv" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-tv, "),
                      _c("br"),
                      _vm._v("icon-im-television")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-drawer" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-drawer, "),
                      _c("br"),
                      _vm._v("icon-im-box")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-drawer2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-drawer2, "),
                      _c("br"),
                      _vm._v("icon-im-box2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-box-add" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-box-add, "),
                      _c("br"),
                      _vm._v("icon-im-box3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-box-remove" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-box-remove, "),
                      _c("br"),
                      _vm._v("icon-im-box4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-download" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-download, "),
                      _c("br"),
                      _vm._v("icon-im-save")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-upload" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-upload, "),
                      _c("br"),
                      _vm._v("icon-im-load")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-floppy-disk" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-floppy-disk, "),
                      _c("br"),
                      _vm._v("icon-im-save2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-drive" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-drive, "),
                      _c("br"),
                      _vm._v("icon-im-save3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-database" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-database, "),
                      _c("br"),
                      _vm._v("icon-im-db")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-undo" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-undo, "),
                      _c("br"),
                      _vm._v("icon-im-ccw")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-redo" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-redo, "),
                      _c("br"),
                      _vm._v("icon-im-cw")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-undo2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-undo2, "),
                      _c("br"),
                      _vm._v("icon-im-left")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-redo2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-redo2, "),
                      _c("br"),
                      _vm._v("icon-im-right")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-forward" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-forward, "),
                      _c("br"),
                      _vm._v("icon-im-right2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-reply" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-reply, "),
                      _c("br"),
                      _vm._v("icon-im-left2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-bubble" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-bubble, "),
                      _c("br"),
                      _vm._v("icon-im-comment")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-bubbles" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-bubbles, "),
                      _c("br"),
                      _vm._v("icon-im-comments")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-bubbles2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-bubbles2, "),
                      _c("br"),
                      _vm._v("icon-im-comments2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-bubble2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-bubble2, "),
                      _c("br"),
                      _vm._v("icon-im-comment2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-bubbles3" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-bubbles3, "),
                      _c("br"),
                      _vm._v("icon-im-comments3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-bubbles4" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-bubbles4, "),
                      _c("br"),
                      _vm._v("icon-im-comments4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-user" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-user, "),
                      _c("br"),
                      _vm._v("icon-im-profile2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-users" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-users, "),
                      _c("br"),
                      _vm._v("icon-im-group")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-user-plus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-user-plus, "),
                      _c("br"),
                      _vm._v("icon-im-user2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-user-minus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-user-minus, "),
                      _c("br"),
                      _vm._v("icon-im-user3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-user-check" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-user-check, "),
                      _c("br"),
                      _vm._v("icon-im-user4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-user-tie" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-user-tie, "),
                      _c("br"),
                      _vm._v("icon-im-user5")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-quotes-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-quotes-left, "),
                      _c("br"),
                      _vm._v("icon-im-ldquo")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-quotes-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-quotes-right, "),
                      _c("br"),
                      _vm._v("icon-im-rdquo")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-hour-glass" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-hour-glass, "),
                      _c("br"),
                      _vm._v("icon-im-loading")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-spinner" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-spinner, "),
                      _c("br"),
                      _vm._v("icon-im-loading2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-spinner2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-spinner2, "),
                      _c("br"),
                      _vm._v("icon-im-loading3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-spinner3" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-spinner3, "),
                      _c("br"),
                      _vm._v("icon-im-loading4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-spinner4" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-spinner4, "),
                      _c("br"),
                      _vm._v("icon-im-loading5")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-spinner5" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-spinner5, "),
                      _c("br"),
                      _vm._v("icon-im-loading6")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-spinner6" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-spinner6, "),
                      _c("br"),
                      _vm._v("icon-im-loading7")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-spinner7" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-spinner7, "),
                      _c("br"),
                      _vm._v("icon-im-loading8")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-spinner8" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-spinner8, "),
                      _c("br"),
                      _vm._v("icon-im-loading9")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-spinner9" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-spinner9, "),
                      _c("br"),
                      _vm._v("icon-im-loading10")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-spinner10" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-spinner10, "),
                      _c("br"),
                      _vm._v("icon-im-loading11")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-spinner11" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-spinner11, "),
                      _c("br"),
                      _vm._v("icon-im-loading12")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-binoculars" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-binoculars, "),
                      _c("br"),
                      _vm._v("icon-im-lookup")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-search" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-search, "),
                      _c("br"),
                      _vm._v("icon-im-magnifier")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-zoom-in" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-zoom-in, "),
                      _c("br"),
                      _vm._v("icon-im-magnifier2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-zoom-out" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-zoom-out, "),
                      _c("br"),
                      _vm._v("icon-im-magnifier3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-enlarge" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-enlarge, "),
                      _c("br"),
                      _vm._v("icon-im-expand")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-shrink" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-shrink, "),
                      _c("br"),
                      _vm._v("icon-im-collapse")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-enlarge2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-enlarge2, "),
                      _c("br"),
                      _vm._v("icon-im-expand2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-shrink2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-shrink2, "),
                      _c("br"),
                      _vm._v("icon-im-collapse2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-key" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-key, "),
                      _c("br"),
                      _vm._v("icon-im-password")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-key2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-key2, "),
                      _c("br"),
                      _vm._v("icon-im-password2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-lock" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-lock, "),
                      _c("br"),
                      _vm._v("icon-im-secure")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-unlocked" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-unlocked, "),
                      _c("br"),
                      _vm._v("icon-im-lock-open")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-wrench" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-wrench, "),
                      _c("br"),
                      _vm._v("icon-im-tool")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-equalizer" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-equalizer, "),
                      _c("br"),
                      _vm._v("icon-im-sliders")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-equalizer2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-equalizer2, "),
                      _c("br"),
                      _vm._v("icon-im-sliders2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-cog" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-cog, "),
                      _c("br"),
                      _vm._v("icon-im-gear")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-cogs" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-cogs, "),
                      _c("br"),
                      _vm._v("icon-im-gears")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-hammer" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-hammer, "),
                      _c("br"),
                      _vm._v("icon-im-tool2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-magic-wand" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-magic-wand, "),
                      _c("br"),
                      _vm._v("icon-im-wizard")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-aid-kit" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-aid-kit, "),
                      _c("br"),
                      _vm._v("icon-im-health")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-bug" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-bug, "),
                      _c("br"),
                      _vm._v("icon-im-virus")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-pie-chart" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-pie-chart, "),
                      _c("br"),
                      _vm._v("icon-im-stats")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-stats-dots" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-stats-dots, "),
                      _c("br"),
                      _vm._v("icon-im-stats2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-stats-bars" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-stats-bars, "),
                      _c("br"),
                      _vm._v("icon-im-stats3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-stats-bars2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-stats-bars2, "),
                      _c("br"),
                      _vm._v("icon-im-stats4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-trophy" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-trophy, "),
                      _c("br"),
                      _vm._v("icon-im-cup")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-gift" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-gift, "),
                      _c("br"),
                      _vm._v("icon-im-present")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-glass" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-glass, "),
                      _c("br"),
                      _vm._v("icon-im-drink")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-glass2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-glass2, "),
                      _c("br"),
                      _vm._v("icon-im-drink2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-mug" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-mug, "),
                      _c("br"),
                      _vm._v("icon-im-drink3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-spoon-knife" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-spoon-knife, "),
                      _c("br"),
                      _vm._v("icon-im-food")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-leaf" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-leaf, "),
                      _c("br"),
                      _vm._v("icon-im-nature")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-rocket" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-rocket, "),
                      _c("br"),
                      _vm._v("icon-im-jet")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-meter" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-meter, "),
                      _c("br"),
                      _vm._v("icon-im-gauge")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-meter2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-meter2, "),
                      _c("br"),
                      _vm._v("icon-im-gauge2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-hammer2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-hammer2, "),
                      _c("br"),
                      _vm._v("icon-im-gavel")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-fire" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-fire, "),
                      _c("br"),
                      _vm._v("icon-im-flame")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-lab" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-lab, "),
                      _c("br"),
                      _vm._v("icon-im-beta")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-magnet" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-magnet, "),
                      _c("br"),
                      _vm._v("icon-im-attract")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-bin" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-bin, "),
                      _c("br"),
                      _vm._v("icon-im-trashcan")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-bin2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-bin2, "),
                      _c("br"),
                      _vm._v("icon-im-trashcan2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-briefcase" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-briefcase, "),
                      _c("br"),
                      _vm._v("icon-im-portfolio")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-airplane" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-airplane, "),
                      _c("br"),
                      _vm._v("icon-im-travel")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-truck" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-truck, "),
                      _c("br"),
                      _vm._v("icon-im-transit")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-road" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-road, "),
                      _c("br"),
                      _vm._v("icon-im-asphalt")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-accessibility" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-im-accessibility")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-target" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-target, "),
                      _c("br"),
                      _vm._v("icon-im-goal")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-shield" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-shield, "),
                      _c("br"),
                      _vm._v("icon-im-security")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-power" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-power, "),
                      _c("br"),
                      _vm._v("icon-im-lightning")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-switch" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-im-switch")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-power-cord" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-power-cord, "),
                      _c("br"),
                      _vm._v("icon-im-plugin")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-clipboard" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-clipboard, "),
                      _c("br"),
                      _vm._v("icon-im-board")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-list-numbered" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-list-numbered, "),
                      _c("br"),
                      _vm._v("icon-im-options")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-list" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-list, "),
                      _c("br"),
                      _vm._v("icon-im-todo")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-list2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-list2, "),
                      _c("br"),
                      _vm._v("icon-im-todo2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-tree" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-tree, "),
                      _c("br"),
                      _vm._v("icon-im-branches")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-menu" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-menu, "),
                      _c("br"),
                      _vm._v("icon-im-list3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-menu2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-menu2, "),
                      _c("br"),
                      _vm._v("icon-im-options2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-menu3" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-menu3, "),
                      _c("br"),
                      _vm._v("icon-im-options3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-menu4" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-menu4, "),
                      _c("br"),
                      _vm._v("icon-im-options4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-cloud" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-cloud, "),
                      _c("br"),
                      _vm._v("icon-im-weather")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-cloud-download" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-cloud-download, "),
                      _c("br"),
                      _vm._v("icon-im-cloud2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-cloud-upload" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-cloud-upload, "),
                      _c("br"),
                      _vm._v("icon-im-cloud3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-cloud-check" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-cloud-check, "),
                      _c("br"),
                      _vm._v("icon-im-cloud4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-download2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-download2, "),
                      _c("br"),
                      _vm._v("icon-im-save4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-upload2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-upload2, "),
                      _c("br"),
                      _vm._v("icon-im-load2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-download3" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-download3, "),
                      _c("br"),
                      _vm._v("icon-im-save5")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-upload3" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-upload3, "),
                      _c("br"),
                      _vm._v("icon-im-load3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-sphere" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-sphere, "),
                      _c("br"),
                      _vm._v("icon-im-globe")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-earth" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-earth, "),
                      _c("br"),
                      _vm._v("icon-im-globe2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-link" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-link, "),
                      _c("br"),
                      _vm._v("icon-im-chain")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-flag" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-flag, "),
                      _c("br"),
                      _vm._v("icon-im-report")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-attachment" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-attachment, "),
                      _c("br"),
                      _vm._v("icon-im-paperclip")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-eye" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-eye, "),
                      _c("br"),
                      _vm._v("icon-im-views")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-eye-plus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-eye-plus, "),
                      _c("br"),
                      _vm._v("icon-im-views2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-eye-minus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-eye-minus, "),
                      _c("br"),
                      _vm._v("icon-im-views3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-eye-blocked" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-eye-blocked, "),
                      _c("br"),
                      _vm._v("icon-im-views4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-bookmark" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-bookmark, "),
                      _c("br"),
                      _vm._v("icon-im-ribbon")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-bookmarks" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-bookmarks, "),
                      _c("br"),
                      _vm._v("icon-im-ribbons")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-sun" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-sun, "),
                      _c("br"),
                      _vm._v("icon-im-weather2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-contrast" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-im-contrast")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-im icon-im-brightness-contrast"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-im-brightness-contrast")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-star-empty" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-star-empty, "),
                      _c("br"),
                      _vm._v("icon-im-rate")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-star-half" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-star-half, "),
                      _c("br"),
                      _vm._v("icon-im-rate2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-star-full" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-star-full, "),
                      _c("br"),
                      _vm._v("icon-im-rate3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-heart" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-heart, "),
                      _c("br"),
                      _vm._v("icon-im-like")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-heart-broken" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-heart-broken, "),
                      _c("br"),
                      _vm._v("icon-im-heart2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-man" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-man, "),
                      _c("br"),
                      _vm._v("icon-im-male")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-woman" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", { staticClass: "unit1" }, [_vm._v("e9dd")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v("icon-im-woman, "),
                      _c("br"),
                      _vm._v("icon-im-female")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-man-woman" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-man-woman, "),
                      _c("br"),
                      _vm._v("icon-im-toilet")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-happy" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-happy, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-happy2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-happy2, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-smile" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-smile, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-smile2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-smile2, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-tongue" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-tongue, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon5")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-tongue2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-tongue2, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon6")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-sad" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-sad, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon7")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-sad2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-sad2, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon8")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-wink" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-wink, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon9")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-wink2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-wink2, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon10")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-grin" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-grin, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon11")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-grin2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-grin2, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon12")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-cool" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-cool, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon13")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-cool2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-cool2, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon14")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-angry" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-angry, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon15")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-angry2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-angry2, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon16")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-evil" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-evil, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon17")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-evil2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-evil2, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon18")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-shocked" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-shocked, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon19")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-shocked2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-shocked2, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon20")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-baffled" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-baffled, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon21")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-baffled2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-baffled2, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon22")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-confused" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-confused, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon23")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-confused2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-confused2, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon24")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-neutral" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-neutral, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon25")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-neutral2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-neutral2, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon26")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-hipster" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-hipster, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon27")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-hipster2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-hipster2, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon28")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-wondering" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-wondering, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon29")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-wondering2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-wondering2, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon30")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-sleepy" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-sleepy, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon31")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-sleepy2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-sleepy2, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon32")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-frustrated" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-frustrated, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon33")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-frustrated2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-frustrated2, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon34")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-crying" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-crying, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon35")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-crying2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-crying2, "),
                      _c("br"),
                      _vm._v("icon-im-emoticon36")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-point-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-point-up, "),
                      _c("br"),
                      _vm._v("icon-im-finger")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-point-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-point-right, "),
                      _c("br"),
                      _vm._v("icon-im-finger2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-point-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-point-down, "),
                      _c("br"),
                      _vm._v("icon-im-finger3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-point-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-point-left, "),
                      _c("br"),
                      _vm._v("icon-im-finger4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-warning" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-warning, "),
                      _c("br"),
                      _vm._v("icon-im-sign")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-notification" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-notification, "),
                      _c("br"),
                      _vm._v("icon-im-warning2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-question" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-question, "),
                      _c("br"),
                      _vm._v("icon-im-help")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-plus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-plus, "),
                      _c("br"),
                      _vm._v("icon-im-add")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-minus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-minus, "),
                      _c("br"),
                      _vm._v("icon-im-subtract")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-info" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-info, "),
                      _c("br"),
                      _vm._v("icon-im-information")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-cancel-circle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-cancel-circle, "),
                      _c("br"),
                      _vm._v("icon-im-close")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-blocked" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-blocked, "),
                      _c("br"),
                      _vm._v("icon-im-forbidden")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-cross" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-cross, "),
                      _c("br"),
                      _vm._v("icon-im-cancel")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-checkmark" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-checkmark, "),
                      _c("br"),
                      _vm._v("icon-im-tick")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-checkmark2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", { staticClass: "unit1" }, [_vm._v("ea11")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v("icon-im-checkmark2, "),
                      _c("br"),
                      _vm._v("icon-im-tick2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-spell-check" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-spell-check, "),
                      _c("br"),
                      _vm._v("icon-im-spelling")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-enter" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-enter, "),
                      _c("br"),
                      _vm._v("icon-im-signin")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-exit" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-exit, "),
                      _c("br"),
                      _vm._v("icon-im-signout")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-play2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-play2, "),
                      _c("br"),
                      _vm._v("icon-im-player")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-pause" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-pause, "),
                      _c("br"),
                      _vm._v("icon-im-player2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-stop" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-stop, "),
                      _c("br"),
                      _vm._v("icon-im-player3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-previous" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-previous, "),
                      _c("br"),
                      _vm._v("icon-im-player4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-next" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-next, "),
                      _c("br"),
                      _vm._v("icon-im-player5")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-backward" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-backward, "),
                      _c("br"),
                      _vm._v("icon-im-player6")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-forward2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-forward2, "),
                      _c("br"),
                      _vm._v("icon-im-player7")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-play3" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-play3, "),
                      _c("br"),
                      _vm._v("icon-im-player8")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-pause2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-pause2, "),
                      _c("br"),
                      _vm._v("icon-im-player9")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-stop2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-stop2, "),
                      _c("br"),
                      _vm._v("icon-im-player10")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-backward2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-backward2, "),
                      _c("br"),
                      _vm._v("icon-im-player11")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-forward3" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-forward3, "),
                      _c("br"),
                      _vm._v("icon-im-player12")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-first" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-first, "),
                      _c("br"),
                      _vm._v("icon-im-player13")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-last" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-last, "),
                      _c("br"),
                      _vm._v("icon-im-player14")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-previous2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-previous2, "),
                      _c("br"),
                      _vm._v("icon-im-player15")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-next2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-next2, "),
                      _c("br"),
                      _vm._v("icon-im-player16")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-eject" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-eject, "),
                      _c("br"),
                      _vm._v("icon-im-player17")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-volume-high" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-volume-high, "),
                      _c("br"),
                      _vm._v("icon-im-volume")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-volume-medium" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-volume-medium, "),
                      _c("br"),
                      _vm._v("icon-im-volume2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-volume-low" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-volume-low, "),
                      _c("br"),
                      _vm._v("icon-im-volume3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-volume-mute" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-volume-mute, "),
                      _c("br"),
                      _vm._v("icon-im-volume4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-volume-mute2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-volume-mute2, "),
                      _c("br"),
                      _vm._v("icon-im-volume5")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-volume-increase" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-volume-increase, "),
                      _c("br"),
                      _vm._v("icon-im-volume6")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-volume-decrease" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-volume-decrease, "),
                      _c("br"),
                      _vm._v("icon-im-volume7")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-loop" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-loop, "),
                      _c("br"),
                      _vm._v("icon-im-repeat")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-loop2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-loop2, "),
                      _c("br"),
                      _vm._v("icon-im-repeat2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-infinite" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-im-infinite")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-shuffle" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-shuffle, "),
                      _c("br"),
                      _vm._v("icon-im-random")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-arrow-up-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-arrow-up-left, "),
                      _c("br"),
                      _vm._v("icon-im-up-left")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-arrow-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-arrow-up, "),
                      _c("br"),
                      _vm._v("icon-im-up")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-arrow-up-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-arrow-up-right, "),
                      _c("br"),
                      _vm._v("icon-im-up-right")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-arrow-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-arrow-right, "),
                      _c("br"),
                      _vm._v("icon-im-right3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-arrow-down-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-arrow-down-right, "),
                      _c("br"),
                      _vm._v("icon-im-down-right")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-arrow-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-arrow-down, "),
                      _c("br"),
                      _vm._v("icon-im-down")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-arrow-down-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-arrow-down-left, "),
                      _c("br"),
                      _vm._v("icon-im-down-left")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-arrow-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-arrow-left, "),
                      _c("br"),
                      _vm._v("icon-im-left3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-arrow-up-left2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-arrow-up-left2, "),
                      _c("br"),
                      _vm._v("icon-im-up-left2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-arrow-up2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-arrow-up2, "),
                      _c("br"),
                      _vm._v("icon-im-up2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-arrow-up-right2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-arrow-up-right2, "),
                      _c("br"),
                      _vm._v("icon-im-up-right2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-arrow-right2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-arrow-right2, "),
                      _c("br"),
                      _vm._v("icon-im-right4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-im icon-im-arrow-down-right2"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-arrow-down-right2, "),
                      _c("br"),
                      _vm._v("icon-im-down-right2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-arrow-down2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-arrow-down2, "),
                      _c("br"),
                      _vm._v("icon-im-down2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-arrow-down-left2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-arrow-down-left2, "),
                      _c("br"),
                      _vm._v("icon-im-down-left2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-arrow-left2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-arrow-left2, "),
                      _c("br"),
                      _vm._v("icon-im-left4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-circle-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-circle-up, "),
                      _c("br"),
                      _vm._v("icon-im-up3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-circle-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-circle-right, "),
                      _c("br"),
                      _vm._v("icon-im-right5")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-circle-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-circle-down, "),
                      _c("br"),
                      _vm._v("icon-im-down3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-circle-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-circle-left, "),
                      _c("br"),
                      _vm._v("icon-im-left5")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-tab" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-tab, "),
                      _c("br"),
                      _vm._v("icon-im-arrows")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-move-up" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-move-up, "),
                      _c("br"),
                      _vm._v("icon-im-sort")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-move-down" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-move-down, "),
                      _c("br"),
                      _vm._v("icon-im-sort2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-sort-alpha-asc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-sort-alpha-asc, "),
                      _c("br"),
                      _vm._v("icon-im-arrange")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-sort-alpha-desc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-sort-alpha-desc, "),
                      _c("br"),
                      _vm._v("icon-im-arrange2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-sort-numeric-asc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-sort-numeric-asc, "),
                      _c("br"),
                      _vm._v("icon-im-arrange3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-im icon-im-sort-numberic-desc"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-sort-numberic-desc, "),
                      _c("br"),
                      _vm._v("icon-im-arrange4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-sort-amount-asc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-sort-amount-asc, "),
                      _c("br"),
                      _vm._v("icon-im-arrange5")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-sort-amount-desc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-sort-amount-desc, "),
                      _c("br"),
                      _vm._v("icon-im-arrange6")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-command" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-command, "),
                      _c("br"),
                      _vm._v("icon-im-cmd")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-shift" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-im-shift")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-ctrl" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-ctrl, "),
                      _c("br"),
                      _vm._v("icon-im-control")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-opt" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-opt, "),
                      _c("br"),
                      _vm._v("icon-im-option")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-checkbox-checked" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-checkbox-checked, "),
                      _c("br"),
                      _vm._v("icon-im-checkbox")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-im icon-im-checkbox-unchecked"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-checkbox-unchecked, "),
                      _c("br"),
                      _vm._v("icon-im-checkbox2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-radio-checked" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-radio-checked, "),
                      _c("br"),
                      _vm._v("icon-im-radio-button")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-radio-checked2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-radio-checked2, "),
                      _c("br"),
                      _vm._v("icon-im-radio-button2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-radio-unchecked" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-radio-unchecked, "),
                      _c("br"),
                      _vm._v("icon-im-radio-button3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-crop" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-crop, "),
                      _c("br"),
                      _vm._v("icon-im-resize")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-make-group" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-im-make-group")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-ungroup" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-im-ungroup")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-scissors" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-scissors, "),
                      _c("br"),
                      _vm._v("icon-im-cut")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-filter" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-filter, "),
                      _c("br"),
                      _vm._v("icon-im-funnel")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-font" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-font, "),
                      _c("br"),
                      _vm._v("icon-im-typeface")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-ligature" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-ligature, "),
                      _c("br"),
                      _vm._v("icon-im-typography")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-ligature2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-ligature2, "),
                      _c("br"),
                      _vm._v("icon-im-typography2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-text-height" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-text-height, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-text-width" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-text-width, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-font-size" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-font-size, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-bold" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-bold, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-underline" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-underline, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg5")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-italic" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-italic, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg6")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-strikethrough" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-strikethrough, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg7")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-omega" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-omega, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg8")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-sigma" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-sigma, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg9")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-page-break" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-page-break, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg10")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-superscript" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-superscript, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg11")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-subscript" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-subscript, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg12")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-superscript2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-superscript2, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg13")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-subscript2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-subscript2, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg14")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-text-color" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-text-color, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg15")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-pagebreak" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-pagebreak, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg16")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-clear-formatting" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-clear-formatting, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg17")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-table" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-table, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg18")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-table2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-table2, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg19")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-insert-template" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-insert-template, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg20")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-pilcrow" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-pilcrow, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg21")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-ltr" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-ltr, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg22")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-rtl" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-rtl, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg23")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-section" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-section, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg24")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-paragraph-left" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-paragraph-left, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg25")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-paragraph-center" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-paragraph-center, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg26")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-paragraph-right" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-paragraph-right, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg27")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", {
                      staticClass: "icon-im icon-im-paragraph-justify"
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-paragraph-justify, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg28")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-indent-increase" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-indent-increase, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg29")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-indent-decrease" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-indent-decrease, "),
                      _c("br"),
                      _vm._v("icon-im-wysiwyg30")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-share" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-share, "),
                      _c("br"),
                      _vm._v("icon-im-out")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-new-tab" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-new-tab, "),
                      _c("br"),
                      _vm._v("icon-im-out2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-embed" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-embed, "),
                      _c("br"),
                      _vm._v("icon-im-code")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-embed2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-embed2, "),
                      _c("br"),
                      _vm._v("icon-im-code2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-terminal" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-terminal, "),
                      _c("br"),
                      _vm._v("icon-im-console")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-share2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-share2, "),
                      _c("br"),
                      _vm._v("icon-im-social")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-mail" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-mail2, "),
                      _c("br"),
                      _vm._v("icon-im-contact2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-mail2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-mail3, "),
                      _c("br"),
                      _vm._v("icon-im-contact3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-mail3" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-mail4, "),
                      _c("br"),
                      _vm._v("icon-im-contact4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-mail4" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-mail5, "),
                      _c("br"),
                      _vm._v("icon-im-contact5")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-amazon" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-amazon, "),
                      _c("br"),
                      _vm._v("icon-im-brand")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-google" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-google, "),
                      _c("br"),
                      _vm._v("icon-im-brand2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-google2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-google2, "),
                      _c("br"),
                      _vm._v("icon-im-brand3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-google3" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-google3, "),
                      _c("br"),
                      _vm._v("icon-im-brand4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-google-plus" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-google-plus, "),
                      _c("br"),
                      _vm._v("icon-im-brand5")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-google-plus2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-google-plus2, "),
                      _c("br"),
                      _vm._v("icon-im-brand6")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-google-plus3" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-google-plus3, "),
                      _c("br"),
                      _vm._v("icon-im-brand7")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-hangouts" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-hangouts, "),
                      _c("br"),
                      _vm._v("icon-im-brand8")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-google-drive" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-google-drive, "),
                      _c("br"),
                      _vm._v("icon-im-brand9")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-facebook" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-facebook, "),
                      _c("br"),
                      _vm._v("icon-im-brand10")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-facebook2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-facebook2, "),
                      _c("br"),
                      _vm._v("icon-im-brand11")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-instagram" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-instagram, "),
                      _c("br"),
                      _vm._v("icon-im-brand12")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-whatsapp" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-whatsapp, "),
                      _c("br"),
                      _vm._v("icon-im-brand13")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-spotify" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-spotify, "),
                      _c("br"),
                      _vm._v("icon-im-brand14")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-telegram" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-telegram, "),
                      _c("br"),
                      _vm._v("icon-im-brand15")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-twitter" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-twitter, "),
                      _c("br"),
                      _vm._v("icon-im-brand16")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-vine" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-vine, "),
                      _c("br"),
                      _vm._v("icon-im-brand17")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-vk" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-vk, "),
                      _c("br"),
                      _vm._v("icon-im-brand18")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-renren" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-renren, "),
                      _c("br"),
                      _vm._v("icon-im-brand19")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-sina-weibo" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-sina-weibo, "),
                      _c("br"),
                      _vm._v("icon-im-brand20")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-rss" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-feed2, "),
                      _c("br"),
                      _vm._v("icon-im-rss")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-rss2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-feed3, "),
                      _c("br"),
                      _vm._v("icon-im-rss2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-youtube" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-youtube, "),
                      _c("br"),
                      _vm._v("icon-im-brand21")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-youtube2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-youtube2, "),
                      _c("br"),
                      _vm._v("icon-im-brand22")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-twitch" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-twitch, "),
                      _c("br"),
                      _vm._v("icon-im-brand23")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-vimeo" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-vimeo, "),
                      _c("br"),
                      _vm._v("icon-im-brand24")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-vimeo2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-vimeo2, "),
                      _c("br"),
                      _vm._v("icon-im-brand25")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-lanyrd" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-lanyrd, "),
                      _c("br"),
                      _vm._v("icon-im-brand26")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-flickr" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-flickr, "),
                      _c("br"),
                      _vm._v("icon-im-brand27")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-flickr2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-flickr2, "),
                      _c("br"),
                      _vm._v("icon-im-brand28")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-flickr3" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-flickr3, "),
                      _c("br"),
                      _vm._v("icon-im-brand29")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-flickr4" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-flickr4, "),
                      _c("br"),
                      _vm._v("icon-im-brand30")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-dribbble" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-dribbble, "),
                      _c("br"),
                      _vm._v("icon-im-brand31")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-behance" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-behance, "),
                      _c("br"),
                      _vm._v("icon-im-brand32")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-behance2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-behance2, "),
                      _c("br"),
                      _vm._v("icon-im-brand33")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-deviantart" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-deviantart, "),
                      _c("br"),
                      _vm._v("icon-im-brand34")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-500px" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-500px, "),
                      _c("br"),
                      _vm._v("icon-im-brand35")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-steam" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-steam, "),
                      _c("br"),
                      _vm._v("icon-im-brand36")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-steam2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-steam2, "),
                      _c("br"),
                      _vm._v("icon-im-brand37")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-dropbox" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-dropbox, "),
                      _c("br"),
                      _vm._v("icon-im-brand38")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-onedrive" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-onedrive, "),
                      _c("br"),
                      _vm._v("icon-im-brand39")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-github" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-github, "),
                      _c("br"),
                      _vm._v("icon-im-brand40")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-npm" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-npm, "),
                      _c("br"),
                      _vm._v("icon-im-brand41")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-basecamp" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-basecamp, "),
                      _c("br"),
                      _vm._v("icon-im-brand42")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-trello" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-trello, "),
                      _c("br"),
                      _vm._v("icon-im-brand43")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-wordpress" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-wordpress, "),
                      _c("br"),
                      _vm._v("icon-im-brand44")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-joomla" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-joomla, "),
                      _c("br"),
                      _vm._v("icon-im-brand45")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-ello" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-ello, "),
                      _c("br"),
                      _vm._v("icon-im-brand46")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-blogger" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-blogger, "),
                      _c("br"),
                      _vm._v("icon-im-brand47")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-blogger2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-blogger2, "),
                      _c("br"),
                      _vm._v("icon-im-brand48")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-tumblr" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-tumblr, "),
                      _c("br"),
                      _vm._v("icon-im-brand49")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-tumblr2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-tumblr2, "),
                      _c("br"),
                      _vm._v("icon-im-brand50")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-yahoo" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", { staticClass: "unit1" }, [_vm._v("eabb")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v("icon-im-yahoo, "),
                      _c("br"),
                      _vm._v("icon-im-brand51")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-yahoo2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-im-yahoo2")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-tux" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-tux, "),
                      _c("br"),
                      _vm._v("icon-im-brand52")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-appleinc" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-apple, "),
                      _c("br"),
                      _vm._v("icon-im-brand53")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-finder" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-finder, "),
                      _c("br"),
                      _vm._v("icon-im-brand54")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-android" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-android, "),
                      _c("br"),
                      _vm._v("icon-im-brand55")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-windows" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-windows, "),
                      _c("br"),
                      _vm._v("icon-im-brand56")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-windows8" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-windows8, "),
                      _c("br"),
                      _vm._v("icon-im-brand57")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-soundcloud" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-soundcloud, "),
                      _c("br"),
                      _vm._v("icon-im-brand58")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-soundcloud2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-soundcloud2, "),
                      _c("br"),
                      _vm._v("icon-im-brand59")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-skype" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-skype, "),
                      _c("br"),
                      _vm._v("icon-im-brand60")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-reddit" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-reddit, "),
                      _c("br"),
                      _vm._v("icon-im-brand61")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-hackernews" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-hackernews, "),
                      _c("br"),
                      _vm._v("icon-im-brand62")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-wikipedia" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-wikipedia, "),
                      _c("br"),
                      _vm._v("icon-im-brand63")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-linkedin" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-linkedin, "),
                      _c("br"),
                      _vm._v("icon-im-brand64")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-linkedin2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-linkedin2, "),
                      _c("br"),
                      _vm._v("icon-im-brand65")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-lastfm" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-lastfm, "),
                      _c("br"),
                      _vm._v("icon-im-brand66")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-lastfm2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-lastfm2, "),
                      _c("br"),
                      _vm._v("icon-im-brand67")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-delicious" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-delicious, "),
                      _c("br"),
                      _vm._v("icon-im-brand68")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-stumbleupon" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-stumbleupon, "),
                      _c("br"),
                      _vm._v("icon-im-brand69")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-stumbleupon2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-stumbleupon2, "),
                      _c("br"),
                      _vm._v("icon-im-brand70")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-stackoverflow" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-stackoverflow, "),
                      _c("br"),
                      _vm._v("icon-im-brand71")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-pinterest" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-pinterest, "),
                      _c("br"),
                      _vm._v("icon-im-brand72")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-pinterest2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-pinterest2, "),
                      _c("br"),
                      _vm._v("icon-im-brand73")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-xing" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-xing, "),
                      _c("br"),
                      _vm._v("icon-im-brand74")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-xing2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-xing2, "),
                      _c("br"),
                      _vm._v("icon-im-brand75")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-flattr" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-flattr, "),
                      _c("br"),
                      _vm._v("icon-im-brand76")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-foursquare" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-foursquare, "),
                      _c("br"),
                      _vm._v("icon-im-brand77")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-yelp" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-yelp, "),
                      _c("br"),
                      _vm._v("icon-im-brand78")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-paypal" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-paypal, "),
                      _c("br"),
                      _vm._v("icon-im-brand79")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-chrome" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-chrome, "),
                      _c("br"),
                      _vm._v("icon-im-browser")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-firefox" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-firefox, "),
                      _c("br"),
                      _vm._v("icon-im-browser2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-IE" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-IE, "),
                      _c("br"),
                      _vm._v("icon-im-browser3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-edge" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-edge, "),
                      _c("br"),
                      _vm._v("icon-im-browser4")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-safari" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-safari, "),
                      _c("br"),
                      _vm._v("icon-im-browser5")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-opera" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-opera, "),
                      _c("br"),
                      _vm._v("icon-im-browser6")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-file-pdf" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-file-pdf, "),
                      _c("br"),
                      _vm._v("icon-im-file10")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-file-openoffice" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-file-openoffice, "),
                      _c("br"),
                      _vm._v("icon-im-file11")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-file-word" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-file-word, "),
                      _c("br"),
                      _vm._v("icon-im-file12")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-file-excel" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-file-excel, "),
                      _c("br"),
                      _vm._v("icon-im-file13")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-libreoffice" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-libreoffice, "),
                      _c("br"),
                      _vm._v("icon-im-file14")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-html-five" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-html-five, "),
                      _c("br"),
                      _vm._v("icon-im-w3c")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-html-five2" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-html-five2, "),
                      _c("br"),
                      _vm._v("icon-im-w3c2")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-css3" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-css3, "),
                      _c("br"),
                      _vm._v("icon-im-w3c3")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-git" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-git, "),
                      _c("br"),
                      _vm._v("icon-im-brand80")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-codepen" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-codepen, "),
                      _c("br"),
                      _vm._v("icon-im-brand81")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-svg" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [_vm._v("icon-im-svg")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "demo-icon" }, [
                  _c("div", { staticClass: "icon-box" }, [
                    _c("i", { staticClass: "icon-im icon-im-IcoMoon" })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-classname" }, [
                    _c("span", [
                      _vm._v("icon-im-IcoMoon, "),
                      _c("br"),
                      _vm._v("icon-im-icomoon")
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }