<template>
    <div class="card my-1 mx-3">
        <div class="card-header d-flex align-items-center">
            <span>Képgaléria</span>

            <button class="btn btn-sm btn-info ml-auto"
                @click="onAdd"
                type="button">
                <i class="icon-fa icon-fa-plus"></i>
                Kép hozzáadása
            </button>
        </div>

        <div class="card-body">
            <p v-if="!images || images.length==0">Nincs kép</p>
            <ul class="list-unstyled" v-if="images && images.length">
                <li class="media mb-1 p-2"
                    :class="{'bg-secondary text-white': highlights && image.highlight}"
                    v-for="(image, index) in images"
                    v-bind:key="index">
                    <div class="media-image mr-3 w-25">
                        <img :src="image.dataUrl" :alt="image.name" class="w-100">
                    </div>

                    <div class="media-body">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <div class="form-group">
                                    <label>Alt szöveg</label>
                                    <input type="text" class="form-control" v-model="image.name">
                                </div>

                                <div class="form-group">
                                    <label>Link</label>
                                    <input type="link" class="form-control" v-model="image.link">
                                </div>
                            </div>
                            <div class="col-12 col-lg-6 d-flex flex-column">
                                <label>Felirat</label>
                                <textarea class="form-control flex-grow-1 mb-3" v-model="image.caption"></textarea>
                            </div>
                        </div>

                        <div class="d-flex">
                            <button class="btn btn-light"
                                type="button"
                                v-if="highlights && !image.highlight"
                                @click="highlightImage(image)">
                                <i class="icon-fa icon-fa-star-o"></i>
                                Kiemelés
                            </button>
                            <button class="btn btn-light"
                                type="button"
                                v-if="highlights && image.highlight"
                                @click="unhighlightImage(image)">
                                <i class="icon-fa icon-fa-star"></i>
                                Kiemelés eltávolítása
                            </button>

                            <button type="button"
                                class="btn btn-danger ml-auto"
                                @click="removeImage(index)">
                                <i class="icon-fa icon-fa-minus"></i>
                                Eltávolítás
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        images: { type: [Array], default: () => [] },
        highlights: { type: Boolean, default: () => true }
    },
    methods: {
        onAdd(){
            this.$emit('add')
        },
        removeImage(index){
            this.images.splice(index, 1)
            this.$forceUpdate()
        },
        highlightImage(image){
            if(!this.highlights){
                return
            }
            this.images.forEach(image => {
                image.highlight = false
            })
            image.highlight = true
            this.$forceUpdate()
        },
        unhighlightImage(image){
            if(!this.highlights){
                return
            }
            image.highlight = false
            this.$forceUpdate()
        }
    }
}
</script>

<style>
.media-image{
    overflow:hidden;
    max-height: 12em;
}
</style>
