<template>
    <div class="main-content">
        <div class="page-header">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link slot="item-title" :to="'/admin/sites/'+this.site">Vezérlőpult</router-link></li>
                <li class="breadcrumb-item active">Akciók</li>
            </ol>
            <h1 class="d-inline mr-3">Akciók</h1>
            <button class="btn btn-outline-info btn-sm align-text-bottom">
                <router-link to="/admin/sales/create">
                    <i class="icon-fa icon-fa-plus"/> Új hozzáadása
                </router-link>
            </button>
        </div>
        <div class="card">
            <div class="card-body">
                <cool-table :fields="fields"
                    :data-source="dataSource"
                    :textfilter="true"
                    :selectfilters="filters"
                    :row-class="getRowClass">
                    <template slot="name" slot-scope="props">
                        <router-link :to="`/admin/sales/${props.rowData.id}/edit`">
                            {{ props.rowData.name }}
                        </router-link>
                    </template>

                    <template slot="value" slot-scope="props">
                        {{ props.rowData.value }} {{ props.rowData.type==0 ? 'Ft' : '%' }}
                    </template>

                    <template slot="starts_at" slot-scope="props">
                        <span v-tooltip="getTooltip(props.rowData)">
                            {{ formatUtcDate(props.rowData.starts_at) }}
                        </span>
                    </template>

                    <template slot="ends_at" slot-scope="props">
                        <span v-if="props.rowData.ends_at" v-tooltip="getTooltip(props.rowData)">
                            {{ formatUtcDate(props.rowData.ends_at) }}
                        </span>
                    </template>
                </cool-table>
            </div>
        </div>
    </div>
</template>

<script>
import ls from '../../services/ls'
import CoolTable from '../../components/CoolTable'

export default {
    components: {
        CoolTable
    },
    data () {
        return {
            site: null,
            fields: [
                {
                    name: 'name',
                    title: 'Név',
                },
                {
                    name: 'value',
                    title: 'Kedvezmény',
                },
                {
                    name: 'order',
                    title: 'Prioritás',
                },
                {
                    name: 'starts_at',
                    title: 'Kezdés ideje',
                },
                {
                    name: 'ends_at',
                    title: 'Lejárat ideje',
                }
            ],
            types: [
                {
                    name: 'Összeg',
                    icon: 'icon-fa-money'
                },
                {
                    name: 'Százalék',
                    icon: 'icon-fa-percent'
                },
            ],
            filters: {
                'active': {
                    name: 'Érvényesség',
                    value: 'active+future',
                    options: {
                        'all': 'Mindegy',
                        'active': 'Aktív',
                        'future': 'Ezután kezdődik',
                        'active+future': 'Aktív vagy Ezután kezdődik',
                        'expired': 'Lejárt',
                    }
                }
            }
        }
    },
    methods: {
        dataSource (sortOrder, pagination, filters) {
            var sort = {
                field: sortOrder[0] ? sortOrder[0].sortField : 'starts_at',
                direction: sortOrder[0] ? sortOrder[0].direction : 'desc',
            };

            return axios.get('/api/admin/sales', {
                params: {
                    with: 'isActive',
                    page: pagination.current_page,
                    filters: {
                        site: this.site,
                        ...filters,
                    },
                    sort: sort.field,
                    order: sort.direction
                }
            })
        },
        isActiveInTime(sale){
            let now = new Date

            return (new Date(sale.starts_at) < now)
                &&
                (sale.ends_at == null || new Date(sale.ends_at) > now)
        },
        getRowClass(sale){
            if(sale.relations.isActive){
                return 'bg-info'
            }

            if(this.isActiveInTime(sale)){
                return 'text-info'
            }

            return ''
        },
        getTooltip(sale){
            if(sale.relations.isActive){
                return 'Jelenleg ez az akció van érvényben'
            }else if(this.isActiveInTime(sale)){
                return 'Ez az akció aktív, de egy nagyobb prioritású van érvényben.'
            }

            return ''
        }
    },
    created() {
        this.site = ls.get('site')
    }
}
</script>

<style>

</style>
