var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main-content" }, [
      _c("div", { staticClass: "page-header" }, [
        _c("h3", { staticClass: "page-title" }, [_vm._v("Tables")]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item" }, [
            _c("a", { attrs: { href: "#" } }, [_vm._v("Basic UI")])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v("Tables")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-xl-6 mb-4" }, [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("Default Table")
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "This is just a Simple Bootstrap style table. To use this just apply\n            "
                ),
                _c("mark", [_vm._v(".table")]),
                _vm._v("\n            class to your table element.\n          ")
              ]),
              _vm._v(" "),
              _c("table", { staticClass: "table" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("#")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Product Name")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Status")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Price")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("MRP")])
                  ])
                ]),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v("1")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Colgate Toothpaste")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-success" }, [
                        _vm._v("in stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$5")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$6")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("2")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Pink T-Shirt")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-danger" }, [
                        _vm._v("out of stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$20")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$40")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("3")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Nike Running Shoes")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-success" }, [
                        _vm._v("in stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$100")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$120")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("4")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Reebok Casual Shoes")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-danger" }, [
                        _vm._v("out of stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$70")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$80")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("5")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Titan Raga Premium Watch")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-success" }, [
                        _vm._v("in stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$100")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$120")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("6")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Philips Hair Remover")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-danger" }, [
                        _vm._v("out of stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$50")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$70")])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xl-6 mb-4" }, [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("Bordered Table")
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("To use this just apply\n            "),
                _c("mark", [_vm._v(".table")]),
                _vm._v("\n            ,\n            "),
                _c("mark", [_vm._v(".table-bordered")]),
                _vm._v(
                  "\n            classes to your table element to make it bordered.\n          "
                )
              ]),
              _vm._v(" "),
              _c("table", { staticClass: "table table-bordered" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("#")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Product Name")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Status")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Price")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("MRP")])
                  ])
                ]),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v("1")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Colgate Toothpaste")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-success" }, [
                        _vm._v("in stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$5")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$6")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("2")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Pink T-Shirt")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-danger" }, [
                        _vm._v("out of stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$20")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$40")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("3")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Nike Running Shoes")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-success" }, [
                        _vm._v("in stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$100")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$120")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("4")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Reebok Casual Shoes")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-danger" }, [
                        _vm._v("out of stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$70")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$80")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("5")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Titan Raga Premium Watch")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-success" }, [
                        _vm._v("in stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$100")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$120")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("6")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Philips Hair Remover")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-danger" }, [
                        _vm._v("out of stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$50")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$70")])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xl-6 mb-4" }, [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("Hover Table")
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("To use this just apply\n            "),
                _c("mark", [_vm._v(".table")]),
                _vm._v("\n            ,\n            "),
                _c("mark", [_vm._v(".table-hover")]),
                _vm._v(
                  "\n            classes to your table element to make it hoverable.\n          "
                )
              ]),
              _vm._v(" "),
              _c("table", { staticClass: "table table-hover" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("#")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Product Name")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Status")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Price")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("MRP")])
                  ])
                ]),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v("1")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Colgate Toothpaste")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-success" }, [
                        _vm._v("in stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$5")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$6")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("2")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Pink T-Shirt")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-danger" }, [
                        _vm._v("out of stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$20")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$40")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("3")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Nike Running Shoes")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-success" }, [
                        _vm._v("in stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$100")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$120")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("4")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Reebok Casual Shoes")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-danger" }, [
                        _vm._v("out of stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$70")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$80")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("5")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Titan Raga Premium Watch")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-success" }, [
                        _vm._v("in stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$100")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$120")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("6")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Philips Hair Remover")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-danger" }, [
                        _vm._v("out of stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$50")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$70")])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xl-6 mb-4" }, [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("Striped Table")
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("To use this just apply\n            "),
                _c("mark", [_vm._v(".table")]),
                _vm._v("\n            ,\n            "),
                _c("mark", [_vm._v(".table-bordered")]),
                _vm._v(
                  "\n            classes to your table element to make it striped.\n          "
                )
              ]),
              _vm._v(" "),
              _c("table", { staticClass: "table table-striped" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("#")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Product Name")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Status")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Price")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("MRP")])
                  ])
                ]),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v("1")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Colgate Toothpaste")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-success" }, [
                        _vm._v("in stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$5")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$6")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("2")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Pink T-Shirt")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-danger" }, [
                        _vm._v("out of stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$20")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$40")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("3")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Nike Running Shoes")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-success" }, [
                        _vm._v("in stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$100")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$120")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("4")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Reebok Casual Shoes")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-danger" }, [
                        _vm._v("out of stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$70")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$80")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("5")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Titan Raga Premium Watch")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-success" }, [
                        _vm._v("in stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$100")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$120")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("6")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Philips Hair Remover")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-danger" }, [
                        _vm._v("out of stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$50")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$70")])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xl-6 mb-4" }, [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("Small Table")
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("To use this just apply\n            "),
                _c("mark", [_vm._v(".table")]),
                _vm._v("\n            ,\n            "),
                _c("mark", [_vm._v(".table-sm")]),
                _vm._v(
                  "\n            class to your table element to make it small.\n          "
                )
              ]),
              _vm._v(" "),
              _c("table", { staticClass: "table table-sm" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("#")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Product Name")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Status")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Price")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("MRP")])
                  ])
                ]),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v("1")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Colgate Toothpaste")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-success" }, [
                        _vm._v("in stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$5")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$6")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("2")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Pink T-Shirt")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-danger" }, [
                        _vm._v("out of stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$20")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$40")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("3")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Nike Running Shoes")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-success" }, [
                        _vm._v("in stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$100")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$120")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("4")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Reebok Casual Shoes")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-danger" }, [
                        _vm._v("out of stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$70")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$80")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("5")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Titan Raga Premium Watch")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-success" }, [
                        _vm._v("in stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$100")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$120")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("6")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Philips Hair Remover")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-danger" }, [
                        _vm._v("out of stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$50")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$70")])
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xl-6 mb-4" }, [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("Contextual Classes")
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("To use this just apply\n            "),
                _c("mark", [_vm._v(".table-active")]),
                _vm._v("\n            ,\n            "),
                _c("mark", [_vm._v(".table-success")]),
                _vm._v("\n            ,\n            "),
                _c("mark", [_vm._v(".table-warning")]),
                _vm._v("\n            ,\n            "),
                _c("mark", [_vm._v(".table-danger")]),
                _vm._v("\n            ,\n            "),
                _c("mark", [_vm._v(".table-info")]),
                _vm._v("\n            classes on the row or cells.\n          ")
              ]),
              _vm._v(" "),
              _c("table", { staticClass: "table table-default" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("#")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Product Name")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Status")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Price")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("MRP")])
                  ])
                ]),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", { staticClass: "table-success" }, [
                    _c("td", [_vm._v("1")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Colgate Toothpaste")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-success" }, [
                        _vm._v("in stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$5")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$6")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("2")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Pink T-Shirt")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-danger" }, [
                        _vm._v("out of stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$20")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$40")])
                  ]),
                  _vm._v(" "),
                  _c("tr", { staticClass: "table-danger" }, [
                    _c("td", [_vm._v("3")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Nike Running Shoes")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-success" }, [
                        _vm._v("in stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$100")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$120")])
                  ]),
                  _vm._v(" "),
                  _c("tr", { staticClass: "table-warning" }, [
                    _c("td", [_vm._v("4")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Reebok Casual Shoes")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-danger" }, [
                        _vm._v("out of stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$70")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$80")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", { staticClass: "table-success" }, [_vm._v("5")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Titan Raga Premium Watch")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-success" }, [
                        _vm._v("in stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$100")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$120")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("6")]),
                    _vm._v(" "),
                    _c("td", [_vm._v("Philips Hair Remover")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", { staticClass: "label label-danger" }, [
                        _vm._v("out of stock")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v("$50")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "table-danger" }, [_vm._v("$70")])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }