var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: { id: "productEditForm" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      }
    },
    [
      _c(
        "tabs",
        { staticClass: "tabs-simple" },
        [
          _c("tab", { attrs: { id: "basic", name: "Alap adatok" } }, [
            _c("div", { staticClass: "row m-1" }, [
              _c("div", { staticClass: "col-8" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-header" }, [
                    _vm._v(
                      "\n                            Leírás\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("editor", {
                        ref: "editor",
                        attrs: { config: _vm.editorConfig }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-4" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-header" }, [
                    _vm._v(
                      "\n                            Közzététel\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Állapot:")]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.status,
                              expression: "status"
                            }
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.status = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "draft" } }, [
                            _vm._v("Vázlat")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "published" } }, [
                            _vm._v("Publikus")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "timed" } }, [
                            _vm._v("Időzített")
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        class: { collapse: !_vm.showPublishTiming }
                      },
                      [
                        _c("label", [
                          _vm._v(
                            "\n                                    Publikálás időpontja:\n                                "
                          )
                        ]),
                        _vm._v(" "),
                        _c("datetime", {
                          attrs: {
                            type: "datetime",
                            "min-datetime": new Date().toISOString(),
                            format: "yyyy-MM-dd HH:mm",
                            "input-class": "form-control"
                          },
                          model: {
                            value: _vm.product.published_at,
                            callback: function($$v) {
                              _vm.$set(_vm.product, "published_at", $$v)
                            },
                            expression: "product.published_at"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "typeInput" } }, [
                        _vm._v("Típus")
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.product.product_type,
                              expression: "product.product_type"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { id: "typeInput" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.product,
                                "product_type",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "0" } }, [
                            _vm._v("Egyszerű termék")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "1" } }, [
                            _vm._v("Csoportosított termék")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "2" } }, [
                            _vm._v("Variálható termék")
                          ])
                        ]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "titleInput" } }, [
                        _vm._v("Név:")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.product.title,
                            expression: "product.title"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", id: "titleInput" },
                        domProps: { value: _vm.product.title },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.product, "title", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "slugInput" } }, [
                        _vm._v("Slug:")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.product.slug,
                            expression: "product.slug"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", id: "slugInput" },
                        domProps: { value: _vm.product.slug },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.product, "slug", $event.target.value)
                            },
                            _vm.updateCanonical
                          ]
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "descriptionInput" } }, [
                        _vm._v("Meta leírás:")
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.product.description,
                            expression: "product.description"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { id: "descriptionInput", rows: "4" },
                        domProps: { value: _vm.product.description },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.product,
                              "description",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "tab",
            { attrs: { id: "categorization", name: "Kategorizálás" } },
            [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v("\n                    Kategória\n                ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("treeselect", {
                        attrs: {
                          options: _vm.categories,
                          multiple: false,
                          clearable: false,
                          normalizer: _vm.normalizeCategories
                        },
                        model: {
                          value: _vm.product.category,
                          callback: function($$v) {
                            _vm.$set(_vm.product, "category", $$v)
                          },
                          expression: "product.category"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _vm._v("\n                    Címkék\n                ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("treeselect", {
                        attrs: {
                          options: _vm.tags,
                          multiple: true,
                          normalizer: _vm.normalizeTags
                        },
                        model: {
                          value: _vm.product.tags,
                          callback: function($$v) {
                            _vm.$set(_vm.product, "tags", $$v)
                          },
                          expression: "product.tags"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _vm.product.id
            ? _c(
                "tab",
                { attrs: { id: "properties", name: "Tulajdonságok" } },
                [
                  _vm.product.relations && _vm.product.relations.attributes
                    ? _c("attribute-editor", {
                        attrs: {
                          attributes: _vm.product.relations.attributes,
                          units:
                            _vm.meta && _vm.meta.units ? _vm.meta.units : {},
                          tooltipHeader: _vm.tooltips.attributes
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "tab",
            { attrs: { id: "media", name: "Média" } },
            [
              _c("media-editor", {
                attrs: { images: _vm.product.images },
                on: {
                  add: function($event) {
                    return _vm.showLibrary(true)
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.product.id && _vm.product.product_type != 2
            ? _c(
                "tab",
                { attrs: { id: "prices", name: "Árak" } },
                [
                  _c("price-editor", {
                    attrs: {
                      prices: _vm.product.relations.prices,
                      current: _vm.product.relations.currentPrice,
                      product: _vm.product
                    },
                    on: { updateCurrent: _vm.updateCurrentPrice }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.product.id && _vm.product.product_type == 1
            ? _c(
                "tab",
                { attrs: { id: "bundle", name: "Termékek" } },
                [_c("bundle-editor", { attrs: { product: _vm.product } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.product.id && _vm.product.product_type == 2
            ? _c(
                "tab",
                { attrs: { id: "variations", name: "Variációk" } },
                [
                  _c("variation-editor", {
                    attrs: {
                      product: _vm.product,
                      units: _vm.meta && _vm.meta.units ? _vm.meta.units : {},
                      tooltipHeader: _vm.tooltips.attributes
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "tab",
            { attrs: { id: "stock", name: "Készlet" } },
            [
              _c("stock-editor", {
                attrs: { product: _vm.product, dirty: _vm.dirty },
                on: { updateStock: _vm.updateStock }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showMediaLibrary
        ? _c("vue-medialibrary-manager", {
            attrs: { selectable: true },
            on: {
              close: function($event) {
                _vm.showMediaLibrary = false
              },
              select: _vm.selectImage
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }