var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content vue-tabs-demo" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-xl-6 mb-4" },
            [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("Basic")
              ]),
              _vm._v(" "),
              _c(
                "tabs",
                { staticClass: "tabs-default" },
                [
                  _c("tab", { attrs: { id: "basic-home", name: "Home" } }, [
                    _vm._v(
                      "\n              This is the content of the first tab\n              "
                    ),
                    _c("p", [
                      _vm._v(
                        "\n                Lorem ipsum dolor, sit amet consectetur adipisicing elit.\n              "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tab",
                    { attrs: { id: "basic-profile", name: "Profile" } },
                    [
                      _vm._v(
                        "\n              This is the content of the second tab\n              "
                      ),
                      _c("p", [
                        _vm._v(
                          "\n                Lorem ipsum dolor sit, amet consectetur adipisicing elit.\n              "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    { attrs: { id: "basic-message", name: "Message" } },
                    [
                      _vm._v(
                        "\n              The fragment that is appended to the url can be customized\n              "
                      ),
                      _c("p", [
                        _vm._v(
                          "\n                Lorem ipsum dolor sit amet consectetur adipisicing elit.\n              "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    { attrs: { id: "basic-setting", name: "Setting" } },
                    [
                      _vm._v(
                        "\n              A prefix and a suffix can be added\n              "
                      ),
                      _c("p", [
                        _vm._v(
                          "\n                Lorem ipsum dolor, sit amet consectetur adipisicing elit.\n              "
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xl-6 mb-4" },
            [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("basic primary color")
              ]),
              _vm._v(" "),
              _c(
                "tabs",
                { staticClass: "tabs-default primary" },
                [
                  _c(
                    "tab",
                    { attrs: { id: "basic-primary-home", name: "Home" } },
                    [
                      _vm._v(
                        "\n              This is the content of the first tab\n              "
                      ),
                      _c("p", [
                        _vm._v(
                          "\n                Lorem ipsum dolor, sit amet consectetur adipisicing elit.\n              "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    { attrs: { id: "basic-primary-profile", name: "Profile" } },
                    [
                      _vm._v(
                        "\n              This is the content of the second tab\n              Lorem ipsum dolor sit, amet consectetur adipisicing elit.\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    { attrs: { id: "basic-primary-message", name: "Message" } },
                    [
                      _vm._v(
                        "\n              The fragment that is appended to the url can be customized\n              "
                      ),
                      _c("p", [
                        _vm._v(
                          "\n                Lorem ipsum dolor sit amet consectetur adipisicing elit.\n              "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    { attrs: { id: "basic-primary-setting", name: "Setting" } },
                    [
                      _vm._v(
                        "\n              A prefix and a suffix can be added\n              "
                      ),
                      _c("p", [
                        _vm._v(
                          "\n                Lorem ipsum dolor, sit amet consectetur adipisicing elit.\n              "
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-6 mb-4" },
            [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("VERTICAL")
              ]),
              _vm._v(" "),
              _c(
                "tabs",
                { staticClass: "tabs-vertical" },
                [
                  _c(
                    "tab",
                    { attrs: { id: "basic-vertical-home", name: "Home" } },
                    [
                      _vm._v(
                        "\n              This is the content of the first tab\n              "
                      ),
                      _c("p", [
                        _vm._v(
                          "\n                Lorem ipsum dolor, sit amet consectetur adipisicing elit.\n              "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    {
                      attrs: { id: "basic-vertical-profile", name: "Profile" }
                    },
                    [
                      _vm._v(
                        "\n              This is the content of the second tab\n              "
                      ),
                      _c("p", [
                        _vm._v(
                          "\n                Lorem ipsum dolor sit, amet consectetur adipisicing elit.\n              "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    {
                      attrs: { id: "basic-vertical-message", name: "Message" }
                    },
                    [
                      _vm._v(
                        "\n              The fragment that is appended to the url can be customized\n              "
                      ),
                      _c("p", [
                        _vm._v(
                          "\n                Lorem ipsum dolor sit amet consectetur adipisicing elit.\n              "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    {
                      attrs: { id: "basic-vertical-setting", name: "Setting" }
                    },
                    [
                      _vm._v(
                        "\n              A prefix and a suffix can be added\n              "
                      ),
                      _c("p", [
                        _vm._v(
                          "\n                Lorem ipsum dolor, sit amet consectetur adipisicing elit.\n              "
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-lg-6 mb-4" },
            [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("vertical primary color")
              ]),
              _vm._v(" "),
              _c(
                "tabs",
                { staticClass: "tabs-vertical primary" },
                [
                  _c(
                    "tab",
                    { attrs: { id: "vertical-primary-home", name: "Home" } },
                    [
                      _vm._v(
                        "\n              This is the content of the first tab\n              "
                      ),
                      _c("p", [
                        _vm._v(
                          "\n                Lorem ipsum dolor, sit amet consectetur adipisicing elit.\n              "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    {
                      attrs: { id: "vertical-primary-Profile", name: "Profile" }
                    },
                    [
                      _vm._v(
                        "\n              This is the content of the second tab\n              "
                      ),
                      _c("p", [
                        _vm._v(
                          "\n                Lorem ipsum dolor sit, amet consectetur adipisicing elit.\n              "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    {
                      attrs: { id: "vertical-primary-message", name: "Message" }
                    },
                    [
                      _vm._v(
                        "\n              The fragment that is appended to the url can be customized\n              "
                      ),
                      _c("p", [
                        _vm._v(
                          "\n                Lorem ipsum dolor sit amet consectetur adipisicing elit.\n              "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    {
                      attrs: { id: "vertical-primary-setting", name: "Fourth" }
                    },
                    [
                      _vm._v(
                        "\n              A prefix and a suffix can be added\n              "
                      ),
                      _c("p", [
                        _vm._v(
                          "\n                Lorem ipsum dolor, sit amet consectetur adipisicing elit.\n              "
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card" }, [
      _vm._m(2),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-xl-6 mb-4" },
            [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("BASIC")
              ]),
              _vm._v(" "),
              _c(
                "tabs",
                { staticClass: "tabs-simple" },
                [
                  _c("tab", { attrs: { id: "simple-home", name: "Home" } }, [
                    _vm._v(
                      "\n              This is the content of the first tab\n              "
                    ),
                    _c("p", [
                      _vm._v(
                        "\n                Lorem ipsum dolor, sit amet consectetur adipisicing elit.\n              "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tab",
                    { attrs: { id: "simple-profile", name: "Profile" } },
                    [
                      _vm._v(
                        "\n              This is the content of the second tab\n              "
                      ),
                      _c("p", [
                        _vm._v(
                          "\n                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui hic corporis aspernatur non.\n              "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    { attrs: { id: "simple-message", name: "Message" } },
                    [
                      _vm._v(
                        "\n              The fragment that is appended to the url can be customized\n              "
                      ),
                      _c("p", [
                        _vm._v(
                          "\n                Lorem ipsum dolor sit amet consectetur adipisicing elit.\n              "
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    { attrs: { id: "simple-setting", name: "Setting" } },
                    [
                      _vm._v(
                        "\n              A prefix and a suffix can be added\n              "
                      ),
                      _c("p", [
                        _vm._v(
                          "\n                Lorem ipsum dolor, sit amet consectetur adipisicing elit.\n              "
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-xl-6 mb-4" },
            [
              _c("h5", { staticClass: "section-semi-title" }, [
                _vm._v("VERTICAL")
              ]),
              _vm._v(" "),
              _c(
                "tabs",
                { staticClass: "tabs-simple-vertical" },
                [
                  _c("tab", { attrs: { name: "Home" } }, [
                    _vm._v(
                      "\n              This is the content of the first tab\n              "
                    ),
                    _c("p", [
                      _vm._v(
                        "\n                Lorem ipsum dolor, sit amet consectetur adipisicing elit.\n              "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tab", { attrs: { name: "Profile" } }, [
                    _vm._v(
                      "\n              This is the content of the second tab\n              "
                    ),
                    _c("p", [
                      _vm._v(
                        "\n                Lorem ipsum dolor sit, amet consectetur adipisicing elit.\n              "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tab", { attrs: { id: "hello-mark", name: "Message" } }, [
                    _vm._v(
                      "\n              The fragment that is appended to the url can be customized\n              "
                    ),
                    _c("p", [
                      _vm._v(
                        "\n                Lorem ipsum dolor sit amet consectetur adipisicing elit.\n              "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tab", { attrs: { name: "Setting" } }, [
                    _vm._v(
                      "\n              A prefix and a suffix can be added\n              "
                    ),
                    _c("p", [
                      _vm._v(
                        "\n                Lorem ipsum dolor, sit amet consectetur adipisicing elit.\n              "
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-header" }, [
      _c("h3", { staticClass: "page-title" }, [_vm._v("Vue Tabs")]),
      _vm._v(" "),
      _c("ol", { staticClass: "breadcrumb" }, [
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Home")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("a", { attrs: { href: "#" } }, [_vm._v("Components")])
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "breadcrumb-item active" }, [
          _vm._v("Vue Tabs")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [_vm._v("Tabs Default")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h6", [_vm._v("Tabs Simple")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }